/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

var namespace = 'quota_mixin';

module.exports = quotaPlugin;

function quotaPlugin() {
	var quotaStorage = this.createStore(this.storage, null, this._namespacePrefix + namespace)
	this.quota = 4000000;

	return {
		set: quota_set,
		remove: quota_remove,
		setQuota: setQuota
	}

	function quota_set(super_fn, key, val) {
		if (!this.hasNamespace(namespace)) {
			let s = val.length;
			let enoughSpace = false;

			while (!enoughSpace) {
				let _s = quotaStorage.get(key);
				if (typeof _s === 'undefined')
					_s = 0;

				let q = quotaStorage.get("__Size");
				if (typeof q === 'undefined')
					q = 0;

				q -= _s;
				q = Math.max(q, 0);
				q += s;

				if (q < this.quota) {
					quotaStorage.set(key, s);
					quotaStorage.set("__Size", q);
					enoughSpace = true;
				}
				else {
					if (!this.removeOldestKey()) {
						throw new Error("Can't free enough space in cache for the item. Item size is " + s);
					}
				}
			}
		}
		try {
			return super_fn();
		} catch (e) {
			if (!this.hasNamespace(namespace)) {
				let _s = quotaStorage.get(key);
				if (typeof _s === 'undefined')
					_s = 0;

				let q = quotaStorage.get("__Size");
				if (typeof q === 'undefined')
					q = 0;

				q -= _s;
				q = Math.max(q, 0);
				quotaStorage.set("__Size", q);

				quotaStorage.remove(key);
			}

			throw e;
		}
	}

	function quota_remove(super_fn, key) {
		if (!this.hasNamespace(namespace)) {
			let _s = quotaStorage.get(key);
			if (typeof _s === 'undefined')
				_s = 0;

			let q = quotaStorage.get("__Size");
			if (typeof q === 'undefined')
				q = 0;

			q -= _s;
			q = Math.max(q, 0);
			quotaStorage.set("__Size", q);

			quotaStorage.remove(key);
		}
		return super_fn();
	}

	function setQuota(_, quota) {
		this.quota = quota;
	}
}