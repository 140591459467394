/********************************************************************************
*
* (c) 2021 - Gehring Technologies GmbH
*
* This is a class to fetch data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import UniqueIDGenerator from './UniqueIDGenerator.js';
import FetchHelper from "./FetchHelper.js";
import IsNullOrUndefined from "./Utility.js";
import AbortException from '../Exceptions/AbortException.js';

let instance;
export default class FetchCache {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.keyGen = new UniqueIDGenerator();
		this.data = new Map();

		this.sendRequests = this.sendRequests.bind(this);
		this.addPendingRequest = this.addPendingRequest.bind(this);
	}
	
	sendRequests() {
		this.data.forEach((e, k) => {
			if (!IsNullOrUndefined(e.abortController)) {
				e.abortController.signal.removeEventListener('abort', e.handler);
			}

			setTimeout(() => {
				if (!IsNullOrUndefined(e.abortController)) {
					if (!e.abortController.signal.aborted)
						FetchHelper.resolvableSecuredFetch(e.resolve, e.reject, e.url, e.data, e.abortController, e.allowAnonymous, true);
					else
						e.reject(new AbortException());
				}
				else
					FetchHelper.resolvableSecuredFetch(e.resolve, e.reject, e.url, e.data, e.abortController, e.allowAnonymous, true);
			}, 0);

			this.keyGen.release(k);
		});

		this.data.clear();
	}

	addPendingRequest(url, data, abortController, allowAnonymous, resolve, reject) {
		const key = this.keyGen.generate();
		const handler = () => {
			if (!IsNullOrUndefined(abortController))
				abortController.signal.removeEventListener('abort', handler);
			this.data.delete(key);
			this.keyGen.release(key);

			// Inform the outer world
			reject(new AbortException());
		};

		if (!IsNullOrUndefined(abortController)) {
			abortController.signal.addEventListener('abort', handler);
		}

		this.data.set(key, {
			url: url,
			data: data,
			abortController: abortController,
			allowAnonymous: allowAnonymous,
			resolve: resolve,
			reject: reject,
			handler: handler
		});
	}
}