/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple retry function if a lazy part could not beeing loaded. This may
* have different reasons. Popular are offline or server code changes
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import Cache from "./Cache";
import ConnectionStatusManager from "./ConnectionStatusManager";
import IsNullOrUndefined from "./Utility";
import settings from 'Settings';

const timeoutHandler = (componentImport, resolve, reject, name) => {
    const offline = (new ConnectionStatusManager()).getConnectionStatus() === ConnectionStatusManager.STATUS.OFFLINE;
    if (offline) {
        // Wait for another task
        setTimeout(() => timeoutHandler(componentImport, resolve, reject, name), settings.lazyRetryTime);
    }
    else {
        // Try to load the component
        componentImport().then((component) => {
            (new Cache()).remove(`retry-${name}-refreshed`);
            resolve(component);
        }).catch((error) => {
            // Failed to load the component (Again)
            let hasRefreshed = (new Cache()).get(`retry-${name}-refreshed`);
            if (IsNullOrUndefined(hasRefreshed))
                hasRefreshed = false;

            if (!hasRefreshed) {
                (new Cache()).set(`retry-${name}-refreshed`, true, new Date().getTime() + 100000000);
                window.location.reload();
            }
            else 
                reject(error);
        });

    }
}

export default function lazyRetry(componentImport, name) {
    return new Promise((resolve, reject) => {
        // try to import the component
        componentImport().then((component) => {
            (new Cache()).remove(`retry-${name}-refreshed`);
            resolve(component);
        }).catch((error) => {
            // Failed to load the component

            // Inform that we are offline. This triggers a online check
            (new ConnectionStatusManager()).setConnectionStatus(ConnectionStatusManager.STATUS.OFFLINE);

            // Try to reload if not offline. Try this for two times then give up.
            setTimeout(() => timeoutHandler(componentImport, resolve, reject, name), settings.lazyRetryShortTime);
        });
    });
}