import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import settings from 'Settings';

let reactPlugin = null;
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: settings.ApplicationInsights,
            maxBatchInterval: 10000,
            extensions: [reactPlugin],
            extensionConfig: {},
            enableAutoRouteTracking: true,
            disableFetchTracking: false,
            disableAjaxTracking: false,
            autoTrackPageVisitTime: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true
        }
    });

    appInsights.loadAppInsights();

    return { reactPlugin, appInsights };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;