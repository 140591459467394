/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert from the metric unit system to the user unit system
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import IsNullOrUndefined from '../Misc/Utility.js';
import configureMeasurements from 'convert-units';
import temperature from './UnitSystem/convert-units/temperature';

var instance;
export default class TemperatureUnitSystemConverter {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.convert = configureMeasurements({ temperature });

		// UnitSystem
		this.unitSystemDefinition = "celsius";
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	setDefaultUnitSystem() {
		this.setUnitSystem("celsius");
	}

	// Set the UnitSystem to use. Possible values are "metric" and "imperial"
	setUnitSystem(_unitSystem) {
		const unitSystem = _unitSystem.toLowerCase();

		switch (unitSystem) {
			case 'kelvin':
				this.unitSystemDefinition = "kelvin";
				break;
			case 'celsius':
				this.unitSystemDefinition = "celsius";
				break;
			case 'fahrenheit':
				this.unitSystemDefinition = "fahrenheit";
				break;
			case 'rankine':
				this.unitSystemDefinition = "rankine";
				break;
			default:
				this.unitSystemDefinition = "kelvin";
				if (process.env.NODE_ENV === 'development') {
					console.warn("UnitSystem '" + unitSystem + "' is invalid. Using default.");
				}
				break;
		}
	}
	
	/** Format the seconds to the selected unit system (Best choice)
	 * @param value The Value to use
	 * @param targetUnit The target unit system
	 * @param sourceUnit The source unit system. Defaults to "K"
	 * @return { value, unit }
	 */
	format(value, targetUnit, sourceUnit) {
		let source = "K";
		if (!IsNullOrUndefined(sourceUnit))
			source = sourceUnit;

		if (!IsNullOrUndefined(targetUnit)) {
			const r = this.convert(value).from(source).to(targetUnit);
			return { value: r, unit: targetUnit };
		}

		if (this.unitSystemDefinition === "kelvin") {
			// toBest will stay in the same system. So convert to the system first
			const r = this.convert(value).from(source).to("K");
			return { value: r, unit: "K" };
		}
		else if (this.unitSystemDefinition === "celsius") {
			// toBest will stay in the same system. So convert to the system first
			const r = this.convert(value).from(source).to("C");
			return { value: r, unit: "C" };
		}
		else if (this.unitSystemDefinition === "fahrenheit") {
			// toBest will stay in the same system. So convert to the system first
			const r = this.convert(value).from(source).to("F");
			return { value: r, unit: "F" };
		}
		else if (this.unitSystemDefinition === "rankine") {
			// toBest will stay in the same system. So convert to the system first
			const r = this.convert(value).from(source).to("R");
			return { value: r, unit: "R" };
		}
	}

	/**
	 * Get the default unit
	 */
	getDefaultUnit() {
		if (this.unitSystemDefinition === "kelvin") {
			return "K";
		}
		else if (this.unitSystemDefinition === "celsius") {
			return "C";
		}
		else if (this.unitSystemDefinition === "fahrenheit") {
			return "F";
		}
		else if (this.unitSystemDefinition === "rankine") {
			return "R";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 */
	humanizeShortUnit(unit) {
		switch (unit) {
			// temperature ///////////
			// Metric
			case "mK":
				return "mK";
			case "K":
				return "K";
			case "C":
				return "°C";
			// Imperial
			case "F":
				return "°F";
			case "R":
				return "°R";

			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 * @param singular Get the singular unit
	 */
	humanizeUnit(unit, singular) {
		switch (unit) {
			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}
}