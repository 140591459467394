/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { Container } from 'react-bootstrap'
import Header from './Header';
import Footer from './Footer';
import MainNav from './MainNav';
import CurrentUser from "../../../BusinessLogic/DataManagement/CurrentUser.js";
import SearchDialog from './SearchDialog';
import Scrollbar from "../../../Components/Util/Scrollbar.js";
import GlobalAppData from "GlobalAppData"
import ConnectionStatusManager from "../../../BusinessLogic/Misc/ConnectionStatusManager.js";
import ErrorManager from "../../../BusinessLogic/Misc/ErrorManager.js";

export default class Layout extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.handleNavigationCollapse = this.handleNavigationCollapse.bind(this);
		this.handleNavigationShow = this.handleNavigationShow.bind(this);
		this.showSearch = this.showSearch.bind(this);
		this.hideSearch = this.hideSearch.bind(this);
		this.search = this.search.bind(this);

		this.userKey = null;
		this.connectionStatusKey = null;

		this.state = {
			showHeader: true,
			showFooter: true,
			navigationToggleMode: MainNav.NAVIGATIONTOGGLEMODE.auto,
			navigationCollapsed: false,
			showSearch: false,
			offline: true,
			firstOfflineChange: true
		};
	}
	static defaultProps = {
		navigationState: ""
	}

	handleNavigationCollapse() {
		this.setState({ navigationCollapsed: true });
	}

	handleNavigationShow() {
		this.setState({ navigationCollapsed: false });
	}

	showSearch() {
		this.setState({ showSearch: true })
	}

	hideSearch() {
		this.setState({ showSearch: false })
	}

	search(text) {
		// TODO
	}

	componentDidMount() {
		// Get the user data
		this.userKey = (new CurrentUser()).register((loaded, error) => {
			let navigationToggleMode = MainNav.NAVIGATIONTOGGLEMODE.auto;
			switch ((new CurrentUser()).getNavigationToggleMode()) {
				case "HIDDEN":
					navigationToggleMode = MainNav.NAVIGATIONTOGGLEMODE.hidden;
					break;
				case "VISIBLE":
					navigationToggleMode = MainNav.NAVIGATIONTOGGLEMODE.visible;
					break;
				case "AUTO":
				default:
					navigationToggleMode = MainNav.NAVIGATIONTOGGLEMODE.auto;
					break;
			}

			this.setState({
				// Settings
				navigationToggleMode: navigationToggleMode,
				showFooter: (new CurrentUser()).isFooterVisible(),
				showHeader: (new CurrentUser()).isHeaderVisible()
			});
		});

		this.connectionStatusKey = (new ConnectionStatusManager()).register((status) => {
			this.setState((prevState, props) => {
				const offline = (new ConnectionStatusManager()).getStatus() === ConnectionStatusManager.STATUS.OFFLINE;
				if (offline === prevState.offline)
					return {};

				if (!prevState.firstOfflineChange) {
					if (offline)
						(new ErrorManager()).connectionLostError();
					else
						(new ErrorManager()).connectionEstablished();
				}

				return {
					offline: offline,
					firstOfflineChange: false
				};
			});
		});
	}

	componentWillUnmount() {
		// Remove the User
		if (this.userKey !== null) {
			(new CurrentUser()).unregister(this.userKey);
			this.userKey = null;
		}

		if (this.connectionStatusKey !== null) {
			(new ConnectionStatusManager()).unregister(this.connectionStatusKey);
			this.connectionStatusKey = null;
		}
	}

	render() {
		const navToggled = this.state.navigationCollapsed ? "toggled" : "";

		let navToggleMode = "togglemode-auto";
		if (this.state.navigationToggleMode == MainNav.NAVIGATIONTOGGLEMODE.auto)
			navToggleMode = "togglemode-auto";
		else if (this.state.navigationToggleMode == MainNav.NAVIGATIONTOGGLEMODE.hidden)
			navToggleMode = "togglemode-hidden";
		else if (this.state.navigationToggleMode == MainNav.NAVIGATIONTOGGLEMODE.visible)
			navToggleMode = "togglemode-visible";

		return (
			<>
				<Container fluid className="vw-100 vh-100 m-0 p-0">
					{/* ------------- HEADER ------------- */}
					{this.state.showHeader && !new GlobalAppData().isWebView() &&
						<Header
							navigationCollapsed={this.state.navigationCollapsed}
							navigationToggleMode={this.state.navigationToggleMode} />
					}

					<div className="w-100 p-main">
						{/* ------------- OFFLINE ------------- */}
						{!new GlobalAppData().isWebView() && (
							<div className={"p-offline " + (this.state.offline ? "" : "d-none")}>
							</div>
						)}

						{!new GlobalAppData().isWebView() && (
							<div className={"p-main__mainnavtoggler " + navToggled + " " + navToggleMode}>
								{/*Toggle Navigation links*/}
								<button className="navbar-toggler p-0" onClick={this.handleNavigationCollapse}>
									<span className="navbar-toggler-icon--black bg-sprite mainnav__navicon"></span>
								</button>
							</div>
						)}
						
						<div className="vw-100 vh-100 d-flex overflow-hidden">
							{/* ------------- MAIN NAVIGATION ------------- */}
							{!new GlobalAppData().isWebView() && (
								<MainNav
									navigationCollapsed={this.state.navigationCollapsed}
									navigationToggleMode={this.state.navigationToggleMode}
									navState={this.props.navigationState}
									search={this.search}
									onNavigationCollapse={this.handleNavigationCollapse}
									onNavigationShow={this.handleNavigationShow}
									onShowSearch={this.showSearch}
								/>)
							}

							{/* ------------- CONTENT ------------- */}
							<div className={"h-100 p-maincontent " + (this.state.showHeader && !new GlobalAppData().isWebView() ? "" : "pt-0 ")} >
								<Scrollbar
									enableAutoHide={true}
									height={"calc(100% + 2px)"}>
									<div className={"w-100 p-maincontent--bg " + (this.state.showHeader && !new GlobalAppData().isWebView() ? "" : "h-100")} >
										<div className={"w-100 p-maincontent__inner p-maincontent--padding-x p-maincontent--padding-y d-flex flex-column " + (this.state.showHeader ? "" : "p-maincontent__inner--noheader")}>
											{/* Page */}
											{this.props.children}
										</div>

										{/* ------------- FOOTER------------- */}
										{this.state.showFooter && !new GlobalAppData().isWebView() &&
											<Footer />
										}
									</div>
								</Scrollbar>
							</div>
						</div>
					</div>
				</Container>

				<SearchDialog
					show={this.state.showSearch}
					onHide={this.hideSearch}
					search={this.search} />
			</>
		);
	}
}