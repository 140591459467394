/********************************************************************************
*
* (c) 2021 - Gehring Technologies GmbH
*
* This is the language management
*
* @author: Julian Saile (Julian.Saile@gehring.de)
*
*********************************************************************************/
import GlobalAppData from "GlobalAppData";
import CurrentUser from "../DataManagement/CurrentUser"
import DateFormat from '../Formater/DateFormat.js';
import DurationFormat from '../Formater/DurationFormat.js';
import NumberFormat from '../Formater/NumberFormat.js';
import Localizer from './Localizer';

let instance;
export default class LanguageManager {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.userKey = null;
		this.key = null;

		this.destroy = this.destroy.bind(this);

		if ((new GlobalAppData()).isWebView()) {
			// use the phone language in webview
			this.key = (new GlobalAppData()).registerLanguage((language) => {
				(new Localizer()).setLanguage(language);
				(new NumberFormat()).setLanguage(language);
				(new DurationFormat()).setLanguage(language);
				(new DateFormat()).setLanguage(language);
			})
		}
		else {
			this.userKey = (new CurrentUser()).register((loaded, error) => {
				if (loaded && !error) {
					// use the user setting
					(new Localizer()).setLanguage((new CurrentUser()).getLanguage());
					(new NumberFormat()).setLanguage((new CurrentUser()).getLanguage());
					(new DurationFormat()).setLanguage((new CurrentUser()).getLanguage());
					(new DateFormat()).setLanguage((new CurrentUser()).getLanguage());
				} 
				else {
					(new Localizer()).setDefaultLanguage();
					(new NumberFormat()).setDefaultLanguage();
					(new DurationFormat()).setDefaultLanguage();
					(new DateFormat()).setDefaultLanguage();
				}
			});
		}
	}

	// Destroy the singelton
	destroy() {
		if (this.key !== null) {
			(new GlobalAppData()).unregister(this.key);
			this.key = null;
		}
		if (this.userKey !== null) {
			(new CurrentUser()).unregister(this.key);
			this.userKey = null;
        }
		instance = null;
	}
}