/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert from the metric unit system to the user unit system
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import IsNullOrUndefined from '../Misc/Utility.js';
import configureMeasurements from 'convert-units';
import massFlowRate from './UnitSystem/convert-units/massFlowRate';

var instance;
export default class MassFlowRateUnitSystemConverter {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.convert = configureMeasurements({ massFlowRate });

		// UnitSystem
		this.unitSystemDefinition = "metric_per_second";
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	setDefaultUnitSystem() {
		this.setUnitSystem("metric_per_second");
	}

	// Set the UnitSystem to use. Possible values are "metric" and "imperial"
	setUnitSystem(_unitSystem) {
		const unitSystem = _unitSystem.toLowerCase();

		switch (unitSystem) {
			case 'metric_per_second':
				this.unitSystemDefinition = "metric_per_second";
				break;
			default:
				this.unitSystemDefinition = "metric_per_second";
				if (process.env.NODE_ENV === 'development') {
					console.warn("UnitSystem '" + unitSystem + "' is invalid. Using default.");
				}
				break;
		}
	}

	/** Format the seconds to the selected unit system (Best choice)
	 * @param value The Value to use
	 * @param targetUnit The target unit system
	 * @param sourceUnit The source unit system. Defaults to "g/s"
	 * @return { value, unit }
	 */
	format(value, targetUnit, sourceUnit) {
		if (IsNullOrUndefined(sourceUnit))
			sourceUnit = "g/s";

		switch (this.unitSystemDefinition) {
			case "metric_per_second":
			default: {
				if (typeof targetUnit === 'undefined') {
					// This will stay in the same system. So convert to the system first
					let r = this.convert(this.convert(value).from(sourceUnit).to("g/s")).from("g/s").toBest({ exclude: ['kg/h', 'mt/h'] });
					if (IsNullOrUndefined(r))
						r = this.convert(this.convert(value).from(sourceUnit).to("g/s")).from("g/s").toBest({ cutOffNumber: 0 });
					return { value: r.val, unit: r.unit };
				}
				else {
					// This will stay in the same system. So convert to the system first
					const r = this.convert(this.convert(value).from(sourceUnit).to("g/s")).from("g/s").to(targetUnit);
					return { value: r, unit: targetUnit };
				}
			}
		}
	}

	/**
	 * Get the default unit
	 */
	getDefaultUnit() {
		return "g/s";
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 */
	humanizeShortUnit(unit) {
		switch (unit) {
			// Metric
			case "ng/s":
				return "ng/s";
			case "μg/s":
				return "µg/s";
			case "mg/s":
				return "mg/s";
			case "g/s":
				return "g/s";
			case "kg/s":
				return "kg/s";
			case "Mg/s":
				return "Mg/s";
			case "kg/h":
				return "kg/h";
			case "mt/h":
				return "mt/h";

			// Imperial
			case "lb/s":
				return "lb/s";
			case "lb/h":
				return "lb/h";


			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 * @param singular Get the singular unit
	 */
	humanizeUnit(unit, singular) {
		switch (unit) {
			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}
}