/********************************************************************************
*
* (c) 2021 - Gehring Technologies GmbH
*
* This is a class where global app data is stored
*
* @author: Julian Saile (Julian.Saile@gehring.de)
*
*********************************************************************************/
import jwt_decode from "jwt-decode";
import IsNullOrUndefined from "./Utility";
import { IsNullOrUndefinedOrWhitespace } from "./Utility";
import UniqueIDGenerator from './UniqueIDGenerator.js';
import invoke from 'react-native-webview-invoke/browser';

let instance;
export default class GlobalAppData {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		if (IsNullOrUndefined(window.app)) {
			window.app = {
				isApp: false,
				accessToken: null,
			}
		}

		this.keys = new Map();
		this.functions = new Map();
		this.tokenChangedFunctions = new Map();
		this.basketChangedFunctions = new Map();
		this.locationChangedFunctions = new Map();
		this.keyGen = new UniqueIDGenerator();
		this.nativeApp = false;
		this.language = "en-us";
		this.basket = {};
		this.location = "";

		this.destroy = this.destroy.bind(this);
		this.setNativeApp = this.setNativeApp.bind(this);
		this.isNativeApp = this.isNativeApp.bind(this);
		this.isWebView = this.isWebView.bind(this);
		this.getToken = this.getToken.bind(this);
		this.getSub = this.getSub.bind(this);
		this.getLanguage = this.getLanguage.bind(this);
		this.inform = this.inform.bind(this);
		this.setAccessToken = this.setAccessToken.bind(this);
		this.requestAccessToken = this.requestAccessToken.bind(this);
		this.handleTokenChanged = this.handleTokenChanged.bind(this);
		this.handleLocationChanged = this.handleLocationChanged.bind(this);
		this.unregister = this.unregister.bind(this);
		this.handleLanguageMessage = this.handleLanguageMessage.bind(this);
		this.locationChanged = this.locationChanged.bind(this);
		this.registerLocationChanged = this.registerLocationChanged.bind(this);
		this.sparePartAdded = this.sparePartAdded.bind(this);
		this.sparePartRemoved = this.sparePartRemoved.bind(this);
		this.handleBasketMessage = this.handleBasketMessage.bind(this);
		this.changedItemCount = this.changedItemCount.bind(this);
		this.itemsDeleted = this.itemsDeleted.bind(this);
		this.unregister = this.unregister.bind(this);
		this.registerTokenChanged = this.registerTokenChanged.bind(this);
		this.informTokenChanged = this.informTokenChanged.bind(this);
		this.registerBasketChanged = this.registerBasketChanged.bind(this);
		this.informBasketChanged = this.informBasketChanged.bind(this);
		this.databaseSet = this.databaseSet.bind(this);
		this.databaseGet = this.databaseGet.bind(this);
		this.databaseRemove = this.databaseRemove.bind(this);
		this.userDatabaseSet = this.userDatabaseSet.bind(this);
		this.userDatabaseGet = this.userDatabaseGet.bind(this);
		this.userDatabaseRemove = this.userDatabaseRemove.bind(this);


		if (this.isWebView()) {
			// Add the logging functionality
			const consoleLog = (type, log) => {
				(async () => {
					const console = invoke.bind('console');
					await console(type, log);
				})();
			};
			console.log = (log) => consoleLog('log', log);
			console.debug = (log) => consoleLog('debug', log);
			console.info = (log) => consoleLog('info', log);
			console.warn = (log) => consoleLog('warn', log);
			console.error = (log) => consoleLog('error', log);

			// Add event listener for new access token
			invoke.define('handleTokenChanged', (token) => { this.handleTokenChanged(token); });
			// Add event listener for location changes
			invoke.define('handleLocationChanged', (location) => { this.handleLocationChanged(location); });
			// Add event listener for language settings
			invoke.define('handleLanguageMessage', (language) => { this.handleLanguageMessage(language); });
			// Add event listener for the basket
			invoke.define('handleBasketMessage', (basket) => { this.handleBasketMessage(basket); });
		}
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance)) {
			instance.destroy();
		}
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	// Destroy the class
	destroy() {
		// remove the event listeners
		if (this.isWebView()) {
			invoke.define('handleTokenChanged', (token) => { });
			invoke.define('handleLocationChanged', (location) => { });
			invoke.define('handleLanguageMessage', (language) => { });
			invoke.define('handleBasketMessage', (basket) => { });
		}
	}

	locationChanged(location) {
		if (!this.isWebView())
			return;

		if (location === this.location)
			return;
		this.location = location;

		(async () => {
			try {
				const locationChanged = invoke.bind("locationChanged");
				if (!IsNullOrUndefined(locationChanged))
					await locationChanged(this.location);
			} catch (err) {
				// Nothing to do
			}
		})();
	}

	handleLocationChanged(location) {
		this.locationChangedFunctions.forEach((e) => {
			try {
				e(location);
			} catch (err) {
				console.error("Error during processing of registered function");
			}
		});
	}

	informTokenChanged() {
		this.tokenChangedFunctions.forEach((e) => {
			try {
				e();
			} catch (err) {
				console.error("Error during processing of registered function");
			}
		});
	}

	informBasketChanged(data) {
		this.basketChangedFunctions.forEach((e) => {
			try {
				e(data);
			} catch (err) {
				console.error("Error during processing of registered function");
			}
		});
	}

	// Register for changes of the language
	// @param func Function to be registered
	registerLanguage(func) {
		// Integrate the key
		const key = this.keyGen.generate();
		this.functions.set(key, func);

		// Inform the new function
		func(this.language);

		return key;
	}

	registerLocationChanged(func) {
		// Integrate the key
		const key = this.keyGen.generate();
		this.locationChangedFunctions.set(key, func);

		return key;
	}

	registerTokenChanged(func) {
		// Integrate the key
		const key = this.keyGen.generate();
		this.tokenChangedFunctions.set(key, func);

		return key;
	}

	registerBasketChanged(func) {
		// Integrate the key
		const key = this.keyGen.generate();
		this.basketChangedFunctions.set(key, func);

		func(this.basket);

		return key;
	}

	unregister(key) {
		// Free the key
		this.functions.delete(key);
		this.basketChangedFunctions.delete(key);
		this.tokenChangedFunctions.delete(key);
		this.locationChangedFunctions.delete(key);
		this.keyGen.release(key);
	}

	handleTokenChanged(token) {
		this.setAccessToken(token);
	}

	// Set the mobile native app
	setNativeApp(mobileApp) {
		this.nativeApp = mobileApp;
	}

	isNativeApp() {
		return this.nativeApp;
	}

	// Request a new access token
	requestAccessToken() {
		if (!this.isWebView())
			return;

		this.setAccessToken(null);

		(async () => {
			const requestAccessToken = invoke.bind("requestAccessToken");
			if (!IsNullOrUndefined(requestAccessToken))
				await requestAccessToken();
		})();
	}

	// Inform the App about a new item in the basket
	// @param item The item that should be added
	sparePartAdded(item, count, machineID) {
		if (!this.isWebView())
			return;

		(async () => {
			try {
				const sparePartAdded = invoke.bind("sparePartAdded");
				if (!IsNullOrUndefined(sparePartAdded))
					await sparePartAdded(item, count, machineID);
			} catch (err) {
				// Nothing to do
			}
		})();
	}

	// Inform the App about a change in the basket
	// @param item The item whose quantity is to be set
	sparePartRemoved(item) {
		if (!this.isWebView())
			return;

		(async () => {
			try {
				const sparePartRemoved = invoke.bind("sparePartRemoved");
				if (!IsNullOrUndefined(sparePartRemoved))
					await sparePartRemoved(item);
			} catch (err) {
				// Nothing to do
			}
		})();
	}

	// Inform the App about a change in the basket
	// @param item The item where to set the count
	changedItemCount(item, count) {
		if (!this.isWebView())
			return;

		(async () => {
			try {
				const sparePartChangeCount = invoke.bind("sparePartChangeCount");
				if (!IsNullOrUndefined(sparePartChangeCount))
					await sparePartChangeCount(item, count);
			} catch (err) {
				// Nothing to do
			}
		})();
	}

	// Clear the shopping cart
	itemsDeleted() {
		if (!this.isWebView())
			return;

		(async () => {
			try {
				const sparePartClear = invoke.bind("sparePartClear");
				if (!IsNullOrUndefined(sparePartClear))
						await sparePartClear();
			} catch (err) {
				// Nothing to do
			}
		})();
	}

	// This is called by the mobile app to set the items in the shopping cart
	// @param event The event data from the callback function
	// @note Every time the add, setCount oder remove method is called 
	// this event will call the setSmartphoneBasket method to set the 
	// shopping cart in the webview
	handleBasketMessage(basket) {
		const data = JSON.parse(basket);
		this.basket = data;
		this.informBasketChanged(data);
	}

	// Set the phone language
	// @param event The event data from the callback function
	handleLanguageMessage(language) {
		if (language === "de_DE") {
			this.language = "de-de";
		}
		else if (language === "zh_CN") {
			this.language = "zh-cn";
		}
		else {
			this.language = "en-us";
		}
		this.inform();
	}

	// Inform the registered functions
	inform() {
		this.functions.forEach((e) => {
			try {
				e(this.language);
			} catch (err) {
				console.error("Error during processing of registered function");
			}
		});
	}

	getLanguage() {
		return this.language;
	}

	// Is it the WebView?
	isWebView() {
		return window.app.isApp;
	}

	// Get Bearer Token
	getToken() {
		if (this.isWebView()) {
			return window.app.accessToken;
		}
		return null;
	}

	setAccessToken(token) {
		window.app.accessToken = token;

		this.informTokenChanged();
	}

	// Get Sub from Bearer Token
	getSub() {
		if (this.isWebView()) {
			if (!IsNullOrUndefinedOrWhitespace(window.app.accessToken)) {
				const token = jwt_decode(window.app.accessToken);
				return token.sub;
			}
		}
		return null;
	}

	async databaseSet(database, key, obj, expire, apiVersion, timestamp) {
		if (!this.isWebView())
			return;

		const databaseSet = invoke.bind("databaseSet");
		if (!IsNullOrUndefined(databaseSet))
			await databaseSet(database, key, obj, expire, apiVersion, timestamp);
	}

	async databaseGet(database, key) {
		if (!this.isWebView())
			return null;

		const databaseGet = invoke.bind("databaseGet");
		if (!IsNullOrUndefined(databaseGet))
			return await databaseGet(database, key);
		return null;
	}

	async databaseRemove(database, key) {
		if (!this.isWebView())
			return;

		const databaseRemove = invoke.bind("databaseRemove");
		if (!IsNullOrUndefined(databaseRemove))
			await databaseRemove(database, key);
	}

	async userDatabaseSet(database, key, obj, expire, apiVersion, timestamp) {
		if (!this.isWebView())
			return;

		const databaseSet = invoke.bind("databaseSet");
		if (!IsNullOrUndefined(databaseSet))
			await databaseSet(database, key, obj, expire, apiVersion, timestamp);
	}

	async userDatabaseGet(database, key) {
		if (!this.isWebView())
			return null;

		const databaseGet = invoke.bind("databaseGet");
		if (!IsNullOrUndefined(databaseGet))
			return await databaseGet(database, key);
		return null;
	}

	async userDatabaseRemove(database, key) {
		if (!this.isWebView())
			return;

		const databaseRemove = invoke.bind("databaseRemove");
		if (!IsNullOrUndefined(databaseRemove))
			await databaseRemove(database, key);
	}
}