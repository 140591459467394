/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import UniqueIDGenerator from './UniqueIDGenerator.js';
import Notification from './Notification.js';
import Localizer from './Localizer.js';

let instance;
class ErrorManager {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.apiErrorSend = false;

		this.keyGen = new UniqueIDGenerator();
		this.APIIncompatibleFunctions = new Map();

		this.unregister = this.unregister.bind(this);
		this.registerAPIIncompatible = this.registerAPIIncompatible.bind(this);
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	unregister(key) {
		// Free the key
		this.APIIncompatibleFunctions.delete(key);
		this.keyGen.release(key);
	}

	registerAPIIncompatible(func) {
		// Integrate the key
		const key = this.keyGen.generate();
		this.APIIncompatibleFunctions.set(key, func);

		return key;
	}

	// General Errors
	connectionLostError() {
		let title = (new Localizer()).get("Connection lost");
		let message = (new Localizer()).get("Lost the connection to the server");
		(new Notification()).error(message, title);
	}

	connectionEstablished() {
		let title = (new Localizer()).get("Connection established");
		let message = (new Localizer()).get("Established the connection to the server");
		(new Notification()).info(message, title);
	}

	APIIncompatible() {
		if (this.apiErrorSend)
			return;
		this.apiErrorSend = true;

		let title = (new Localizer()).get("API incompatible");
		let message = (new Localizer()).get("Please reload the app to get the last version of the app");
		(new Notification()).stickyError(message, title);

		this.APIIncompatibleFunctions.forEach((v, k) => {
			try {
				v();
			} catch (err) {
				// Nothing to do
			}
		});
	}

	dataReceiveError() {
		let title = (new Localizer()).get("Connection error");
		let message = (new Localizer()).get("Failed to receive data from the server");
		(new Notification()).error(message, title);
	}

	internalError() {
		let title = (new Localizer()).get("Internal error");
		let message = (new Localizer()).get("Internal error on the server");
		(new Notification()).error(message, title);
	}

	setRightError() {
		let title = (new Localizer()).get("Set Right failed");
		let message = (new Localizer()).get("Unable to change the right for the User");
		(new Notification()).error(message, title);
	}

	setGroupRightError() {
		let title = (new Localizer()).get("Set Right failed");
		let message = (new Localizer()).get("Unable to change the right for the Group");
		(new Notification()).error(message, title);
	}

	getMachineRightError() {
		let title = (new Localizer()).get("Get Machine Right failed");
		let message = (new Localizer()).get("Unable to get the machine right for the User");
		(new Notification()).error(message, title);
	}

	setDataError() {
		let title = (new Localizer()).get("Set data failed");
		let message = (new Localizer()).get("Unable to set the data");
		(new Notification()).error(message, title);
	}
}

export default ErrorManager;