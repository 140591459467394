const metric = {
    'nS/m': {
        name: {
            singular: 'Nanosiemens per meter',
            plural: 'Nanosiemens per meter'
        },
        to_anchor: 1 / 1000000000
    },
    'mcS/m': {
        name: {
            singular: 'Microsiemens per meter',
            plural: 'Microsiemens per meter'
        },
        to_anchor: 1 / 1000000
    },
    'mS/m': {
        name: {
            singular: 'Millisiemens per meter',
            plural: 'Millisiemens per meter'
        },
        to_anchor: 1 / 1000
    },
    'S/m': {
        name: {
            singular: 'Siemens per meter',
            plural: 'Siemens per meter'
        },
        to_anchor: 1
    },
    'kS/m': {
        name: {
            singular: 'Kilosiemens per meter',
            plural: 'Kilosiemens per meter'
        },
        to_anchor: 1000
    },
    'MS/m': {
        name: {
            singular: 'Megasiemens per meter',
            plural: 'Megasiemens per meter'
        },
        to_anchor: 1000000
    },
    'GS/m': {
        name: {
            singular: 'Gigasiemens per meter',
            plural: 'Gigasiemens per meter'
        },
        to_anchor: 1000000000
    },
};
const metric_S_cm = {
    'nS/cm': {
        name: {
            singular: 'Nanosiemens per centimeter',
            plural: 'Nanosiemens per centimeter'
        },
        to_anchor: 1 / 1000000000
    },
    'mcS/cm': {
        name: {
            singular: 'Microsiemens per centimeter',
            plural: 'Microsiemens per centimeter'
        },
        to_anchor: 1 / 1000000
    },
    'mS/cm': {
        name: {
            singular: 'Millisiemens per centimeter',
            plural: 'Millisiemens per centimeter'
        },
        to_anchor: 1 / 1000
    },
    'S/cm': {
        name: {
            singular: 'Siemens per centimeter',
            plural: 'Siemens per centimeter'
        },
        to_anchor: 1
    },
    'kS/cm': {
        name: {
            singular: 'Kilosiemens per centimeter',
            plural: 'Kilosiemens per centimeter'
        },
        to_anchor: 1000
    },
    'MS/cm': {
        name: {
            singular: 'Megasiemens per centimeter',
            plural: 'Megasiemens per centimeter'
        },
        to_anchor: 1000000
    },
    'GS/cm': {
        name: {
            singular: 'Gigasiemens per centimeter',
            plural: 'Gigasiemens per centimeter'
        },
        to_anchor: 1000000000
    }
};

const measure = {
    systems: {
        metric,
        metric_S_cm,
    },
    anchors: {
        metric: {
            metric_S_cm: {
                ratio: 100,
            },
        },
        metric_S_cm: {
            metric: {
                ratio: 1/100,
            },
        },
    },
};

export default measure;