/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { Dropdown } from 'react-bootstrap';
import Localizer from '../../../BusinessLogic/Misc/Localizer';
import NotificationService from "../../../BusinessLogic/Misc/NotificationService.js";
import ConnectionStatusManager from "../../../BusinessLogic/Misc/ConnectionStatusManager.js";
import ErrorManager from "../../../BusinessLogic/Misc/ErrorManager";
import CurrentUser from "../../../BusinessLogic/DataManagement/CurrentUser";
import RequestID from "../../../BusinessLogic/Misc/RequestID";
import PreviewItem from "../../Components/Message/PreviewItem.js";
import isEqual from 'lodash/isEqual'
import Notification from '../../../BusinessLogic/Misc/Notification';
import FetchHelper from "../../../BusinessLogic/Misc/FetchHelper.js";
import Scrollbar from '../../../Components/Util/Scrollbar';
import Navigator from "../../../BusinessLogic/Misc/Navigator.js";
import IsNullOrUndefined from '../../../BusinessLogic/Misc/Utility';
import settings from 'Settings';

export default class MessagePreview extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.updateData = this.updateData.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.requestData = this.requestData.bind(this);

		this.requestID = new RequestID();
		this.abortController = new AbortController();
		this.connectionStatusKey = null;
		this.notificationKey = null;
		this.notificationChangedKey = null;
		this.updateTimeout = null;

		this.messagesInitialised = false;
		this.messageIDs = [];

		this.state = {
			data: [],
			numUnread: 0
		};
	}
	static defaultProps = {
		showOnlyNew: false,
		numItems: 10
	}

	handleClick(messageID) {
		new Navigator().redirect('/Protected/Message/Overview?messageID=' + messageID + "&showOnlyNew=" + this.props.showOnlyNew);
	}

	requestData() {
		if (IsNullOrUndefined(this.updateTimeout)) {
			this.updateTimeout = setTimeout(() => {
				this.updateTimeout = null;
				(async () => {
					await this.updateData();
				})();
			}, settings.DataReloadDelay);
		}
	}

	async updateData() {
		// Create the data
		const data = {
			requestID: this.requestID.newRequest(),
			startIndex: 0,
			count: this.props.numItems,
			sortId: "time",
			sortOrder: "desc",
			onlyNew: this.props.showOnlyNew
		};

		try {
			const json = await FetchHelper.fetchData("/api/Message/list", data, this.abortController);

			// Check request
			if (!this.requestID.validateRequest(json.requestID))
				return;

			const messageIDs = [];
			json.data.forEach((e) => {
				messageIDs.push(e.id);
			});

			// Inform notification
			if (this.messagesInitialised) {
				if (!isEqual(messageIDs, this.messageIDs)) {
					(new Notification()).info((new Localizer()).get("Received new messages"), (new Localizer()).get("New message"));
				}
			}

			this.messageIDs = messageIDs;
			this.messagesInitialised = true;

			this.setState({
				data: json.data,
				numUnread: json.totalSize
			});
		} catch (err) {
			if (err.name === "Abort")
				return;
			else if (err.name === "APIIncompatible") {
				// Added error handling
				(new ErrorManager()).APIIncompatible();
			}
			else {
				// Added error handling
				(new ErrorManager()).dataReceiveError();
			}
		}
	}

	componentDidMount() {
		// Register status change of the notification system
		this.connectionStatusKey = (new ConnectionStatusManager()).register((status) => {
			if (status === ConnectionStatusManager.STATUS.ONLINE)
				this.requestData();
		});

		// Register the notifications
		this.notificationKey = (new NotificationService()).registerMessageCreated((new CurrentUser()).getUserID(), (userID, messageID) => {
			this.requestData();
		});

		// Register the notifications
		this.notificationChangedKey = (new NotificationService()).registerMessageChanged((new CurrentUser()).getUserID(), (userID, allChanged, messageIDs) => {
			let changed = allChanged;
			messageIDs.forEach((e) => {
				if (this.messageIDs.includes(e))
					changed = true;
			});

			if (changed)
				this.requestData();
		});
	}

	componentDidUpdate(prevProps, prevState) {
		// Equal properties?
		const propsChanged = !isEqual(this.props, prevProps);
		if (!propsChanged)
			return;

		if (propsChanged) {
			this.messagesInitialised = false;
			if ((new ConnectionStatusManager()).getStatus() === ConnectionStatusManager.STATUS.ONLINE)
				this.requestData();
		}
	}

	componentWillUnmount() {
		// Remove the Notification
		if (this.notificationKey !== null) {
			(new NotificationService()).unregister(this.notificationKey);
			this.notificationKey = null;
		}

		if (this.notificationChangedKey !== null) {
			(new NotificationService()).unregister(this.notificationChangedKey);
			this.notificationChangedKey = null;
		}

		if (!IsNullOrUndefined(this.connectionStatusKey)) {
			(new ConnectionStatusManager()).unregister(this.connectionStatusKey);
			this.connectionStatusKey = null;
		}

		if (!IsNullOrUndefined(this.updateTimeout)) {
			clearTimeout(this.updateTimeout);
			this.updateTimeout = null;
		}

		// Cancel all pending requests
		this.abortController.abort();
	}

	render() {
		let items = [];
		this.state.data.forEach((e, i, a) => {
			if (i >= this.props.numItems-1)
				return;

			const item = (
				<PreviewItem
					key={e.id}
					messageID={e.id}
					selected={false}
					showUnreadMarker={false}
					onClick={() => { this.handleClick(e.id); }}
				/>
			);
			items.push(item);
		});

		return (
			<>
				<Dropdown as="li" className="nav-item">
					<Dropdown.Toggle as="button" className="nav-link">
						<span className={"p-header__dropdown-content " + (this.state.data.length > 0 ? "p-header__navbar__message--full" : "p-header__navbar__message--empty")}>
							{this.state.data.length > 0 &&
								<span>{this.state.data.length < this.props.numItems ? this.state.data.length : ((this.props.numItems-1) + "+")}</span>
							}
						</span>
					</Dropdown.Toggle>
					<Dropdown.Menu className="p-header__message--full__content">
						<Scrollbar
							enableAutoHide={true}
							maxHeight="75vh">
							<div className="d-flex align-items-center border-bottom">
								<div className="p-header__dropdown__title text-center flex-grow-1">
									<h6>{this.state.numUnread + " " + (new Localizer()).get("new messages")}</h6>
								</div>
							</div>
							<div>
								{items}
							</div>
						</Scrollbar>
					</Dropdown.Menu>
				</Dropdown>
			</>
		);
	}
}