/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This JavaScript file is used to display a Export-Button
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

import Button from "./Button";

export default class ExtNavButton extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.handleOnClick = this.handleOnClick.bind(this);
	}

	static defaultProps = {
		url: "",
		newWindow: false
	}

	static get VARIANT() {
		return Button.VARIANT;
	}

	static get ICON() {
		return Button.ICON;
	}

	static get SIZE() {
		return Button.SIZE;
	}

	handleOnClick() {
		if(this.props.newWindow)
			window.open(this.props.url, '_blank').focus();
		else
			window.open(this.props.url, '_self').focus();
	}

	render() {
		return <Button {...this.props}
			onClick={this.handleOnClick}
		/>;
	}
}