/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import { openDB } from 'idb';
import settings from 'Settings';
import IsNullOrUndefined from "./Utility.js";
import Version from "./Version.js";
import GlobalAppData from "GlobalAppData";

export default class Database {
	constructor(databaseName) {
		this.getDatabase = this.getDatabase.bind(this);
		this.set = this.set.bind(this);
		this.get = this.get.bind(this);
		this.has = this.has.bind(this);
		this.remove = this.remove.bind(this);
		this.removeExpired = this.removeExpired.bind(this);
		this.checkUpdate = this.checkUpdate.bind(this);

		this.databaseName = databaseName;
		this.db = null;

		(async () => {
			try {
				await this.removeExpired();
			} catch (e) {
				// Nothing to do
			}
		})();
	}

	async getDatabase() {
		if (this.db !== null)
			return this.db;

		let that = this;

		// Create the db
		this.db = await openDB(this.databaseName, 1, {
			upgrade(db, oldVersion, newVersion, transaction) {
				switch (oldVersion) {
					case 0:
						upgradeV0ToV1();
						break;
					case 1:
						// Up to date
						break;
					default:
						console.warn("Unknown db version");
						break;
				}

				function upgradeV0ToV1() {
					// Create a store of objects
					const store = db.createObjectStore('value', {
						// The 'key' property of the object will be the key.
						keyPath: 'key'
					});

					// Create the index for the expired time
					store.createIndex("expire", "expire", { unique: false });
				}
			},
			// Called if this is blocked by older versions
			blocked() {
				// This db is currently blocked until the db connections in other tabs are closed. After this happened the upgrade callback is called.

				// Warning: The call-sequence will be the following: open -> blocked -> upgrade -> done
				// This means, that the Database can't do any operation until this is solved
				console.warn("Opening a database was blocked. This may slow down the update of the application.");
			},
			// Called if this is blocking a future version
			blocking() {
				// We know that there is a future version of the website available. So just reload the complete website
				if (that.db !== null)
					that.db.close();
				that.db = null;
			},
			// Called if the connection is abnormally closed
			terminated() {
				if (that.db !== null)
					that.db.close();
				that.db = null;
			}
		});
		return this.db;
	}

	async removeExpired() {
		let _db = await this.getDatabase();
		const now = (new Date()).getTime();

		const keys = await _db.getAllKeysFromIndex("value", "expire", IDBKeyRange.upperBound(now))

		let k = [];
		keys.forEach((e) => {
			try {
				k.push(_db.delete('value', e));
			} catch (e) {
				// Nothing to do
			}
		});
		await Promise.all(k);
	}

	checkUpdate(obj, timestamp, apiVersion) {
		// API Version. Always the newest
		if (!IsNullOrUndefined(apiVersion) && !IsNullOrUndefined(obj.apiVersion)) {
			if (Version.fromObject(apiVersion).lessThan(Version.fromObject(obj.apiVersion)))
				return false;
			if (Version.fromObject(obj.apiVersion).lessThan(Version.fromObject(apiVersion)))
				return true;
		}

		// Modification. Always the newest
		if (!IsNullOrUndefined(timestamp) && !IsNullOrUndefined(obj.timestamp)) {
			if (timestamp < obj.timestamp)
				return false;
			if (timestamp > obj.timestamp)
				return true;
		}

		// Equal
		return true;
	}

	/**
	 * Set a key from the database or throws if it is not available
	 * @info It is possible that the result is delayed until other DB-Connections are closed
	 * @exception It is possible that this function throws exceptions. Most likely the version of the database was higher than expected
	 * @param {any} key
	 */
	async set(key, obj, expire, apiVersion, timestamp) {
		if ((new GlobalAppData()).isWebView()) {
			await (new GlobalAppData()).databaseSet(this.databaseName, key, obj, expire, apiVersion, timestamp);
		} else {
			// Remove all expired values
			try {
				await this.removeExpired();
			} catch (e) {
				// Nothing to do.
			}

			let _db = await this.getDatabase();
			const tx = _db.transaction("value", 'readwrite');

			// Check if we should update
			if (!IsNullOrUndefined(timestamp) || !IsNullOrUndefined(apiVersion)) {
				const v = await tx.store.get(key);
				if (!IsNullOrUndefined(v)) {
					if (!this.checkUpdate(v, timestamp, apiVersion))
						return;
				}
			}

			// Compute expiration time
			if (IsNullOrUndefined(expire))
				expire = settings.DefaultExpirationTime;
			const absExpire = new Date().getTime() + expire;

			// Add the new data
			await tx.store.put({
				key: key,
				expire: absExpire,
				relExpire: expire,
				timestamp: timestamp,
				apiVersion: apiVersion,
				body: obj
			});
			await tx.done;
		}
	}

	/**
	 * Get a key from the database or throws if it is not available
	 * @info It is possible that the result is delayed until other DB-Connections are closed
	 * @exception It is possible that this function throws exceptions. Most likely the version of the database was higher than expected
	 * @param {any} key
	 */
	async get(key) {
		if ((new GlobalAppData()).isWebView()) {
			return await (new GlobalAppData()).databaseGet(this.databaseName, key);
		} else {
			let _db = await this.getDatabase();
			const data = await _db.get('value', key);
			if (IsNullOrUndefined(data))
				return null;

			// Check expired data
			const now = (new Date()).getTime();
			if (data.expire < now)
				return null;

			// Update the expire
			let expire = data.relExpire;
			if (IsNullOrUndefined(expire))
				expire = settings.DefaultExpirationTime;

			if (now - (data.expire - expire) > settings.DefaultExpirationUpdateTime) {
				await _db.put('value', {
					key: key,
					expire: now + expire,
					relExpire: expire,
					timestamp: data.timestamp,
					apiVersion: data.apiVersion,
					body: data.body
				});
			}

			return data.body;
		}
	}

	/**
	 * Checks if a key exists on the database
	 * @info It is possible that the result is delayed until other DB-Connections are closed
	 * @exception It is possible that this function throws exceptions. Most likely the version of the database was higher than expected
	 * @param {any} key
	 */
	async has(key) {
		const data = await this.get(key);
		if (IsNullOrUndefined(data))
			return false;
		else
			return true;
	}

	/**
	 * Removed a key from a db
	 * @info It is possible that the result is delayed until other DB-Connections are closed
	 * @exception It is possible that this function throws exceptions. Most likely the version of the database was higher than expected
	 * @param {any} key
	 */
	async remove(key) {
		if ((new GlobalAppData()).isWebView()) {
			await (new GlobalAppData()).databaseRemove(this.databaseName, key);
		} else {
			let _db = await this.getDatabase();
			_db.delete('value', key);
		}
	}
}