/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert a number to a specific format
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import Numeral from 'numeral';
import 'numeral/locales';
import GlobalAppData from "GlobalAppData";

var instance;
class NumberFormat {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;
		
		// Language
		this.language = "en";
		this.hasLanguage = false;

		this.setDefaultLanguage = this.setDefaultLanguage.bind(this);
		this.setLanguage = this.setLanguage.bind(this);

		this.setDefaultLanguage();
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	// Set the default language
	setDefaultLanguage() {
		if (!(new GlobalAppData()).isNativeApp()) {
			const locales = navigator.language || navigator.userLanguage;
			for (var i = 0; i < locales.length; i++) {
				if (locales[i].languageCode === "en-US") {
					this.setLanguage("en-us");
					return;
				}
				if (locales[i].languageCode === "de-DE") {
					this.setLanguage("de-de");
					return;
				}
				if (locales[i].languageCode === "zh-CN") {
					this.setLanguage("zh-cn");
					return;
				}
			}
		}

		// Nothing found. go back to English
		this.setLanguage("en-us");
	}

	// Set the Language to use. The CORE-Language settings are used here
	setLanguage(language) {
		if (process.env.NODE_ENV === 'development') {
			this.hasLanguage = true;
		}

		switch (language) {
			case 'de-de':
				this.language = "de";
				this.hasLanguage = true;
				break;
			case 'en-us':
				this.language = "en";
				this.hasLanguage = true;
				break;
			case 'zh-cn':
				this.language = "chs";
				this.hasLanguage = true;
				break;
			default:				
				this.language = "en";
				this.hasLanguage = true;
					if (process.env.NODE_ENV === 'development') {
						console.warn("Language '" + language + "' is invalid. Using default.");
					}
				break;
		}
	}

	/** Create the numeral class
	 * @param value The Value to use
	 * @return The created class
	 */
	createNumeral(value) {
		const m = Numeral(value);

		if (process.env.NODE_ENV === 'development') {
			if (this.hasLanguage === false) {
				console.warn("Language not set. Using default.");
			}
		}

		Numeral.locale(this.language);

		return m;
	}
	
	/** Format a number to the current selected locale (e.g. "10.000,12434")
	 * @param value The Value to use
	 * @param minPrecision The minimum precision in decimal system
	 * @param maxPrecision The maximum precision in decimal system
	 */
	format(value, minPrecision, maxPrecision) {
		const m = this.createNumeral(value);
		if (typeof minPrecision !== 'undefined') {
			let s = "0,0";
			if (minPrecision === 0)
				s += "[.]";
			else
				s += ".";

			for (var i = 0; i < minPrecision; i++)
				s += "0";

			if (typeof maxPrecision !== 'undefined') {
				if (minPrecision !== maxPrecision)
					s += "[";
				for (var z = minPrecision; z < maxPrecision; z++)
					s += "0";
				if (minPrecision !== maxPrecision)
					s += "]";
			}

			return m.format(s);
		}
		else {
			return m.format();
		}
	}

	/** Format a number to the current selected locale (e.g. "10,32%")
	 * @param value The Value to use
	 * @param minPrecision The minimum precision in decimal system
	 * @param maxPrecision The maximum precision in decimal system
	 */
	formatPercent(value, minPrecision, maxPrecision) {
		const m = this.createNumeral(value);
		if (typeof minPrecision !== 'undefined') {
			let s = "(0,0";
			if (minPrecision === 0)
				s += "[.]";
			else
				s += ".";

			for (var i = 0; i < minPrecision; i++)
				s += "0";

			if (typeof maxPrecision !== 'undefined') {
				if (minPrecision !== maxPrecision)
					s += "[";
				for (var z = minPrecision; z < maxPrecision; z++)
					s += "0";
				if (minPrecision !== maxPrecision)
					s += "]";
			}
			s += "%)";

			return m.format(s);
		}
		else {
			return m.format("0%");
		}
	}

	/** Format a number to the current selected locale (e.g. "7.88 kB")
	 * @param value The Value to use
	 * @param minPrecision The minimum precision in decimal system
	 * @param maxPrecision The maximum precision in decimal system
	 */
	formatByte(value, minPrecision, maxPrecision) {
		const m = this.createNumeral(value);
		if (typeof minPrecision !== 'undefined') {
			let s = "(0,0";
			if (minPrecision === 0)
				s += "[.]";
			else
				s += ".";

			for (var i = 0; i < minPrecision; i++)
				s += "0";

			if (typeof maxPrecision !== 'undefined') {
				if (minPrecision !== maxPrecision)
					s += "[";
				for (var z = minPrecision; z < maxPrecision; z++)
					s += "0";
				if (minPrecision !== maxPrecision)
					s += "]";
			}
			s += "b)";

			return m.format(s);
		}
		else {
			return m.format("0b");
		}
	}

	/** Format a number to be shown as Currency (e.g. "7.88 €")
	 * @param value The Value to use
	 */
	formatCurrency(value, currency) {
		if (currency === "EURO") {
			return this.format(value, 2) + " €";
		}
		else {
			console.warn("Unknown currency: " + currency);
			return this.format(value, 2) + " ?";
		}
	}
}

export default NumberFormat;