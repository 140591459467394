/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { ReactComponent as Logo } from '../../../images/gehring-core-full-logo.svg';
import { ReactComponent as DefaultUserImage } from '../../../images/DefaultUser.svg';
import { Link } from "react-router-dom";
import CurrentUser from "../../../BusinessLogic/DataManagement/CurrentUser.js";
import Localizer from '../../../BusinessLogic/Misc/Localizer'
import Rights from "../../../BusinessLogic/Misc/Rights.js";
import IsNullOrUndefined from "../../../BusinessLogic/Misc/Utility.js";
import { ApplicationPaths } from "../../../Authorization/ApiAuthorizationConstants";
import ShoppingCart from "./ShoppingCart.js";
import MessagePreview from "./MessagePreview.js";
import NotificationPreview from "./NotificationPreview.js";
import MenuToggleButton from "./MenuToggleButton.js";
import MainNav from "./MainNav.js";

export default class Header extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.toggleHeaderMenu = this.toggleHeaderMenu.bind(this);
		this.handleEvent = this.handleEvent.bind(this);
		this.toggleDropdown = this.toggleDropdown.bind(this);
		this.imageNotAvailable = this.imageNotAvailable.bind(this);

		this.userKey = null;

		this.ref = React.createRef();
		this.refHeader = React.createRef();

		this.state = {
			userID: "",
			name: "unknown",
			profileImage: null,

			headerMenuCollapsed: true,
			showDropdown: false,

			visibleMachineIDs: [],

			imageNotAvailable: false,

			// Rights
			Right_Order_Enable: true,
			Right_Message_Enable: false
		};
	}
	static defaultProps = {
		navigationCollapsed: false,
		navigationToggleMode: MainNav.NAVIGATIONTOGGLEMODE.auto,
	}

	toggleHeaderMenu() {
		this.setState({ headerMenuCollapsed: !this.state.headerMenuCollapsed });
	}

	handleEvent() {
		this.setState({
			headerMenuCollapsed: true,
			showDropdown: false
		});
	}

	toggleDropdown(event) {
		event.preventDefault();

		this.setState({ showDropdown: !this.state.showDropdown });
	}

	imageNotAvailable() {
		this.setState({ imageNotAvailable: true });
	}

	componentDidMount() {
		if (!IsNullOrUndefined(this.ref.current)) {
			this.ref.current.addEventListener('click', this);
			this.refHeader.current.addEventListener('click', this);
		}

		// Get the user data
		this.userKey = (new CurrentUser()).register((loaded, error) => {
			let machineIDs = (new CurrentUser()).getVisibleMachines();

			this.setState({
				// Settings
				name: (new CurrentUser()).getName(),
				userID: (new CurrentUser()).getUserID(),
				profileImage: (new CurrentUser()).getProfileImage(),
				imageNotAvailable: false,

				visibleMachineIDs: machineIDs,

				// Rights
				Right_Order_Enable: (new CurrentUser()).hasRight(Rights.RIGHTS.Order_Enable),
				Right_Message_Enable: (new CurrentUser()).hasRight(Rights.RIGHTS.Message_Enable)
			});
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if ((prevState.headerMenuCollapsed !== this.state.headerMenuCollapsed) || (prevState.showDropdown !== this.state.showDropdown)) {
			if (!IsNullOrUndefined(this.ref.current)) {
				this.ref.current.removeEventListener('click', this);
				this.refHeader.current.removeEventListener('click', this);
				this.ref.current.addEventListener('click', this);
				this.refHeader.current.addEventListener('click', this);
			}
		}
	}

	componentWillUnmount() {
		// Remove the User
		if (this.userKey !== null) {
			(new CurrentUser()).unregister(this.userKey);
			this.userKey = null;
		}

		if (!IsNullOrUndefined(this.ref.current)) {
			this.ref.current.removeEventListener('click', this);
			this.refHeader.current.removeEventListener('click', this);
		}
	}

	render() {
		const headerMenuToggled = this.state.headerMenuCollapsed ? "collapse" : "";
		const logoutPath = { pathname: `/${ApplicationPaths.ApiAuthorization}/${ApplicationPaths.LogOut}`, state: { local: true } };

		let navToggleMode = "togglemode-auto";
		if (this.props.navigationToggleMode == MainNav.NAVIGATIONTOGGLEMODE.auto)
			navToggleMode = "togglemode-auto";
		else if (this.props.navigationToggleMode == MainNav.NAVIGATIONTOGGLEMODE.hidden)
			navToggleMode = "togglemode-hidden";
		else if (this.props.navigationToggleMode == MainNav.NAVIGATIONTOGGLEMODE.visible)
			navToggleMode = "togglemode-visible";

		return (
			<>
				{(!this.state.headerMenuCollapsed || this.state.showDropdown) &&
					<div className="p-header--fullScreen" ref={this.ref} />
				}

				<header className="d-flex p-header">

					{(!this.state.headerMenuCollapsed || this.state.showDropdown) &&
						<div className="p-header__fullScreen" ref={this.refHeader} />
					}

					{/* Toggle Main Navigation Bar */}
					<div className={"p-header__mainnavtoggler d-flex " + navToggleMode + " " + (this.props.navigationCollapsed ? "toggled" : "")}>
						<span className="navbar-toggler__placeholder"></span>
						<span className="navbar-toggler__dynamicPlaceholder"></span>
					</div>

					<div className="flex-fill">
						{/* Top Left Menu - Logo */}
						<Link className="align-self-start" to="/Protected/Dashboard">
							<Logo className="p-header__logo" />
						</Link>
					</div>

					{/*Header Navigation Right*/}
					<nav className="navbar navbar-expand-lg p-header__navbar">
						<MenuToggleButton onClick={this.toggleHeaderMenu}></MenuToggleButton>
						<div className={headerMenuToggled + " navbar-collapse align-items-center"}>
							<div className="d-flex flex-wrap flex-lg-nowrap">
								{/* Notifications Preview */}
								{this.state.Right_Message_Enable &&
									<ul className="navbar-nav">
										<NotificationPreview
											showOnlyNew={true}
											machineIDs={this.state.visibleMachineIDs}
										/>
									</ul>
								}

								{/* Messages Preview */}
								{this.state.Right_Message_Enable &&
									<ul className="navbar-nav">
										<MessagePreview
											showOnlyNew={true}
										/>
									</ul>
								}

								{/* Shopping Cart Preview */}
								{this.state.Right_Order_Enable &&
									<ul className="navbar-nav">
										<ShoppingCart />
									</ul>
								}

								<ul className="navbar-nav">
									<li className={"nav-item dropdown " + (this.state.showDropdown ? "show" : "")}>
										<a href="#Dropdown" className="nav-link d-flex align-items-center pe-0 dropdown-toggle" onClick={this.toggleDropdown}>
											<span className="p-header__dropdown-content">{this.state.name}</span>
											<span className="p-avatar--neutral-person">
												{(this.state.profileImage === null || this.state.imageNotAvailable) &&
													<DefaultUserImage />
												}
												{(this.state.profileImage !== null && !this.state.imageNotAvailable) &&
													<img src={this.state.profileImage} alt="" onError={this.imageNotAvailable} />
												}
											</span>
										</a>
										<div className={"dropdown-menu text-center " + (this.state.showDropdown ? "show" : "")}>
											<Link className="dropdown-item" to={"/Protected/User/Details?userID=" + this.state.userID + "&currentTab=data"} as="a" onClick={() => { this.setState({ showDropdown: false }); }}>{(new Localizer()).get("Profile")}</Link>
											<Link className="dropdown-item" to={"/Protected/User/Details?userID=" + this.state.userID + "&currentTab=settings"} as="a" onClick={() => { this.setState({ showDropdown: false }); }}>{(new Localizer()).get("Settings")}</Link>
											<Link className="dropdown-item" to={"/Protected/User/Details?userID=" + this.state.userID + "&currentTab=dashboard"} as="a" onClick={() => { this.setState({ showDropdown: false }); }}>{(new Localizer()).get("Dashboard")}</Link>
											<Link className="dropdown-item" to={logoutPath} as="a">{(new Localizer()).get("Logout")}</Link>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</nav>
				</header >
			</>
		);
	}
}