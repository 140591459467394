/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import store from 'store';
import expirePlugin from 'store/plugins/expire';
import quotaPlugin from './StoreQuota';
import storeAccessTrackingPlugin from './StoreAccessTracking';
import IsNullOrUndefined from "./Utility.js";
import settings from 'Settings';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';
import { random } from '../../random';

store.addPlugin([storeAccessTrackingPlugin, quotaPlugin, expirePlugin]);

let instance;
export default class Cache {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.prefix = random;

		this.getKey = this.getKey.bind(this);
		this.set = this.set.bind(this);
		this.get = this.get.bind(this);
		this.has = this.has.bind(this);
		this.remove = this.remove.bind(this);

		// Set the quota
		store.setQuota(settings.CacheQuota);

		// Set the exceptions
		store.setExceptions("meta");
		
		// Check if the Cache-Key is correct
		let clear = false;
		const currentMeta = this.get("meta");
		if (!IsNullOrUndefined(currentMeta)) {
			if (!IsNullOrUndefined(currentMeta.key)) {
				if (currentMeta.key !== this.prefix)
					clear = true;
			}
			else
				clear = true;
		}
		else {
			clear = true;
		}

		if (clear) {
			console.info("Cache cleared");

			store.clearAll();
			// Never expire
			this.set("meta", {key: this.prefix}, new Date().getTime() + 100000000);
		}

		store.removeExpiredKeys();
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	getKey(key) {
		return this.prefix + "/" + key;
	}

	set(key, obj, expire) {
		// Ensure that we have enough space
		store.removeExpiredKeys();

		// Compress the Object
		const jsonObj = JSON.stringify(obj);
		const _obj = compressToUTF16(jsonObj);

		let success = false;
		while (!success) {
			try {
				store.set(this.getKey(key), _obj, expire);
				success = true;
			}
			catch (e) {
				if (!store.removeOldestKey()) {
					// There is no other key
					success = true;
				}
			}
		}
	}

	get(key) {
		const _obj = store.get(this.getKey(key), undefined);
		if (IsNullOrUndefined(_obj))
			return _obj;
		const obj = JSON.parse(decompressFromUTF16(_obj));
		return obj;
	}

	has(key) {
		return !IsNullOrUndefined(store.get(this.getKey(key), undefined));
	}

	remove(key) {
		return store.remove(this.getKey(key));
	}

}