/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 270.93333 270.93333"
            className="svgIcon"
        >
            <g transform="matrix(13.546667,0,0,13.546667,-0.04064351,-2032.0001)">
                <circle
                    cx="10.003"
                    cy="160"
                    r="9"
                    fillOpacity="0.020067"
                    strokeWidth="2" />
                <circle
                    cx="10.003"
                    cy="160"
                    r="1" />
                <circle
                    cx="6.5029998"
                    cy="160"
                    r="1" />
                <circle
                    cx="13.503"
                    cy="160"
                    r="1" />
            </g>
        </svg>
    );
}