/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the component which reloads the page if required
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";
import CurrentUser from "../BusinessLogic/DataManagement/CurrentUser";

export default class AutomaticReload extends React.Component {
    constructor(props) {
		super(props);

		this.timeoutKey = null;
		this.timeoutTime = 0;
		this.userKey = null;

		this.reloadPage = this.reloadPage.bind(this);
    }
	static defaultProps = {
	}

	reloadPage() {
		window.location.reload();
	}

	componentDidMount() {
		this.userKey = (new CurrentUser()).register((loaded, error) => {
			if ((new CurrentUser()).hasAutomaticReloadTime()) {
				const time = (new CurrentUser()).getAutomaticReloadTime();

				if (this.timeoutKey !== null) {
					if (this.timeoutTime !== time) {
						clearTimeout(this.timeoutKey);
						this.timeoutKey = null;

						this.timeoutTime = time;
						this.timeoutKey = setTimeout(this.reloadPage, time);
					}
				}
				else {
					this.timeoutTime = time;
					this.timeoutKey = setTimeout(this.reloadPage, time);
				}
			}
			else {
				if (this.timeoutKey !== null) {
					clearTimeout(this.timeoutKey);
					this.timeoutKey = null;
				}
			}
		});
	}

	componentWillUnmount() {
		if (this.userKey !== null) {
			(new CurrentUser()).unregister(this.userKey);
			this.userKey = null;
		}

		if (this.timeoutKey !== null) {
			clearTimeout(this.timeoutKey);
			this.timeoutKey = null;
		}
	}

	render() {
		return <></>;
	}
}