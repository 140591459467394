/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This JavaScript file is used to display a Export-Button
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

import Delete from "./Delete";
import Edit from "./Edit";
import Plus from "./Plus";
import Info from "./Info";
import Minus from "./Minus";
import Download from "./Download";
import Upload from "./Upload";
import Trash from "./Trash";
import Close from "./Close";
import Etc from "./Etc";
import Maximize from "./Maximize";
import Message from "./Message";
import MessageNumber from "./MessageNumber";
import Home from "./Home";
import AGB from "./AGB";
import Contact from "./Contact";
import PrivacyNote from "./PrivacyNote";
import Shop from "./Shop";
import Save from "./Save";
import Machine from "./Machine";
import MachineThin from "./MachineThin";
import Down from "./Down";
import Up from "./Up";
import Right from "./Right";
import Left from "./Left";
import NewPage from "./NewPage";


const ICON = {
	delete: "delete",
	edit: "edit",
	plus: "plus",
	minus: "minus",
	info: "info",
	download: "download",
	upload: "upload",
	trash: "trash",
	close: "close",
	etc: "etc",
	maximize: "maximize",
	message: "message",
	home: "home",
	agb: "agb",
	contact: "contact",
	privacyNote: "privacyNote",
	shop: "shop",
	save: "save",
	machine: "machine",
	machineThin: "machineThin",
	down: "down",
	up: "up",
	left: "left",
	right: "right",
	newPage: "newPage"
};

export default class Icon extends React.Component {
	constructor(props, context) {
		super(props, context);
	}

	static defaultProps = {
		icon: ICON.delete
	}

	static get ICON() {
		return ICON;
	}

	render() {
		if (this.props.icon === ICON.delete)
			return <Delete />;
		if (this.props.icon === ICON.edit)
			return <Edit />;
		if (this.props.icon === ICON.plus)
			return <Plus />;
		if (this.props.icon === ICON.info)
			return <Info />;
		if (this.props.icon === ICON.minus)
			return <Minus />;
		if (this.props.icon === ICON.download)
			return <Download />;
		if (this.props.icon === ICON.upload)
			return <Upload />;
		if (this.props.icon === ICON.trash)
			return <Trash />;
		if (this.props.icon === ICON.close)
			return <Close />;
		if (this.props.icon === ICON.etc)
			return <Etc />;
		if (this.props.icon === ICON.maximize)
			return <Maximize />;
		if (this.props.icon === ICON.message)
			return <Message />;
		if (this.props.icon === ICON.messageNumber)
			return <MessageNumber />;
		if (this.props.icon === ICON.home)
			return <Home />;
		if (this.props.icon === ICON.agb)
			return <AGB />;
		if (this.props.icon === ICON.contact)
			return <Contact />;
		if (this.props.icon === ICON.privacyNote)
			return <PrivacyNote />;
		if (this.props.icon === ICON.shop)
			return <Shop />;
		if (this.props.icon === ICON.save)
			return <Save />;
		if (this.props.icon === ICON.machine)
			return <Machine />;
		if (this.props.icon === ICON.machineThin)
			return <MachineThin />;
		if (this.props.icon === ICON.down)
			return <Down />;
		if (this.props.icon === ICON.up)
			return <Up />;
		if (this.props.icon === ICON.left)
			return <Left />;
		if (this.props.icon === ICON.right)
			return <Right />;
		if (this.props.icon === ICON.newPage)
			return <NewPage />;
		return <></>;
	}
}