/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 299.3234 270.93333"
            className="svgIcon"
        >
            <path d="m 135.46667,22.57083 c 62.2313,0 112.89584,50.539491 112.89584,112.89584 0,62.35636 -50.66454,112.89584 -112.89584,112.89584 -62.335508,0 -112.91668,-50.53948 -112.91668,-112.89584 C 22.63339,73.152003 73.152003,22.63336 135.46667,22.57083 Z M 135.46667,0 C 60.522342,0 0,60.647388 0,135.46667 c 0,74.81928 60.522342,135.46667 135.46667,135.46667 74.84012,0 135.46667,-60.64739 135.46667,-135.46667 C 270.93334,60.647388 210.30679,0 135.46667,0 Z m 67.73332,146.76251 h -56.43748 v 56.4375 h -22.61252 v -56.4375 H 67.733337 V 124.17083 H 124.14999 V 67.733337 h 22.61252 v 56.437493 h 56.43748 z" />
        </svg>
    );
}