/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import lazyRetry from '../BusinessLogic/Misc/lazyRetry';
import Layout from './Pages/Layout/LayoutRelay';
import authService from '../Authorization/AuthorizeService'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import CurrentUser from '../BusinessLogic/DataManagement/CurrentUser'

import LengthUnitSystemConverter from '../BusinessLogic/Formater/LengthUnitSystemConverter.js';
import AreaUnitSystemConverter from '../BusinessLogic/Formater/AreaUnitSystemConverter.js';
import MassUnitSystemConverter from '../BusinessLogic/Formater/MassUnitSystemConverter.js';
import FlowRateUnitSystemConverter from '../BusinessLogic/Formater/FlowRateUnitSystemConverter.js';
import PressureUnitSystemConverter from '../BusinessLogic/Formater/PressureUnitSystemConverter.js';
import TemperatureUnitSystemConverter from '../BusinessLogic/Formater/TemperatureUnitSystemConverter.js';
import TemperatureSpanUnitSystemConverter from '../BusinessLogic/Formater/TemperatureSpanUnitSystemConverter.js';
import ConductivityUnitSystemConverter from '../BusinessLogic/Formater/ConductivityUnitSystemConverter.js';
import VolumeUnitSystemConverter from '../BusinessLogic/Formater/VolumeUnitSystemConverter.js';
import AngleUnitSystemConverter from '../BusinessLogic/Formater/AngleUnitSystemConverter.js';
import MassFlowRateUnitSystemConverter from '../BusinessLogic/Formater/MassFlowRateUnitSystemConverter.js';

import GlobalAppData from 'GlobalAppData';
import LanguageManager from '../BusinessLogic/Misc/LanguageManager.js';
import DatabaseManager from "../BusinessLogic/Misc/DatabaseManager.js";
const Privacy = lazy(() => lazyRetry(() => import('./Pages/Privacy'), "Pages_Privacy"));
const Terms = lazy(() => lazyRetry(() => import('./Pages/Terms'), "Pages_Terms"));
const Contact = lazy(() => lazyRetry(() => import('./Pages/ContactRelay'), "Pages_ContactRelay"));
const Dashboard = lazy(() => lazyRetry(() => import('./Pages/Dashboard/IndexRelay'), "Pages_Dashboard_IndexRelay"));
const MachineOverview = lazy(() => lazyRetry(() => import('../Areas/Protected/Machine/Pages/Overview'), "Areas_Protected_Machine_Pages_Overview"));
const MachineDetails = lazy(() => lazyRetry(() => import('./Pages/Machine/DetailsRelay'), "Pages_Machine_DetailsRelay"));
const MachineCreate = lazy(() => lazyRetry(() => import('./Pages/Machine/CreateRelay'), "Pages_Machine_CreateRelay"));
const PieceDetails = lazy(() => lazyRetry(() => import('./Pages/Piece/DetailsRelay'), "Pages_Piece_DetailsRelay"));
const ToolOverview = lazy(() => lazyRetry(() => import('./Pages/Tool/OverviewRelay'), "Pages_Tool_OverviewRelay"));
const ToolDetails = lazy(() => lazyRetry(() => import('./Pages/Tool/DetailsRelay'), "Pages_Tool_DetailsRelay"));
const ToolTypeDetails = lazy(() => lazyRetry(() => import('./Pages/Tool/TypeDetailsRelay'), "Pages_Tool_TypeDetailsRelay"));
const About = lazy(() => lazyRetry(() => import('./Pages/About'), "Pages_About"));
const UserOverview = lazy(() => lazyRetry(() => import('./Pages/User/OverviewRelay'), "Pages_User_OverviewRelay"));
const UserDetails = lazy(() => lazyRetry(() => import('./Pages/User/DetailsRelay'), "Pages_User_DetailsRelay"));
const UserGroupDetails = lazy(() => lazyRetry(() => import('./Pages/User/GroupDetailsRelay'), "Pages_User_GroupDetailsRelay"));
const OrderOverview = lazy(() => lazyRetry(() => import('../Areas/Protected/Order/Pages/Overview'), "Areas_Protected_Order_Pages_Overview"));
const OrderSummary = lazy(() => lazyRetry(() => import('./Pages/Order/SummaryRelay'), "Pages_Order_SummaryRelay"));
const OrderDetails = lazy(() => lazyRetry(() => import('./Pages/Order/Details'), "Pages_Order_Details"));
const MessageOverview = lazy(() => lazyRetry(() => import('./Pages/Message/OverviewRelay'), "Pages_Message_OverviewRelay"));
const UpdateOverview = lazy(() => lazyRetry(() => import('./Pages/Update/Overview'), "Pages_Update_Overview"));
const Extras = lazy(() => lazyRetry(() => import('./Pages/Extras/ExtrasRelay'), "Pages_Extras_ExtrasRelay"));
const PlantOverview = lazy(() => lazyRetry(() => import('./Pages/Plant/OverviewRelay'), "Pages_Plant_OverviewRelay"));
const PlantDetails = lazy(() => lazyRetry(() => import('./Pages/Plant/DetailsRelay'), "Pages_Plant_DetailsRelay"));
const PlantCreate = lazy(() => lazyRetry(() => import('./Pages/Plant/CreateRelay'), "Pages_Plant_CreateRelay"));
const LineOverview = lazy(() => lazyRetry(() => import('./Pages/Line/OverviewRelay'), "Pages_Line_OverviewRelay"));
const LineDetails = lazy(() => lazyRetry(() => import('./Pages/Line/DetailsRelay'), "Pages_Line_DetailsRelay"));
const LineCreate = lazy(() => lazyRetry(() => import('./Pages/Line/CreateRelay'), "Pages_Line_CreateRelay"));
const LinePieceDetails = lazy(() => lazyRetry(() => import('./Pages/Line/PieceDetailsRelay'), "Pages_Line_PieceDetailsRelay"));

export default class App extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.authKey = null;
        this.userKey = null;

        this.populateAuthenticationState = this.populateAuthenticationState.bind(this);
        this.authenticationChanged = this.authenticationChanged.bind(this);
        this.handleRouteChange = this.handleRouteChange.bind(this);

        this.initialised = false;

        this.state = {
            currentUserLoaded: false,
            currentUserError: false,
            navigationState: ""
        }
    }

    async populateAuthenticationState() {
        const user = await authService.getUser();
        if (user === null) {
            (new CurrentUser()).removeUserID();
            (new DatabaseManager()).removeUserID();
        }
        else {
            (new CurrentUser()).setUserID(user.sub);
            (new DatabaseManager()).setUserID(user.sub);
        }
    }

    async authenticationChanged() {
        this.setState({ currentUserLoaded: false, currentUserError: false });
        await this.populateAuthenticationState();
    }

    handleRouteChange() {
        this.setState({
            navigationState: ""
        })
    }

    componentDidMount() {
        this.initialised = true;

        if (!(new GlobalAppData()).isWebView()) {
            // Register changed userdata
            this.authKey = authService.subscribe(() => this.authenticationChanged());
            this.populateAuthenticationState();
        }
        else {
            // It is an app user
            (new CurrentUser()).setUserID((new GlobalAppData()).getSub());
            this.setState({ currentUserLoaded: true, currentUserError: false });
        }

        // set the language
        new LanguageManager();

        // Register the User. This ensures that the user has initial data
        this.userKey = (new CurrentUser()).register((loaded, error) => {
            if (this.initialised) {
                // Set the state
                this.setState({
                    currentUserLoaded: loaded,
                    currentUserError: error
                });
            }

            if (loaded && !error) {
                // Set the UnitSystem
                (new LengthUnitSystemConverter()).setUnitSystem((new CurrentUser()).getUnitSystem());
                (new AreaUnitSystemConverter()).setUnitSystem((new CurrentUser()).getUnitSystem());
                (new MassUnitSystemConverter()).setUnitSystem((new CurrentUser()).getUnitSystem());
                (new FlowRateUnitSystemConverter()).setUnitSystem((new CurrentUser()).getFlowRateUnitSystem());
                (new PressureUnitSystemConverter()).setUnitSystem((new CurrentUser()).getPressureUnitSystem());
                (new TemperatureUnitSystemConverter()).setUnitSystem((new CurrentUser()).getTemperatureUnitSystem());
                (new TemperatureSpanUnitSystemConverter()).setUnitSystem((new CurrentUser()).getTemperatureUnitSystem());
                (new ConductivityUnitSystemConverter()).setUnitSystem((new CurrentUser()).getConductivityUnitSystem());
                (new VolumeUnitSystemConverter()).setUnitSystem((new CurrentUser()).getVolumeUnitSystem());
                (new AngleUnitSystemConverter()).setUnitSystem((new CurrentUser()).getAngleUnitSystem());
                (new MassFlowRateUnitSystemConverter()).setUnitSystem((new CurrentUser()).getMassPerTimeUnitSystem());
            }
            else {
                // Set the UnitSystem
                (new FlowRateUnitSystemConverter()).setDefaultUnitSystem();
                (new PressureUnitSystemConverter()).setDefaultUnitSystem();
                (new TemperatureUnitSystemConverter()).setDefaultUnitSystem();
                (new TemperatureSpanUnitSystemConverter()).setDefaultUnitSystem();
                (new ConductivityUnitSystemConverter()).setDefaultUnitSystem();
                (new VolumeUnitSystemConverter()).setDefaultUnitSystem();
                (new LengthUnitSystemConverter()).setDefaultUnitSystem();
                (new AreaUnitSystemConverter()).setDefaultUnitSystem();
                (new MassUnitSystemConverter()).setDefaultUnitSystem();
                (new AngleUnitSystemConverter()).setDefaultUnitSystem();
                (new MassFlowRateUnitSystemConverter()).setDefaultUnitSystem();
            }
        });
    }

    componentWillUnmount() {
        if (this.userKey !== null) {
            (new CurrentUser()).unregister(this.userKey);
            this.userKey = null;
        }

        if (this.authKey !== null) {
            authService.unsubscribe(this.authKey);
            this.authKey = null;
        }

        this.initialised = false;

        (new LanguageManager()).destroy();
    }

    render() {
        if (this.state.currentUserError) {
            return (
                <>
                    <div className="w-100 h-100">
                        <Error />
                    </div>
                </>);
        } else if (!this.state.currentUserLoaded) {
            return (
                <>
                    <div className="w-100 h-100">
                        <Loading />
                    </div>
                </>);
        }
        else {
            return (
                <Routes>
                    <Route path="/" element={
                        <Layout>
                            <Suspense fallback={<Loading />}>
                                <Outlet />
                            </Suspense>
                        </Layout>}>
                        <Route path={`Privacy`} element={<Privacy />} />
                        <Route path={`Terms`} element={<Terms />} />
                        <Route path={`Contact`} element={<Contact />} />
                        <Route path={`About`} element={<About />} />
                        <Route path={`Dashboard`} element={<Dashboard />} />
                        <Route path={`Machine/Overview`} element={<MachineOverview />} />
                        <Route path={`Machine/Details`} element={<MachineDetails />} />
                        <Route path={`Machine/Create`} element={<MachineCreate />} />
                        <Route path={`Piece/Details`} element={<PieceDetails />} />
                        <Route path={`Tool/Overview`} element={<ToolOverview />} />
                        <Route path={`Tool/Details`} element={<ToolDetails />} />
                        <Route path={`Tool/TypeDetails`} element={<ToolTypeDetails />} />
                        <Route path={`Message/Overview`} element={<MessageOverview />} />
                        <Route path={`Order/Overview`} element={<OrderOverview />} />
                        <Route path={`Order/Summary`} element={<OrderSummary />} />
                        <Route path={`Order/Details`} element={<OrderDetails />} />
                        <Route path={`User/Overview`} element={<UserOverview />} />
                        <Route path={`User/Details`} element={<UserDetails />} />
                        <Route path={`User/GroupDetails`} element={<UserGroupDetails />} />
                        <Route path={`Plant/Overview`} element={<PlantOverview />} />
                        <Route path={`Plant/Details`} element={<PlantDetails />} />
                        <Route path={`Plant/Create`} element={<PlantCreate />} />
                        <Route path={`Line/Overview`} element={<LineOverview />} />
                        <Route path={`Line/Details`} element={<LineDetails />} />
                        <Route path={`Line/PieceDetails`} element={<LinePieceDetails />} />
                        <Route path={`Line/Create`} element={<LineCreate />} />
                        <Route path={`Update`} element={<UpdateOverview />} />
                        <Route path={`Extras`} element={<Extras />} />
                        <Route path={`*`} element={<Navigate to="/PageNotFound" replace />} />
                    </Route>
                </Routes>
            );
        }
    }
}