/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 270.93333 270.93333"
            className="svgIcon"
        >
            <path d="M 135.51181,0 37.004978,101.6 H 110.8569 v 135.46667 h 49.40017 V 101.6 h 73.8519 z m 110.69886,225.74997 v 22.59165 H 24.654933 V 225.74997 H 0 v 45.18336 h 270.93333 v -45.18336 z" />
        </svg>
    );
}