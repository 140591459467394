/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 198.68462 270.93333"
            className="svgIcon"
        >
            <path d="m 154.57622,214.48909 h 44.10846 v 22.57715 h -44.10846 z m 0,-11.28926 h 44.10846 v -22.57784 h -44.10846 z m 0,-33.86641 h 44.10846 v -22.57784 h -44.10846 z m 44.10846,-56.44493 v 22.57783 H 143.39619 V 248.3555 h 55.28849 v 22.57783 H 0 V 112.88849 H 33.10797 V 67.733504 C 32.69837,30.741846 62.35068,0.397312 99.34234,0 c 36.99098,0.4096 66.61325,30.741846 66.23369,67.733504 V 112.88849 Z M 121.4505,135.46632 H 22.10816 V 248.3555 h 99.34234 z m 21.96343,-22.57783 V 67.733504 C 143.8918,43.330902 124.53888,23.173803 100.13696,22.686379 75.73504,22.276779 55.59569,41.560747 55.12602,65.96335 c -0.0184,0.546133 -0.0184,1.140053 0,1.809066 V 112.9274 Z" />
        </svg>
    );
}