/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import ErrorManager from '../../Misc/ErrorManager.js';
import UniqueIDGenerator from '../../Misc/UniqueIDGenerator.js';
import IsNullOrUndefined, { removeNullOrUndefined } from "../../Misc/Utility.js";
import FetchHelper from "../../Misc/FetchHelper.js";
import settings from 'Settings';
import isEqual from 'lodash/isEqual';

const ERROR = {
	general: "general",
	access: "access"
};

export default class GroupedFetch {
	constructor(url) {
		this.url = url;

		this.abortController = new AbortController();
		this.requestID = new UniqueIDGenerator();
		this.keyGen = new UniqueIDGenerator();

		this.pending = new Map();
		this.inProcess = new Map();
		this.timeout = null;

		this.destroy = this.destroy.bind(this);
		this.fetch = this.fetch.bind(this);
		this.abort = this.abort.bind(this);
		this.update = this.update.bind(this);
		this.informAccessError = this.informAccessError.bind(this);
		this.informError = this.informError.bind(this);
		this.inform = this.inform.bind(this);
		this.clearKeys = this.clearKeys.bind(this);
	}

	static get ERROR() {
		return ERROR;
	}

	destroy() {
		if (this.timeout !== null) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}

		// Cancel all pending requests
		this.abortController.abort();

		this.pending.clear();
		this.inProcess.clear();
	}

	fetch(id, cachedLastModification, cachedCacheID, cachedApiVersion, func, error) {
		const key = this.keyGen.generate();

		// Remove all null-values from the id since the server must handle this case
		const _id = removeNullOrUndefined(id);

		// Remember for the next request
		this.pending.set(key, {
			id: _id,
			cachedLastModification: cachedLastModification,
			cachedCacheID: cachedCacheID,
			cachedApiVersion: cachedApiVersion,
			func: func,
			error: error
		});

		if (IsNullOrUndefined(this.timeout)) {
			this.timeout = setTimeout(() => {
				this.timeout = null;
				(async () => {
					let l = [];
					while (this.pending.size > 0) {
						l.push(this.update());
					}
					await Promise.all(l);
				})();
			}, settings.DataReloadDelay);
		}

		return key;
	}

	abort(key) {
		this.pending.delete(key);

		let found = false;
		this.inProcess.forEach((v, k) => {
			if (found)
				return;

			let nv = v.filter((e) => {
				if (e.key === key) {
					found = true;
					return false;
				}
				return true;
			});

			this.inProcess.set(k, nv);
		});

		this.keyGen.release(key);
	}

	async update() {
		let d = [];
		this.pending.forEach((v, k) => {
			if (d.length > 100)
				return;

			if (!d.some(e => {
				if (!isEqual(e.id, v.id))
					return false;
				return true;
			})) {
				let data = {};

				data.id = v.id;

				if (!IsNullOrUndefined(v.cachedLastModification))
					data.cachedLastModification = v.cachedLastModification;
				if (!IsNullOrUndefined(v.cachedApiVersion))
					data.cachedApiVersion = v.cachedApiVersion;
				if (!IsNullOrUndefined(v.cachedCacheID))
					data.cachedCacheID = v.cachedCacheID;

				d.push(data);
			}
		});
		if (d.length === 0)
			return;

		const requestID = this.requestID.generate();
		let data = {
			data: d,
			requestID: requestID
		};

		let processData = [];
		let completeClear = true;
		this.pending.forEach((v, k) => {
			if (!data.data.some(e => {
				if (!isEqual(e.id, v.id))
					return false;
				return true;
			})) {
				completeClear = false;
				return;
			}

			processData.push({
				key: k,
				id: v.id,
				func: v.func,
				error: v.error
			});
		});
		this.inProcess.set(requestID, processData);

		if (!completeClear) {
			let p = this.pending;
			this.pending = new Map();
			p.forEach((v, k) => {
				if (data.data.some(e => {
					if (!isEqual(e.id, v.id))
						return false;
					return true;
				}))
					return;

				this.pending.set(k, v);
			});
		}
		else {
			this.pending.clear();
		}

		try {
			const json = await FetchHelper.fetchData(this.url, data, this.abortController, false, false);

			// Get the corresponding request
			let request = this.inProcess.get(json.requestID);
			if (IsNullOrUndefined(request))
				return;
			this.inProcess.delete(json.requestID);

			// Inform all currently in process lines about the result
			let done = [];
			if (!IsNullOrUndefined(json.data)) {
				json.data.forEach((e) => {
					// Remove all null-values from the id since the server must handle this case
					const _id = removeNullOrUndefined(e.id);

					done.push(_id);
					this.inform(request, _id, { cacheID: e.cacheID, lastModification: e.lastModification, data: e.data, apiVersion: json.apiVersion });
				});
			}

			// Inform remaining
			this.informAccessError(request, done);

			// Clear all keys
			this.clearKeys(request);
		} catch (err) {
			if (err.name === "Abort")
				return;
			else if (err.name === "APIIncompatible") {
				// Added error handling
				(new ErrorManager()).APIIncompatible();
			}

			// Get the corresponding request
			let request = this.inProcess.get(requestID);
			if (IsNullOrUndefined(request))
				return;
			this.inProcess.delete(requestID);

			// Inform remaining
			this.informError(request);

			// Clear all keys
			this.clearKeys(request);
		} finally {
			this.requestID.release(requestID);
		}
	}

	clearKeys(request) {
		request.forEach((e) => {
			if (IsNullOrUndefined(e.key))
				return;
			this.keyGen.release(e.key);
		});
	}

	inform(request, id, data) {
		request.forEach((e) => {
			if (!isEqual(e.id, id))
				return;

			try {
				e.func(data);
			} catch (err) {
				console.error("Error during processing of registered function. Error: " + err);
			}
		});
	}

	informError(request, done) {
		request.forEach((e) => {
			try {
				e.error(ERROR.general);
			} catch (err) {
				console.error("Error during processing of registered function. Error: " + err);
			}
		});
	}

	informAccessError(request, done) {
		request.forEach((v) => {
			if (done.some(e => {
				if (!isEqual(v.id, e))
					return false;
				return true;
			}))
				return;

			try {
				v.error(ERROR.access);
			} catch (err) {
				console.error("Error during processing of registered function. Error: " + err);
			}
		});
	}
}
