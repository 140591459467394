/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 270.93333 270.93333"
            className="svgIcon"
        >
            <path d="m 135.46633,22.577835 c 62.35067,0 112.88917,50.537813 112.88917,112.888495 0,62.35067 -50.5385,112.88917 -112.88917,112.88917 C 73.133397,248.3555 22.577835,197.817 22.577835,135.46633 22.646101,73.151829 73.151828,22.649515 135.46633,22.577835 Z M 135.46633,0 C 60.580521,0 0,60.616704 0,135.46633 c 0,74.8858 60.580521,135.467 135.46633,135.467 74.84962,0 135.467,-60.5812 135.467,-135.467 C 270.93333,60.634453 210.31595,0 135.46633,0 Z m 0,64.915456 c 7.82131,0 14.01651,6.321493 14.01651,14.016512 0,7.87456 -6.34198,14.088192 -14.01651,14.088192 -7.87525,0 -14.07045,-6.321493 -14.07045,-14.088192 0,-7.695019 6.21227,-14.016512 14.07045,-14.016512 z m 22.57783,138.284374 h -45.15567 v -11.28926 c 5.42037,-1.93195 11.28926,-2.27328 11.28926,-8.2903 v -50.42996 c 0,-6.03477 -5.78219,-6.98982 -11.28926,-9.03099 v -11.2524 h 33.86709 v 70.73178 c 0,6.03477 5.74123,6.39659 11.28858,8.2903 z" />
        </svg>
    );
}