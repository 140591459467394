/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 288.99537 270.93333"
            className="svgIcon"
        >
            <path d="m 144.49459,24.636758 c 25.63004,-0.05393 46.81728,19.976874 48.17169,45.571413 0,25.214293 -20.69913,60.760749 -48.17169,105.428989 C 117.00361,130.96892 96.32222,95.477078 96.32222,70.208171 97.69438,44.613632 118.86387,24.582827 144.49459,24.636758 Z M 144.49459,0 c -39.05058,0.136534 -71.0929,31.22927 -72.24934,70.316032 0,38.761818 31.2477,85.109418 72.24934,151.470078 C 185.44162,155.37152 216.74325,109.11403 216.74325,70.316032 215.51445,31.175339 183.56292,0.144726 144.49459,0 Z m 0,98.52928 c -13.60145,0.4096 -24.87227,-10.476202 -25.21497,-24.07697 -0.34134,-13.600768 10.4762,-24.871595 24.07697,-25.214976 13.60077,-0.4096 24.87159,10.476202 25.21497,24.07697 v 0.477867 c 0.13654,13.564587 -10.60249,24.600576 -24.07697,24.763051 0.0178,0 0.0178,0 0,0 z m 96.32563,73.89252 h -37.7501 c -4.73088,7.91142 -9.60921,16.18398 -14.82888,24.63676 h 37.69549 l 6.01429,12.31872 H 180.6179 l -4.13696,6.70378 -3.39285,5.65249 h 6.71744 l 10.24136,24.619 H 99.88028 l 10.29529,-24.63675 h 5.72758 l -3.44747,-5.65248 -4.08235,-6.70379 H 83.82669 L 71.61651,197.04081 h 29.13485 C 95.51531,188.49724 90.58168,180.27929 85.92179,172.40405 H 48.17169 L 0,270.93333 H 288.99533 Z M 86.69525,246.27814 H 38.95706 l 21.24117,-43.42101 18.29683,18.82044 H 97.11684 Z M 192.7564,221.65982 h 45.15566 l 12.04771,24.61832 h -46.79953 z" />
        </svg>
    );
}