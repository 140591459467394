/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert from the metric unit system to the user unit system
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import IsNullOrUndefined from '../Misc/Utility.js';
import configureMeasurements from 'convert-units';
import conductivity from './UnitSystem/convert-units/conductivity';

var instance;
export default class ConductivityUnitSystemConverter {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.convert = configureMeasurements({ conductivity });

		// UnitSystem
		this.unitSystemDefinition = "s_m";
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	setDefaultUnitSystem() {
		this.setUnitSystem("s_m");
	}

	// Set the UnitSystem to use. Possible values are "metric" and "imperial"
	setUnitSystem(_unitSystem) {
		const unitSystem = _unitSystem.toLowerCase();

		switch (unitSystem) {
			case 's_m':
				this.unitSystemDefinition = "s_m";
				break;
			case 's_cm':
				this.unitSystemDefinition = "s_cm";
				break;
			default:
				this.unitSystemDefinition = "s_m";
				if (process.env.NODE_ENV === 'development') {
					console.warn("UnitSystem '" + unitSystem + "' is invalid. Using default.");
				}
				break;
		}
	}

	/** Format the seconds to the selected unit system (Best choice)
	 * @param value The Value to use
	 * @param targetUnit The target unit system
	 * @param sourceUnit The source unit system. Defaults to "S"
	 * @return { value, unit }
	 */
	format(value, targetUnit, sourceUnit) {
		if (IsNullOrUndefined(sourceUnit))
			sourceUnit = "S/m";

		switch (this.unitSystemDefinition) {
			case "s_cm": {
				if (typeof targetUnit === 'undefined') {
					// This will stay in the same system. So convert to the system first
					let r = this.convert(this.convert(value).from(sourceUnit).to("S/cm")).from("S/cm").toBest();
					if (IsNullOrUndefined(r))
						r = this.convert(this.convert(value).from(sourceUnit).to("S/cm")).from("S/cm").toBest({ cutOffNumber: 0 });
					return { value: r.val, unit: r.unit };
				}
				else {
					// This will stay in the same system. So convert to the system first
					const r = this.convert(this.convert(value).from(sourceUnit).to("S/cm")).from("S/cm").to(targetUnit);
					return { value: r, unit: targetUnit };
				}
			}
			case "s_m":
			default: {
				if (typeof targetUnit === 'undefined') {
					// This will stay in the same system. So convert to the system first
					let r = this.convert(this.convert(value).from(sourceUnit).to("S/m")).from("S/m").toBest();
					if (IsNullOrUndefined(r))
						r = this.convert(this.convert(value).from(sourceUnit).to("S/m")).from("S/m").toBest({ cutOffNumber: 0 });
					return { value: r.val, unit: r.unit };
				}
				else {
					// This will stay in the same system. So convert to the system first
					const r = this.convert(this.convert(value).from(sourceUnit).to("S/m")).from("S/m").to(targetUnit);
					return { value: r, unit: targetUnit };
				}
			}
		}
	}

	/**
	 * Get the default unit
	 */
	getDefaultUnit() {
		return "S/m";
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 */
	humanizeShortUnit(unit) {
		switch (unit) {
			// Metric
			case "nS/m":
				return "nS/m";
			case "mcS/m":
				return "µS/m";
			case "mS/m":
				return "mS/m";
			case "S/m":
				return "S/m";
			case "kS/m":
				return "kS/m";
			case "MS/m":
				return "MS/m";
			case "GS/m":
				return "GS/m";

			// Metric S/cm
			case "nS/cm":
				return "nS/cm";
			case "mcS/cm":
				return "µS/cm";
			case "mS/cm":
				return "mS/cm";
			case "S/cm":
				return "S/cm";
			case "kS/cm":
				return "kS/cm";
			case "MS/cm":
				return "MS/cm";
			case "GS/cm":
				return "GS/cm";

			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 * @param singular Get the singular unit
	 */
	humanizeUnit(unit, singular) {
		switch (unit) {
			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}
}