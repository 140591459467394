/********************************************************************************
*
* (c) 2021 - Gehring Technologies GmbH
*
* These are shared styles between web app and smartphone app
*
* @author: Julian Saile (Julian.Saile@gehring.de)
*
*********************************************************************************/

import { StyleSheet, Platform } from 'react-native';


// These styles must be synchronized with the stylesheets.
const styles = StyleSheet.create({
	messageTitle: {
		fontWeight: (Platform.OS === 'web') ? "600" : "bold",
		color: (Platform.OS === 'android') ? 'white' : "#4d4d4d",
		fontFamily: "Open Sans, Arial, sans-serif",
		fontSize: (Platform.OS === 'web') ? 13 : 14
	},
	messageContentPreview: {
		fontSize: (Platform.OS === 'web') ? 11 : 14,
		color: (Platform.OS === 'web') ? "#411a91" : "white",
		fontFamily: "Open Sans, Arial, sans-serif"
	},
	messageContent: {
		color: "#4d4d4d",
		fontFamily: "Open Sans, Arial, sans-serif",
		fontSize: 13
	}
})

export default styles;