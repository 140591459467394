/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 216.74667 270.93333"
            className="svgIcon"
        >
            <path d="m 75.752789,214.48909 c 0,5.98016 -4.860587,10.90969 -10.837333,10.90969 -5.98016,0 -10.873515,-4.89472 -10.873515,-10.90969 V 101.6006 c 0,-5.980162 4.89472,-10.855768 10.891947,-10.855768 5.993813,0 10.837333,4.87424 10.837333,10.855768 z m 43.457871,0 c 0,5.98016 -4.87424,10.90969 -10.85576,10.90969 -5.99382,0 -10.909021,-4.89472 -10.909021,-10.90969 V 101.6006 c 0,-5.980162 4.894721,-10.855768 10.909021,-10.855768 5.98016,0 10.85576,4.87424 10.85576,10.855768 z m 43.34934,0 c 0,5.98016 -4.89472,10.90969 -10.9097,10.90969 -6.01429,0 -10.85508,-4.89472 -10.85508,-10.90969 V 101.6006 c 0,-5.980162 4.87424,-10.855768 10.85508,-10.855768 5.98016,0 10.9097,4.87424 10.9097,10.855768 z M 216.74666,22.577835 V 45.15567 H 0 V 22.577835 H 61.917184 C 71.671125,22.577835 79.581866,10.151253 79.581866,0 h 57.600684 c 0,10.169003 7.89299,22.577835 17.66468,22.577835 z M 184.12612,67.733504 V 248.3555 H 32.475818 V 67.733504 H 10.801152 V 270.93333 H 205.87315 V 67.733504 Z" />
        </svg>
    );
}