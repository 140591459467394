/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert from the metric unit system to the user unit system
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import IsNullOrUndefined from '../Misc/Utility.js';
import configureMeasurements, { pressure } from 'convert-units';

var instance;
export default class PressureUnitSystemConverter {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.convert = configureMeasurements({ pressure });

		// UnitSystem
		this.unitSystemDefinition = "bar";
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	setDefaultUnitSystem() {
		this.setUnitSystem("bar");
	}

	// Set the UnitSystem to use. Possible values are "metric" and "imperial"
	setUnitSystem(_unitSystem) {
		const unitSystem = _unitSystem.toLowerCase();

		switch (unitSystem) {
			case 'bar':
				this.unitSystemDefinition = "bar";
				break;
			case 'pa':
				this.unitSystemDefinition = "pa";
				break;
			case 'psi':
				this.unitSystemDefinition = "psi";
				break;
			default:
				this.unitSystemDefinition = "bar";
				if (process.env.NODE_ENV === 'development') {
					console.warn("UnitSystem '" + unitSystem + "' is invalid. Using default.");
				}
				break;
		}
	}

	/** Format the seconds to the selected unit system (Best choice)
	 * @param value The Value to use
	 * @param targetUnit The target unit system
	 * @param sourceUnit The source unit system. Defaults to "K"
	 * @return { value, unit }
	 */
	format(value, targetUnit, sourceUnit) {
		let source = "Pa";
		if (typeof sourceUnit !== 'undefined')
			source = sourceUnit;

		let negativ = false;
		if (value < 0)
			negativ = true;

		if (!IsNullOrUndefined(targetUnit)) {
			const r = this.convert(value).from(source).to(targetUnit);
			return { value: negativ ? r * -1.0 : r, unit: targetUnit };
		}

		if (this.unitSystemDefinition === "bar") {
			// toBest will stay in the same system. So convert to the system first
			let r = this.convert(this.convert(value).from(source).to("bar")).from("bar").toBest({ exclude: ['Pa', 'kPa', 'MPa', 'hPa', 'torr'] });
			if (IsNullOrUndefined(r))
				r = this.convert(this.convert(value).from(source).to("bar")).from("bar").toBest({ cutOffNumber: 0, exclude: ['Pa', 'kPa', 'MPa', 'hPa', 'torr'] });
			return { value: negativ ? r.val * -1.0 : r.val, unit: r.unit };
		}
		else if (this.unitSystemDefinition === "pa") {
			// toBest will stay in the same system. So convert to the system first
			let r = this.convert(this.convert(value).from(source).to("Pa")).from("Pa").toBest({ exclude: ['bar', 'torr'] });
			if (IsNullOrUndefined(r))
				r = this.convert(this.convert(value).from(source).to("Pa")).from("Pa").toBest({ cutOffNumber: 0, exclude: ['bar', 'torr'] });
			return { value: negativ ? r.val * -1.0 : r.val, unit: r.unit };
		}
		else if (this.unitSystemDefinition === "psi") {
			// toBest will stay in the same system. So convert to the system first
			let r = this.convert(this.convert(value).from(source).to("psi")).from("psi").toBest();
			if (IsNullOrUndefined(r))
				r = this.convert(this.convert(value).from(source).to("psi")).from("psi").toBest({ cutOffNumber: 0 });
			return { value: negativ ? r.val * -1.0 : r.val, unit: r.unit };
		}
	}

	/**
	 * Get the default unit
	 */
	getDefaultUnit() {
		if (this.unitSystemDefinition === "bar") {
			return "bar";
		}
		else if (this.unitSystemDefinition === "pa") {
			return "kPa";
		}
		else if (this.unitSystemDefinition === "psi") {
			return "psi";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 */
	humanizeShortUnit(unit) {
		switch (unit) {
			case "bar":
				return "Bar";
			case "Pa":
				return "Pa";
			case "kPa":
				return "kPa";
			case "MPa":
				return "MPa";
			case "hPa":
				return "hPa";
			case "psi":
				return "psi";
			case "ksi":
				return "ksi";

			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 * @param singular Get the singular unit
	 */
	humanizeUnit(unit, singular) {
		switch (unit) {
			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}
}