/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import NotificationService from '../Misc/NotificationService.js';
import settings from 'Settings';
import UniqueIDGenerator from '../Misc/UniqueIDGenerator.js';
import IsNullOrUndefined from '../Misc/Utility.js';
import CurrentUser from "./CurrentUser";
import GroupedFetch from './Intern/GroupedFetch.js';
import BaseData from './Intern/BaseData.js';

class Data extends BaseData {
	constructor(ID: string, fetch: GroupedFetch) {
		super(ID, fetch, ID);

		this.initUpdateListener = this.initUpdateListener.bind(this);
		this.releaseUpdateListener = this.releaseUpdateListener.bind(this);

		this.currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

		this.lineNotificationKey = null;
		this.currentUserKey = null;

		this.init("MessageData");
	}

	initUpdateListener() {
		// Notification
		this.messageNotificationKey = (new NotificationService()).registerMessageChanged((new CurrentUser()).getUserID(), (ID, allChanged, messageIDs) => {
			// Is this relevant?
			if (!allChanged && !messageIDs.includes(this.ID))
				return;
			this.triggerUpdateData();
		});

		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

			if (this.currentUserRightsChangeID === currentUserRightsChangeID)
				return;

			this.currentUserRightsChangeID = currentUserRightsChangeID;

			// Update the data
			this.reload();
		});
	}

	releaseUpdateListener() {
		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}

		if (this.messageNotificationKey !== null) {
			(new NotificationService()).unregister(this.messageNotificationKey);
			this.messageNotificationKey = null;
		}
	}
}

class MachineData extends BaseData {
	constructor(messageID: string, fetch: GroupedFetch, machineID: string) {
		super({ messageID: messageID, machineID: machineID }, fetch, messageID + "_" + machineID);

		this.machineID = machineID;
		this.messageID = messageID;

		this.initUpdateListener = this.initUpdateListener.bind(this);
		this.releaseUpdateListener = this.releaseUpdateListener.bind(this);

		this.currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
		this.currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

		this.lineNotificationKey = null;
		this.currentUserKey = null;

		this.init("MachineMessageData");
	}

	initUpdateListener() {
		// Notification
		this.messageNotificationKey = (new NotificationService()).registerMachineMessageChanged(this.machineID, (ID, allChanged, messageIDs) => {
			// Is this relevant?
			if (!allChanged && !messageIDs.includes(this.messageID))
				return;
			this.triggerUpdateData();
		});

		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
			const currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

			if (this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID &&
				this.currentUserRightsChangeID === currentUserRightsChangeID)
				return;

			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
			this.currentUserRightsChangeID = currentUserRightsChangeID;

			// Update the data
			this.reload();
		});
	}

	releaseUpdateListener() {
		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}

		if (this.messageNotificationKey !== null) {
			(new NotificationService()).unregister(this.messageNotificationKey);
			this.messageNotificationKey = null;
		}
	}
}

let instance;
export default class MessageData {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.keys = new Map();
		this.keyGen = new UniqueIDGenerator();

		this.fetch = new GroupedFetch("/api/Message/GetData");
		this.machineFetch = new GroupedFetch("/api/Message/GetMachineData");
		this.dataArray = new Map();
		this.machineDataArray = new Map();

		this.destroy = this.destroy.bind(this);
		this.register = this.register.bind(this);
		this.registerMachine = this.registerMachine.bind(this);
		this.unregister = this.unregister.bind(this);
		this.checkDelete = this.checkDelete.bind(this);
		this.checkDeleteMachine = this.checkDeleteMachine.bind(this);
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance)) {
			instance.destroy();
		}

		instance = null;
	}

	destroy() {
		this.dataArray.forEach((v, k) => {
			if (IsNullOrUndefined(v.data)) {
				v.data.destroy();
				v.data = null;
			}

			if (!IsNullOrUndefined(v.releaseTimeoutKey)) {
				clearTimeout(v.releaseTimeoutKey);
				v.releaseTimeoutKey = null;
			}
		});
		this.dataArray.clear();

		this.machineDataArray.forEach((v, k) => {
			if (IsNullOrUndefined(v.data)) {
				v.data.destroy();
				v.data = null;
			}

			if (!IsNullOrUndefined(v.releaseTimeoutKey)) {
				clearTimeout(v.releaseTimeoutKey);
				v.releaseTimeoutKey = null;
			}
		});
		this.machineDataArray.clear();

		this.keys.clear();

		if (!IsNullOrUndefined(this.fetch))
			this.fetch.destroy();
		this.fetch = null;

		if (!IsNullOrUndefined(this.machineFetch))
			this.machineFetch.destroy();
		this.machineFetch = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	// Register for the current dataset
	register(messageID, func) {
		let m = this.dataArray.get(messageID);
		if (IsNullOrUndefined(m)) {
			m = { releaseTimeoutKey: null, data: null };
			this.dataArray.set(messageID, m);
		}

		if (IsNullOrUndefined(m.data)) {
			let data = new Data(messageID, this.fetch);
			m.data = data;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Register the Function
		const subKey = m.data.register(func);

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: 0, key: subKey, messageID: messageID });

		return key;
	}

	// Register for the current dataset
	registerMachine(messageID, machineID, func) {
		let m = this.machineDataArray.get(messageID);
		if (IsNullOrUndefined(m)) {
			m = { releaseTimeoutKey: null, data: null };
			this.machineDataArray.set(messageID, m);
		}

		if (IsNullOrUndefined(m.data)) {
			let data = new MachineData(messageID, this.machineFetch, machineID);
			m.data = data;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Register the Function
		const subKey = m.data.register(func);

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: 1, key: subKey, messageID: messageID });

		return key;
	}

	// Unregister
	unregister(key) {
		const obj = this.keys.get(key);
		if (IsNullOrUndefined(obj))
			return;
		switch (obj.type) {
			case 0:
				const m = this.dataArray.get(obj.messageID);
				if (IsNullOrUndefined(m))
					return;

				m.data.unregister(obj.key);

				if (IsNullOrUndefined(m.releaseTimeoutKey)) {
					m.releaseTimeoutKey = setTimeout(() => { m.releaseTimeoutKey = null; this.checkDelete(obj.messageID); }, settings.LocalDataHoldTime);
				}
				break;
			case 1:
				const mm = this.machineDataArray.get(obj.messageID);
				if (IsNullOrUndefined(mm))
					return;

				mm.data.unregister(obj.key);

				if (IsNullOrUndefined(mm.releaseTimeoutKey)) {
					mm.releaseTimeoutKey = setTimeout(() => { mm.releaseTimeoutKey = null; this.checkDeleteMachine(obj.messageID); }, settings.LocalDataHoldTime);
				}
				break;
			default:
				break;
		}
		

		// Free the key
		this.keys.delete(key);
		this.keyGen.release(key);
	}

	// Check the release
	checkDelete(messageID) {
		const m = this.dataArray.get(messageID);
		if (IsNullOrUndefined(m))
			return;

		if (!IsNullOrUndefined(m.data)) {
			if (m.data.empty()) {
				m.data.destroy();
				m.data = null;
			}
		}

		if (IsNullOrUndefined(m.data))
			this.dataArray.delete(messageID);
	}

	checkDeleteMachine(messageID) {
		const m = this.machineDataArray.get(messageID);
		if (IsNullOrUndefined(m))
			return;

		if (!IsNullOrUndefined(m.data)) {
			if (m.data.empty()) {
				m.data.destroy();
				m.data = null;
			}
		}

		if (IsNullOrUndefined(m.data))
			this.machineDataArray.delete(messageID);
	}
}