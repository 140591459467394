/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 299.3234 270.93333"
            className="svgIcon"
        >
            <path
                d="m 105.01792,138.95219 54.91989,54.17944 -72.61851,16.18159 z M 299.32346,55.877045 178.53942,176.66108 121.9219,120.78402 242.724,-1.9628002e-5 Z M 224.59195,165.64459 v 80.74543 H 24.976822 V 98.552385 h 84.014258 l 24.6697,-24.633586 H 9.907e-5 V 270.93331 H 249.46031 v -130.1932 z" />
        </svg>
    );
}