/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import Database from 'Database';
import UserDatabase from 'UserDatabase';

let instance;
export default class DatabaseManager {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.setUserID = this.setUserID.bind(this);
		this.removeUserID = this.removeUserID.bind(this);
		this.getDatabase = this.getDatabase.bind(this);
		this.getUserDatabase = this.getUserDatabase.bind(this);

		this.userID = null;

		// List with all databases
		this.databases = new Map();
		this.userDatabases = new Map();
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	setUserID(userID) {
		this.userID = userID;
		this.userDatabases.forEach((v, k) => {
			v.setUserID(userID);
		});
	}

	removeUserID() {
		this.userID = null;
		this.userDatabases.forEach((v, k) => {
			v.removeUserID();
		});
	}

	getDatabase(db) {
		if (this.databases.has(db))
			return this.databases.get(db);
		let _db = new Database(db);
		this.databases.set(db, _db);
		return _db;
	}

	getUserDatabase(db) {
		if (this.userDatabases.has(db))
			return this.userDatabases.get(db);
		let _db = new UserDatabase(db, this.userID);
		this.userDatabases.set(db, _db);
		return _db;
	}
}