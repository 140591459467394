/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

function IsNullOrUndefined(value) {
	if (typeof value === 'undefined')
		return true;
	if (value === null)
		return true;
	return false;
}

export default class Version {
	constructor(major, minor, patch, intern) {
		this.major = major;
		this.minor = minor;
		this.patch = patch;
		this.intern = intern;

		if (IsNullOrUndefined(this.major))
			this.major = 0;
		if (IsNullOrUndefined(this.minor))
			this.minor = 0;
		if (IsNullOrUndefined(this.patch))
			this.patch = 0;
		if (IsNullOrUndefined(this.intern))
			this.intern = 0;

		this.toObject = this.toObject.bind(this);
		this.isCompatible = this.isCompatible.bind(this);
		this.lessThan = this.lessThan.bind(this);
		this.equals = this.equals.bind(this);
	}

	static fromObject(version) {
		return new Version(version.major, version.minor, version.patch, version.intern);
	}

	toObject() {
		return { major: this.major, minor: this.minor, patch: this.patch, intern: this.intern };
	}

	static fromNumber(number) {
		const major = (number >> 24) & 0xff;
		const minor = (number >> 16) & 0xff;
		const patch = (number >>  8) & 0xff;
		const intern = (number >> 0) & 0xff;
		return new Version(major, minor, patch, intern);
	}

	toNumber() {
		let number = 0;
		number += this.major << 24;
		number += this.minor << 16;
		number += this.patch <<  8;
		number += this.intern << 0;
		return number;
	}

	isCompatible(version) {
		if (this.major !== version.major)
			return false;
		if (this.minor !== version.minor)
			return false;
		return true;
	}

	lessThan(version) {
		if (this.major > version.major)
			return false;
		if (this.major < version.major)
			return true;

		if (this.minor > version.minor)
			return false;
		if (this.minor < version.minor)
			return true;

		if (this.patch > version.patch)
			return false;
		if (this.patch < version.patch)
			return true;

		if (this.intern > version.intern)
			return false;
		if (this.intern < version.intern)
			return true;

		// Equal
		return false;
	}

	equals(version) {
		if (this.major !== version.major)
			return false;
		if (this.minor !== version.minor)
			return false;
		if (this.patch !== version.patch)
			return false;
		if (this.intern !== version.intern)
			return false;

		// Equal
		return false;
	}
}