/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the public part of the Application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import lazyRetry from '../BusinessLogic/Misc/lazyRetry';
import Layout from './Pages/Layout';
import Loading from '../Components/Loading';
import ApiAuthorizationRoutes from '../Authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from '../Authorization/ApiAuthorizationConstants';
const Contact = lazy(() => lazyRetry(() => import('./Pages/Contact'), "Pages_Contact"));
const Privacy = lazy(() => lazyRetry(() => import('./Pages/Privacy'), "Pages_Privacy"));
const Terms = lazy(() => lazyRetry(() => import('./Pages/Terms'), "Pages_Terms"));
const PageNotFound = lazy(() => lazyRetry(() => import('./Pages/PageNotFound'), "Pages_PageNotFound"));
const Login = lazy(() => lazyRetry(() => import('./Pages/LoginRelay'), "Pages_LoginRelay"));
const InternalError = lazy(() => lazyRetry(() => import('./Pages/InternalError'), "Pages_InternalError"));
const AccessDenied = lazy(() => lazyRetry(() => import('./Pages/AccessDenied'), "Pages_AccessDenied"));
const Lockout = lazy(() => lazyRetry(() => import('./Pages/Lockout'), "Pages_Lockout"));
const ForgotPassword = lazy(() => lazyRetry(() => import('./Pages/ForgotPassword'), "Pages_ForgotPassword"));
const ForgotUsername = lazy(() => lazyRetry(() => import('./Pages/ForgotUsername'), "Pages_ForgotUsername"));
const ResetPassword = lazy(() => lazyRetry(() => import('./Pages/ResetPasswordRelay'), "Pages_ResetPasswordRelay"));
const ConfirmEmail = lazy(() => lazyRetry(() => import('./Pages/ConfirmEmailRelay'), "Pages_ConfirmEmailRelay"));
const InitPassword = lazy(() => lazyRetry(() => import('./Pages/InitPasswordRelay'), "Pages_InitPasswordRelay"));

export default class App extends React.Component {
	render() {
		return (
			<Routes>
				<Route path="/" element={
					<Layout>
						<Suspense fallback={<Loading />}>
							<Outlet />
						</Suspense>
					</Layout>}>
					{/* Yes it is correct to go to a protected page. If the user is not logged in then the login-page will be shown */}
					<Route path={`/`} element={<Navigate to="/Protected/Dashboard" replace />} />
					<Route path={`Index`} element={<Navigate to="/Protected/Dashboard" replace />} />
					<Route path={`Login`} element={<Login />} />
					<Route path={`Contact`} element={<Contact />} />
					<Route path={`Privacy`} element={<Privacy />} />
					<Route path={`Terms`} element={<Terms />} />
					<Route path={`InternalError`} element={<InternalError />} />
					<Route path={`AccessDenied`} element={<AccessDenied />} />
					<Route path={`Lockout`} element={<Lockout />} />
					<Route path={`ForgotPassword`} element={<ForgotPassword />} />
					<Route path={`ResetPassword`} element={<ResetPassword />} />
					<Route path={`ForgotUsername`} element={<ForgotUsername />} />
					<Route path={`ConfirmEmail`} element={<ConfirmEmail />} />
					<Route path={`InitPassword`} element={<InitPassword />} />
					<Route path={`*`} element={<PageNotFound />} />
					<Route path={ApplicationPaths.ApiAuthorization + "/*"} element={<ApiAuthorizationRoutes />} />
				</Route>
			</Routes>
		);
	}
}