/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* The RequestID ensures that the last request will be used. This ensures that it doesn't matter which server handles an request
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

class RequestID {
	last: number;
	lastValid: number;

	constructor() {
		this.last = 0;
		this.lastValid = 0;
	}

	newRequest() : number {
		this.last++;
		if (this.last >= 0x7FFFFFFF)
			this.last = 0;
		return this.last;
	}

	validateRequest(id : number) {
		if (this.lastValid > 0x6FFFFFFF && id < 0x0FFFFFFF) {
			// Roundtrip. This is valid
		}
		else {
			// Only newer IDs are valid
			if (id <= this.lastValid)
				return false;
		}

		this.lastValid = id;
		return true;
	}
}

export default RequestID;