/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import settings from 'Settings';
import UniqueIDGenerator from "../../Misc/UniqueIDGenerator";
import IsNullOrUndefined from '../../Misc/Utility';

export default class BaseAutomaticUpdateDataContainer {
	constructor() {
		this.keyGen = new UniqueIDGenerator();

		this.areas = new Map();
		this.now = Date.now();

		this.destroy = this.destroy.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.empty = this.empty.bind(this);
		this.update = this.update.bind(this);
		this.create = this.create.bind(this);

		// Update the Lists
		this.interval = setInterval(() => {
			this.now = Date.now();
			this.areas.forEach((v, k) => {
				v.data.update(this.now - v.timeRange, this.now);
			});
		}, parseInt(settings.DataUpdateTime));
	}

	destroy() {
		clearInterval(this.interval);

		this.areas.forEach((v,k) => {
			v.data.destroy();
		});
		this.areas = new Map();
	}

	create(startTime, endTime, func) {
		return null;
	}

	// Register a specific time. The function is called if the data is available
	register(timeRange, func) {
		const key = this.keyGen.generate();

		let d = this.create(this.now - timeRange, null, func);
		this.areas.set(key, { timeRange: timeRange, data: d });

		return key;
	}

	update(key, timeRange) {
		let d = this.areas.get(key);
		if (IsNullOrUndefined(d))
			return;
		d.timeRange = timeRange;
		d.data.update(this.now - d.timeRange, null);
	}

	unregister(key) {
		const d = this.areas.get(key);
		if (IsNullOrUndefined(d))
			return;
		d.data.destroy();

		this.areas.delete(key);
		this.keyGen.release(key);
	}

	empty() {
		return this.areas.size === 0;
	}
}