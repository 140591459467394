/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 365.76 270.93333"
            className="svgIcon"
        >
            <path d="M 182.88,146.26335 0.243837,0 H 365.54325 Z m 0,38.67573 L 0,38.567354 V 270.93333 H 365.76001 V 38.608003 Z" />
        </svg>
    );
}