/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import NotificationService from '../Misc/NotificationService.js';
import settings from 'Settings';
import IsNullOrUndefined from "../Misc/Utility.js";
import cloneDeep from 'lodash/cloneDeep';
import UniqueIDGenerator from '../Misc/UniqueIDGenerator.js';
import CurrentUser from "./CurrentUser.js";
import GroupedFetch from './Intern/GroupedFetch.js';
import BaseData from './Intern/BaseData.js';
import BaseList from './Intern/BaseList.js';

class Data extends BaseData {
	constructor(ID: string, fetch: GroupedFetch) {
		super(ID, fetch, ID);

		this.initUpdateListener = this.initUpdateListener.bind(this);
		this.releaseUpdateListener = this.releaseUpdateListener.bind(this);
		this.handleHeartbeatMessage = this.handleHeartbeatMessage.bind(this);
		this.updateTimer = this.updateTimer.bind(this);
		this.checkTimeout = this.checkTimeout.bind(this);
		this.dataChanged = this.dataChanged.bind(this);
		this.informKey = this.informKey.bind(this);

		this.currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
		this.currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

		this.heartbeatStatus = null;
		this.heartbeatStatusTime = null;
		this.heartbeatLastActivity = null;
		this.timeoutKey = null;

		this.machineNotificationKey = null;
		this.heartbeatNotificationKey = null;
		this.currentUserKey = null;

		this.init("MachineData");
	}

	initUpdateListener() {
		// Notification
		this.machineNotificationKey = (new NotificationService()).registerMachineChanged(this.ID, (machineID) => {
			this.triggerUpdateData();
		});
		this.heartbeatNotificationKey = (new NotificationService()).registerHeartbeatMessage(this.ID, this.handleHeartbeatMessage);

		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
			const currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

			if (this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID &&
				this.currentUserRightsChangeID === currentUserRightsChangeID)
				return;

			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
			this.currentUserRightsChangeID = currentUserRightsChangeID;

			// Update the data
			this.reload();
		});
	}

	releaseUpdateListener() {
		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}

		if (this.machineNotificationKey !== null) {
			(new NotificationService()).unregister(this.machineNotificationKey);
			this.machineNotificationKey = null;
		}
		if (this.heartbeatNotificationKey !== null) {
			(new NotificationService()).unregister(this.heartbeatNotificationKey);
			this.heartbeatNotificationKey = null;
		}

		if (this.timeoutKey !== null) {
			clearTimeout(this.timeoutKey);
			this.timeoutKey = null;
		}
	}

	handleHeartbeatMessage(machineID, status, lastActivity, statusTime) {
		let changed = false;

		// Update the Data
		if (IsNullOrUndefined(this.heartbeatLastActivity)) {
			if (!IsNullOrUndefined(lastActivity)) {
				this.heartbeatLastActivity = lastActivity;
				changed = true;
			}
		}
		else {
			if (!IsNullOrUndefined(lastActivity)) {
				if (this.heartbeatLastActivity < lastActivity) {
					this.heartbeatLastActivity = lastActivity;
					changed = true;
				}
			}
		}

		if (IsNullOrUndefined(this.heartbeatStatusTime)) {
			if (!IsNullOrUndefined(statusTime)) {
				this.heartbeatStatusTime = statusTime;
				this.heartbeatStatus = status;
				changed = true;
			}
		}
		else {
			if (!IsNullOrUndefined(statusTime)) {
				if (this.heartbeatStatusTime < statusTime) {
					this.heartbeatStatusTime = statusTime;
					this.heartbeatStatus = status;
					changed = true;
				}
			}
		}

		if (changed) {
			// Update the Timer
			this.updateTimer();

			// Inform the Functions
			this.inform();
		}
	}

	updateTimer() {
		if (this.timeoutKey !== null) {
			clearTimeout(this.timeoutKey);
			this.timeoutKey = null;
		}

		let lastActivity = 0;
		if (!IsNullOrUndefined(this.data.lastActivity)) {
			if (!IsNullOrUndefined(this.heartbeatLastActivity)) {
				lastActivity = Math.max(this.data.lastActivity, this.heartbeatLastActivity);
			}
			else {
				lastActivity = this.data.lastActivity;
			}
		}
		else {
			if (!IsNullOrUndefined(this.heartbeatLastActivity)) {
				lastActivity = this.heartbeatLastActivity;
			}
		}

		const age = Date.now() - lastActivity;
		this.timeoutKey = setTimeout(this.checkTimeout, Math.max(settings.MachineInactiveTime - age, 5));
	}

	checkTimeout() {
		this.timeoutKey = null;
		this.inform();
	}

	dataChanged() {
		// Update the Timer
		this.updateTimer();
	}

	informKey(key) {
		const f = this.functions.get(key);
		if (IsNullOrUndefined(f))
			return;

		let data = cloneDeep(this.data);

		// Fix the lastActivity
		if (!IsNullOrUndefined(data.lastActivity)) {
			if (!IsNullOrUndefined(this.heartbeatLastActivity)) {
				data.lastActivity = Math.max(data.lastActivity, this.heartbeatLastActivity);
			}
		}
		else {
			data.lastActivity = this.heartbeatLastActivity;
		}

		// Fix the status
		if (!IsNullOrUndefined(data.statusTime)) {
			if (!IsNullOrUndefined(this.heartbeatStatusTime)) {
				if (data.statusTime < this.heartbeatStatusTime) {
					data.status = this.heartbeatStatus;
					data.statusTime = this.heartbeatStatusTime;
				}
			}
		}
		else {
			data.status = this.heartbeatStatus;
			data.statusTime = this.heartbeatStatusTime;
		}

		const now = Date.now();
		if (!IsNullOrUndefined(data.lastActivity)) {
			if (data.lastActivity + settings.MachineInactiveTime < now) {
				data.status = "OFFLINE";
				data.statusTime = now;
			}
		}

		try {
			f(data, this.dataError);
		} catch (err) {
			// Nothing to do. Errorhandling has to be done in component
			console.error("Error during processing of registered function. Error: " + err);
		}
	}
}

class List extends BaseList {
	constructor(firstIndex, count, sortField, sortOrder, filters, func) {
		super("/api/Machine/list", firstIndex, count, sortField, sortOrder, filters, func);

		this.init = this.init.bind(this);
		this.release = this.release.bind(this);
		this.unregisterMachines = this.unregisterMachines.bind(this);
		this.handleMachineMessage = this.handleMachineMessage.bind(this);
		this.handleHeartbeatMessage = this.handleHeartbeatMessage.bind(this);
		this.dataChanged = this.dataChanged.bind(this);
		this.transformFilter = this.transformFilter.bind(this);

		this.currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
		this.currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

		this.currentUserKey = null;

		this.newMachineNotification = null;
		this.keys = [];
		this.MachineKeys = [];
	}

	init() {
		super.init();

		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
			const currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

			if (this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID &&
				this.currentUserRightsChangeID === currentUserRightsChangeID)
				return;

			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
			this.currentUserRightsChangeID = currentUserRightsChangeID;

			// Update the data
			this.reload();
		});

		this.newMachineNotification = (new NotificationService()).registerNewMachine((machineID) => {
			// Update the data
			this.reload();
		});
	}

	release() {
		if (!IsNullOrUndefined(this.newMachineNotification)) {
			(new NotificationService()).unregister(this.newMachineNotification);
			this.newMachineNotification = null;
		}

		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}

		// Remove old machines
		this.unregisterMachines();

		super.release();
	}

	// Unregister the currently registered Machines
	unregisterMachines() {
		this.keys.forEach((element) => {
			(new NotificationService()).unregister(element);
		});
		this.MachineKeys.forEach((element) => {
			(new NotificationService()).unregister(element);
		});

		this.keys = [];
		this.MachineKeys = [];
	}

	handleHeartbeatMessage(machineID, status, lastActivity, statusTime) {
		this.triggerUpdateData();
	}

	handleMachineMessage() {
		this.triggerUpdateData();
	}

	dataChanged() {
		// Remove old machines
		this.unregisterMachines();

		// Register the machines
		for (let i = 0; i < this.data.length; i++) {
			var machineid = this.data[i].id;
			this.keys.push((new NotificationService()).registerHeartbeatMessage(machineid, this.handleHeartbeatMessage));
			this.MachineKeys.push((new NotificationService()).registerMachineChanged(machineid, this.handleMachineMessage));
		}
	}

	transformFilter() {
		// Create the filters
		let serialNumberFilter = null;
		let lastActivityFilter = null;
		let typeFilter = null;
		let statusFilter = null;
		let plantFilter = null;
		let lineFilter = null;

		for (const fieldId in this.filters) {
			const val = this.filters[fieldId];

			if (fieldId === "serialNumber") {
				if (val === '-')
					serialNumberFilter = { textFilter: null };
				else
					serialNumberFilter = { textFilter: val };
			}
			else if (fieldId === "type") {
				if (val === '-')
					typeFilter = { textFilter: null };
				else
					typeFilter = { textFilter: val };
			}
			if (fieldId === "line") {
				if (val === '-')
					lineFilter = { textFilter: null };
				else
					lineFilter = { textFilter: val };
			}
			if (fieldId === "plant") {
				if (val === '-')
					plantFilter = { textFilter: null };
				else
					plantFilter = { textFilter: val };
			}
			else if (fieldId === "lastActivity") {
				lastActivityFilter = { startTime: val.start, endTime: val.end };
			}
			else if (fieldId === "status") {
				let v = [];
				switch (val) {
					case "PRODUCING":
						// Producing
						v.push('PRODUCING');
						break;
					case "READYTOPRODUCE":
						// readytoproduce
						v.push('READYTOPRODUCE');
						break;
					case "DRIVESOFF":
						// drivesoff
						v.push('DRIVESOFF');
						break;
					case "ACTIONREQUIRED":
						// actionrequired
						v.push('PRODUCINGACTIONREQUIRED');
						v.push('ACTIONREQUIRED');
						break;
					case "SETUPOPERATION":
						// setupoperation
						v.push('SETUPOPERATION');
						break;
					case "MAINTENANCE":
						// maintenance
						v.push('MAINTENANCE');
						break;
					case "FAILURE":
						// failure
						v.push('FAILURE');
						break;
					case "POWERUP":
						// powerup
						v.push('POWERUP');
						break;
					case "OFFLINE":
					default:
						// offline
						v = null;
						break;
				}
				statusFilter = { status: v, time: this.state.time - parseInt(settings.MachineInactiveTime) };
			}
		}

		return {
			serialNumberFilter: serialNumberFilter,
			typeFilter: typeFilter,
			statusFilter: statusFilter,
			lineFilter: lineFilter,
			plantFilter: plantFilter,
			lastActivityFilter: lastActivityFilter
		};
	}
}

let instance;
export default class MachineData {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.keys = new Map();
		this.keyGen = new UniqueIDGenerator();

		this.fetch = new GroupedFetch("/api/Machine/GetData");
		this.machineArray = new Map();
		this.listArray = new Map();

		this.destroy = this.destroy.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.checkDelete = this.checkDelete.bind(this);
		this.list = this.list.bind(this);
		this.updateList = this.updateList.bind(this);
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance)) {
			instance.destroy();
		}

		instance = null;
	}

	destroy() {
		this.machineArray.forEach((v, k) => {
			if (IsNullOrUndefined(v.data)) {
				v.data.destroy();
				v.data = null;
			}

			if (!IsNullOrUndefined(v.releaseTimeoutKey)) {
				clearTimeout(v.releaseTimeoutKey);
				v.releaseTimeoutKey = null;
			}
		});
		this.listArray.forEach((v, k) => {
			v.release();
			v.destroy();
		});

		this.listArray.clear();
		this.machineArray.clear();
		this.keys.clear();

		if (!IsNullOrUndefined(this.fetch))
			this.fetch.destroy();
		this.fetch = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	// Register for the current dataset
	register(machineID, func) {
		let m = this.machineArray.get(machineID);
		if (IsNullOrUndefined(m)) {
			m = { releaseTimeoutKey: null, data: null };
			this.machineArray.set(machineID, m);
		}

		if (IsNullOrUndefined(m.data)) {
			let data = new Data(machineID, this.fetch);
			m.data = data;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Register the Function
		const subKey = m.data.register(func);

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: 0, key: subKey, machineID: machineID });

		return key;
	}

	// Register for the current dataset
	list(firstIndex, count, sortField, sortOrder, filters, func) {
		let data = new List(firstIndex, count, sortField, sortOrder, filters, func);
		data.init();

		// Integrate the key
		const key = this.keyGen.generate();
		this.listArray.set(key, data );

		this.keys.set(key, { type: 1 });

		return key;
	}

	updateList(key, firstIndex, count, sortField, sortOrder, filters) {
		const m = this.listArray.get(key);
		if (IsNullOrUndefined(m))
			return true;

		return m.update(firstIndex, count, sortField, sortOrder, filters);
	}

	// Unregister
	unregister(key) {
		const obj = this.keys.get(key);
		if (IsNullOrUndefined(obj))
			return;
		switch (obj.type) {
			case 0:
				{
					const m = this.machineArray.get(obj.machineID);
					if (IsNullOrUndefined(m))
						return;

					m.data.unregister(obj.key);

					if (IsNullOrUndefined(m.releaseTimeoutKey)) {
						m.releaseTimeoutKey = setTimeout(() => { m.releaseTimeoutKey = null; this.checkDelete(obj.machineID); }, settings.LocalDataHoldTime);
					}
				} break;
			case 1:
				{
					const m = this.listArray.get(key);
					if (IsNullOrUndefined(m))
						return;

					m.release();
					m.destroy();
					this.listArray.delete(key);
				} break;
			default:	
				break;
		}

		// Free the key
		this.keys.delete(key);
		this.keyGen.release(key);
	}

	// Check the release
	checkDelete(machineID) {
		const m = this.machineArray.get(machineID);
		if (IsNullOrUndefined(m))
			return;

		if (!IsNullOrUndefined(m.data)) {
			if (m.data.empty()) {
				m.data.destroy();
				m.data = null;
			}
		}

		if (IsNullOrUndefined(m.data))
			this.machineArray.delete(machineID);
	}
}