/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is the connection manager which holds the current status of the connection
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import UniqueIDGenerator from "./UniqueIDGenerator";
import IsNullOrUndefined from "./Utility";
import { AsyncExecution } from "./Utility";
import OnlineChecker from "./OnlineChecker";
import FetchCache from "./FetchCache";
import settings from 'Settings';

const STATUS = {
	OFFLINE: "offline",
	ONLINE: "online"
};

let instance;
export default class ConnectionStatusManager {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.keys = new UniqueIDGenerator();
		this.registrations = new Map();
		this.status = STATUS.OFFLINE;
		this.connectionStatus = STATUS.OFFLINE;
		this.notificationStatus = STATUS.OFFLINE;
		this.timeout = null;

		this.destroy = this.destroy.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.setNotificationStatus = this.setNotificationStatus.bind(this);
		this.setConnectionStatus = this.setConnectionStatus.bind(this);
		this.getStatus = this.getStatus.bind(this);
		this.getConnectionStatus = this.getConnectionStatus.bind(this);
		this.updateStatus = this.updateStatus.bind(this);
		this.check = this.check.bind(this);

		// Initial check
		this.timeout = setTimeout(() => { this.timeout = null; this.check(); }, 0);
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance))
			instance.destroy();
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	destroy() {
		this.registrations.forEach((e) => {
			if (e.timeout !== null) {
				clearTimeout(e.timeout);
				e.timeout = null;
			}
		});

		if (!IsNullOrUndefined(this.timeout)) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
	}

	static get STATUS() {
		return STATUS;
	}

	register(callback) {
		const key = this.keys.generate();

		let abortController = new AbortController();
		this.registrations.set(key, { abortController: abortController, callback: callback, inProcess: true });

		AsyncExecution(() => {
			const obj = this.registrations.get(key);
			if (IsNullOrUndefined(obj))
				return;
			obj.inProcess = false;

			try {
				obj.callback(this.status);
			} catch (e) {
				console.error("Exception in callback function");
			}
		}, abortController.signal);

		return key;
	}

	unregister(key) {
		const obj = this.registrations.get(key);
		if (!IsNullOrUndefined(obj)) {
			obj.abortController.abort();
			obj.abortController = null;
		}

		if(this.registrations.delete(key))
			this.keys.release(key);
	}

	setNotificationStatus(status) {
		if (this.notificationStatus === status)
			return;
		this.notificationStatus = status;

		this.updateStatus(this.connectionStatus, this.notificationStatus);
	}

	setConnectionStatus(status) {
		if (this.connectionStatus === status)
			return;
		this.connectionStatus = status;

		this.updateStatus(this.connectionStatus, this.notificationStatus);

		if (this.connectionStatus === STATUS.OFFLINE) {
			// Continously recheck the connection status until we are online again
			if (IsNullOrUndefined(this.timeout))
				this.timeout = setTimeout(() => { this.timeout = null; this.check(); }, settings.initialConnectionCheckTimeout);
		} else {
			// Trigger resend of all data
			(new FetchCache()).sendRequests();
		}
	}

	updateStatus(connectionStatus, notificationStatus) {
		let newStatus = STATUS.ONLINE;
		if (this.connectionStatus === STATUS.OFFLINE)
			newStatus = STATUS.OFFLINE;
		if (this.notificationStatus === STATUS.OFFLINE)
			newStatus = STATUS.OFFLINE;

		if (newStatus === this.status)
			return;
		this.status = newStatus;

		// Inform eth
		this.registrations.forEach((e) => {
			if (e.inProcess)
				return;
			e.inProcess = true;

			AsyncExecution(() => {
				e.inProcess = false;
				try {
					e.callback(this.status);
				} catch (e) {
					console.error("Exception in callback function");
				}
			}, e.abortController.signal);
		});
	}

	getStatus() {
		return this.status;
	}

	getConnectionStatus() {
		return this.connectionStatus;
	}

	check() {
		OnlineChecker.checkConnection(() => {
			// Inform the connection status manager
			this.setConnectionStatus(ConnectionStatusManager.STATUS.ONLINE);
		}, () => {
			// We are still down -> Recheck after time
			if (IsNullOrUndefined(this.timeout))
				this.timeout = setTimeout(() => { this.timeout = null; this.check(); }, settings.connectionCheckTimeout);
		});
	}

}