/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import ProtectedApp from './Protected/App';
import PublicApp from './Public/App';
import AuthorizeRoute from './Authorization/AuthorizeRoute';
import Localizer from './BusinessLogic/Misc/Localizer'
import Loading from './Components/Loading.js';
import Error from './Components/Error.js';
import CookieConsent from './Public/Components/CookieConsent';
import GlobalAppData from 'GlobalAppData';
import ErrorManager from './BusinessLogic/Misc/ErrorManager.js';
import Navigator from './BusinessLogic/Misc/Navigator';
import CurrentUser from './BusinessLogic/DataManagement/CurrentUser.js';
import NavigatorComponent from './Components/Navigator.js';
import { ToastContainer } from 'react-toastify';
import TelemetryProvider from './BusinessLogic/Tracking/TelemetryProvider';
import AutomaticReload from "./Components/AutomaticReload";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

export default class App extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.languageKey = null;
		this.apiIncompatibleErrorKey = null;

		this.state = {
			localizationLoaded: false,
			localizationError: false
		}
	}

	componentDidMount() {
		// Init the basic singletons, which should be also available if the user is not logged on.

		// Setup the Localization Service
		this.languageKey = (new Localizer()).register((loaded, error) => {
			this.setState({
				localizationLoaded: loaded,
				localizationError: error
			});
		});

		// Setup the ErrorManager Service
		this.apiIncompatibleErrorKey = (new ErrorManager()).registerAPIIncompatible(() => {
			if ((new CurrentUser()).shouldReloadOnAPIIncompatibility()) {
				setTimeout(() => {
					(new Navigator()).reload();
				}, 5000);
			}
		});

		(new GlobalAppData());
	}

	componentWillUnmount() {
		if (this.apiIncompatibleErrorKey !== null) {
			(new ErrorManager()).unregister(this.apiIncompatibleErrorKey);
			this.apiIncompatibleErrorKey = null;
		}

		if (this.languageKey !== null) {
			(new Localizer()).unregister(this.languageKey);
			this.languageKey = null;
		}
	}

	render() {
		if (this.state.localizationError) {
			return (
				<>
					<div className="w-100 h-100">
						<Error />
					</div>
				</>);
		} else if (!this.state.localizationLoaded) {
			return (
				<>
					<div className="w-100 h-100">
						<Loading />
					</div>
				</>);
		}
		else {
			return (
				<>
					<TelemetryProvider>
						<BrowserRouter basename={baseUrl}>
							<Routes>
								<Route path='/Protected/*' element={
									<AuthorizeRoute>
										<ProtectedApp />
									</AuthorizeRoute>} />
								<Route path='/*' element={<PublicApp />} />
							</Routes>
							<NavigatorComponent />
							<CookieConsent />
						</BrowserRouter>
					</TelemetryProvider>
					<ToastContainer theme="colored" pauseOnFocusLoss={false} />
					<AutomaticReload />
				</>
			);
		}
	}
}