/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 270.93333 270.93333"
            className="svgIcon"
        >
            <path
                d="M 88.464024,258.619 H 12.31405 v -73.68716"
                fill="none"
                strokeWidth="24.6281" />
            <line
                x1="13.225307"
                x2="70.362411"
                y1="261.08167"
                y2="194.58626"
                fill="none"
                strokeWidth="24.6281" />
            <path
                d="m 182.42009,258.619 h 76.19923 v -73.68716"
                fill="none"
                strokeWidth="24.6281" />
            <line
                x1="257.53568"
                x2="200.52164"
                y1="261.08167"
                y2="194.58626"
                fill="none"
                strokeWidth="24.6281" />
            <path
                d="M 88.464024,12.31405 H 12.31405 V 85.9889"
                fill="none"
                strokeWidth="24.6281" />
            <line
                x1="13.225307"
                x2="70.362411"
                y1="9.8510838"
                y2="76.34716"
                fill="none"
                strokeWidth="24.6281" />
            <path
                d="m 182.42009,12.31405 h 76.19923 V 85.9889"
                fill="none"
                strokeWidth="24.6281" />
            <line
                x1="257.53568"
                x2="200.52164"
                y1="9.8510838"
                y2="76.34716"
                fill="none"
                strokeWidth="24.6281" />
        </svg>
    );
}