/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import Moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/zh-cn';
import NumberFormat from './NumberFormat.js';
import GlobalAppData from "GlobalAppData";

var instance;
class DurationFormat {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		// Language
		this.language = "en";
		this.hasLanguage = false;

		this.setDefaultLanguage = this.setDefaultLanguage.bind(this);
		this.setLanguage = this.setLanguage.bind(this);

		this.setDefaultLanguage();
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	// Set the default language
	setDefaultLanguage() {
		if (!(new GlobalAppData()).isNativeApp()) {
			const locales = navigator.language || navigator.userLanguage;
			for (var i = 0; i < locales.length; i++) {
				if (locales[i].languageCode === "en-US") {
					this.setLanguage("en-us");
					return;
				}
				if (locales[i].languageCode === "de-DE") {
					this.setLanguage("de-de");
					return;
				}
				if (locales[i].languageCode === "zh-CN") {
					this.setLanguage("zh-cn");
					return;
				}
			}
		}
		// Nothing found. go back to English
		this.setLanguage("en-us");
	}

	// Set the Language to use. The CORE-Language settings are used here
	setLanguage(language) {
		if (process.env.NODE_ENV === 'development') {
			this.hasLanguage = true;
		}

		switch (language) {
			case 'de-de':
				this.language = "de";
				this.hasLanguage = true;
				break;
			case 'en-us':
				this.language = "en";
				this.hasLanguage = true;
				break;
			case 'zh-cn':
				this.language = "zh-cn";
				this.hasLanguage = true;
				break;
			default:
				this.language = "en";
				this.hasLanguage = true;
					if (process.env.NODE_ENV === 'development') {
						console.warn("Language '" + language + "' is invalid. Using default.");
					}
				break;
		}
	}

	/** Create the moment class
	 * @param date The Date to parse (Milliseconds or Date)
	 * @return The created class
	 */
	createMoment(timespan) {
		const m = Moment.duration(timespan);

		if (process.env.NODE_ENV === 'development') {
			if (this.hasLanguage === false) {
				console.warn("Language not set. Using default.");
			}
		}

		m.locale(this.language);
		return m;
	}

	/** Get the Duration in a human readable format (e.g. "4 days, 2 hours, 2 seconds")
	 * @param timespan The time to parse [ms]
	 * @param enableSeconds Is the visualisation of the seconds enabled? (Default: false)
	 */
	format(timespan, enableSeconds) {
		if (typeof enableSeconds === 'undefined')
			enableSeconds = false;

		const d = this.createMoment(timespan);

		const seconds = d.get('seconds');
		const minutes = d.get('minutes');
		const hours = d.get('hours');
		const days = Math.floor(d.as('days'));

		const locale = d.localeData();
		let res = '';

		if (days > 0) {
			if (days <= 1)
				res += locale.relativeTime(days || 1, true, 'd', false);
			else
				res += locale.relativeTime(days || 1, true, 'dd', false);

			if (hours > 0 || minutes > 0 || (seconds > 0 && enableSeconds))
				res += ", ";
		}

		if (hours > 0) {
			if (hours <= 1)
				res += locale.relativeTime(hours || 1, true, 'h', false);
			else
				res += locale.relativeTime(hours || 1, true, 'hh', false);

			if (minutes > 0 || (seconds > 0 && enableSeconds))
				res += ", ";
		}

		if (minutes > 0) {
			if (minutes <= 1)
				res += locale.relativeTime(minutes || 1, true, 'm', false);
			else
				res += locale.relativeTime(minutes || 1, true, 'mm', false);

			if (seconds > 0 && enableSeconds)
				res += ", ";
		}

		if (seconds > 0 && enableSeconds) {
			if (seconds <= 1)
				res += locale.relativeTime(seconds || 1, true, 's', false);
			else
				res += locale.relativeTime(seconds || 1, true, 'ss', false);
		}

		// Eth. Zero?
		if (enableSeconds) {
			if (days === 0 && hours === 0 && minutes === 0 && seconds === 0)
				res = locale.relativeTime(0, true, 'ss', false);
		}
		else {
			if (days === 0 && hours === 0 && minutes === 0)
				res = locale.relativeTime(0, true, 'mm', false);
		}

		return res;
	}

	/** Get the Duration in a human readable format (e.g. "4.2 s")
	 * @param timespan The time to parse [ms]
	 * @param scaleFactor The Factor for the Scaling [ms]
	 * @param appendUnit Append the Unit to the result
	 */
	formatScaledSimplified(timespan, scaleFactor, appendUnit) {
		if (typeof appendUnit === "undefined")
			appendUnit = true;

		const d = this.createMoment(timespan);
		const ms = d.as('milliseconds');

		const sd = this.createMoment(scaleFactor);
		const sms = sd.as('milliseconds');

		// ms
		if (sms < 1000) {
			if (appendUnit === true)
				return (new NumberFormat()).format(ms, 0, 0) + " ms";
			else
				return (new NumberFormat()).format(ms, 0, 0);
		}
		// s
		else if (sms < (2 * 60 * 1000)) {
			if (appendUnit === true)
				return (new NumberFormat()).format(ms / (1000), 1, 1) + " s";
			else
				return (new NumberFormat()).format(ms / (1000), 1, 1);
		}
		// min
		else if (sms < (2 * 60 * 60 * 1000)) {
			if (appendUnit === true)
				return (new NumberFormat()).format(ms / (1000 * 60), 1, 1) + " min";
			else
				return (new NumberFormat()).format(ms / (1000 * 60), 1, 1);
		}
		// h
		else if (sms < (2 * 24 * 60 * 60 * 1000)) {
			if (appendUnit === true)
				return (new NumberFormat()).format(ms / (1000 * 60 * 60), 1, 1) + " h";
			else
				return (new NumberFormat()).format(ms / (1000 * 60 * 60), 1, 1);
		}
		// d
		else {
			if (appendUnit === true)
				return (new NumberFormat()).format(ms / (1000 * 60 * 60 * 24), 1, 1) + " d";
			else
				return (new NumberFormat()).format(ms / (1000 * 60 * 60 * 24), 1, 1);
		}
	}

	/** Get the Duration in a human readable format (e.g. "4.2 s")
	 * @param timespan The time to parse [ms]
	 * @param appendUnit Append the Unit to the result
	 */
	formatSimplified(timespan, appendUnit) {
		if (typeof appendUnit === "undefined")
			appendUnit = true;

		const d = this.createMoment(timespan);

		const ms = d.as('milliseconds');

		// ms
		if (ms < 1000) {
			if(appendUnit === true)
				return (new NumberFormat()).format(ms, 0, 0) + " ms";
			else
				return (new NumberFormat()).format(ms, 0, 0);
		}
		// s
		else if (ms < (2 * 60 * 1000)) {
			if (appendUnit === true)
				return (new NumberFormat()).format(ms / (1000), 1, 1) + " s";
			else
				return (new NumberFormat()).format(ms / (1000), 1, 1);
		}
		// min
		else if (ms < (2 * 60 * 60 * 1000)) {
			if (appendUnit === true)
				return (new NumberFormat()).format(ms / (1000 * 60), 1, 1) + " min";
			else
				return (new NumberFormat()).format(ms / (1000 * 60), 1, 1);
		}
		// h
		else if (ms < (2 * 24 * 60 * 60 * 1000)) {
			if (appendUnit === true)
				return (new NumberFormat()).format(ms / (1000 * 60 * 60), 1, 1) + " h";
			else
				return (new NumberFormat()).format(ms / (1000 * 60 * 60), 1, 1);
		}
		// d
		else {
			if (appendUnit === true)
				return (new NumberFormat()).format(ms / (1000 * 60 * 60 * 24), 1, 1) + " d";
			else
				return (new NumberFormat()).format(ms / (1000 * 60 * 60 * 24), 1, 1);
		}
	}
}

export default DurationFormat;