/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import Moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/zh-cn';
import GlobalAppData from "GlobalAppData";
import IsNullOrUndefined from "../Misc/Utility";

var instance;

class DateFormat {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;
		// Language
		this.language = "en";
		this.hasLanguage = false;
		this.enumTimeFormat = {
			EUROPEANTIMEFORMAT: '24H',
			AMERICANTIMEFORMAT: '12H'
		}

		this.getTimeFormatString = this.getTimeFormatString.bind(this);
		this.getDateTimeFormat = this.getDateTimeFormat.bind(this);
		this.getTimeFormatEnum = this.getTimeFormatEnum.bind(this);
		this.setDefaultLanguage = this.setDefaultLanguage.bind(this);
		this.setLanguage = this.setLanguage.bind(this);

		this.setDefaultLanguage();
	}
	
	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	// Set the default language
	setDefaultLanguage() {
		if (!(new GlobalAppData()).isNativeApp()) {
			const locales = navigator.language || navigator.userLanguage;
			for (var i = 0; i < locales.length; i++) {
				if (locales[i].languageCode === "en-US") {
					this.setLanguage("en-us");
					return;
				}
				if (locales[i].languageCode === "de-DE") {
					this.setLanguage("de-de");
					return;
				}
				if (locales[i].languageCode === "zh-CN") {
					this.setLanguage("zh-cn");
					return;
				}
			}
		}
		// Nothing found. go back to English
		this.setLanguage("en-us");
	}

	// Set the Language to use. The CORE-Language settings are used here
	setLanguage(language) {
		if (process.env.NODE_ENV === 'development') {
			this.hasLanguage = true;
		}

		switch (language) {
			case 'de-de':				
				this.language = "de";
				this.hasLanguage = true;			
				break;
			case 'en-us':			
				this.language = "en";
				this.hasLanguage = true;				
				break;
			case 'zh-cn':
				this.language = "zh-cn";
				this.hasLanguage = true;
				break;
			default:				
				this.language = "en";
				this.hasLanguage = true;
					if (process.env.NODE_ENV === 'development') {
						console.warn("Language '" + language + "' is invalid. Using default.");
					}								
				break;
		}
	}

	getTimeFormatEnum() {
		switch (this.language) {
			case "de":
				return this.enumTimeFormat.EUROPEANTIMEFORMAT;
			case "en":
				return this.enumTimeFormat.AMERICANTIMEFORMAT;
			case "zh-cn":
				return this.enumTimeFormat.EUROPEANTIMEFORMAT;
			default:
				return this.enumTimeFormat.EUROPEANTIMEFORMAT;
		}		
	}
	getTimeFormatString() {
		switch (this.language) {
			case "de":
				return "HH:mm";
			case "en":
				return "hh:mm aa";
			case "zh-cn":
				return "HH:mm";
			default:
				return "hh:mm aa";
		}
	}	

	/** Get the Date-Format which is used for the language (e.g. 01.04.2020)
	 */
	getDateFormat() {
		switch (this.language) {
			case "de":
				return "dd.MM.yyyy";
			case "en":
				return "MM/dd/yyyy";
			case "zh-cn":
				return "yyyy-MM-dd";
			default:
				return "MM/dd/yyyy";
		}
	}
	getDateTimeFormat() {
		switch (this.language) {
			case "de":
				return "dd.MM.yyyy HH:mm";
			case "en":
				return "MM/dd/yyyy hh:mm aa";
			case "zh-cn":
				return "yyyy-MM-dd HH:mm";
			default:
				return "MM/dd/yyyy hh:mm aa";
		}
	}	

	/** Create the moment class
	 * @param date The Date to parse (Milliseconds or Date)
	 * @return The created class
	 */
	createMoment(date) {
		const m = Moment(date);

		if (process.env.NODE_ENV === 'development') {
			if (this.hasLanguage === false) {
				console.warn("Language not set. Using default.");
			}
		}

		m.locale(this.language);

		return m;
	}

	/** Get the Date as short string (e.g. "21.10.2019")
	 * @param date The Date to parse (Milliseconds or Date)
	 */
	formatDate(date) {
		const m = this.createMoment(date);
		return m.format("L");
	}

	/** Get the Time as short string (e.g. "08:32")
	 * @param date The Date to parse (Milliseconds or Date)
	 * @param seconds Display seconds
	 */
	formatTime(date, seconds) {
		const m = this.createMoment(date);

		if (typeof seconds === "undefined")
			return m.format("LT");

		if (seconds === true)
			return m.format("LTS");
		else
			return m.format("LT");
	}

	/** Get the Date and Time format (e.g. "September 4, 1986 8:30 PM")
	 * @param date The Date to parse (Milliseconds or Date)
	 */
	formatDateTime(date, seconds) {
		const m = this.createMoment(date);

		if (IsNullOrUndefined(seconds))
			return m.format("LLL");

		if (seconds)
			return m.format("LL LTS");
		else
			return m.format("LLL");
	}

	/** Get the Date and Time format (e.g. "Sep 4, 1986 8:30 PM")
	 * @param date The Date to parse (Milliseconds or Date)
	 */
	formatShortDateTime(date, seconds) {
		const m = this.createMoment(date);

		if (IsNullOrUndefined(seconds))
			return m.format("lll");

		if (seconds)
			return m.format("ll LTS");
		else
			return m.format("lll");
	}

	/** Get the Date and Time format from now (e.g. "in 2 seconds")
	 * @param date The Date to parse (Milliseconds or Date)
	 */
	formatFromNow(date) {
		const m = this.createMoment(date);
		return m.fromNow();
	}

	/** Get the Date as short string (e.g. "2019")
	 * @param date The Date to parse (Milliseconds or Date)
	 */
	formatYear(date) {
		const m = this.createMoment(date);
		return m.format("YYYY");
	}

}

export default DateFormat;