/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

const RIGHTS = {
	Group_AddRemove: "Group_AddRemove",
	Group_EditDetails: "Group_EditDetails",
	Group_EditCRMCompanyID: "Group_EditCRMCompanyID",
	Group_ViewGroups: "Group_ViewGroups",
	Group_ViewAllGroups: "Group_ViewAllGroups",
	Group_EditRights: "Group_EditRights",
	Group_ViewRights: "Group_ViewRights",
	Group_ActivateDeactivate: "Group_ActivateDeactivate",
	Group_EditAllRights: "Group_EditAllRights",
	Group_ExtensionManagement: "Group_ExtensionManagement",
	Group_CreateRootGroup: "Group_CreateRootGroup",

	User_AddRemove: "User_AddRemove",
	User_ActivateDeactivate: "User_ActivateDeactivate",
	User_EditCRMUserID: "User_EditCRMUserID",
	User_EditCRMCompanyID: "User_EditCRMCompanyID",
	User_EditSAPCustomerID: "User_EditSAPCustomerID",
	User_EditEMailAddress: "User_EditEMailAddress",
	User_EditUserName: "User_EditUserName",
	User_EditUserData: "User_EditUserData",
	User_ViewUsers: "User_ViewUsers",
	User_ViewAllUsers: "User_ViewAllUsers",
	User_EditRights: "User_EditRights",
	User_ViewRights: "User_ViewRights",
	User_EditAllRights: "User_EditAllRights",
	User_ExtensionManagement: "User_ExtensionManagement",
	User_DemonstrationSettings: "User_DemonstrationSettings",

	Machine_ViewMachines: "Machine_ViewMachines",
	Machine_AddRemove: "Machine_AddRemove",
	Machine_ViewAllMachines: "Machine_ViewAllMachines",
	Machine_EnableOverview: "Machine_EnableOverview",
	Machine_EnableLastViewedMachines: "Machine_EnableLastViewedMachines",

	Tool_EnableOverview: "Tool_EnableOverview",
	Tool_EnableTypeOverview: "Tool_EnableTypeOverview",
	Tool_EnableDetails: "Tool_EnableDetails",
	Tool_EnableTypeDetails: "Tool_EnableTypeDetails",
	Tool_EnableLastViewedTools: "Tool_EnableLastViewedTools",

	Message_Enable: "Message_Enable",
	Message_Write: "Message_Write",
	Message_ReceiveMailNotification: "Message_ReceiveMailNotification",
	Message_ReceiveAppNotification: "Message_ReceiveAppNotification",
	Message_ReceiveBotNotification: "Message_ReceiveBotNotification",

	Order_Enable: "Order_Enable",

	Dashboard_View: "Dashboard_View",
	Dashboard_Edit: "Dashboard_Edit",

	App_ReceivePushNotifications: "App_ReceivePushNotifications",

	Update_Edit: "Update_Edit",
	Update_View: "Update_View",

	Extras_CreatePieceSerialNumber: "Extras_CreatePieceSerialNumber",

	Line_View: "Line_View",
	Line_ViewAll: "Line_ViewAll",
	Line_Create: "Line_Create",

	Plant_View: "Plant_View",
	Plant_ViewAll: "Plant_ViewAll",
	Plant_Create: "Plant_Create"
};

const MACHINERIGHTS = {
	Settings_EditAllRights: "Settings_EditAllRights",
	Settings_EditData: "Settings_EditData",
	Pieces_View: "Pieces_View",
	Pieces_DetailsView: "Pieces_DetailsView",
	Pieces_DetailsExport: "Pieces_DetailsExport",
	Pieces_Create: "Pieces_Create",
	Tools_View: "Tools_View",
	Components_View: "Components_View",
	Downloads_View: "Downloads_View",
	Downloads_UploadFile: "Downloads_UploadFile",
	Order_ViewSpareParts: "Order_ViewSpareParts",
	Order_OrderSpareParts: "Order_OrderSpareParts",
	Fluid_View: "Fluid_View",
	Energy_View: "Energy_View",
	Message_ReceiveMailNotification: "Message_ReceiveMailNotification",
	Message_ReceiveAppNotification: "Message_ReceiveAppNotification",
	Message_ReceiveBotNotification: "Message_ReceiveBotNotification",

	Update_Approve: "Update_Approve",
	Update_View: "Update_View"
};

const LINERIGHTS = {
	Settings_EditAllRights: "Settings_EditAllRights",
	Settings_Edit: "Settings_Edit",
	Pieces_View: "Pieces_View",
	Pieces_DetailsView: "Pieces_DetailsView"
};

const PLANTRIGHTS = {
	Settings_EditAllRights: "Settings_EditAllRights",
	Settings_Edit: "Settings_Edit"
};

class Rights {
	// Get the Rights
	static get RIGHTS() {
		return RIGHTS;
	}

	// Get the Rights
	static get MACHINERIGHTS() {
		return MACHINERIGHTS;
	}

	// Get the Rights
	static get LINERIGHTS() {
		return LINERIGHTS;
	}

	// Get the Rights
	static get PLANTRIGHTS() {
		return PLANTRIGHTS;
	}
}

export default Rights;