/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This JavaScript file is used for the edit-mode of the dashboard
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import UniqueIDGenerator from '../Misc/UniqueIDGenerator.js';
import Basket from "./Basket.js";
import ErrorManager from "../Misc/ErrorManager.js";
import RequestID from "../Misc/RequestID";
import isEqual from 'lodash/isEqual';
import FetchHelper from "../Misc/FetchHelper.js";
import { AsyncExecution } from "../Misc/Utility.js";
import IsNullOrUndefined from "../Misc/Utility.js";

let instance;
export default class BasketDetails {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.keys = new UniqueIDGenerator();
		this.functions = new Map();

		this.requestID = new RequestID();
		this.abortController = new AbortController()

		// Basket
		this.basketKey = null;
		this.items = [];
		this.basketItems = [];
		this.total = { currency: "EURO", price: 0.0 };
		this.price = { currency: "EURO", price: 0.0};
		this.tax = { currency: "EURO", price: 0.0};
		this.shipping = { currency: "EURO", price: 0.0};

		// Functions
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.inform = this.inform.bind(this);
		this.basketChanged = this.basketChanged.bind(this);
		this.updateData = this.updateData.bind(this);
		this.informKey = this.informKey.bind(this);

		// Register in Basket
		this.basketKey = (new Basket()).register(this.basketChanged);
	}

	// Destroy the class
	static destroySingleton() {
		// Remove the User
		if (this.basketKey !== null) {
			(new Basket()).unregister(this.basketKey);
			this.basketKey = null;
		}

		// Cancel all pending requests
		this.abortController.abort();

		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	basketChanged(items) {
		let changed = !isEqual(this.basketItems, items);

		this.basketItems = [ ...items ];

		// Download complete itemset
		if (changed)
			this.updateData(items);
	}

	updateData(items) {
		// Create the data
		let item = [];
		items.forEach((e) => {
			let n = e.count;
			if (isNaN(n))
				n = 0;

			// MachineID may be undefined
			item.push({
				machineID: e.machineID,
				id: e.item,
				quantity: n
			});
		});

		const data = {
			requestID: this.requestID.newRequest(),
			items: item
		};

		(async () => {
			try {
				const json = await FetchHelper.fetchData("/api/Order/GetItem", data, this.abortController);

				// Check request
				if (!this.requestID.validateRequest(json.requestID))
					return;

				this.items = json.items;
				this.price = json.price;
				this.total = json.total;
				this.shipping = json.shipping;
				this.tax = json.tax;

				// Inform the parent
				this.inform();
			} catch (err) {
				if (err.name === "Abort")
					return;
				else if (err.name === "APIIncompatible") {
					// Added error handling
					(new ErrorManager()).APIIncompatible();
				}
				else {
					// Added error handling
					(new ErrorManager()).dataReceiveError();
				}
			}
		})();
	}

	register(func) {
		// Integrate the key
		const key = this.keys.generate();
		this.functions.set(key, func);

		AsyncExecution(() => { this.informKey(key); });

		return key;
	}

	unregister(key) {
		// Free the key
		this.functions.delete(key);
		this.keys.release(key);
	}

	// Inform about a change
	inform() {
		this.functions.forEach((v, k) => {
			AsyncExecution(() => { this.informKey(k); });
		});
	}

	informKey(key) {
		const f = this.functions.get(key);
		if (IsNullOrUndefined(f))
			return;

		try {
			f(this.items, this.total, this.price, this.tax, this.shipping);
		} catch (err) {
			// Nothing to do. Errorhandling has to be done in component
			console.error("Error during processing of registered function");
		}
	}
};