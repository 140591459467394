/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import Localizer from '../../../BusinessLogic/Misc/Localizer';
import DateFormat from "../../../BusinessLogic/Formater/DateFormat.js";
import MessageData from "../../../BusinessLogic/DataManagement/MessageData.js";
import Loading from '../../../Components/Loading.js';
import Error from '../../../Components/Error.js';
import IsNullOrUndefined, { IsNullOrUndefinedOrWhitespace } from '../../../BusinessLogic/Misc/Utility';
import UserData from "../../../BusinessLogic/DataManagement/UserData";
import MachineData from "../../../BusinessLogic/DataManagement/MachineData";
import { ReactComponent as DefaultUserImage } from '../../../images/DefaultUser.svg';
import { ReactComponent as DefaultMachineImage } from '../../../images/DefaultMachine.svg';
import { ReactComponent as COREImage } from '../../../images/Gehring_Core_Icon.svg';
import Title from "./Title.js";
import Content from './Content.js';

export default class PreviewItem extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.updateData = this.updateData.bind(this);
		this.handleClick = this.handleClick.bind(this);

		this.key = null;
		this.userKey = null;
		this.machineKey = null;

		this.state = {
			loading: true,
			error: false,
			loadingSource: true,
			errorSource: false,

			data: {},
			userName: null,
			userImage: null,
			machineName: null
		};
	}
	static defaultProps = {
		messageID: "",
		machineID: null,
		selected: false,
		showUnreadMarker: true,
		maxLines: 3,
		onClick: () => { }
	}

	handleClick(event) {
		if (event)
			event.preventDefault();

		this.props.onClick();
	}

	updateData(data, error) {
		if (!error) {
			if (this.userKey !== null) {
				(new UserData()).unregister(this.userKey);
				this.userKey = null;
			}
			if (this.machineKey !== null) {
				(new MachineData()).unregister(this.machineKey);
				this.machineKey = null;
			}

			// Get the user
			if (IsNullOrUndefined(this.props.machineID)) {
				if (!IsNullOrUndefined(data.source)) {
					this.userKey = (new UserData()).register(data.source, (data, error) => {
						if (error) {
							this.setState({
								loadingSource: false,
								errorSource: true
							});
						} else {
							let name = data.name ?? (data.username ?? "");

							this.setState({
								loadingSource: false,
								errorSource: false,
								userName: name,
								userImage: data.profileImage ?? null
							});
						}
					});
				}
				else {
					this.setState({
						loadingSource: false,
						errorSource: false
					});
				}
			}

			// Get the Machine
			if (!IsNullOrUndefined(data.source) && !IsNullOrUndefined(this.props.machineID)) {
				this.machineKey = (new MachineData()).register(data.source, (data, error) => {
					if (error) {
						this.setState({
							loadingSource: false,
							errorSource: true
						});
					} else {
						let name = data.name;
						if (IsNullOrUndefinedOrWhitespace(name))
							name = data.username;

						this.setState({
							loadingSource: false,
							errorSource: false,
							machineName: data.serialNumber
						});
					}
				});
			}
		}

		this.setState({
			loading: false,
			error: error,
			data: data
		});
	}

	componentDidMount() {
		if (IsNullOrUndefined(this.props.machineID))
			this.key = (new MessageData()).register(this.props.messageID, this.updateData);
		else
			this.key = (new MessageData()).registerMachine(this.props.messageID, this.props.machineID, this.updateData);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.messageID !== this.props.messageID || prevProps.machineID !== this.props.machineID) {
			if (this.key !== null) {
				(new MessageData()).unregister(this.key);
				this.key = null;
			}

			this.setState({
				loading: true,
				loadingSource: true,
				error: false,
				errorSource: false
			});

			if (IsNullOrUndefined(this.props.machineID))
				this.key = (new MessageData()).register(this.props.messageID, this.updateData);
			else
				this.key = (new MessageData()).registerMachine(this.props.messageID, this.props.machineID, this.updateData);
		}
	}

	componentWillUnmount() {
		if (this.key !== null) {
			(new MessageData()).unregister(this.key);
			this.key = null;
		}

		if (this.userKey !== null) {
			(new UserData()).unregister(this.userKey);
			this.userKey = null;
		}
		if (this.machineKey !== null) {
			(new MachineData()).unregister(this.machineKey);
			this.machineKey = null;
		}
	}

	render() {
		let content = <></>;
		if (this.state.loading || this.state.loadingSource) {
			content = (
				<div className="w-100 h-100">
					<Loading />
				</div>
			);
		} else if (this.state.error || this.state.errorSource) {
			content = (
				<div className="w-100 h-100">
					<Error />
				</div>
			);
		} else {
			let image = <></>;
			let name = "";
			if (IsNullOrUndefined(this.props.machineID)) {
				if (!IsNullOrUndefined(this.state.data.source)) {
					image = (
						<span className="float-start message-item__message-image">
							{this.state.userImage === null &&
								<DefaultUserImage />
							}
							{this.state.userImage !== null &&
								<img alt="" src={this.state.userImage} />
							}
						</span>
					);

					name = this.state.userName;
				}
				else {
					image = (
						<span className="float-start message-item__message-image">
							<COREImage className="logo"/>
						</span>
					);

					name = (new Localizer()).get("CORE");
				}
			}
			if (!IsNullOrUndefined(this.state.data.source) && !IsNullOrUndefined(this.props.machineID)) {
				image = (
					<span className="float-start message-item__message-image">
						<DefaultMachineImage />
					</span>
				);

				name = this.state.machineName;
			}

			let severity = "";
			if (!IsNullOrUndefined(this.state.data.message.severity)) {
				if (this.state.data.message.severity === "ERROR") {
					severity = "ERROR";
				}
				else if (this.state.data.message.severity === "WARNING") {
					severity = "WARNING";
				}
				else if (this.state.data.message.severity === "INFO") {
					severity = "INFO";
				}
			}

			content = (
				<a href="#Preview" className="d-flex align-items-center" onClick={this.handleClick}>
					<div className={"w-100 message-item__message " + ((this.state.data.newMessage && this.props.showUnreadMarker) ? "message-item__unread" : "") + " " + (this.props.selected ? "message-item__selected" : "")}>
						{image}
						<span className={"flex-fill w-100"}>
							<span className="d-flex justify-content-between">
								{severity === "ERROR" &&
									<span className="flex-fill text-danger fw-bold">{(new Localizer()).get("Error")}</span>
								}
								{severity === "WARNING" &&
									<span className="flex-fill text-warning fw-bold">{(new Localizer()).get("Warning")}</span>
								}
								{severity === "INFO" &&
									<span className="flex-fill text-info">{(new Localizer()).get("Info")}</span>
								}
								<span className="flex-fill text-end small message-item__message-date">{(new DateFormat()).formatShortDateTime(this.state.data.message.time)}</span>
							</span>
							<span className="d-flex justify-content-between">
								<span className="flex-fill fw-bold message-item__message-source">{name}</span>
							</span>
							<span className="d-flex flex-column">
								<span className="message-item__message-header">
									<Title messageID={this.props.messageID} machineID={this.props.machineID} />
								</span>
								<span className="message-item__message-text">
									<Content messageID={this.props.messageID} machineID={this.props.machineID} maxLines={this.props.maxLines} />
								</span>
							</span>
						</span>
					</div>
				</a>
			);
		}

		return (
			<div className="border-bottom message-item">
				{content}
			</div>
		);
	}
}