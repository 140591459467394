import React from 'react';
import { Route, Routes } from 'react-router';
import { Login } from './Login'
import { Logout } from './Logout'
import { ApplicationPaths, LoginActions, LogoutActions } from './ApiAuthorizationConstants';

export default class ApiAuthorizationRoutes extends React.Component {
    render() {
        return (
            <Routes>
                <Route path={ApplicationPaths.Login} element={<Login action={LoginActions.Login} />} />
                <Route path={ApplicationPaths.LoginCallback} element={<Login action={LoginActions.LoginCallback} />} />
                <Route path={ApplicationPaths.LogOut} element={<Logout action={LogoutActions.Logout} />} />
                <Route path={ApplicationPaths.LogOutCallback} element={<Logout action={LogoutActions.LogoutCallback} />} />
                <Route path={ApplicationPaths.LoggedOut} element={<Logout action={LogoutActions.LoggedOut} />} />
            </Routes>
        );
    }
}