/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

export default class DataProvider {
	constructor() {
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
	}

	register(id, func) { return null; }
	unregister(key) { }
}