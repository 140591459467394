/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import settings from 'Settings';
import IsNullOrUndefined, { AsyncExecution } from "../../Misc/Utility.js";
import ConnectionStatusManager from '../../Misc/ConnectionStatusManager.js';
import UncachedGroupedFetch from './UncachedGroupedFetch';


export default class BaseArea {
	constructor(id, startTime, endTime, func, fetch: UncachedGroupedFetch, idField = "id", timeField = "time") {
		this.id = id;
		this.startTime = Math.round(startTime);
		if (!IsNullOrUndefined(endTime))
			this.endTime = Math.round(endTime);
		else
			this.endTime = null;
		this.func = func;
		this.timeField = timeField;
		this.idField = idField;
		this.fetch = fetch;

		this.destroyed = false;

		this.connectionStatus = ConnectionStatusManager.STATUS.OFFLINE;
		this.connectionStatusKey = null;

		this.updateTimeout = null;
		this.updateInProcess = false;
		this.updateKey = null;
		this.updatePending = false;
		this.reloadInProcess = false;

		this.data = new Map();
		this.dataInformable = false;
		this.dataError = false;

		this.destroy = this.destroy.bind(this);
		this.getData = this.getData.bind(this);
		this.add = this.add.bind(this);
		this.inform = this.inform.bind(this);
		this.processInform = this.processInform.bind(this);
		this.isRequired = this.isRequired.bind(this);
		this.update = this.update.bind(this);
		this.updateData = this.updateData.bind(this);
		this.reload = this.reload.bind(this);
		this.init = this.init.bind(this);
		this.release = this.release.bind(this);
		this.checkNotRequiredData = this.checkNotRequiredData.bind(this);
		this.clearNotRequiredData = this.clearNotRequiredData.bind(this);
		this.triggerUpdateData = this.triggerUpdateData.bind(this);
		this.triggerReloadData = this.triggerReloadData.bind(this);
		this.initUpdateListener = this.initUpdateListener.bind(this);
		this.releaseUpdateListener = this.releaseUpdateListener.bind(this);
		this.getId = this.getId.bind(this);
	}

	destroy() {
		this.release();

		this.destroyed = true;
	}

	init() {
		// ConnectionStatusManager
		this.connectionStatus = (new ConnectionStatusManager()).getStatus();

		// Init the update functionality
		this.initUpdateListener();

		// Update the data
		this.reload();

		// Register the connection manager
		this.connectionStatusKey = (new ConnectionStatusManager()).register((status) => {
			this.connectionStatus = status;

			if (this.connectionStatus === ConnectionStatusManager.STATUS.OFFLINE) {
				// Cancel all pending requests
				if (!IsNullOrUndefined(this.updateKey))
					this.fetch.abort(this.updateKey);
				this.updateKey = null;
			}
			else {
				// We are back online. Reload the complete data.
				this.reload();
			}
		});
	}

	release() {
		this.dataInformable = false;

		this.releaseUpdateListener();

		if (!IsNullOrUndefined(this.connectionStatusKey)) {
			(new ConnectionStatusManager()).unregister(this.connectionStatusKey);
			this.connectionStatusKey = null;
		}

		if (!IsNullOrUndefined(this.updateTimeout)) {
			clearTimeout(this.updateTimeout);
			this.updateTimeout = null;
		}

		// Cancel all pending requests
		if (!IsNullOrUndefined(this.updateKey))
			this.fetch.abort(this.updateKey);
	}

	initUpdateListener() { }
	releaseUpdateListener() { }

	triggerUpdateData() {
		if (IsNullOrUndefined(this.updateTimeout)) {
			this.updateTimeout = setTimeout(() => {
				this.updateTimeout = null;
				this.updateData();
			}, settings.DataUpdateDelay);
		}
	}

	triggerReloadData() {
		if (IsNullOrUndefined(this.updateTimeout)) {
			this.updateTimeout = setTimeout(() => {
				this.updateTimeout = null;
				this.updateData();
			}, settings.DataReloadDelay);
		}
	}

	reload() {
		if (this.reloadInProcess)
			return;

		if (!IsNullOrUndefined(this.updateTimeout)) {
			clearTimeout(this.updateTimeout);
			this.updateTimeout = null;
		}

		// Cancel all pending requests
		if (!IsNullOrUndefined(this.updateKey))
			this.fetch.abort(this.updateKey);
		this.updateKey = null;
		this.updatePending = false;
		this.updateInProcess = false;

		// Reload the data
		this.reloadInProcess = true;

		this.triggerReloadData();
	}

	updateData() {
		this.reloadInProcess = false;

		if (this.connectionStatus === ConnectionStatusManager.STATUS.OFFLINE)
			return;

		if (this.updateInProcess) {
			this.updatePending = true;
			return;
		}
		this.updateInProcess = true;

		// We have to wait until the new data is available
		this.dataInformable = false;
		this.dataError = false;
		this.data.clear();

		// Construct the request to the server to update the data
		let data = {
			...this.id,
			start: this.startTime,
			end: this.endTime
		};

		this.updateKey = this.fetch.fetch(data,
			(data) => {
				this.updateKey = null;

				// Set the data
				this.dataInformable = true;
				this.dataError = false;
				this.add(data.data);
					
				this.updateInProcess = false;
				// Reshedule the update if requested
				if (this.updatePending) {
					this.updatePending = false;
					this.updateData();
				}
			},
			(error) => {
				this.updateKey = null;

				switch (error) {
					case UncachedGroupedFetch.ERROR.access:
						{
							// We have a valid request but no access to the data. This means that the data on the client should be deleted and that the application should be informed that no access is possible.

							this.data.clear();
							this.dataInformable = true;
							this.dataError = true;

							// Inform the functions
							this.inform();
						}
						break;
					case UncachedGroupedFetch.ERROR.general:
					default:
						{
							// We have a connection error. This means that we have to use the old state of the data. The application should not be able to decide if this is old or current data.
						}
						break;
				}

				this.updateInProcess = false;
				// Reshedule the update if requested
				if (this.updatePending) {
					this.updatePending = false;
					this.updateData();
				}
			}
		);
	}

	checkNotRequiredData(dataId) {
		const v = this.data.get(dataId);
		if (IsNullOrUndefined(v))
			return;

		if (!IsNullOrUndefined(v.data[this.timeField])) {
			if (!this.isRequired(v.data[this.timeField])) {
				this.data.delete(dataId);
			}
		}
	}

	clearNotRequiredData() {
		let toDelete = [];
		this.data.forEach((v, k) => {
			if (!IsNullOrUndefined(v.data[this.timeField])) {
				if (!this.isRequired(v.data[this.timeField])) {
					toDelete.push(k);
				}
			}
		});

		toDelete.forEach((e) => { 
			this.data.delete(e);
		});
	}

	update(startTime, endTime) {
		// New time
		const oldStartTime = this.startTime;
		const oldEndTime = this.endTime;
		this.startTime = Math.round(startTime);
		if (!IsNullOrUndefined(endTime))
			this.endTime = Math.round(endTime);
		else
			this.endTime = null;

		// Clear all pending data
		this.clearNotRequiredData();

		let rel = false;
		if (this.endTime !== null) {
			if (oldStartTime > this.StartTime || oldEndTime < this.endTime) {
				rel = true;
			}
		}
		else {
			if (oldStartTime > this.StartTime) {
				rel = true;
			}
		}

		if (rel) {
			// Cancel all pending requests
			this.updatePending = false;
			if (!IsNullOrUndefined(this.updateKey))
				this.fetch.abort(this.updateKey);

			if (!IsNullOrUndefined(this.updateTimeout)) {
				clearTimeout(this.updateTimeout);
				this.updateTimeout = null;
			}

			// Reload the data
			this.dataInformable = false;
			this.reload();
		}
	}

	isRequired(time) {
		if (this.endTime !== null) {
			if (time >= this.startTime && time <= this.endTime)
				return true;
		}
		else {
			if (time >= this.startTime)
				return true;
		}

		return false;
	}

	getId(data) {
		return data[this.idField];
	}

	add(data) {
		if (IsNullOrUndefined(data) || data.length === 0)
			return;

		// Add the data to the list and request the data
		data.forEach((newData) => {
			const id = this.getId(newData);
			let d = this.data.get(id);
			if (IsNullOrUndefined(d)) {
				this.data.set(id, { data: newData });
			}
			else {
				d = { data: newData };
			}
		});

		// Inform about the data-change
		this.inform();
	}

	getData() {
		let data = [];
		this.data.forEach((v, k) => {
			data.push(v.data);
		});

		// Sort after time
		data.sort((a, b) => { return a[this.timeField] - b[this.timeField]; });

		return data;
	}

	inform() {
		if (this.destroyed)
			return;
		if (!this.dataInformable)
			return;

		AsyncExecution(() => { this.processInform(); });
	}

	processInform() {
		if (this.destroyed)
			return;
		if (!this.dataInformable)
			return;

		if (this.dataError) {
			try {
				this.func([], true);
			} catch (err) {
				// Nothing to do. Errorhandling has to be done in component
				console.error("Error during processing of registered function. Error: " + err);
			}
		}
		else {
			const d = this.getData();

			try {
				this.func(d, false);
			} catch (err) {
				// Nothing to do. Errorhandling has to be done in component
				console.error("Error during processing of registered function. Error: " + err);
			}
		}
	}
}
