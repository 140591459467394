/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { Dropdown } from 'react-bootstrap'
import { Link } from "react-router-dom";
import Localizer from '../../../BusinessLogic/Misc/Localizer'
import BasketDetails from "../../../BusinessLogic/Order/BasketDetails.js";
import NumberFormat from "../../../BusinessLogic/Formater/NumberFormat.js";

export default class ShoppingCart extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.basketKey = null;

		this.state = {
			data: [],
			totalPrice: 0,
			totalCurrency: "EURO",
			show: false
		};
	}
	static defaultProps = {
	}

	componentDidMount() {
		// Get the basket data
		this.basketKey = (new BasketDetails()).register((items, total, price, tax, shipping) => {
			if (items.length === 0) {
				this.setState({
					data: [],
					totalPrice: 0,
					totalCurrency: "EURO"
				});
			}
			else {
				// Set the Data
				this.setState(() => ({
					data: items,
					totalCurrency: total.currency,
					totalPrice: total.price
				}));
			}
		});
	}

	componentWillUnmount() {
		// Remove the User
		if (this.basketKey !== null) {
			(new BasketDetails()).unregister(this.basketKey);
			this.basketKey = null;
		}
	}

	render() {
		let items = [];
		this.state.data.forEach((e, i, a) => {
			if (i >= 9)
				return;

			const item = (
				<div key={e.id} className="border-bottom p-3 d-flex justify-content-between">
					<span className="fw-bold pe-2">
						<span className="font-weight-600 pe-1">{i}</span>
						{e.title}
					</span>
					<span className="font-weight-600 text-nowrap">{(new NumberFormat()).formatCurrency(e.price.price, e.price.currency)}</span>
				</div>
			);
			items.push(item);
		});

		return (
			<>
				<Dropdown as="li" className="nav-item" show={this.state.show} onToggle={() => { this.setState((prevState, props) => { return { show: !prevState.show } }); }}>
					<Dropdown.Toggle as="button" className="nav-link">
						<span className={"p-header__dropdown-content " + (this.state.data.length > 0 ? "p-header__navbar__cart--full" : "p-header__navbar__cart--empty")}>
							<span>{this.state.data.length <= 9 ? this.state.data.length : "9+"}</span>
						</span>
					</Dropdown.Toggle>
					<Dropdown.Menu className="p-header__cart--full__content">
						<div className="d-flex align-items-center border-bottom ">
							<div className="p-header__dropdown__title text-center flex-grow-1">
								<h6>{(new Localizer()).get("Your Shopping Cart")}</h6>
							</div>
						</div>

						{/* Listing of items in cart */}
						<div>
							{items}
						</div>

						{/* Only needed when there are more than 9 items in the cart. "(x - 9)" is the real amount of items not shown. */}
						{this.state.data.length > 9 &&
							<div className="border-bottom py-2 px-3 justify-content-between text-primary">
								<span className="font-italic">*{(new Localizer()).get("There are")} <span className="fw-bold px-1">{this.state.data.length - 9}</span> {(new Localizer()).get("more items in your cart.")}</span>
							</div>
						}

						{/* Value of Goods for all items */}
						<div className="border-bottom p-3 d-flex justify-content-between">
							<span className="fw-bold pe-2">
								{(new Localizer()).get("Value of Goods")}:<br />
							</span>
							<span className="font-weight-600 text-nowrap">{(new NumberFormat()).formatCurrency(this.state.totalPrice, this.state.totalCurrency)}</span>
						</div>

						{/* Go to cart */}
						<div className="border-bottom p-3 d-flex justify-content-between">
							<Link className="btn btn-secondary text-uppercase w-100" to="/Protected/Order/Details" disabled={this.state.data.length === 0} onClick={() => { this.setState({ show: false }); }}>{(new Localizer()).get("Shopping Cart")}</Link>
						</div>
					</Dropdown.Menu>
				</Dropdown>
			</>
		);
	}
}