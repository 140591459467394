/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 270.93333 270.93333"
            className="svgIcon"
        >
            <path d="m 169.3696,33.866411 h 33.83023 V 90.310652 H 169.3696 Z M 270.93333,45.15567 V 270.93333 H 0 V 0 H 225.77766 Z M 56.444246,101.59991 H 214.48909 V 22.577835 H 56.444246 Z M 237.06624,146.75558 H 33.866411 V 248.3555 H 237.06624 Z" />
        </svg>
    );
}