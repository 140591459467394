/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 270.93333 270.93333"
            className="svgIcon"
        >
            <path
                d="M 22.599191,1.2230871e-4 H 248.35429 C 260.81722,-0.04051769 270.95012,10.051745 270.96367,22.514672 v 0.04064 c 0,238.218028 -2.81771,249.258558 0,248.310288 H 22.599191 C 15.81231,262.68342 8.19909,255.09729 0.03045,248.29687 V 22.555312 C 0.03045,10.092385 10.13626,1.2230871e-4 22.599191,1.2230871e-4 Z"
                fill="none"
                strokeWidth="13.5467" />
            <path
                d="m 22.603727,13.54938 c -4.959076,0 -9.023892,4.119012 -9.023892,9.105183 V 241.76158 c 2.127257,1.97821 4.647443,4.57969 7.696053,7.64185 3.224752,3.38734 5.744936,5.97527 7.574102,7.98058 h 227.33149 c 0.0271,-5.36555 0.1084,-12.83126 0.23034,-22.97975 0.35228,-33.23662 1.02975,-95.13019 1.02975,-211.749697 0,-4.986171 -4.05126,-9.105183 -9.03743,-9.105183 H 22.603727 M 22.603727,0 H 248.40414 c 12.47897,0 22.58675,10.094289 22.58675,22.654563 0,224.391277 -6.2e-4,246.035527 0,248.278767 H 22.603727 c -0.867161,-1.78852 -21.0421837,-22.8171 -22.5055173,-22.8171 -0.04060815,0 -0.05421088,0 -0.06771359,0.0135 V 22.586816 C 0.03049611,10.094289 10.138335,0 22.603767,0 Z M 0.03045611,248.14334 v 0.21678 c -0.04060815,-0.10839 -0.04060815,-0.18968 0,-0.21678 z"
                strokeWidth="13.5467" />
            <rect
                x="108.36308"
                y="0.00012231182"
                width="94.826622"
                height="135.4666"
                fill="none"
                strokeWidth="13.5467" />
            <rect
                x="115.13641"
                y="6.7734532"
                width="81.279961"
                height="121.91994"
                fill="none"
                strokeWidth="13.5467" />
            <path
                d="M 115.13643,74.506755 H 6.76314"
                strokeWidth="13.5467" />
            <line
                x1="264.16321"
                x2="196.42992"
                y1="74.50676"
                y2="74.50676"
                fill="none"
                strokeWidth="13.5467" />
            <line
                x1="264.14969"
                x2="6.7631226"
                y1="196.4267"
                y2="196.4267"
                fill="none"
                strokeWidth="13.5467" />
        </svg>
    );
}