/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from 'react';
import { toast } from 'react-toastify';

let instance;
class Notification {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.stickyError = this.stickyError.bind(this);
		this.error = this.error.bind(this);
		this.warning = this.warning.bind(this);
		this.success = this.success.bind(this);
		this.info = this.info.bind(this);
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}
	
	// Global errors
	stickyError(message, title) {
		toast.error(<><div className="fw-bold">{title}</div><div>{message}</div></>, { autoClose: false, closeOnClick: false, draggable: false, position: "top-center" });
		if (process.env.NODE_ENV === 'development') {
			console.error("Error '" + title + "': " + message);
		}
	}

	error(message, title) {
		toast.error(<><div className="fw-bold">{title}</div><div>{message}</div></>);
		if (process.env.NODE_ENV === 'development') {
			console.error("Error '" + title + "': " + message);
		}
	}

	warning(message, title) {
		toast.warn(<><div className="fw-bold">{title}</div><div>{message}</div></>);
		if (process.env.NODE_ENV === 'development') {
			console.warning("Warning '" + title + "': " + message);
		}
	}

	success(message, title) {
		toast.success(<><div className="fw-bold">{title}</div><div>{message}</div></>);
	}

	info(message, title) {
		toast.info(<><div className="fw-bold">{title}</div><div>{message}</div></>);
	}
}

export default Notification;