/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This JavaScript file is used for the edit-mode of the dashboard
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import Cookies from "js-cookie";
import UniqueIDGenerator from '../Misc/UniqueIDGenerator.js';
import IsNullOrUndefined from "../Misc/Utility.js";
import settings from 'Settings';
import GlobalAppData from "GlobalAppData";

let instance;
export default class Basket {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.items = new Map();

		this.keys = new UniqueIDGenerator();
		this.functions = new Map();

		// Functions
		this.destroy = this.destroy.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.inform = this.inform.bind(this);
		this.add = this.add.bind(this);
		this.remove = this.remove.bind(this);
		this.clear = this.clear.bind(this);
		this.setCount = this.setCount.bind(this);
		this.updateCookie = this.updateCookie.bind(this);
		this.setCookie = this.setCookie.bind(this);
		this.checkUpdate = this.checkUpdate.bind(this);
		this.setSmartphoneBasket = this.setSmartphoneBasket.bind(this);

		this.checkUpdate();

		this.lastCookie = "";
		this.interval = setInterval(this.checkUpdate, settings.BasketChangedCheckInterval);

		if ((new GlobalAppData()).isWebView()) {
			this.basketChangedKey = (new GlobalAppData()).registerBasketChanged((data) => {
				this.setSmartphoneBasket(data);
			});
		}
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance))
			instance.destroy();
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	destroy() {
		if (!IsNullOrUndefined(this.basketChangedKey)) {
			(new GlobalAppData()).unregister(this.basketChangedKey);
			this.basketChangedKey = null;
		}

		clearInterval(this.interval);
	}

	checkUpdate() {
		if (this.updateCookie()) {
			this.inform();
		}
	}

	register(func) {
		// Integrate the key
		const key = this.keys.generate();
		this.functions.set(key, func);

		// Get the list
		let l = [];
		this.items.forEach((value, key) => {
			l.push({ item: key, machineID: value.machineID, count: value.count });
		});

		func(l);

		return key;
	}

	unregister(key) {
		// Free the key
		this.functions.delete(key);
		this.keys.release(key);
	}

	// Inform about a change
	inform() {
		// Get the list
		let l = [];
		this.items.forEach((value, key) => {
			l.push({ item: key, machineID: value.machineID, count: value.count });
		});

		this.functions.forEach((e) => {
			e(l);
		});
	}

	add(item, count, machineID) {
		if ((new GlobalAppData()).isWebView()) {
			// Clear the Map. Items are set again by the app.
			this.items.clear();
			(new GlobalAppData()).sparePartAdded(item, count, machineID);
		}
		else {
			this.updateCookie();

			const _count = this.items.get(item);
			let c = count;
			if (!IsNullOrUndefined(_count))
				c += _count.count;

			this.items.set(item, { count: c, machineID: machineID });

			this.setCookie();

			// Inform the User
			this.inform();
		}
	}

	remove(item) {
		if ((new GlobalAppData()).isWebView()) {
			// Clear the Map. Items are set again by the app.
			this.items.clear();
			(new GlobalAppData()).sparePartRemoved(item);
		}
		else {
			this.updateCookie();

			this.items.delete(item);

			this.setCookie();

			// Inform the User
			this.inform();
		}
	}

	clear() {
		if ((new GlobalAppData()).isWebView()) {
			// Clear the Map. Items are set again by the app.
			this.items.clear();
			(new GlobalAppData()).itemsDeleted();
		}
		else {
			this.items.clear();

			this.setCookie();

			// Inform the User
			this.inform();
		}
	}

	setCount(item, count) {
		if ((new GlobalAppData()).isWebView()) {
			// Clear the Map. Items are set again by the app.
			this.items.clear();
			(new GlobalAppData()).changedItemCount(item, count);
		}
		else {
			this.updateCookie();

			const i = this.items.get(item);
			if (IsNullOrUndefined(i))
				return;

			if (count === 0)
				this.items.delete(item);
			else
				this.items.set(item, { count: count, machineID: i.machineID });

			this.setCookie();

			// Inform the User
			this.inform();
		}
	}

	setSmartphoneBasket(data) {
		// Set the basket items
		const keys = Object.keys(data);
		keys.forEach((item) => {
			this.items.set(item, { count: data[item].count, machineID: data[item].machineID })
		})
		// Inform the User
		this.inform();
	}

	updateCookie() {
		const value = Cookies.get('basket');
		if (IsNullOrUndefined(value))
			return false;

		if (this.lastCookie === value)
			return false;
		this.lastCookie = value;

		this.items.clear();

		const v = value.split(";");
		v.forEach((e) => {
			const s = e.split("|");
			if (s.length < 2)
				return;

			const _s = s[1].split(",");
			if (_s.length < 1)
				return;
			else if (_s.length < 2) {
				// Item
				this.items.set(parseInt(s[0]), { count: parseInt(_s[0]) });
			}
			else {
				// Machine
				this.items.set(parseInt(s[0]), { machineID: _s[0], count: parseInt(_s[1]) });
			}
		});

		return true;
	}

	setCookie() {
		let s = "";
		this.items.forEach((value, key) => {
			if (IsNullOrUndefined(value.machineID))
				s += key + "|" + value.count + ";";
			else
				s += key + "|" + value.machineID + "," + value.count + ";";
		});
		this.lastCookie = s;
		Cookies.set('basket', s, { expires: 2 });
	}
};