/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import NotificationService from '../Misc/NotificationService.js';
import settings from 'Settings';
import UniqueIDGenerator from '../Misc/UniqueIDGenerator.js';
import IsNullOrUndefined from "../Misc/Utility.js";
import CurrentUser from "./CurrentUser.js";
import GroupedFetch from './Intern/GroupedFetch.js';
import BaseData from './Intern/BaseData.js';

class Data extends BaseData {
	constructor(ID: string, fetch: GroupedFetch) {
		super(ID, fetch, ID);

		this.initUpdateListener = this.initUpdateListener.bind(this);
		this.releaseUpdateListener = this.releaseUpdateListener.bind(this);

		this.currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
		this.currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

		this.toolNotificationKey = null;
		this.currentUserKey = null;

		this.init("ToolData");
	}

	initUpdateListener() {
		// Notification
		this.toolNotificationKey = (new NotificationService()).registerToolChanged(this.ID, (toolID, pieceID) => {
			this.triggerUpdateData();
		});

		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
			const currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

			if (this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID &&
				this.currentUserRightsChangeID === currentUserRightsChangeID)
				return;

			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
			this.currentUserRightsChangeID = currentUserRightsChangeID;

			// Update the data
			this.reload();
		});
	}

	releaseUpdateListener() {
		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}

		if (this.toolNotificationKey !== null) {
			(new NotificationService()).unregister(this.toolNotificationKey);
			this.toolNotificationKey = null;
		}
	}
}

let instance;
export default class ToolData {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.keys = new Map();
		this.keyGen = new UniqueIDGenerator();

		this.fetch = new GroupedFetch("/api/Tool/GetData");
		this.toolArray = new Map();

		this.destroy = this.destroy.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.checkDelete = this.checkDelete.bind(this);
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance))
			instance.destroy();
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	// Destroy the class
	destroy() {
		this.toolArray.forEach((v, k) => {
			if (!IsNullOrUndefined(v.data)) {
				v.data.destroy();
				v.data = null;
			}

			if (!IsNullOrUndefined(v.releaseTimeoutKey)) {
				clearTimeout(v.releaseTimeoutKey);
				v.releaseTimeoutKey = null;
			}
		});

		this.toolArray.clear();
		this.keys.clear();

		if (!IsNullOrUndefined(this.fetch))
			this.fetch.destroy();
		this.fetch = null;
	}

	// Register for the current dataset
	register(toolID, func) {
		let m = this.toolArray.get(toolID);
		if (IsNullOrUndefined(m)) {
			m = { releaseTimeoutKey: null, data: null };
			this.toolArray.set(toolID, m);
		}

		if (IsNullOrUndefined(m.data)) {
			let data = new Data(toolID, this.fetch);
			m.data = data;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Register the Function
		const subKey = m.data.register(func);

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: 0, key: subKey, toolID: toolID });

		return key;
	}

	// Unregister
	unregister(key) {
		const obj = this.keys.get(key);
		if (IsNullOrUndefined(obj))
			return;
		switch (obj.type) {
			case 0:
				{
					const m = this.toolArray.get(obj.toolID);
					if (IsNullOrUndefined(m))
						return;

					m.data.unregister(obj.key);

					if (IsNullOrUndefined(m.releaseTimeoutKey)) {
						m.releaseTimeoutKey = setTimeout(() => { m.releaseTimeoutKey = null; this.checkDelete(obj.toolID); }, settings.LocalDataHoldTime);
					}
				} break;
			default:
				break;
		}

		// Free the key
		this.keys.delete(key);
		this.keyGen.release(key);
	}

	// Check the release
	checkDelete(toolID) {
		const m = this.toolArray.get(toolID);
		if (IsNullOrUndefined(m))
			return;

		if (!IsNullOrUndefined(m.data)) {
			if (m.data.empty()) {
				m.data.destroy();
				m.data = null;
			}
		}

		// Destroy the Object
		if (IsNullOrUndefined(m.data))
			this.toolArray.delete(toolID);
	}
}