/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import NotificationService from "../../../BusinessLogic/Misc/NotificationService.js";
import ErrorManager from "../../../BusinessLogic/Misc/ErrorManager";
import CurrentUser from "../../../BusinessLogic/DataManagement/CurrentUser";
import RequestID from "../../../BusinessLogic/Misc/RequestID";
import BasketDetails from "../../../BusinessLogic/Order/BasketDetails.js";
import Rights from "../../../BusinessLogic/Misc/Rights.js";
import FetchHelper from "../../../BusinessLogic/Misc/FetchHelper.js";

export default class MenuToggleButton extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.updateNotificationData = this.updateNotificationData.bind(this);
		this.updateMessageData = this.updateMessageData.bind(this);

		this.notificationRequestID = new RequestID();
		this.messageRequestID = new RequestID();
		this.notificationAbortController = new AbortController();
		this.messageAbortController = new AbortController();
		this.notificationKey = null;
		this.notificationChangedKey = null;
		this.basketKey = null;
		this.userKey = null;

		this.state = {
			numNotificationUnread: 0,
			numMessageUnread: 0,
			numShoppingCartItems: 0,

			// Rights
			Right_Message_Enable: false
		};
	}
	static defaultProps = {
		onClick: (e) => { }
	}

	updateNotificationData() {
		if (!this.state.Right_Message_Enable)
			return;

		// Create the data
		const data = {
			requestID: this.notificationRequestID.newRequest(),
			startIndex: 0,
			count: 1,
			sortId: "time",
			sortOrder: "desc",
			onlyNew: true,
			showNotifications: true,
			showAdverticement: false,
			showUser: false
		};

		(async () => {
			try {
				const json = await FetchHelper.fetchData("/api/Message/list", data, this.notificationAbortController);
				if (!this.notificationRequestID.validateRequest(json.requestID))
					return;

				this.setState({
					numNotificationUnread: json.totalSize
				});
			} catch (err) {
				if (err.name === "Abort")
					return;
				else if (err.name === "APIIncompatible") {
					// Added error handling
					(new ErrorManager()).APIIncompatible();
				}
				else {
					// Added error handling
					(new ErrorManager()).dataReceiveError();
				}
			}
		})();
	}

	updateMessageData() {
		if (!this.state.Right_Message_Enable)
			return;

		// Create the data
		const data = {
			requestID: this.messageRequestID.newRequest(),
			startIndex: 0,
			count: 1,
			sortId: "time",
			sortOrder: "desc",
			onlyNew: true,
			showNotifications: false,
			showAdverticement: true,
			showUser: true
		};

		(async () => {
			try {
				const json = await FetchHelper.fetchData("/api/Message/list", data, this.messageAbortController);
				if (!this.messageRequestID.validateRequest(json.requestID))
					return;

				this.setState({
					numMessageUnread: json.totalSize
				});
			} catch (err) {
				if (err.name === "Abort")
					return;
				else if (err.name === "APIIncompatible") {
					// Added error handling
					(new ErrorManager()).APIIncompatible();
				}
				else {
					// Added error handling
					(new ErrorManager()).dataReceiveError();
				}
			}
		})();
	}

	componentDidMount() {
		// Get the basket data
		this.basketKey = (new BasketDetails()).register((items, total, price, tax, shipping) => {
			if (items.length === 0) {
				this.setState({
					numShoppingCartItems: 0
				});
			}
			else {
				// Set the Data
				this.setState(() => ({
					numShoppingCartItems: items.length
				}));
			}
		});

		// Get the user data
		this.userKey = (new CurrentUser()).register((loaded, error) => {
			this.setState({
				// Rights
				Right_Message_Enable: (new CurrentUser()).hasRight(Rights.RIGHTS.Message_Enable)
			}, () => {
				// Remove the Notification
				if (this.notificationKey !== null) {
					(new NotificationService()).unregister(this.notificationKey);
					this.notificationKey = null;
				}

				if (this.notificationChangedKey !== null) {
					(new NotificationService()).unregister(this.notificationChangedKey);
					this.notificationChangedKey = null;
				}

				// Register the notifications
				this.notificationKey = (new NotificationService()).registerMessageCreated((new CurrentUser()).getUserID(), (userID, messageID) => {
					this.updateNotificationData();
					this.updateMessageData();
				});

				// Register the notifications
				this.notificationChangedKey = (new NotificationService()).registerMessageChanged((new CurrentUser()).getUserID(), (userID, allChanged, messageIDs) => {
					this.updateNotificationData();
					this.updateMessageData();
				});

				// Update the Data
				this.updateNotificationData();
				this.updateMessageData();
			});
		});
	}

	componentWillUnmount() {
		// Remove the User
		if (this.userKey !== null) {
			(new CurrentUser()).unregister(this.userKey);
			this.userKey = null;
		}

		// Remove the Notification
		if (this.notificationKey !== null) {
			(new NotificationService()).unregister(this.notificationKey);
			this.notificationKey = null;
		}

		if (this.notificationChangedKey !== null) {
			(new NotificationService()).unregister(this.notificationChangedKey);
			this.notificationChangedKey = null;
		}

		// Remove the User
		if (this.basketKey !== null) {
			(new BasketDetails()).unregister(this.basketKey);
			this.basketKey = null;
		}

		// Cancel all pending requests
		this.notificationAbortController.abort();
		this.messageAbortController.abort();
	}

	render() {
		let toggleClass = "p-header__navtoggler--";
		let found = false;
		if (this.state.numNotificationUnread > 0) {
			toggleClass += "l";
			found = true;
		}
		if (this.state.numMessageUnread > 0) {
			toggleClass += "m";
			found = true;
		}
		if (this.state.numShoppingCartItems > 0) {
			toggleClass += "r";
			found = true;
		}

		if (!found)
			toggleClass += "neutr";


		return (
			<button className={"navbar-toggler p-header__navtoggler " + toggleClass} onClick={this.props.onClick}></button>
		);
	}
}