/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 216.74667 270.93333"
            className="svgIcon"
        >
            <path d="m 75.75279,214.48909 c 0,5.98016 -4.86059,10.90963 -10.83734,10.90963 -5.98016,0 -10.87351,-4.89465 -10.87351,-10.90963 V 101.6006 c 0,-5.980162 4.89472,-10.855768 10.89194,-10.855768 5.99382,0 10.83734,4.87424 10.83734,10.855768 z m 43.45787,0 c 0,5.98016 -4.87424,10.90963 -10.85508,10.90963 -6.00064,0 -10.9097,-4.89465 -10.9097,-10.90963 V 101.6006 c 0,-5.980162 4.89472,-10.855768 10.9097,-10.855768 5.98016,0 10.85508,4.87424 10.85508,10.855768 z m 43.34934,0 c 0,5.98016 -4.89472,10.90963 -10.9097,10.90963 -6.01429,0 -10.85508,-4.89465 -10.85508,-10.90963 V 101.6006 c 0,-5.980162 4.87424,-10.855768 10.85508,-10.855768 5.98016,0 10.9097,4.87424 10.9097,10.855768 z M 216.74666,22.577835 V 45.155601 H 0 V 22.577835 H 61.91718 C 71.67112,22.577835 79.58255,10.151253 79.58255,0 h 57.6 c 0,10.168934 7.89299,22.577835 17.66468,22.577835 z M 184.12612,67.733504 V 248.35543 H 32.47582 V 67.733504 H 10.80115 V 270.93334 h 195.072 V 67.733504 Z"/>
        </svg>
    );
}