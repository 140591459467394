/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 270.93333 270.93333"
            className="svgIcon"
        >
            <path d="m 135.51181,237.06667 -98.506832,-101.6 H 110.8569 V 0 h 49.40017 v 135.46667 h 73.8519 z m 110.69886,-11.3167 v 22.59165 H 24.654933 V 225.74997 H 0 v 45.18336 h 270.93333 v -45.18336 z" />
        </svg>
    );
}