/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 166.30994 270.93333"
            className="svgIcon"
        >
            <path d="M 83.154965,82.55006 0,166.61189 l 21.650884,21.77145 61.504081,-62.24522 61.580075,62.24522 21.57489,-21.77145 z" />
        </svg>
    );
}