/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 270.93333 270.93333"
            className="svgIcon"
        >
            <path d="m 135.46666,22.570774 c 62.23131,0 112.89584,50.539525 112.89584,112.895856 0,62.35633 -50.66453,112.89585 -112.89584,112.89585 -62.356342,0 -113.020875,-50.53952 -113.020875,-112.89585 C 22.633334,73.151968 73.152,22.633317 135.46666,22.570774 Z M 135.46666,0 C 60.522338,0 0,60.647351 0,135.46663 c 0,74.81927 60.522338,135.4667 135.46666,135.4667 74.81929,0 135.46667,-60.64743 135.46667,-135.4667 C 270.93333,60.647351 210.28595,0 135.46666,0 Z M 203.2,146.76245 H 67.733333 V 124.1708 H 203.2 Z" />
        </svg>
    );
}