/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 307.07595 270.93333"
            className="svgIcon"
        >
            <path d="M 63.993895,270.9335 H 38.400023 V 147.87594 L 153.4383,49.291982 l 43.25925,36.900882 28.97172,24.510486 c 14.45001,12.39109 28.91777,24.7815 43.20462,37.19033 V 270.9335 H 243.20766 V 158.64091 L 153.54684,82.1112 64.102435,158.71328 Z M 89.588448,246.27898 H 217.523 V 270.9335 H 89.570018 Z m 0,-36.91932 H 217.523 v 24.63677 H 89.570018 Z M 217.523,172.42259 v 24.63678 H 89.570018 V 172.40416 Z M 153.54684,0 307.07595,130.9335 290.11508,149.37507 153.54684,32.475837 16.941745,149.37507 0,130.9335 Z" />
        </svg>
    );
}