/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This JavaScript file is used to display a Export-Button
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import BootstrapButton from 'react-bootstrap/Button';
import IsNullOrUndefined, { IsNullOrUndefinedOrWhitespace } from "../../BusinessLogic/Misc/Utility";
import Icon from "../Icons/Icon"

const VARIANT = {
	primary: "primary",
	secondary: "secondary",
	success: "success",
	danger: "danger",
	warning: "warning",
	info: "info",
	light: "light",
	dark: "dark",
	link: "link",
	outlinePrimary: "outline-primary",
	outlineSecondary: "outline-secondary",
	outlineSuccess: "outline-success",
	outlineDanger: "outline-danger",
	outlineWarning: "outline-warning",
	outlineInfo: "outline-info",
	outlineLight: "outline-light",
	outlineDark: "outline-dark"
};

const SIZE = {
	lg: "lg",
	md: "md",
	sm: "sm"
};

export default class Button extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.handleOnClick = this.handleOnClick.bind(this);
	}

	static defaultProps = {
		className: "",
		onClick: () => { },
		tooltip: "",
		text: "",
		variant: VARIANT.primary,
		icon: null,
		disabled: false,
		size: SIZE.md,
		enableWordWrap: false,
		/// Automatically hide the text below the requested screen size
		autohideText: false,
		autohideTextSize: "lg"
	}

	static get VARIANT() {
		return VARIANT;
	}

	static get ICON() {
		return Icon.ICON;
	}

	static get SIZE() {
		return SIZE;
	}

	handleOnClick(event) {
		event.preventDefault();

		this.props.onClick();
	}

	render() {
		let className = this.props.className;
		if (!this.props.enableWordWrap)
			className += " text-nowrap";

		if (this.props.autohideText) {
			switch (this.props.autohideTextSize) {
				case "sm":
					className += " autohide-sm";
					break;
				case "md":
					className += " autohide-md";
					break;
				case "lg":
					className += " autohide-lg";
					break;
				case "xl":
					className += " autohide-xl";
					break;
				case "xxl":
					className += " autohide-xxl";
					break;
			}
		}

		if (!IsNullOrUndefinedOrWhitespace(this.props.tooltip)) {
			return (
				<OverlayTrigger
					placement="top"
					overlay={
						<Tooltip>
							{this.props.tooltip}
						</Tooltip>
					}>
					<BootstrapButton variant={this.props.variant} size={this.props.size} className={className} onClick={this.handleOnClick} disabled={this.props.disabled}>
						<div className={IsNullOrUndefinedOrWhitespace(this.props.text) ? "" : "d-flex"}>
							{!IsNullOrUndefined(this.props.icon) &&
								<div className="icon"><Icon icon={this.props.icon} /></div>
							}
							{!IsNullOrUndefinedOrWhitespace(this.props.text) &&
								<div className="text">{this.props.text}</div>
							}
						</div>
					</BootstrapButton>
				</OverlayTrigger>
			);
		}
		else {
			return (
				<BootstrapButton variant={this.props.variant} size={this.props.size} className={className} onClick={this.handleOnClick} disabled={this.props.disabled}>
					<div className={IsNullOrUndefinedOrWhitespace(this.props.text) ? "" : "d-flex"}>
						{!IsNullOrUndefined(this.props.icon) &&
							<div className="icon"><Icon icon={this.props.icon} /></div>
						}
						{!IsNullOrUndefinedOrWhitespace(this.props.text) &&
							<div className="text">{this.props.text}</div>
						}
					</div>
				</BootstrapButton>
			);
		}
	}
}