/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a helper for the localization support withing js
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import UniqueIDGenerator from './UniqueIDGenerator.js';
import { setDefaultLocale } from "react-datepicker";
import GlobalAppData from "GlobalAppData";
import settings from 'Settings';
import { random } from '../../random';

let instance;
class Localizer {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		// Language
		this.language = "en";
		this.hasLanguage = false;

		this.keyGen = new UniqueIDGenerator();
		this.functions = new Map();

		// Get the Localizer singleton
		this.loaded = false;
		this.loadedError = false;
		this.values = new Map();
		this.manualValues = new Map();

		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.setLanguage = this.setLanguage.bind(this);
		this.getLanguage = this.getLanguage.bind(this);
		this.getCulture = this.getCulture.bind(this);
		this.load = this.load.bind(this);
		this.get = this.get.bind(this);
		this.setDefaultLanguage = this.setDefaultLanguage.bind(this);

		this.setDefaultLanguage();
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	// Register for changes of the user data
	register(func) {
		// Integrate the key
		const key = this.keyGen.generate();
		this.functions.set(key, func);

		// Inform the new function
		func(this.loaded, this.loadedError);

		return key;
	}

	// Unregister
	unregister(key) {
		// Free the key
		this.functions.delete(key);
		this.keyGen.release(key);
	}

	// Set the default language
	setDefaultLanguage() {
		if (!(new GlobalAppData()).isNativeApp()) {
			const locales = navigator.language || navigator.userLanguage;
			for (var i = 0; i < locales.length; i++) {
				if (locales[i].languageCode === "en-US") {
					this.setLanguage("en-us");
					return;
				}
				if (locales[i].languageCode === "de-DE") {
					this.setLanguage("de-de");
					return;
				}
				if (locales[i].languageCode === "zh-CN") {
					this.setLanguage("zh-cn");
					return;
				}
			}
		}

		// Nothing found. go back to English
		this.setLanguage("en-us");
	}

	// Set the Language to use. The CORE-Language settings are used here
	setLanguage(language) {
		let changed = false;

		switch (language) {
			case 'de-de':
				if (this.language !== "de" || !this.hasLanguage) {
					changed = true;
					this.language = "de";
					this.hasLanguage = true;

					// Set the default locale for the datepicker
					setDefaultLocale("de-DE");
				}
				break;
			case 'en-us':
				if (this.language !== "en" || !this.hasLanguage) {
					changed = true;
					this.language = "en";
					this.hasLanguage = true;

					// Set the default locale for the datepicker
					setDefaultLocale("en-US");
				}
				break;
			case 'zh-cn':
				if (this.language !== "zh" || !this.hasLanguage) {
					changed = true;
					this.language = "zh";
					this.hasLanguage = true;

					// Set the default locale for the datepicker
					setDefaultLocale("zh-CN");
				}
				break;
			default:
				if (this.language !== "en" || !this.hasLanguage) {
					changed = true;
					this.language = "en";
					this.hasLanguage = true;

					// Set the default locale for the datepicker
					setDefaultLocale("en-US");

					if (process.env.NODE_ENV === 'development') {
						console.warn("Language '" + language + "' is invalid. Using english.");
					}
				}
				break;
		}

		// Load the data
		if (changed)
			this.load();
	}

	// Get the current used language
	getLanguage() {
		return this.language;
	}

	getISOCode() {
		if (this.language === "de") {
			return "de-DE";
		} else if (this.language === "zh") {
			return "zh-CN";
		} else {
			return "en-US";
		}
	}

	// Get the current used language
	getCulture() {
		switch (this.language) {
			case 'de':
				return { culture: "de-DE", uiculture: "de-DE" };
			case 'zh':
				return { culture: "zh-CN", uiculture: "zh-CN" };
			case 'en':
			default:
				return { culture: "en-US", uiculture: "en-US" };
		}
	}

	// Load the localisation
	load() {
		this.loaded = false;

		// Inform
		this.functions.forEach((e) => {
			e(false, false);
		});

		// Get the required culture
		const culture = this.getCulture();

		// Get the Localisation. This is most probably already in the Cache.
		let url = "/api/Localization/GetLocalization?random=" + random + "&culture=" + culture.culture + "&uiculture=" + culture.uiculture;
		if ((new GlobalAppData()).isNativeApp())
			url = settings.FetchUrlExtension + url;

		fetch(url)
			.then(response => {
				if (!response.ok) { throw response }
				return response.json()
			})
			.then(json => {
				const culture = this.getCulture();

				// Check if there is a newer request on the way
				if (json.culture !== culture.culture || json.uiculture !== culture.uiculture)
					return;

				const localization = json.localization;
				localization.forEach((e) => {
					this.values.set(e.key, e.value);
				});

				this.loaded = true;
				this.loadedError = false;

				// Inform
				this.functions.forEach((e) => {
					e(true, false);
				});
			})
			.catch(err => {
				this.values.clear();

				// Inform that there was a change in the data
				this.loaded = true;
				this.loadedError = true;

				// Inform
				this.functions.forEach((e) => {
					e(true, true);
				});
			});
	}

	// Get the translation
	get(key) {
		if (this.values.has(key))
			return this.values.get(key);
		if (this.manualValues.has(key))
			return this.manualValues.get(key);

		if (process.env.NODE_ENV === 'development') {
			console.warn("Localization for key '" + key + "' not found.");
		}

		return key;
	}

	// Add some values to the Array
	addArray(valueArray) {
		valueArray.forEach((e) => {
			this.manualValues.set(e.key, e.value);
		});
	}
}

export default Localizer;