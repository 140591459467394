/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 365.76 270.93333"
            className="svgIcon"
        >
            <path d="M 299.25819,270.93333 H 0 V 98.840281 l 149.62931,108.433629 95.3412,-69.04794 c 14.697,8.06798 31.63308,12.33275 48.74593,12.33275 1.85158,0 3.71154,0 5.54175,-0.18963 z M 149.62931,178.58522 0.19982,70.241861 h 199.50546 c 1.28605,19.98886 10.37429,39.004529 25.4923,53.584779 z" />
            <ellipse
                cx="293.71637"
                cy="65.224686"
                rx="72.04364"
                ry="65.224686"
                className="svgIcon-number" />
        </svg>
    );
}