/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import GroupedFetch from "./GroupedFetch.js";

export default class UncachedGroupedFetch {
	constructor(url) {
		this.groupedFetch = new GroupedFetch(url);

		this.destroy = this.destroy.bind(this);
		this.fetch = this.fetch.bind(this);
		this.abort = this.abort.bind(this);
	}

	static get ERROR() {
		return GroupedFetch.ERROR;
	}

	destroy() {
		this.groupedFetch.destroy();
	}

	fetch(id, func, error) {
		return this.groupedFetch.fetch(id, null, null, null, func, error);
	}

	abort(key) {
		this.groupedFetch.abort(key);
	}
}
