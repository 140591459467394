/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import NotificationService from '../Misc/NotificationService.js';
import settings from 'Settings';
import IsNullOrUndefined from "../Misc/Utility.js";
import UniqueIDGenerator from '../Misc/UniqueIDGenerator.js';
import { SafeMax } from "../Misc/Utility";
import { SafeMin } from "../Misc/Utility";
import { AsyncExecution } from "../Misc/Utility.js";
import CurrentUser from "./CurrentUser.js";
import BaseList from './Intern/BaseList.js';
import GroupedFetch from './Intern/GroupedFetch.js';
import UncachedGroupedFetch from './Intern/UncachedGroupedFetch.js';
import BaseData from './Intern/BaseData.js';
import DataProvider from './Intern/DataProvider.js';
import DataArrayProvider from './Intern/DataArrayProvider.js';
import isEqual from 'lodash/isEqual';
import ConnectionStatusManager from '../Misc/ConnectionStatusManager.js';

class AgeData {
	constructor(machineID : string, fetch: UncachedGroupedFetch) {
		this.machineID = machineID;
		this.keyGen = new UniqueIDGenerator();
		this.fetch = fetch;

		this.pieceAddedNotificationKey = null;
		this.pieceChangedNotificationKey = null;
		this.functions = new Map();

		this.dataAvailable = false;
		this.dataError = false;
		this.data = {};

		this.connectionStatus = ConnectionStatusManager.STATUS.OFFLINE;
		this.connectionStatusKey = null;

		this.updateInProcess = false;
		this.updatePending = false;
		this.updateKey = null;
		this.updateTimeout = null;
		this.reloadInProcess = false;

		this.pieceAdded = this.pieceAdded.bind(this);
		this.pieceChanged = this.pieceChanged.bind(this);
		this.register = this.register.bind(this);
		this.updateData = this.updateData.bind(this);
		this.destroy = this.destroy.bind(this);
		this.empty = this.empty.bind(this);
		this.unregister = this.unregister.bind(this);
		this.inform = this.inform.bind(this);
		this.informKey = this.informKey.bind(this);
		this.reload = this.reload.bind(this);
		this.triggerUpdateData = this.triggerUpdateData.bind(this);
		this.triggerReloadData = this.triggerReloadData.bind(this);


		this.pieceAddedNotificationKey = (new NotificationService()).registerPieceAdded(this.machineID, this.pieceAdded);
		this.pieceChangedNotificationKey = (new NotificationService()).registerPieceChanged(this.machineID, this.pieceChanged);

		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
			const currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

			if (this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID &&
				this.currentUserRightsChangeID === currentUserRightsChangeID)
				return;

			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
			this.currentUserRightsChangeID = currentUserRightsChangeID;

			// Update the data
			this.reload();
		});

		// Update the data
		this.reload();

		// Register the connection manager
		this.connectionStatusKey = (new ConnectionStatusManager()).register((status) => {
			this.connectionStatus = status;

			if (this.connectionStatus === ConnectionStatusManager.STATUS.OFFLINE) {
				// Cancel all pending requests
				if (!IsNullOrUndefined(this.updateKey))
					this.fetch.abort(this.updateKey);
				this.updateKey = null;
			}
			else {
				// We are back online. Reload the complete data.
				this.reload();
			}
		});
	}

	destroy() {
		if (!IsNullOrUndefined(this.connectionStatusKey)) {
			(new ConnectionStatusManager()).unregister(this.connectionStatusKey);
			this.connectionStatusKey = null;
		}

		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}

		// Notifications
		if (this.pieceAddedNotificationKey !== null) {
			(new NotificationService()).unregister(this.pieceAddedNotificationKey);
			this.pieceAddedNotificationKey = null;
		}

		if (this.pieceChangedNotificationKey !== null) {
			(new NotificationService()).unregister(this.pieceChangedNotificationKey);
			this.pieceChangedNotificationKey = null;
		}

		if (!IsNullOrUndefined(this.updateTimeout)) {
			clearTimeout(this.updateTimeout);
			this.updateTimeout = null;
		}

		// Cancel all pending requests
		if (!IsNullOrUndefined(this.updateKey))
			this.fetch.abort(this.updateKey);

		// Clear all remaining functions
		this.functions.forEach((v, k) => {
			this.keyGen.release(k);
		});
		this.functions.clear();
	}

	// Register a function for a specific range which is automatically updated from the beginning on
	// @param func The function to be informed if data is available
	// @return Key for this Function
	register(func) {
		const key = this.keyGen.generate();

		// Set the values
		this.functions.set(key, func);

		if (this.dataAvailable) {
			AsyncExecution(() => { this.informKey(key); });
		}

		return key;
	}

	unregister(key) {
		// find the element
		this.functions.delete(key);
		this.keyGen.release(key);
	}

	reload() {
		if (this.connectionStatus === ConnectionStatusManager.STATUS.OFFLINE)
			return;

		if (this.reloadInProcess)
			return;

		if (!IsNullOrUndefined(this.updateTimeout)) {
			clearTimeout(this.updateTimeout);
			this.updateTimeout = null;
		}

		// Cancel all pending requests
		if (!IsNullOrUndefined(this.updateKey))
			this.fetch.abort(this.updateKey);
		this.updateKey = null;
		this.updatePending = false;
		this.updateInProcess = false;

		this.data = {};
		this.dataAvailable = false;
		this.dataError = false;

		// Reload the data
		this.reloadInProcess = true;

		this.triggerReloadData();
	}

	triggerReloadData() {
		if (IsNullOrUndefined(this.updateTimeout)) {
			this.updateTimeout = setTimeout(() => {
				this.updateTimeout = null;
				this.updateData();
			}, settings.DataReloadDelay);
		}
	}

	triggerUpdateData() {
		if (IsNullOrUndefined(this.updateTimeout)) {
			this.updateTimeout = setTimeout(() => {
				this.updateTimeout = null;
				this.updateData();
			}, settings.DataUpdateDelay);
		}
	}

	updateData() {
		this.reloadInProcess = false;

		if (this.connectionStatus === ConnectionStatusManager.STATUS.OFFLINE)
			return;

		if (this.updateInProcess) {
			this.updatePending = true;
			return;
		}
		this.updateInProcess = true;

		this.updateKey = this.fetch.fetch({ machineID: this.machineID },
			(data) => {
				this.updateKey = null;

				// Update the pieces
				let changed = false;
				const endTime = SafeMax(this.data.endTime, data.data.endTime);
				if (this.data.endTime !== endTime)
					changed = true;
				this.data.endTime = endTime;

				const startTime = SafeMin(this.data.startTime, data.data.startTime);
				if (this.data.startTime !== startTime)
					changed = true;
				this.data.startTime = startTime;

				if (!this.dataAvailable)
					changed = true;
				if (!this.dataError)
					changed = true;

				this.dataAvailable = true;
				this.dataError = false;

				// Inform the functions
				if(changed)
					this.inform();

				this.updateInProcess = false;
				// Reshedule the update if requested
				if (this.updatePending) {
					this.updatePending = false;
					this.updateData();
				}
			},
			(error) => {
				this.updateKey = null;

				switch (error) {
					case GroupedFetch.ERROR.access:
						{
							// We have a valid request but no access to the data. This means that the data on the client should be deleted and that the application should be informed that no access is possible.

							this.data = {};
							this.dataAvailable = true;
							this.dataError = true;

							// Inform the functions
							this.inform();
						}
						break;
					case GroupedFetch.ERROR.general:
					default:
						{
							// We have a connection error. This means that we have to use the old state of the data. The application should not be able to decide if this is old or current data.
						}
						break;
				}

				this.updateInProcess = false;
				// Reshedule the update if requested
				if (this.updatePending) {
					this.updatePending = false;
					this.updateData();
				}
			}
		);
	}

	pieceAdded(machineID, pieceID, endOfProduction, status, customerID) {
		if (machineID !== this.machineID)
			return;

		// We can simply fix the counts
		this.data.endTime = SafeMax(this.data.endTime, endOfProduction);
		this.data.startTime = SafeMin(this.data.startTime, endOfProduction);

		// Check dependencies
		this.inform();
	}

	pieceChanged(machineID, pieceID, endOfProduction, status, customerID) {
		if (machineID !== this.machineID)
			return;

		this.triggerReloadData();
	}

	inform() {
		if (!this.dataAvailable)
			return;

		this.functions.forEach((v, k) => {
			AsyncExecution(() => { this.informKey(k); });
		});
	}

	informKey(key) {
		if (!this.dataAvailable)
			return;

		const f = this.functions.get(key);
		if (IsNullOrUndefined(f))
			return;

		try {
			f(this.data, this.dataError);
		} catch (err) {
			// Nothing to do. Errorhandling has to be done in component
			console.error("Error during processing of registered function. Error: " + err);
		}
	}

	empty() {
		return this.functions.size === 0;
	}
}

class SegmentData extends BaseData {
	constructor(time, machineID: string, fetch: GroupedFetch) {
		super({ time: time, machineID: machineID }, fetch, time.toString() + "_" + machineID);
		
		this.initUpdateListener = this.initUpdateListener.bind(this);
		this.releaseUpdateListener = this.releaseUpdateListener.bind(this);
		this.getSegmentTime = this.getSegmentTime.bind(this);

		this.currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();

		this.pieceNotificationKey = null;
		this.pieceAddedNotificationKey = null;
		this.currentUserKey = null;

		this.init("PieceSegmentData");
	}

	initUpdateListener() {
		// Notification
		this.pieceNotificationKey = (new NotificationService()).registerPieceChanged(this.ID.machineID, (machineID, pieceID, endOfProduction, status, customerID) => {
			const segmentTime = this.getSegmentTime(endOfProduction);

			if (segmentTime === this.ID.time)
				this.reload();
		});
		this.pieceAddedNotificationKey = (new NotificationService()).registerPieceAdded(this.ID.time, (machineID, pieceID, endOfProduction, status, customerID) => {
			const segmentTime = this.getSegmentTime(endOfProduction);

			if (segmentTime === this.ID.time)
				this.reload();
		});

		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();

			if (this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID)
				return;

			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;

			// Update the data
			this.reload();
		});
	}

	releaseUpdateListener() {
		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}

		if (this.pieceNotificationKey !== null) {
			(new NotificationService()).unregister(this.pieceNotificationKey);
			this.pieceNotificationKey = null;
		}
		if (this.pieceAddedNotificationKey !== null) {
			(new NotificationService()).unregister(this.pieceAddedNotificationKey);
			this.pieceAddedNotificationKey = null;
		}
	}

	getSegmentTime(endOfProduction) {
		return Math.floor(endOfProduction / settings.PieceDataTimeCacheSegmentSize) * settings.PieceDataTimeCacheSegmentSize;
	}
}

class SegmentPieceData {
	constructor(time, machineID: string, excludeOperations: boolean, segmentFetch: GroupedFetch, dataArrayProvider: DataArrayProvider) {
		this.excludeOperations = excludeOperations;
		this.machineID = machineID;
		this.segmentFetch = segmentFetch;
		this.dataArrayProvider = dataArrayProvider;

		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.inform = this.inform.bind(this);
		this.informKey = this.informKey.bind(this);
		this.destroy = this.destroy.bind(this);

		this.keyGen = new UniqueIDGenerator();
		this.functions = new Map();

		this.data = [];
		this.error = false;
		this.available = false;
		this.dataKey = null;
		this.segmentKey = null;

		// Create the segment data
		this.segmentData = new SegmentData(time, machineID, segmentFetch);

		// Register the segments
		this.segmentKey = this.segmentData.register((data, error) => {
			if (error) {
				this.error = true;
				this.available = true;
				this.inform();
				return;
			}

			if (this.dataKey !== null) {
				this.dataArrayProvider.unregister(this.dataKey);
				this.dataKey = null;
			}

			const d = data.pieceIds.map((e) => { return { id: e, machineID: this.machineID, excludeOperations: this.excludeOperations }; });
			this.dataKey = this.dataArrayProvider.register(d, (data, error) => {
				if (error) {
					this.error = true;
					this.available = true;
					this.inform();
					return;
				}

				this.data = data;
				this.data.sort((a, b) => { return a.data.endOfProduction - b.data.endOfProduction; });
				this.error = false;
				this.available = true;
				this.inform();
			});
		});
	}

	destroy() {
		if (this.segmentKey !== null) {
			this.segmentData.unregister(this.segmentKey);
			this.segmentKey = null;
		}

		if (this.dataKey !== null) {
			this.dataArrayProvider.unregister(this.dataKey);
			this.dataKey = null;
		}

		this.functions.clear();
		this.available = false;

		if (this.segmentData !== null) {
			this.segmentData.destroy();
			this.segmentData = null;
		}
	}

	register(func) {
		const key = this.keyGen.generate();

		this.functions.set(key, func);

		// Inform the function
		AsyncExecution(() => { this.informKey(key); });

		return key;
	}

	unregister(key) {
		if (this.functions.delete(key))
			this.keyGen.release(key);
	}

	inform() {
		this.functions.forEach((v, k) => {
			AsyncExecution(() => { this.informKey(k); });
		});
	}

	informKey(key) {
		if (!this.available)
			return;

		const f = this.functions.get(key);
		if (IsNullOrUndefined(f))
			return;

		try {
			f(this.data, this.error);
		} catch (err) {
			// Nothing to do. Errorhandling has to be done in component
			console.error("Error during processing of registered function. Err: " + err);
		}
	}
}

class SegmentsPieceData {
	constructor(machineID: string, excludeOperations: boolean, segmentFetch: GroupedFetch, dataArrayProvider: DataArrayProvider) {
		this.excludeOperations = excludeOperations;
		this.machineID = machineID;
		this.segmentFetch = segmentFetch;
		this.dataArrayProvider = dataArrayProvider;

		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.inform = this.inform.bind(this);
		this.informKey = this.informKey.bind(this);
		this.destroy = this.destroy.bind(this);

		this.keyGen = new UniqueIDGenerator();
		this.functions = new Map();

		this.segments = new Map();
	}

	destroy() {
		this.segments.forEach((s) => {
			s.provider.unregister(s.key);
			s.provider.destroy();
		});
		this.segments.clear();

		this.functions.clear();
	}

	registerSegments(segments) {
		segments.forEach((e) => {
			let s = this.segments.get(e);
			if (IsNullOrUndefined(s)) {
				s = {
					provider: new SegmentPieceData(e, this.machineID, this.excludeOperations, this.segmentFetch, this.dataArrayProvider),
					key: null,
					data: null,
					available: false,
					error: false,
					count: 0
				};

				s.key = s.provider.register((data, error) => {
					s.data = data;
					s.error = error;
					s.available = true;

					this.inform([e]);
				});

				this.segments.set(e, s);
			}

			s.count++;
		});
	}

	register(segments, func) {
		const key = this.keyGen.generate();

		this.functions.set(key, { func: func, segments: segments });

		// Register all segments
		this.registerSegments(segments);

		// Inform the function
		AsyncExecution(() => { this.informKey(key, segments); });

		return key;
	}

	unregister(key) {
		// Unregister segments
		const f = this.functions.get(key);
		if (!IsNullOrUndefined(f)) {
			f.segments.forEach((e) => {
				let s = this.segments.get(e);
				if (IsNullOrUndefined(s))
					return;

				s.count--;
				if (s.count === 0) {
					s.provider.unregister(s.key);
					s.provider.destroy();

					this.segments.delete(e);
				}
			});
		}

		if (this.functions.delete(key))
			this.keyGen.release(key);
	}

	getData(segments) {
		let unorderedData = [];
		let error = false;
		let available = true;

		segments.forEach((e) => {
			if (!available)
				return;
			if (error)
				return;

			let v = this.segments.get(e);
			if (IsNullOrUndefined(v)) {
				available = false;
				return;
			}
			if (!v.available) {
				available = false;
				return;
			}

			if (v.error) {
				error = true;
				return;
			}
			unorderedData.push({ time: e, data: v.data });
		});

		if (!available) {
			return {
				data: [],
				error: false,
				available: false,
			};
		}
		if (error) {
			return {
				data: [],
				error: true,
				available: true,
			};
		}

		// Sort the data
		let data = [];
		unorderedData.sort((a, b) => { return a.time - b.time; });
		unorderedData.forEach((e) => {
			data.push(...e.data);
		});

		return {
			data: data,
			error: error,
			available: available,
		};
	}

	inform(segments) {
		this.functions.forEach((v, k) => {
			let found = false;
			for (const s of v.segments) {
				if (segments.includes(s)) {
					found = true;
					break;
				}
			}
			if (!found)
				return;

			AsyncExecution(() => { this.informKey(k); });
		});
	}

	informKey(key) {
		const f = this.functions.get(key);
		if (IsNullOrUndefined(f))
			return;

		const data = this.getData(f.segments);
		if (!data.available)
			return;

		try {
			f.func(data.data, data.error);
		} catch (err) {
			// Nothing to do. Errorhandling has to be done in component
			console.error("Error during processing of registered function. Err: " + err);
		}
	}
}

class AreaData {
	constructor(startTime, endTime, func, segmentsPieceData) {
		this.startTime = startTime;
		this.endTime = endTime;
		this.func = func;
		this.segmentsPieceData = segmentsPieceData;

		this.segmentDataKey = null;
		this.segments = [];

		this.timeout = null;
		this.destroyed = false;

		this.destroy = this.destroy.bind(this);
		this.inform = this.inform.bind(this);
		this.getTimeSegments = this.getTimeSegments.bind(this);
		this.dataChanged = this.dataChanged.bind(this);
		this.update = this.update.bind(this);

		// Get the segments which should be downloaded
		this.segments = this.getTimeSegments(this.startTime, this.endTime);
		this.segmentDataKey = this.segmentsPieceData.register(this.segments, this.dataChanged);
	}

	destroy() {
		// Unregister if class is deleted
		if (this.segmentDataKey !== null) {
			this.segmentsPieceData.unregister(this.segmentDataKey);
			this.segmentDataKey = null;
		}

		this.destroyed = true;
	}

	update(startTime, endTime) {
		if (this.segmentDataKey !== null) {
			this.segmentsPieceData.unregister(this.segmentDataKey);
			this.segmentDataKey = null;
		}

		this.startTime = startTime;
		this.endTime = endTime;

		// Get the segments which should be downloaded
		this.segments = this.getTimeSegments(this.startTime, this.endTime);
		this.segmentDataKey = this.segmentsPieceData.register(this.segments, this.dataChanged);
	}

	dataChanged(data, error) {
		if (error) {
			this.inform([], true);
			return;
		}

		// Filter the data
		const firstIdx = data.findIndex((e) => { return e.data.endOfProduction >= this.startTime; });
		const lastIdx = data.findLastIndex((e) => { return e.data.endOfProduction <= this.endTime; });
		const filteredData = data.slice(firstIdx, lastIdx+1);

		// Inform
		this.inform(filteredData, false);
	}

	getTimeSegments(startTime, endTime) {
		let l = [];

		let _startTime = Math.floor(startTime / settings.PieceDataTimeCacheSegmentSize);
		let _endTime = Math.floor(endTime / settings.PieceDataTimeCacheSegmentSize);

		for (let i = _startTime; i <= _endTime; i++) {
			l.push(i * settings.PieceDataTimeCacheSegmentSize);
		}

		return l;
	}

	inform(data, error) {
		AsyncExecution(() => {
			if (this.destroyed)
				return;

			try {
				this.func(data, error);
			} catch (e) {
				// Nothing to do
				console.error("Error during processing of registered function. Error: " + e);
			}
		});
	}
}

class Area {
	constructor(segmentsPieceData) {
		this.keyGen = new UniqueIDGenerator();
		this.segmentsPieceData = segmentsPieceData;

		this.areas = new Map();

		this.destroy = this.destroy.bind(this);
		this.empty = this.empty.bind(this);
		this.update = this.update.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
	}

	destroy() {
		this.areas.forEach((e) => {
			e.destroy();
		});
		this.areas = [];
	}

	// Register a specific time. The function is called if the data is available
	register(startTime, endTime, func) {
		const key = this.keyGen.generate();

		let d = new AreaData(startTime, endTime, func, this.segmentsPieceData);
		this.areas.set(key, d);

		return key;
	}

	update(key, startTime, endTime) {
		let d = this.areas.get(key);
		if (!IsNullOrUndefined(d))
			d.update(startTime, endTime);
	}

	unregister(key) {
		let d = this.areas.get(key);
		if (!IsNullOrUndefined(d))
			d.destroy();

		if (this.areas.delete(key))
			this.keyGen.release(key);
	}

	empty() {
		return this.areas.size === 0;
	}
}

class TimeBasedCountData {
	constructor(machineID, time, lesserEqualCount, greaterCount, func, segmentsPieceData, ageData) {
		this.machineID = machineID;
		this.time = time;
		this.lesserEqualCount = lesserEqualCount;
		this.greaterCount = greaterCount;
		this.func = func;
		this.segmentsPieceData = segmentsPieceData;
		this.ageData = ageData;

		this.destroy = this.destroy.bind(this);
		this.inform = this.inform.bind(this);
		this.processInform = this.processInform.bind(this);
		this.getSegment = this.getSegment.bind(this);
		this.update = this.update.bind(this);
		this.updateData = this.updateData.bind(this);
		this.getPieceIndex = this.getPieceIndex.bind(this);
		this.requestSegment = this.requestSegment.bind(this);
		this.getBasePieceIndex = this.getBasePieceIndex.bind(this);
		this.getGreaterCount = this.getGreaterCount.bind(this);
		this.getLesserCount = this.getLesserCount.bind(this);
		this.requestAdditionalGreaterSegment = this.requestAdditionalGreaterSegment.bind(this);
		this.requestAdditionalLesserSegment = this.requestAdditionalLesserSegment.bind(this);
		this.checkRequestAdditionalSegments = this.checkRequestAdditionalSegments.bind(this);
		this.updateCurrentData = this.updateCurrentData.bind(this);
		this.removeUnusedSegments = this.removeUnusedSegments.bind(this);
		this.removeLesserUnusedSegments = this.removeLesserUnusedSegments.bind(this);
		this.removeGreaterUnusedSegments = this.removeGreaterUnusedSegments.bind(this);
		this.getMinMaxSegment = this.getMinMaxSegment.bind(this);
		this.getMinMaxAgeSegment = this.getMinMaxAgeSegment.bind(this);
		this.removeSegmentsOutOfMinMax = this.removeSegmentsOutOfMinMax.bind(this);
		this.removeSegmentsOutOfAge = this.removeSegmentsOutOfAge.bind(this);
		this.ensureCurrentDataUpToDate = this.ensureCurrentDataUpToDate.bind(this);
		this.invalidateCurrentData = this.invalidateCurrentData.bind(this);
		this.getInvalidSegmentCount = this.getInvalidSegmentCount.bind(this);


		this.informAbortController = new AbortController();
		this.destroyed = false;

		//{
		//	// Must be sorted by endOfProduction in increasing order (smalest first)
		//	data: [],
		//	ready: false,
		//	key: key
		//};
		this.data = new Map();

		this.currentDataUpToData = false;
		this.currentBaseSegment = null;
		this.currentGreaterCount = 0;
		this.currentLesserEqualCount = 0;
		this.currentGreaterCountReady = true;
		this.currentLesserEqualCountReady = true;

		// Get the age of the machine
		this.age = null;
		this.ageKey = this.ageData.register((data, error) => {
			if (error) {
				this.age = null;
			}
			else {
				// Now we have the age
				this.age = data;

				// Update the data
				this.updateData();
			}
		});

		// Update the data
		this.updateData();
	}

	destroy() {
		this.data.forEach((v, k) => {
			this.segmentsPieceData.unregister(v.key);
		});
		this.data.clear();

		if (this.ageKey !== null) {
			this.ageData.unregister(this.ageKey);
			this.ageKey = null;
		}

		this.informAbortController.abort();

		this.destroyed = true;
	}

	update(time, lesserEqualCount, greaterCount) {
		this.time = time;
		this.lesserEqualCount = lesserEqualCount;
		this.greaterCount = greaterCount;

		// The current data is now invalid
		this.invalidateCurrentData();

		// Update the data
		this.updateData();
	}

	updateData() {
		if (this.destroyed)
			return;

		// ensure that the current data is up to date
		if (!this.ensureCurrentDataUpToDate())
			return;

		// Have we enough segments or do we require additional ones
		this.checkRequestAdditionalSegments();
		// Remove all segments which are not required anymore
		this.removeUnusedSegments();

		// Inform the function
		this.inform();
	}

	checkRequestAdditionalSegments() {
		if (!this.ensureCurrentDataUpToDate())
			return;

		if (!this.currentGreaterCountReady)
			return;
		if (!this.currentLesserEqualCountReady)
			return;

		if (this.currentGreaterCount < this.greaterCount) {
			this.requestAdditionalGreaterSegment();
		}
		if (this.currentLesserEqualCount < this.lesserEqualCount) {
			this.requestAdditionalLesserSegment();
		}
	}

	requestAdditionalGreaterSegment() {
		let maxSegment = null;
		this.data.forEach((v, k) => {
			maxSegment = SafeMax(maxSegment, k);
		});

		// Abort if too much empty
		const counts = this.getInvalidSegmentCount();
		if (counts.newCount > settings.TimeBasedPieceCountRequestSegmentsCount)
			return;

		for (let i = 0; i < settings.TimeBasedPieceCountNumParallelRequests; i++) {
			const segment = maxSegment + settings.PieceDataTimeCacheSegmentSize * (i + 1);

			this.requestSegment(segment);
		}
	}

	requestAdditionalLesserSegment() {
		let minSegment = null;
		this.data.forEach((v, k) => {
			minSegment = SafeMin(minSegment, k);
		});

		// Abort if too much empty
		const counts = this.getInvalidSegmentCount();
		if (counts.oldCount > settings.TimeBasedPieceCountRequestSegmentsCount)
			return;

		for (let i = 0; i < settings.TimeBasedPieceCountNumParallelRequests; i++) {
			const segment = minSegment - settings.PieceDataTimeCacheSegmentSize * (i + 1);

			this.requestSegment(segment);
		}
	}

	removeUnusedSegments() {
		this.removeSegmentsOutOfMinMax();
		this.removeSegmentsOutOfAge();

		if (this.ensureCurrentDataUpToDate()) {
			let invalidate = false;
			if (this.currentGreaterCount > this.greaterCount && this.currentGreaterCountReady) {
				this.removeGreaterUnusedSegments(this.currentBaseSegment.segment);
				invalidate = true;
			}
			if (this.currentLesserEqualCount > this.lesserEqualCount && this.currentLesserCountReady) {
				this.removeLesserUnusedSegments(this.currentBaseSegment.segment);
				invalidate = true;
			}

			if (invalidate)
				this.invalidateCurrentData();
		}
	}

	removeGreaterUnusedSegments(baseSegment) {
		let maxSegment = null;
		this.data.forEach((v, k) => {
			maxSegment = SafeMax(maxSegment, k);
		});

		if (IsNullOrUndefined(maxSegment))
			return;
		if (maxSegment <= baseSegment)
			return;

		const d = this.data.get(maxSegment);
		if (IsNullOrUndefined(d))
			return;

		// This can be removed
		if (d.ready) {
			if (this.currentGreaterCount - d.data.length < this.greaterCount)
				return;
			this.currentGreaterCount -= d.data.length;
		}

		this.segmentsPieceData.unregister(d.key);

		this.data.delete(maxSegment);

		// Remove additional ones
		this.removeGreaterUnusedSegments(baseSegment);
	}

	removeLesserUnusedSegments(baseSegment) {
		let minSegment = null;
		this.data.forEach((v, k) => {
			minSegment = SafeMin(minSegment, k);
		});

		if (IsNullOrUndefined(minSegment))
			return;
		if (minSegment >= baseSegment)
			return;

		const d = this.data.get(minSegment);
		if (IsNullOrUndefined(d))
			return;

		// This can be removed
		if (d.ready) {
			if (this.currentLesserEqualCount - d.data.length < this.lesserEqualCount)
				return;
			this.currentLesserEqualCount -= d.data.length;
		}

		this.segmentsPieceData.unregister(d.key);

		this.data.delete(minSegment);

		// Remove additional ones
		this.removeLesserUnusedSegments(baseSegment);
	}

	removeSegmentsOutOfMinMax() {
		const minMaxSegment = this.getMinMaxSegment();

		let toDelete = [];
		this.data.forEach((v, k) => {
			if (minMaxSegment.min <= k && minMaxSegment.max >= k)
				return;

			this.segmentsPieceData.unregister(v.key);
			toDelete.push(k);
		});

		toDelete.forEach((e) => {
			this.data.delete(e);
		});
	}

	removeSegmentsOutOfAge() {
		const minMaxSegment = this.getMinMaxAgeSegment();
		if (IsNullOrUndefined(minMaxSegment))
			return;

		let toDelete = [];
		this.data.forEach((v, k) => {
			if (minMaxSegment.min <= k && minMaxSegment.max >= k)
				return;

			this.segmentsPieceData.unregister(v.key);
			toDelete.push(k);
		});

		toDelete.forEach((e) => {
			this.data.delete(e);
		});
	}

	requestSegment(segment) {
		if (this.destroyed)
			return;

		if (segment < 0)
			return;

		// Max segments reached?
		if (this.data.size > settings.TimeBasedPieceCountMaxSegments) {
			// Impossible to fullfill the request
			return;
		}

		// Get the minMax-Segment
		const minMaxSegment = this.getMinMaxSegment();
		if (minMaxSegment.min > segment)
			return;
		if (minMaxSegment.max < segment)
			return;

		// Max possible segment
		const minMaxAgeSegment = this.getMinMaxAgeSegment();
		if (IsNullOrUndefined(minMaxAgeSegment))
			return;
		if (minMaxAgeSegment.min > segment)
			return;
		if (minMaxAgeSegment.max < segment)
			return;

		// Already available?
		let data = this.data.get(segment)
		if (!IsNullOrUndefined(data))
			return;

		// Get the segments which should be downloaded
		const key = this.segmentsPieceData.register([segment], (data, error) => {
			const d = this.data.get(segment);
			if (IsNullOrUndefined(d))
				return;

			if (error) {
				// This is failed
				d.data = [];
				d.ready = false;

				// Ready?
				this.invalidateCurrentData();
				this.updateData();
			}
			else {
				// This is now ready
				d.data = data;
				d.ready = true;

				// Ready?
				this.invalidateCurrentData();
				this.updateData();
			}
		});
		data = {
			data: [],
			ready: false,
			key: key
		};
		this.data.set(segment, data);
	}

	invalidateCurrentData() {
		this.currentDataUpToData = false;
	}

	ensureCurrentDataUpToDate() {
		if (this.currentDataUpToData)
			return true;
		this.updateCurrentData();

		return this.currentDataUpToData;
	}

	getPieceIndex(segment, time) {
		const data = this.data.get(segment)
		if (IsNullOrUndefined(data))
			return { index: null, segment: null };
		if (!data.ready)
			return { index: null, segment: null };

		if (data.data.length === 0) {
			// No elements in this segment. The base must be in a earlier segment.
			return { index: null, segment: segment - settings.PieceDataTimeCacheSegmentSize };
		}
		const idx = data.data.findIndex((e) => { return e.data.endOfProduction > time; });
		if (idx < 0) {
			// Nothing was successful. The base is the last piece
			return { index: data.data.length - 1, segment: segment };
		}
		else if (idx === 0) {
			// Direct the first item was successfull. The base must be in an earlier segment
			return { index: null, segment: segment - settings.PieceDataTimeCacheSegmentSize };
		}
		else
			return { index: idx - 1, segment: segment };
	}

	getBasePieceIndex() {
		// Max possible segment
		const minMaxAgeSegment = this.getMinMaxAgeSegment();
		if (IsNullOrUndefined(minMaxAgeSegment))
			return { index: null, segment: null };
		if (minMaxAgeSegment.min > this.currentBaseSegment.startTime) {
			// We are past the min. So we use the min-segment as start segment
			return { index: 0, segment: minMaxAgeSegment.min };
		}

		let baseIndex = { index: null, segment: this.currentBaseSegment.startTime };

		if (minMaxAgeSegment.max < this.currentBaseSegment.startTime) {
			// We are in front of the max, so we use the max segment and detect the piece
			baseIndex = { index: null, segment: minMaxAgeSegment.max };
		}

		// Get the segment
		while (true) {
			// Requested?
			const newBaseIndex = this.getPieceIndex(baseIndex.segment, this.time);
			if (!IsNullOrUndefined(newBaseIndex.index) && !IsNullOrUndefined(newBaseIndex.segment))
				return newBaseIndex;
			if (IsNullOrUndefined(newBaseIndex.segment)) {
				// Segment doesn't exist. We have simply to wait until we can find the base index...
				return { index: null, segment: baseIndex.segment };
			}

			baseIndex = newBaseIndex;
		}
	}

	ensureBaseSegmentLoaded() {
		this.currentBaseSegment = this.getSegment(this.time);

		// Get the base index 
		const index = this.getBasePieceIndex();
		if (IsNullOrUndefined(index.index)) {
			if (!IsNullOrUndefined(index.segment)) {
				let segment = index.segment;

				// request a new segment
				this.requestSegment(segment);
			}
			this.currentBaseSegment = null;
			return false;
		}

		this.currentBaseSegment = index;
		return true;
	}

	getLesserCount(segment, index) {
		let count = 0;
		let ready = true;

		const data = this.data.get(segment)
		if (!IsNullOrUndefined(data)) {
			if (data.ready) {
				// base
				const c = index;
				if (c < data.data.length)
					count += c;
				else
					count += data.data.length;
			}
			else
				ready = false;
		}

		// Previous segments
		this.data.forEach((v, k) => {
			if (k >= segment)
				return;
			if (!v.ready) {
				ready = false;
				return;
			}

			count += v.data.length;
		});

		return { count: count, ready: ready };
	}

	getGreaterCount(segment, index) {
		let count = 0;
		let ready = true;

		const data = this.data.get(segment)
		if (!IsNullOrUndefined(data)) {
			if (data.ready) {
				// base
				const c = data.data.length - index - 1;
				if(c > 0)
					count += c;
			}
			else
				ready = false;
		}

		// Previous segments
		this.data.forEach((v, k) => {
			if (k <= segment)
				return;
			if (!v.ready) {
				ready = false;
				return;
			}

			count += v.data.length;
		});

		return { count: count, ready: ready };
	}

	updateCurrentData() {
		this.currentDataUpToData = false;

		// Check that the base-segment is available
		if (!this.ensureBaseSegmentLoaded())
			return;

		// Get the count
		const greaterCount = this.getGreaterCount(this.currentBaseSegment.segment, this.currentBaseSegment.index);
		const lesserCount = this.getLesserCount(this.currentBaseSegment.segment, this.currentBaseSegment.index);

		this.currentGreaterCountReady = greaterCount.ready;
		this.currentLesserEqualCountReady = lesserCount.ready;
		this.currentGreaterCount = greaterCount.count;
		this.currentLesserEqualCount = lesserCount.count + 1;

		this.currentDataUpToData = true;
	}

	getSegment(time) {
		const _time = Math.floor(time / settings.PieceDataTimeCacheSegmentSize);
		return { startTime: _time * settings.PieceDataTimeCacheSegmentSize, endTime: _time * settings.PieceDataTimeCacheSegmentSize + settings.PieceDataTimeCacheSegmentSize - 1 };
	}

	getInvalidSegmentCount() {
		// Check that the base-segment is available
		if (!this.ensureBaseSegmentLoaded())
			return;

		// Get the list
		let oldList = [];
		let newList = [];
		this.data.forEach((v, k) => {
			let empty = true;
			if (!v.ready)
				empty = false;
			else {
				if (v.data.length > 0)
					empty = false;
			}

			if (k < this.currentBaseSegment.segment) {
				oldList.push({ t: k, empty: empty });
			}
			if (k > this.currentBaseSegment.segment) {
				newList.push({ t: k, empty: empty });
			}
		});

		// Sort
		oldList.sort((a, b) => { return a.t - b.t; }); // Smalest first
		newList.sort((a, b) => { return b.t - a.t; }); // Smalest last

		let oldCount = 0;
		let newCount = 0;

		for (let i = 0; i < oldList.length; i++) {
			if (!oldList[i].empty)
				break;
			oldCount++;
		}
		for (let i = 0; i < newList.length; i++) {
			if (!newList[i].empty)
				break;
			newCount++;
		}

		return {
			oldCount: oldCount,
			newCount: newCount
		};
	}

	getMinMaxSegment() {
		const baseSegment = this.getSegment(this.time);

		return {
			min: baseSegment.startTime - settings.TimeBasedPieceCountMaxSegmentRange * settings.PieceDataTimeCacheSegmentSize,
			max: baseSegment.startTime + settings.TimeBasedPieceCountMaxSegmentRange * settings.PieceDataTimeCacheSegmentSize
		};
	}

	getMinMaxAgeSegment() {
		if (IsNullOrUndefined(this.age))
			return null;

		return {
			min: this.getSegment(this.age.startTime).startTime,
			max: this.getSegment(this.age.endTime).startTime
		};
	}

	inform() {
		AsyncExecution(() => { this.processInform(); }, this.informAbortController.signal);
	}

	processInform() {
		if (this.destroyed)
			return;

		// Not ready. we have an empty dataset
		let invalid = false;
		if (!this.ensureCurrentDataUpToDate())
			invalid = true;

		if (!this.currentLesserEqualCountReady)
			invalid = true;
		if (!this.currentGreaterCountReady)
			invalid = true;

		if (invalid) {
			try {
				this.func([], 0, 0);
			} catch (e) {
				// Nothing to do
				console.error("Error during processing of registered function");
			}
			return;
		}


		// Get the data
		let data = [];
		let ordered = [];
		this.data.forEach((v, k) => {
			if (v.ready) {
				if (v.data.length > 0)
					ordered.push({ segment: k, data: v.data });
			}
		});
		ordered.sort((a, b) => { return a.segment - b.segment; });
		ordered.forEach((e) => {
			data.push(...e.data);
		});

		// Check if count is correct
		if (data.length !== this.currentGreaterCount + this.currentLesserEqualCount) {
			console.error("Count not correct");
		}

		// Remove too much lesser counts at the beginning
		data.splice(this.currentLesserEqualCount + this.greaterCount);
		data.splice(0, this.currentLesserEqualCount - this.lesserEqualCount);

		try {
			this.func(data, Math.min(this.currentLesserEqualCount, this.lesserEqualCount), Math.min(this.currentGreaterCount, this.greaterCount));
		} catch (e) {
			// Nothing to do
			console.error("Error during processing of registered function. Error: " + e);
		}
	}
}

class TimeBasedCount {
	constructor(machineID, segmentsPieceData, ageData) {
		this.machineID = machineID;
		this.keyGen = new UniqueIDGenerator();
		this.segmentsPieceData = segmentsPieceData;
		this.ageData = ageData;

		this.registrations = new Map();

		this.destroy = this.destroy.bind(this);
		this.empty = this.empty.bind(this);
		this.update = this.update.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
	}

	destroy() {
		this.registrations.forEach((v,k) => {
			v.destroy();
		});
		this.registrations.clear();
	}

	// Register a specific time. The function is called if the data is available
	register(time, lesserEqualCount, greaterCount, func) {
		const key = this.keyGen.generate();

		let d = new TimeBasedCountData(this.machineID, time, lesserEqualCount, greaterCount, func, this.segmentsPieceData, this.ageData);
		this.registrations.set(key, d);

		return key;
	}

	update(key, time, lesserEqualCount, greaterCount) {
		const data = this.registrations.get(key);
		if (IsNullOrUndefined(data))
			return;
		data.update(time, lesserEqualCount, greaterCount);
	}

	unregister(key) {
		const data = this.registrations.get(key);
		if (!IsNullOrUndefined(data))
			data.destroy();

		this.registrations.delete(key);

		this.keyGen.release(key);
	}

	empty() {
		return this.registrations.size === 0;
	}
}

class AutomaticUpdateData {
	constructor(timeRange, func, segmentsPieceData) {
		this.timeRange = timeRange;
		this.func = func;
		this.segmentsPieceData = segmentsPieceData;

		this.segmentDataKey = null;
		this.segments = [];

		this.timeout = null;
		this.destroyed = false;
		this.data = [];
		this.error = false;
		this.available = false;

		this.destroy = this.destroy.bind(this);
		this.inform = this.inform.bind(this);
		this.getTimeSegments = this.getTimeSegments.bind(this);
		this.dataChanged = this.dataChanged.bind(this);

		// Get the segments which should be downloaded
		const now = Date.now();
		this.segments = this.getTimeSegments(now - this.timeRange, now);
		this.segmentDataKey = this.segmentsPieceData.register(this.segments, this.dataChanged);

		// Update the Lists
		this.interval = setInterval(() => {
			const now = Date.now();
			const segments = this.getTimeSegments(now - this.timeRange, now);
			if (!isEqual(segments, this.segments)) {
				if (this.segmentDataKey !== null) {
					this.segmentsPieceData.unregister(this.segmentDataKey);
					this.segmentDataKey = null;
				}

				this.segmentDataKey = this.segmentsPieceData.register(this.segments, this.dataChanged);
			}

			// Update the present data
			this.inform();
		}, parseInt(settings.DataUpdateTime));
	}

	destroy() {
		if (this.interval !== null) {
			clearInterval(this.interval);
			this.interval = null;
		}

		// Unregister if class is deleted
		if (this.segmentDataKey !== null) {
			this.segmentsPieceData.unregister(this.segmentDataKey);
			this.segmentDataKey = null;
		}

		this.destroyed = true;
	}

	dataChanged(data, error) {
		this.available = true;

		if (error) {
			this.error = true;
			this.inform();
			return;
		}

		this.data = data;
		this.error = false;

		this.inform();
	}

	getTimeSegments(startTime, endTime) {
		let l = [];

		let _startTime = Math.floor(startTime / settings.PieceDataTimeCacheSegmentSize);
		let _endTime = Math.floor(endTime / settings.PieceDataTimeCacheSegmentSize);

		for (let i = _startTime; i <= _endTime; i++) {
			l.push(i * settings.PieceDataTimeCacheSegmentSize);
		}

		return l;
	}

	inform() {
		AsyncExecution(() => {
			if (this.destroyed)
				return;
			if (!this.available)
				return;

			if (this.error) {
				try {
					this.func([], true);
				} catch (e) {
					// Nothing to do
					console.error("Error during processing of registered function. Error: " + e);
				}
			}
			else {

				// Filter the data
				const now = Date.now();
				const firstIdx = this.data.findIndex((e) => { return e.data.endOfProduction >= (now - this.timeRange); });
				const lastIdx = this.data.findLastIndex((e) => { return e.data.endOfProduction <= now; });
				const filteredData = this.data.slice(firstIdx, lastIdx + 1);

				try {
					this.func(filteredData, false);
				} catch (e) {
					// Nothing to do
					console.error("Error during processing of registered function. Error: " + e);
				}
			}
		});
	}
}

class AutomaticUpdate {
	constructor(segmentsPieceData) {
		this.keyGen = new UniqueIDGenerator();
		this.segmentsPieceData = segmentsPieceData;

		this.automaticUpdate = new Map();

		this.destroy = this.destroy.bind(this);
		this.empty = this.empty.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
	}

	destroy() {
		this.automaticUpdate.forEach((e) => {
			e.destroy();
		});
		this.automaticUpdate = [];
	}

	// Register a specific time. The function is called if the data is available
	register(timeRange, func) {
		const key = this.keyGen.generate();

		let d = new AutomaticUpdateData(timeRange, func, this.segmentsPieceData);
		this.automaticUpdate.set(key, d);

		return key;
	}

	unregister(key) {
		let d = this.automaticUpdate.get(key);
		if (!IsNullOrUndefined(d))
			d.destroy();

		if (this.automaticUpdate.delete(key))
			this.keyGen.release(key);
	}

	empty() {
		return this.automaticUpdate.size === 0;
	}
}

class AutomaticUpdateCount {
	constructor(machineID, segmentsPieceData, ageData) {
		this.machineID = machineID;
		this.keyGen = new UniqueIDGenerator();
		this.segmentsPieceData = segmentsPieceData;
		this.ageData = ageData;

		this.registrations = new Map();

		this.destroy = this.destroy.bind(this);
		this.empty = this.empty.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);

		// Update the Lists
		this.interval = setInterval(() => {
			const now = Date.now();

			this.registrations.forEach((e) => {
				e.data.update(now, e.count, 0);
			});
		}, parseInt(settings.DataUpdateTime));
	}

	destroy() {
		if (this.interval !== null) {
			clearInterval(this.interval);
			this.interval = null;
		}

		this.registrations.forEach((v, k) => {
			v.data.destroy();
		});
		this.registrations.clear();
	}

	// Register a specific time. The function is called if the data is available
	register(count, func) {
		const key = this.keyGen.generate();

		const now = Date.now();
		let d = new TimeBasedCountData(this.machineID, now, count, 0, (data, min, max) => { func(data, false); }, this.segmentsPieceData, this.ageData);
		this.registrations.set(key, { data: d, count: count });

		return key;
	}

	unregister(key) {
		const data = this.registrations.get(key);
		if (!IsNullOrUndefined(data))
			data.data.destroy();

		this.registrations.delete(key);

		this.keyGen.release(key);
	}

	empty() {
		return this.registrations.size === 0;
	}
}

class Data extends BaseData {
	constructor(ID: string, fetch: GroupedFetch, machineID: string, excludeOperations: boolean) {
		super({ id: ID, machineID: machineID, excludeOperations: excludeOperations }, fetch, ID + "_" + machineID + "_" + excludeOperations.toString());

		this.initUpdateListener = this.initUpdateListener.bind(this);
		this.releaseUpdateListener = this.releaseUpdateListener.bind(this);

		this.currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();

		this.pieceNotificationKey = null;
		this.currentUserKey = null;

		this.init("PieceData");
	}

	initUpdateListener() {
		// Notification
		this.pieceNotificationKey = (new NotificationService()).registerPieceChanged(this.ID.machineID, (machineID, pieceID, endOfProduction, status, customerID) => {
			if (this.ID.id === pieceID)
				this.triggerUpdateData();
		});

		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();

			if (this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID)
				return;

			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;

			// Update the data
			this.reload();
		});
	}

	releaseUpdateListener() {
		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}

		if (this.pieceNotificationKey !== null) {
			(new NotificationService()).unregister(this.pieceNotificationKey);
			this.pieceNotificationKey = null;
		}
	}
}

class List extends BaseList {
	constructor(machineIDs, firstIndex, count, sortField, sortOrder, filters, func) {
		super("/api/Piece/list", firstIndex, count, sortField, sortOrder, filters, func);

		this.machineIDs = machineIDs;

		this.init = this.init.bind(this);
		this.release = this.release.bind(this);
		this.transformFilter = this.transformFilter.bind(this);
		this.customerData = this.customerData.bind(this);

		this.currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();
		this.currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();

		this.currentUserKey = null;

		this.keys = [];
	}

	initUpdateListener() {
		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();
			const currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();

			if (this.currentUserRightsChangeID === currentUserRightsChangeID &&
				this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID)
				return;

			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
			this.currentUserRightsChangeID = currentUserRightsChangeID;

			// Update the data
			this.reload();
		});

		this.machineIDs.forEach((element) => {
			this.keys.push((new NotificationService()).registerPieceAdded(element, (machineID, pieceID, endOfProduction, status, customerID) => {
				// Update the data
				this.triggerUpdateData();
			}));
			this.keys.push((new NotificationService()).registerPieceChanged(element, (machineID, pieceID, endOfProduction, status, customerID) => {
				// Update the data
				this.triggerUpdateData();
			}));
		});
	}

	releaseUpdateListener() {
		this.keys.forEach((element) => {
			(new NotificationService()).unregister(element)
		})
		this.keys = [];

		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}
	}

	transformFilter() {
		// Create the filters
		let endOfProductionFilter = null;
		let idFilter = null;
		let typeFilter = null;
		let statusFilter = null;
		let durationFilter = null;

		for (const fieldId in this.filters) {
			const val = this.filters[fieldId];

			if (fieldId === "visibleID") {
				if (val === '-')
					idFilter = { textFilter: null };
				else
					idFilter = { textFilter: val };
			}
			else if (fieldId === "type") {
				if (val === '-')
					typeFilter = { textFilter: null };
				else
					typeFilter = { textFilter: val };
			}
			else if (fieldId === "endOfProduction") {
				endOfProductionFilter = { startTime: val.start, endTime: val.end };
			}
			else if (fieldId === "duration") {
				durationFilter = { startTime: val.start, endTime: val.end };
			}
			else if (fieldId === "status") {
				if (!IsNullOrUndefined(val)) {
					if (val === "UNKNOWN")
						statusFilter = { status: null };
					else
						statusFilter = { status: val };
				}
			}
		}

		// Create the data
		return {
			endOfProductionFilter: endOfProductionFilter,
			idFilter: idFilter,
			typeFilter: typeFilter,
			statusFilter: statusFilter,
			durationFilter: durationFilter
		};
	}

	customerData() {
		return {
			machineIds: this.machineIDs
		};
	}
}

class DataList {
	constructor(excludeOperations: boolean, machineID: string, fetch: GroupedFetch, parent) {
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.empty = this.empty.bind(this);

		this.excludeOperations = excludeOperations;
		this.machineID = machineID;
		this.fetch = fetch;
		this.parent = parent;

		this.keys = new Map();
		this.keyGen = new UniqueIDGenerator();
		this.data = new Map();
	}

	destroy() {
		this.data.forEach((v, k) => {
			v.data.destroy();
		});
		this.data.clear();
		this.keys.clear();
	}

	register(pieceID, func) {
		let d = this.data.get(pieceID);
		if (IsNullOrUndefined(d)) {
			d = {
				data: new Data(pieceID, this.fetch, this.machineID, this.excludeOperations),
				timeout: null
			};
			this.data.set(pieceID, d);
		}

		if (!IsNullOrUndefined(d.timeout)) {
			clearTimeout(d.timeout);
			d.timeout = null;
		}

		const subKey = d.data.register(func);

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { key: subKey, pieceID: pieceID });

		return key;
	}

	unregister(key) {
		const obj = this.keys.get(key);
		if (IsNullOrUndefined(obj))
			return;

		const d = this.data.get(obj.pieceID);
		if (IsNullOrUndefined(d))
			return;

		d.data.unregister(obj.key);

		// Free the key
		this.keys.delete(key);
		this.keyGen.release(key);

		if (d.data.empty()) {
			if (IsNullOrUndefined(d.timeout)) {
				d.timeout = setTimeout(() => {
					d.timeout = null;

					if (!d.data.empty())
						return;
					d.data.destroy();
					this.data.delete(obj.pieceID);

					this.parent.addCheckDeleteMachine(this.machineID);
				}, settings.LocalDataHoldTime);
			}
		}
	}

	empty() {
		return this.data.size === 0;
	}
}

class PieceDataProvider extends DataProvider {
	constructor() {
		super();

		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
	}

	register(id, func)
	{
		return (new PieceData()).register(id.id, id.machineID, func, id.excludeOperations);
	}
	unregister(key)
	{
		return (new PieceData()).unregister(key);
	}
}

let instance;
export default class PieceData {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.fetch = new GroupedFetch("/api/Piece/GetData");
		this.segmentFetch = new GroupedFetch("/api/Piece/GetSegmentData");
		this.ageFetch = new UncachedGroupedFetch("/api/Piece/GetAge");
		this.dataProvider = new PieceDataProvider();
		this.dataArrayProvider = new DataArrayProvider(this.dataProvider);

		this.keys = new Map();
		this.keyGen = new UniqueIDGenerator();

		this.machineArray = new Map();
		this.listArray = new Map();

		this.destroy = this.destroy.bind(this);
		this.checkDeleteMachine = this.checkDeleteMachine.bind(this);
		this.registerAutomaticUpdate = this.registerAutomaticUpdate.bind(this);
		this.registerAutomaticUpdateCount = this.registerAutomaticUpdateCount.bind(this);
		this.registerArea = this.registerArea.bind(this);
		this.updateArea = this.updateArea.bind(this);
		this.registerTimeBasedCount = this.registerTimeBasedCount.bind(this);
		this.updateTimeBasedCount = this.updateTimeBasedCount.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.list = this.list.bind(this);
		this.updateList = this.updateList.bind(this);
		this.createMachineObject = this.createMachineObject.bind(this);
		this.addCheckDeleteMachine = this.addCheckDeleteMachine.bind(this);
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance)) {
			instance.destroy();
		}

		instance = null;
	}

	destroy() {
		this.machineArray.forEach((v, k) => {
			if (!IsNullOrUndefined(v.au))
				v.au.destroy();
			if (!IsNullOrUndefined(v.excludeOperationsAu))
				v.excludeOperationsAu.destroy();
			if (!IsNullOrUndefined(v.auCount))
				v.auCount.destroy();
			if (!IsNullOrUndefined(v.excludeOperationsAuCount))
				v.excludeOperationsAuCount.destroy();
			if (!IsNullOrUndefined(v.area))
				v.area.destroy();
			if (!IsNullOrUndefined(v.excludeOperationsArea))
				v.excludeOperationsArea.destroy();
			if (!IsNullOrUndefined(v.data))
				v.data.destroy();
			if (!IsNullOrUndefined(v.excludeOperationsData))
				v.excludeOperationsData.destroy();
			if (!IsNullOrUndefined(v.count))
				v.count.destroy();
			if (!IsNullOrUndefined(v.excludeOperationsCount))
				v.excludeOperationsCount.destroy();

			if (!IsNullOrUndefined(v.segmentData))
				v.segmentData.destroy();
			if (!IsNullOrUndefined(v.excludeOperationsSegmentData))
				v.excludeOperationsSegmentData.destroy();
			if (!IsNullOrUndefined(v.ageData))
				v.ageData.destroy();

			if (!IsNullOrUndefined(v.releaseTimeoutKey)) {
				clearTimeout(v.releaseTimeoutKey);
				v.releaseTimeoutKey = null;
			}
		});
		this.listArray.forEach((v, k) => {
			v.release();
			v.destroy();
		});

		this.listArray.clear();
		this.machineArray.clear();
		this.keys.clear();

		if (!IsNullOrUndefined(this.dataArrayProvider))
			this.dataArrayProvider.destroy();
		this.dataArrayProvider = null;
		if (!IsNullOrUndefined(this.dataProvider))
			this.dataProvider.destroy();
		this.dataProvider = null;

		if (!IsNullOrUndefined(this.fetch))
			this.fetch.destroy();
		this.fetch = null;
		if (!IsNullOrUndefined(this.segmentFetch))
			this.segmentFetch.destroy();
		this.segmentFetch = null;
		if (!IsNullOrUndefined(this.ageFetch))
			this.ageFetch.destroy();
		this.ageFetch = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	createMachineObject(machineID) {
		return {
			releaseTimeoutKey: null,
			au: null,
			excludeOperationsAu: null,
			auCount: null,
			excludeOperationsAuCount: null,
			area: null,
			excludeOperationsArea: null,
			data: null,
			excludeOperationsData: null,
			count: null,
			excludeOperationsCount: null,
			segmentData: new SegmentsPieceData(machineID, false, this.segmentFetch, this.dataArrayProvider),
			excludeOperationsSegmentData: new SegmentsPieceData(machineID, true, this.segmentFetch, this.dataArrayProvider),
			ageData: new AgeData(machineID, this.ageFetch),
		};
	}

	// Register a single ID
	register(pieceID, machineID, func, excludeOperations = false) {
		let m = this.machineArray.get(machineID);
		if (IsNullOrUndefined(m)) {
			m = this.createMachineObject(machineID);
			this.machineArray.set(machineID, m);
		}

		let d;
		let type;
		let subKey;

		if (excludeOperations) {
			if (IsNullOrUndefined(m.excludeOperationsData)) {
				let excludeOperationsData = new DataList(true, machineID, this.fetch, this);
				m.excludeOperationsData = excludeOperationsData;
			}

			subKey = m.excludeOperationsData.register(pieceID, func);
			type = 4;
		}
		else {
			if (IsNullOrUndefined(m.data)) {
				let data = new DataList(false, machineID, this.fetch, this);
				m.data = data;
			}

			subKey = m.data.register(pieceID, func);
			type = 3;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: type, key: subKey, machineID: machineID, pieceID: pieceID });

		return key;
	}

	// Register for the current dataset
	// The data is always sorted after the endOfProduction
	registerAutomaticUpdate(machineID, timeRange, func, excludeOperations = false) {
		let m = this.machineArray.get(machineID);
		if (IsNullOrUndefined(m)) {
			m = this.createMachineObject(machineID);
			this.machineArray.set(machineID, m);
		}

		let subKey;
		let type;

		if (excludeOperations) {
			if (IsNullOrUndefined(m.au)) {
				let au = new AutomaticUpdate(m.excludeOperationsSegmentData);
				m.excludeOperationsAu = au;
			}
			subKey = m.excludeOperationsAu.register(timeRange, func);
			type = 8;
		}
		else {
			if (IsNullOrUndefined(m.au)) {
				let au = new AutomaticUpdate(m.segmentData);
				m.au = au;
			}
			subKey = m.au.register(timeRange, func);
			type = 0;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: type, key: subKey, machineID: machineID });

		return key;
	}

	// Register for the current dataset
	// The data is always sorted after the endOfProduction
	registerAutomaticUpdateCount(machineID, count, func, excludeOperations = false) {
		let m = this.machineArray.get(machineID);
		if (IsNullOrUndefined(m)) {
			m = this.createMachineObject(machineID);
			this.machineArray.set(machineID, m);
		}

		let subKey;
		let type;

		if (excludeOperations) {
			if (IsNullOrUndefined(m.auCount)) {
				let auCount = new AutomaticUpdateCount(machineID, m.excludeOperationsSegmentData, m.ageData);
				m.excludeOperationsAuCount = auCount;
			}
			subKey = m.excludeOperationsAuCount.register(count, func);
			type = 9;
		}
		else {
			if (IsNullOrUndefined(m.auCount)) {
				let auCount = new AutomaticUpdateCount(machineID, m.segmentData, m.ageData);
				m.auCount = auCount;
			}
			subKey = m.auCount.register(count, func);
			type = 2;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: type, key: subKey, machineID: machineID });

		return key;
	}

	// Register for the current dataset
	registerTimeBasedCount(machineID, time, lesserEqualCount, greaterCount, func, excludeOperations = false) {
		let m = this.machineArray.get(machineID);
		if (IsNullOrUndefined(m)) {
			m = this.createMachineObject(machineID);
			this.machineArray.set(machineID, m);
		}

		let subKey;
		let type;

		if (excludeOperations) {
			if (IsNullOrUndefined(m.excludeOperationsCount)) {
				m.excludeOperationsCount = new TimeBasedCount(machineID, m.excludeOperationsSegmentData, m.ageData);
			}
			subKey = m.excludeOperationsCount.register(time, lesserEqualCount, greaterCount, func);
			type = 10;
		}
		else {
			if (IsNullOrUndefined(m.count)) {
				let count = new TimeBasedCount(machineID, m.segmentData, m.ageData);
				m.count = count;
			}
			subKey = m.count.register(time, lesserEqualCount, greaterCount, func);
			type = 5;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: type, key: subKey, machineID: machineID });

		return key;
	}

	// Update for the current dataset
	updateTimeBasedCount(key, time, lesserEqualCount, greaterCount) {
		const k = this.keys.get(key);
		if (IsNullOrUndefined(k))
			return;
		// Is this a area
		if (k.type !== 5 && k.type !== 10)
			return;

		// Get the Machine
		let m = this.machineArray.get(k.machineID);
		if (IsNullOrUndefined(m))
			return;

		if (k.type === 5) {
			if (IsNullOrUndefined(m.count))
				return;

			// Register the Function
			m.count.update(k.key, time, lesserEqualCount, greaterCount);
		}
		else {
			if (IsNullOrUndefined(m.excludeOperationsCount))
				return;

			// Register the Function
			m.excludeOperationsCount.update(k.key, time, lesserEqualCount, greaterCount);
		}
	}

	// Register for the current dataset
	// The data is always sorted after the endOfProduction
	registerArea(machineID, startTime, endTime, func, excludeOperations = false) {
		let m = this.machineArray.get(machineID);
		if (IsNullOrUndefined(m)) {
			m = this.createMachineObject(machineID);
			this.machineArray.set(machineID, m);
		}

		let subKey;
		let type;

		if (excludeOperations) {
			if (IsNullOrUndefined(m.excludeOperationsArea)) {
				let excludeOperationsArea = new Area(m.excludeOperationsSegmentData);
				m.excludeOperationsArea = excludeOperationsArea;
			}

			subKey = m.excludeOperationsArea.register(startTime, endTime, func);
			type = 7;
		}
		else {
			if (IsNullOrUndefined(m.area)) {
				let area = new Area(m.segmentData);
				m.area = area;
			}

			subKey = m.area.register(startTime, endTime, func);
			type = 1;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: type, key: subKey, machineID: machineID });

		return key;
	}

	// Update for the current dataset
	updateArea(key, startTime, endTime) {
		const k = this.keys.get(key);
		if (IsNullOrUndefined(k))
			return;
		// Is this a area
		if (k.type !== 1 && k.type !== 7)
			return;

		// Get the Machine
		let m = this.machineArray.get(k.machineID);
		if (IsNullOrUndefined(m))
			return;

		if (k.type === 7) {
			if (IsNullOrUndefined(m.excludeOperationsArea))
				return;
			// Register the Function
			m.excludeOperationsArea.update(k.key, startTime, endTime);
		}
		else {
			if (IsNullOrUndefined(m.area))
				return;
			// Register the Function
			m.area.update(k.key, startTime, endTime);
		}
	}

	// Register for the current dataset
	list(machineIds, firstIndex, count, sortField, sortOrder, filters, func) {
		let data = new List(machineIds, firstIndex, count, sortField, sortOrder, filters, func);
		data.init();

		// Integrate the key
		const key = this.keyGen.generate();
		this.listArray.set(key, data);

		this.keys.set(key, { type: 6 });

		return key;
	}

	updateList(key, firstIndex, count, sortField, sortOrder, filters) {
		const m = this.listArray.get(key);
		if (IsNullOrUndefined(m))
			return true;

		return m.update(firstIndex, count, sortField, sortOrder, filters);
	}

	// Unregister
	unregister(key) {
		const obj = this.keys.get(key);
		if (IsNullOrUndefined(obj))
			return;

		if (obj.type === 6) {
			const m = this.listArray.get(key);
			if (IsNullOrUndefined(m))
				return;

			m.release();
			m.destroy();
			this.listArray.delete(key);
		}
		else {
			const m = this.machineArray.get(obj.machineID);
			if (IsNullOrUndefined(m))
				return;

			switch (obj.type) {
				case 0:
					{
						if (!IsNullOrUndefined(m.au))
							m.au.unregister(obj.key);
					} break;
				case 1:
					{
						if (!IsNullOrUndefined(m.area))
							m.area.unregister(obj.key);
					} break;
				case 2:
					{
						if (!IsNullOrUndefined(m.auCount))
							m.auCount.unregister(obj.key);
					} break;
				case 3:
					{
						if (!IsNullOrUndefined(m.excludeOperationsData))
							m.excludeOperationsData.unregister(obj.key);
					} break;
				case 4:
					{
						if (!IsNullOrUndefined(m.data))
							m.data.unregister(obj.key);
					} break;
				case 5:
					{
						if (!IsNullOrUndefined(m.count))
							m.count.unregister(obj.key);
					} break;
				case 7:
					{
						if (!IsNullOrUndefined(m.excludeOperationsArea))
							m.excludeOperationsArea.unregister(obj.key);
					} break;
				case 8:
					{
						if (!IsNullOrUndefined(m.excludeOperationsAu))
							m.excludeOperationsAu.unregister(obj.key);
					} break;
				case 9:
					{
						if (!IsNullOrUndefined(m.excludeOperationsAuCount))
							m.excludeOperationsAuCount.unregister(obj.key);
					} break;
				case 10:
					{
						if (!IsNullOrUndefined(m.excludeOperationsCount))
							m.excludeOperationsCount.unregister(obj.key);
					} break;
				case 6:
				default:
					break;
			}

			// Release?
			if (IsNullOrUndefined(m.releaseTimeoutKey)) {
				m.releaseTimeoutKey = setTimeout(() => { m.releaseTimeoutKey = null; this.checkDeleteMachine(obj.machineID); }, settings.LocalDataHoldTime);
			}
		}

		// Free the key
		this.keys.delete(key);
		this.keyGen.release(key);
	}

	addCheckDeleteMachine(machineID) {
		const m = this.machineArray.get(machineID);
		if (IsNullOrUndefined(m))
			return;

		if (IsNullOrUndefined(m.releaseTimeoutKey)) {
			m.releaseTimeoutKey = setTimeout(() => { m.releaseTimeoutKey = null; this.checkDeleteMachine(obj.machineID); }, settings.LocalDataHoldTime);
		}
	}

	// Check the release
	checkDeleteMachine(machineID) {
		const m = this.machineArray.get(machineID);
		if (IsNullOrUndefined(m))
			return;

		// Destroy the Object
		if (!IsNullOrUndefined(m.au)) {
			if (m.au.empty()) {
				m.au.destroy();
				m.au = null;
			}
		}
		if (!IsNullOrUndefined(m.excludeOperationsAu)) {
			if (m.excludeOperationsAu.empty()) {
				m.excludeOperationsAu.destroy();
				m.excludeOperationsAu = null;
			}
		}
		if (!IsNullOrUndefined(m.auCount)) {
			if (m.auCount.empty()) {
				m.auCount.destroy();
				m.auCount = null;
			}
		}
		if (!IsNullOrUndefined(m.excludeOperationsAuCount)) {
			if (m.excludeOperationsAuCount.empty()) {
				m.excludeOperationsAuCount.destroy();
				m.excludeOperationsAuCount = null;
			}
		}
		if (!IsNullOrUndefined(m.area)) {
			if (m.area.empty()) {
				m.area.destroy();
				m.area = null;
			}
		}
		if (!IsNullOrUndefined(m.excludeOperationsArea)) {
			if (m.excludeOperationsArea.empty()) {
				m.excludeOperationsArea.destroy();
				m.excludeOperationsArea = null;
			}
		}
		if (!IsNullOrUndefined(m.data)) {
			if (m.data.empty()) {
				m.data.destroy();
				m.data = null;
			}
		}
		if (!IsNullOrUndefined(m.excludeOperationsData)) {
			if (m.excludeOperationsData.empty()) {
				m.excludeOperationsData.destroy();
				m.excludeOperationsData = null;
			}
		}
		if (!IsNullOrUndefined(m.count)) {
			if (m.count.empty()) {
				m.count.destroy();
				m.count = null;
			}
		}
		if (!IsNullOrUndefined(m.excludeOperationsCount)) {
			if (m.excludeOperationsCount.empty()) {
				m.excludeOperationsCount.destroy();
				m.excludeOperationsCount = null;
			}
		}

		if (IsNullOrUndefined(m.area) &&
			IsNullOrUndefined(m.excludeOperationsArea) &&
			IsNullOrUndefined(m.auCount) &&
			IsNullOrUndefined(m.excludeOperationsAuCount) &&
			IsNullOrUndefined(m.au) &&
			IsNullOrUndefined(m.excludeOperationsAu) &&
			IsNullOrUndefined(m.data) &&
			IsNullOrUndefined(m.excludeOperationsData) &&
			IsNullOrUndefined(m.count) &&
			IsNullOrUndefined(m.excludeOperationsCount)) {
			m.segmentData.destroy();
			m.excludeOperationsSegmentData.destroy();
			m.ageData.destroy();
			this.machineArray.delete(machineID);
		}
	}
}