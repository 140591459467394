/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert from the metric unit system to the user unit system
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import IsNullOrUndefined from '../Misc/Utility.js';
import configureMeasurements, { volumeFlowRate } from 'convert-units';

var instance;
export default class FlowRateUnitSystemConverter {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		// UnitSystem
		this.unitSystemDefinition = "l_min";

		this.convert = configureMeasurements({ volumeFlowRate });
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	setDefaultUnitSystem() {
		this.setUnitSystem("l_min");
	}

	// Set the UnitSystem to use. Possible values are "metric" and "imperial"
	setUnitSystem(_unitSystem) {
		const unitSystem = _unitSystem.toLowerCase();

		switch (unitSystem) {
			case 'm3_s':
				this.unitSystemDefinition = "m3_s";
				break;
			case 'mm3_s':
				this.unitSystemDefinition = "mm3_s";
				break;
			case 'l_s':
				this.unitSystemDefinition = "l_s";
				break;
			case 'l_min':
				this.unitSystemDefinition = "l_min";
				break;
			case 'l_h':
				this.unitSystemDefinition = "l_h";
				break;
			case 'in3_s':
				this.unitSystemDefinition = "in3_s";
				break;
			case 'floz_s':
				this.unitSystemDefinition = "floz_s";
				break;
			case 'floz_h':
				this.unitSystemDefinition = "floz_h";
				break;
			case 'gal_s':
				this.unitSystemDefinition = "gal_s";
				break;
			case 'gal_h':
				this.unitSystemDefinition = "gal_h";
				break;
			default:
				this.unitSystemDefinition = "l_min";
				if (process.env.NODE_ENV === 'development') {
					console.warn("UnitSystem '" + unitSystem + "' is invalid. Using default.");
				}
				break;
		}
	}
	
	/** Format the seconds to the selected unit system (Best choice)
	 * @param value The Value to use
	 * @param targetUnit The target unit system
	 * @param sourceUnit The source unit system. Defaults to "K"
	 * @return { value, unit }
	 */
	format(value, targetUnit, sourceUnit) {
		let source = "m3/s";
		if (!IsNullOrUndefined(sourceUnit))
			source = sourceUnit;

		let negativ = false;
		if (value < 0)
			negativ = true;

		if (!IsNullOrUndefined(targetUnit)) {
			const r = Convert(value).from(source).to(targetUnit);
			return { value: negativ ? r * -1.0 : r, unit: targetUnit };
		}

		switch (this.unitSystemDefinition) {
			case 'm3_s':
				{
					const r = this.convert(value).from(source).to("m3/s");
					return { value: negativ ? r * -1.0 : r, unit: "m3/s" };
				}
			case 'mm3_s':
				{
					const r = this.convert(value).from(source).to("mm3/h");
					return { value: negativ ? r * -1.0 : r, unit: "mm3/h" };
				}
			case 'l_s':
				{
					const r = this.convert(value).from(source).to("l/s");
					return { value: negativ ? r * -1.0 : r, unit: "l/s" };
				}
			case 'l_h':
				{
					const r = this.convert(value).from(source).to("l/h");
					return { value: negativ ? r * -1.0 : r, unit: "l/h" };
				}
			case 'in3_s':
				{
					const r = this.convert(value).from(source).to("in3/s");
					return { value: negativ ? r * -1.0 : r, unit: "in3/s" };
				}
			case 'floz_s':
				{
					const r = this.convert(value).from(source).to("fl-oz/s");
					return { value: negativ ? r * -1.0 : r, unit: "fl-oz/s" };
				}
			case 'floz_h':
				{
					const r = this.convert(value).from(source).to("fl-oz/h");
					return { value: negativ ? r * -1.0 : r, unit: "fl-oz/h" };
				}
			case 'gal_s':
				{
					const r = this.convert(value).from(source).to("gal/s");
					return { value: negativ ? r * -1.0 : r, unit: "gal/s" };
				}
			case 'gal_h':
				{
					const r = this.convert(value).from(source).to("gal/h");
					return { value: negativ ? r * -1.0 : r, unit: "gal/h" };
				}
			case 'l_min':
			default:
				{
					const r = this.convert(value).from(source).to("l/min");
					return { value: negativ ? r * -1.0 : r, unit: "l/min" };
				}
		}
	}

	/**
	 * Get the default unit
	 */
	getDefaultUnit() {
		switch (this.unitSystemDefinition) {
			case 'm3_s':
				return "m3/s";
			case 'mm3_s':
				return "mm3/s";
			case 'l_s':
				return "l/s";
			case 'l_h':
				return "l/h";
			case 'in3_s':
				return "in3/s";
			case 'floz_s':
				return "fl-oz/s";
			case 'floz_h':
				return "fl-oz/h";
			case 'gal_s':
				return "gal/s";
			case 'gal_h':
				return "gal/h";
			case 'l_min':
			default:
				return "l/min";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 */
	humanizeShortUnit(unit) {
		switch (unit) {
			case 'm3/s':
				return "m³/s";
			case 'mm3/s':
				return "mm³/s";
			case 'l/s':
				return "l/s";
			case 'l/min':
				return "l/min";
			case 'l/h':
				return "l/h";
			case 'in3/s':
				return "in³/s";
			case 'fl-oz/s':
				return "fl-oz/s";
			case 'fl-oz/h':
				return "fl-oz/h";
			case 'gal/s':
				return "gal/s";
			case 'gal/h':
				return "gal/h";

			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 * @param singular Get the singular unit
	 */
	humanizeUnit(unit, singular) {
		switch (unit) {
			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}
}