/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import Localizer from '../../../BusinessLogic/Misc/Localizer';
import DateFormat from "../../../BusinessLogic/Formater/DateFormat.js";
import MessageData from "../../../BusinessLogic/DataManagement/MessageData.js";
import IsNullOrUndefined from '../../../BusinessLogic/Misc/Utility';
import ExtensionManager from "../../../BusinessLogic/Extension/ExtensionManager.js";
import CurrentUser from "../../../BusinessLogic/DataManagement/CurrentUser.js"
import MachineData from "../../../BusinessLogic/DataManagement/MachineData.js"
import ErrorData from "../../../BusinessLogic/DataManagement/ErrorData.js"
import ToolData from "../../../BusinessLogic/DataManagement/ToolData.js";
import PieceData from "../../../BusinessLogic/DataManagement/PieceData.js";
import LengthUnitSystemConverter from "../../../BusinessLogic/Formater/LengthUnitSystemConverter.js";
import { View, Text } from "react-native";
import styles from "./CommonStyles"

export default class Content extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.updateData = this.updateData.bind(this);
		this.updateExtensionPack = this.updateExtensionPack.bind(this);

		this.key = null;
		this.userKey = null;
		this.extensionKey = null;
		this.machineKey = null;
		this.errorKey = null;
		this.toolKey = null;
		this.pieceKey = null;

		this.state = {
			loading: true,
			error: false,
			loadingEP: false,
			errorEP: false,
			loadingError: false,
			errorError: false,
			loadingMachine: false,
			errorMachine: false,
			loadingTool: false,
			errorTool: false,
			loadingPiece: false,
			errorPiece: false,
			loadingUser: true,

			data: {},
			predefinedData: {},
			extensionPack: null,
			username: "unknown",
			machineData: null,
			errorData: null,
			toolData: null,
			pieceData: null
		};
	}
	static defaultProps = {
		messageID: "",
		machineID: null,
		maxLines: null
	}

	updateExtensionPack(data, error) {
		this.setState({
			loadingEP: false,
			errorEP: error,
			extensionPack: data
		});
	}

	updateData(data, error) {
		if (!error) {
			if (this.extensionKey !== null) {
				(new ExtensionManager()).unregister(this.extensionKey);
				this.extensionKey = null;
			}
			if (this.machineKey !== null) {
				(new MachineData()).unregister(this.machineKey);
				this.machineKey = null;
			}
			if (this.errorKey !== null) {
				(new ErrorData()).unregister(this.errorKey);
				this.errorKey = null;
			}
			if (this.toolKey !== null) {
				(new ToolData()).unregister(this.toolKey);
				this.toolKey = null;
			}
			if (this.pieceKey !== null) {
				(new PieceData()).unregister(this.pieceKey);
				this.pieceKey = null;
			}

			if (!IsNullOrUndefined(data.message)) {
				if (!IsNullOrUndefined(data.message.predefinedMessageID)) {

					if (data.message.predefinedMessageID === "00000001-0000-0000-0000-000000000000") {
						const d = JSON.parse(data.message.predefinedMessageData);

						this.setState({
							loadingEP: true,
							errorEP: false,
							predefinedData: d
						});

						this.extensionKey = (new ExtensionManager()).register(d.extensionpack, this.updateExtensionPack);
					}
					else if (data.message.predefinedMessageID === "00000002-0000-0000-0000-000000000000") {
						const d = JSON.parse(data.message.predefinedMessageData);

						this.setState({
							loadingMachine: true,
							errorMachine: false,
							predefinedData: d
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
					}
					else if (data.message.predefinedMessageID === "00000003-0000-0000-0000-000000000000") {
						const d = JSON.parse(data.message.predefinedMessageData);

						this.setState({
							loadingMachine: true,
							errorMachine: false,
							loadingError: true,
							errorError: false,
							predefinedData: d
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
						this.errorKey = (new ErrorData()).register(this.props.machineID, d.errorID, (data, error) => {
							this.setState({
								loadingError: false,
								errorError: error,
								errorData: data
							});
						});
					}
					else if (data.message.predefinedMessageID === "00000004-0000-0000-0000-000000000000") {
						const d = JSON.parse(data.message.predefinedMessageData);

						this.setState({
							loadingMachine: true,
							errorMachine: false,
							loadingTool: true,
							errorTool: false,
							predefinedData: d
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
						this.toolKey = (new ToolData()).register(d.toolID, (data, error) => {
							this.setState({
								loadingTool: false,
								errorTool: error,
								toolData: data
							});
						});
					}
					else if (data.message.predefinedMessageID === "00000004-0000-0000-0000-000000000001") {
						const d = JSON.parse(data.message.predefinedMessageData);

						this.setState({
							loadingMachine: true,
							errorMachine: false,
							loadingTool: true,
							errorTool: false,
							predefinedData: d
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
						this.toolKey = (new ToolData()).register(d.toolID, (data, error) => {
							this.setState({
								loadingTool: false,
								errorTool: error,
								toolData: data
							});
						});
					}
					else if (data.message.predefinedMessageID === "00000005-0000-0000-0000-000000000000") {
						const d = JSON.parse(data.message.predefinedMessageData);

						this.setState({
							loadingMachine: true,
							errorMachine: false,
							loadingPiece: true,
							errorPiece: false,
							predefinedData: d
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
						this.pieceKey = (new PieceData()).register(d.pieceID, this.props.machineID, (data, error) => {
							this.setState({
								loadingPiece: false,
								errorPiece: error,
								pieceData: data
							});
						});
					}
					else if (data.message.predefinedMessageID === "00000006-0000-0000-0000-000000000000") {
						this.setState({
							loadingMachine: true,
							errorMachine: false
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
					}
					else if (data.message.predefinedMessageID === "00000007-0000-0000-0000-000000000000") {
						this.setState({
							loadingMachine: true,
							errorMachine: false
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
					}
					else if (data.message.predefinedMessageID === "00000008-0000-0000-0000-000000000000") {
						this.setState({
							loadingMachine: true,
							errorMachine: false
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
					}
					else if (data.message.predefinedMessageID === "00000009-0000-0000-0000-000000000000") {
						this.setState({
							loadingMachine: true,
							errorMachine: false
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
					}
					else if (data.message.predefinedMessageID === "00000009-0000-0000-0000-000000000001") {
						this.setState({
							loadingMachine: true,
							errorMachine: false
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
					}
					else if (data.message.predefinedMessageID === "00000009-0000-0000-0000-000000000002") {
						this.setState({
							loadingMachine: true,
							errorMachine: false
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
					}
					else if (data.message.predefinedMessageID === "00000009-0000-0000-0000-000000000003") {
						this.setState({
							loadingMachine: true,
							errorMachine: false
						});

						this.machineKey = (new MachineData()).register(this.props.machineID, (data, error) => {
							this.setState({
								loadingMachine: false,
								errorMachine: error,
								machineData: data
							});
						});
					}
				}
			}
		}

		this.setState({
			loading: false,
			error: error,
			data: data
		});
	}

	componentDidMount() {
		if (IsNullOrUndefined(this.props.machineID))
			this.key = (new MessageData()).register(this.props.messageID, this.updateData);
		else
			this.key = (new MessageData()).registerMachine(this.props.messageID, this.props.machineID, this.updateData);

		// Get the user data
		this.userKey = (new CurrentUser()).register((loaded, error) => {
			// Assume that the current User is available

			this.setState({
				loadingUser: false,
				username: (new CurrentUser()).getName()
			});
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.messageID !== this.props.messageID) {
			if (this.key !== null) {
				(new MessageData()).unregister(this.key);
				this.key = null;
			}
			if (this.extensionKey !== null) {
				(new ExtensionManager()).unregister(this.extensionKey);
				this.extensionKey = null;
			}
			if (this.machineKey !== null) {
				(new MachineData()).unregister(this.machineKey);
				this.machineKey = null;
			}
			if (this.errorKey !== null) {
				(new ErrorData()).unregister(this.errorKey);
				this.errorKey = null;
			}
			if (this.toolKey !== null) {
				(new ToolData()).unregister(this.toolKey);
				this.toolKey = null;
			}
			if (this.pieceKey !== null) {
				(new PieceData()).unregister(this.pieceKey);
				this.pieceKey = null;
			}

			this.setState({
				loading: true,
				error: false,
				loadingEP: false,
				errorEP: false,
				loadingMachine: false,
				errorMachine: false,
				loadingError: false,
				errorError: false,
				loadingTool: false,
				errorTool: false,
				loadingPiece: false,
				errorPiece: false
			});

			if (IsNullOrUndefined(this.props.machineID))
				this.key = (new MessageData()).register(this.props.messageID, this.updateData);
			else
				this.key = (new MessageData()).registerMachine(this.props.messageID, this.props.machineID, this.updateData);
		}
	}

	componentWillUnmount() {
		if (this.key !== null) {
			(new MessageData()).unregister(this.key);
			this.key = null;
		}
		if (this.extensionKey !== null) {
			(new ExtensionManager()).unregister(this.extensionKey);
			this.extensionKey = null;
		}
		if (this.userKey !== null) {
			(new CurrentUser()).unregister(this.userKey);
			this.userKey = null;
		}
		if (this.machineKey !== null) {
			(new MachineData()).unregister(this.machineKey);
			this.machineKey = null;
		}
		if (this.errorKey !== null) {
			(new ErrorData()).unregister(this.errorKey);
			this.errorKey = null;
		}
		if (this.toolKey !== null) {
			(new ToolData()).unregister(this.toolKey);
			this.toolKey = null;
		}
		if (this.pieceKey !== null) {
			(new PieceData()).unregister(this.pieceKey);
			this.pieceKey = null;
		}
	}

	render() {
		if (this.state.loading || this.state.loadingEP || this.state.loadingUser || this.state.loadingMachine || this.state.loadingError || this.state.loadingTool || this.state.loadingPiece) {
			return <View/>;
		} else if (this.state.error || this.state.errorEP || this.state.errorMachine || this.state.errorError || this.state.errorTool || this.state.errorPiece) {
			return <View />;
		} else {
			let text = "";
			if (!IsNullOrUndefined(this.state.data.message)) {
				if (!IsNullOrUndefined(this.state.data.message.predefinedMessageID)) {
					if (this.state.data.message.predefinedMessageID === "00000001-0000-0000-0000-000000000000") {
						let d = new Date(this.state.predefinedData?.date ?? 0);

						let str = (new Localizer()).get("Hello %%USER%%, \\nthe extension pack '%%EXTENSIONPACK%%' expires on %%DATE%%. You have to renew it if you want to continue to use the provided functionality.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%EXTENSIONPACK%%", (new Localizer()).get(this.state.extensionPack.title));
						str = str.replace("%%DATE%%", (new DateFormat()).formatDate(d));
						text = str;
					}
					else if (this.state.data.message.predefinedMessageID === "00000002-0000-0000-0000-000000000000") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let d = new Date(this.state.predefinedData?.date ?? 0);

						let str = (new Localizer()).get("Hello %%USER%%, \\nthe next inspecion of the machine %%MACHINE%% is sheduled on %%DATE%%. Please contact the support to plan the inspection.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%MACHINE%%", machine);
						str = str.replace("%%DATE%%", (new DateFormat()).formatDate(d));
						text = str;
					}
					else if (this.state.data.message.predefinedMessageID === "00000003-0000-0000-0000-000000000000") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let description = "-";
						if (!IsNullOrUndefined(this.state.errorData.description))
							description = this.state.errorData.description;

						let errorCode = this.state.errorData.code;
						if (!IsNullOrUndefined(this.state.errorData.codeMinor))
							errorCode += "." + this.state.errorData.codeMinor;
						if (!IsNullOrUndefined(this.state.errorData.codePatch))
							errorCode += "." + this.state.errorData.codePatch;
						if (!IsNullOrUndefined(this.state.errorData.codeIntern))
							errorCode += "." + this.state.errorData.codeIntern;

						if (this.state.errorData.status === "ALLSOLVED") {
							let str = (new Localizer()).get("Hello %%USER%%,\\nall errors on the machine %%MACHINE%% were solved.\\n\\nDate: %%DATE%%\\n\\nKind regards\\nCORE");
							str = str.replace("%%USER%%", this.state.username);
							str = str.replace("%%MACHINE%%", machine);
							str = str.replace("%%DATE%%", (new DateFormat()).formatDate(this.state.errorData.time));
							text = str;
						}
						else if (this.state.errorData.status === "SOLVED") {
							if (this.state.errorData.severity === "CRITICAL") {
								let str = (new Localizer()).get("Hello %%USER%%,\\nthe CRITICAL error on the machine %%MACHINE%% was solved.\\n\\nErrorcode: %%ERRORCODE%%\\nDescription: %%DESCRIPTION%%\\nDate: %%DATE%%\\n\\nKind regards\\nCORE");
								str = str.replace("%%USER%%", this.state.username);
								str = str.replace("%%MACHINE%%", machine);
								str = str.replace("%%DESCRIPTION%%", description);
								str = str.replace("%%ERRORCODE%%", errorCode);
								str = str.replace("%%DATE%%", (new DateFormat()).formatDate(this.state.errorData.time));
								text = str;
							}
							else if (this.state.errorData.severity === "ERROR") {
								let str = (new Localizer()).get("Hello %%USER%%,\\nthe error on the machine %%MACHINE%% was solved.\\n\\nErrorcode: %%ERRORCODE%%\\nDescription: %%DESCRIPTION%%\\nDate: %%DATE%%\\n\\nKind regards\\nCORE");
								str = str.replace("%%USER%%", this.state.username);
								str = str.replace("%%MACHINE%%", machine);
								str = str.replace("%%DESCRIPTION%%", description);
								str = str.replace("%%ERRORCODE%%", errorCode);
								str = str.replace("%%DATE%%", (new DateFormat()).formatDate(this.state.errorData.time));
								text = str;
							}
							else if (this.state.errorData.severity === "WARNING") {
								let str = (new Localizer()).get("Hello %%USER%%,\\nthe warning on the machine %%MACHINE%% was solved.\\n\\nErrorcode: %%ERRORCODE%%\\nDescription: %%DESCRIPTION%%\\nDate: %%DATE%%\\n\\nKind regards\\nCORE");
								str = str.replace("%%USER%%", this.state.username);
								str = str.replace("%%MACHINE%%", machine);
								str = str.replace("%%DESCRIPTION%%", description);
								str = str.replace("%%ERRORCODE%%", errorCode);
								str = str.replace("%%DATE%%", (new DateFormat()).formatDate(this.state.errorData.time));
								text = str;
							}
							else if (this.state.errorData.severity === "INFO") {
								let str = (new Localizer()).get("Hello %%USER%%,\\nthe info message on the machine %%MACHINE%% was solved.\\n\\nErrorcode: %%ERRORCODE%%\\nDescription: %%DESCRIPTION%%\\nDate: %%DATE%%\\n\\nKind regards\\nCORE");
								str = str.replace("%%USER%%", this.state.username);
								str = str.replace("%%MACHINE%%", machine);
								str = str.replace("%%DESCRIPTION%%", description);
								str = str.replace("%%ERRORCODE%%", errorCode);
								str = str.replace("%%DATE%%", (new DateFormat()).formatDate(this.state.errorData.time));
								text = str;
							}
							else if (this.state.errorData.severity === "DEBUG") {
								let str = (new Localizer()).get("Hello %%USER%%,\\nthe debug message on the machine %%MACHINE%% was solved.\\n\\nErrorcode: %%ERRORCODE%%\\nDescription: %%DESCRIPTION%%\\nDate: %%DATE%%\\n\\nKind regards\\nCORE");
								str = str.replace("%%USER%%", this.state.username);
								str = str.replace("%%MACHINE%%", machine);
								str = str.replace("%%DESCRIPTION%%", description);
								str = str.replace("%%ERRORCODE%%", errorCode);
								str = str.replace("%%DATE%%", (new DateFormat()).formatDate(this.state.errorData.time));
								text = str;
							}
						}
						else {
							if (this.state.errorData.severity === "CRITICAL") {
								let str = (new Localizer()).get("Hello %%USER%%,\\nthere was a CRITICAL error on the machine %%MACHINE%%.\\n\\nErrorcode: %%ERRORCODE%%\\nDescription: %%DESCRIPTION%%\\nDate: %%DATE%%\\n\\nKind regards\\nCORE");
								str = str.replace("%%USER%%", this.state.username);
								str = str.replace("%%MACHINE%%", machine);
								str = str.replace("%%DESCRIPTION%%", description);
								str = str.replace("%%ERRORCODE%%", errorCode);
								str = str.replace("%%DATE%%", (new DateFormat()).formatDate(this.state.errorData.time));
								text = str;
							}
							else if (this.state.errorData.severity === "ERROR") {
								let str = (new Localizer()).get("Hello %%USER%%,\\nthere was an error on the machine %%MACHINE%%.\\n\\nErrorcode: %%ERRORCODE%%\\nDescription: %%DESCRIPTION%%\\nDate: %%DATE%%\\n\\nKind regards\\nCORE");
								str = str.replace("%%USER%%", this.state.username);
								str = str.replace("%%MACHINE%%", machine);
								str = str.replace("%%DESCRIPTION%%", description);
								str = str.replace("%%ERRORCODE%%", errorCode);
								str = str.replace("%%DATE%%", (new DateFormat()).formatDate(this.state.errorData.time));
								text = str;
							}
							else if (this.state.errorData.severity === "WARNING") {
								let str = (new Localizer()).get("Hello %%USER%%,\\nthere was a warning on the machine %%MACHINE%%.\\n\\nErrorcode: %%ERRORCODE%%\\nDescription: %%DESCRIPTION%%\\nDate: %%DATE%%\\n\\nKind regards\\nCORE");
								str = str.replace("%%USER%%", this.state.username);
								str = str.replace("%%MACHINE%%", machine);
								str = str.replace("%%DESCRIPTION%%", description);
								str = str.replace("%%ERRORCODE%%", errorCode);
								str = str.replace("%%DATE%%", (new DateFormat()).formatDate(this.state.errorData.time));
								text = str;
							}
							else if (this.state.errorData.severity === "INFO") {
								let str = (new Localizer()).get("Hello %%USER%%,\\nthere was an info message on the machine %%MACHINE%%.\\n\\nErrorcode: %%ERRORCODE%%\\nDescription: %%DESCRIPTION%%\\nDate: %%DATE%%\\n\\nKind regards\\nCORE");
								str = str.replace("%%USER%%", this.state.username);
								str = str.replace("%%MACHINE%%", machine);
								str = str.replace("%%DESCRIPTION%%", description);
								str = str.replace("%%ERRORCODE%%", errorCode);
								str = str.replace("%%DATE%%", (new DateFormat()).formatDate(this.state.errorData.time));
								text = str;
							}
							else if (this.state.errorData.severity === "DEBUG") {
								let str = (new Localizer()).get("Hello %%USER%%,\\nthere was a debug message on the machine %%MACHINE%%.\\n\\nErrorcode: %%ERRORCODE%%\\nDescription: %%DESCRIPTION%%\\nDate: %%DATE%%\\n\\nKind regards\\nCORE");
								str = str.replace("%%USER%%", this.state.username);
								str = str.replace("%%MACHINE%%", machine);
								str = str.replace("%%DESCRIPTION%%", description);
								str = str.replace("%%ERRORCODE%%", errorCode);
								str = str.replace("%%DATE%%", (new DateFormat()).formatDate(this.state.errorData.time));
								text = str;
							}
						}
					}
					else if (this.state.data.message.predefinedMessageID === "00000004-0000-0000-0000-000000000000") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let tool = this.state.toolData?.data?.serialNumber ?? (this.state.predefinedData?.toolID ?? "");
						const pdLayer = this.state.predefinedData?.layer ?? 0;

						let warningRL = 0;
						if (!IsNullOrUndefined(this.state.toolData)) {
							if (!IsNullOrUndefined(this.state.toolData.data)) {
								if (!IsNullOrUndefined(this.state.toolData.data.pt)) {
									if (!IsNullOrUndefined(this.state.toolData.data.pt.layers)) {
										if (this.state.toolData.data.pt.layers.length > pdLayer) {
											if (!IsNullOrUndefined(this.state.toolData.data.pt.layers[pdLayer])) {
												if (!IsNullOrUndefined(this.state.toolData.data.pt.layers[pdLayer].warningResidualLayer)) {
													warningRL = this.state.toolData.data.pt.layers[pdLayer].warningResidualLayer;
												}
											}
										}
									}
								}
							}
						}
						const u = (new LengthUnitSystemConverter()).format(warningRL, undefined, "μm");
						warningRL = u.value + " " + (new LengthUnitSystemConverter()).humanizeShortUnit(u.unit);

						let str = (new Localizer()).get("Hello %%USER%%,\\nthe abrasive wear on layer %%LAYER%% of tool '%%TOOL%%' in machine '%%MACHINE%%' is below %%WARNINGRL%%. An exchange of the abrasives is required in the near future.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%LAYER%%", pdLayer);
						str = str.replace("%%TOOL%%", tool);
						str = str.replace("%%MACHINE%%", machine);
						str = str.replace("%%WARNINGRL%%", warningRL);
						text = str;
					}
					else if (this.state.data.message.predefinedMessageID === "00000004-0000-0000-0000-000000000001") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let tool = this.state.toolData?.data?.serialNumber ?? (this.state.predefinedData?.toolID ?? "");

						let str = (new Localizer()).get("Hello %%USER%%,\\nthe abrasive wear on layer %%LAYER%% of tool '%%TOOL%%' in machine '%%MACHINE%%' is below the critical level.\\n\\nPlease consider to change the abrasives now.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%LAYER%%", this.state.predefinedData?.layer ?? 0);
						str = str.replace("%%TOOL%%", tool);
						str = str.replace("%%MACHINE%%", machine);
						text = str;
					}
					else if (this.state.data.message.predefinedMessageID === "00000005-0000-0000-0000-000000000000") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let piece = this.state.pieceData.visibleID;

						let str = (new Localizer()).get("Hello %%USER%%,\\nthe piece '%%PIECE%%' on machine '%%MACHINE%%' was NIO.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%PIECE%%", piece);
						str = str.replace("%%MACHINE%%", machine);
						text = str;
					}
					else if (this.state.data.message.predefinedMessageID === "00000006-0000-0000-0000-000000000000") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let str = (new Localizer()).get("Hello %%USER%%,\\nthe power supply voltage on machine '%%MACHINE%%' is out of range.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%MACHINE%%", machine);
						text = str;
					}
					else if (this.state.data.message.predefinedMessageID === "00000007-0000-0000-0000-000000000000") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let str = (new Localizer()).get("Hello %%USER%%,\\nthe power supply current on machine '%%MACHINE%%' is out of range.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%MACHINE%%", machine);
						text = str;
					}
					else if (this.state.data.message.predefinedMessageID === "00000008-0000-0000-0000-000000000000") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let str = (new Localizer()).get("Hello %%USER%%,\\nthe power supply frequency on machine '%%MACHINE%%' is out of range.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%MACHINE%%", machine);
						text = str;
					}
					else if (this.state.data.message.predefinedMessageID === "00000009-0000-0000-0000-000000000000") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let str = (new Localizer()).get("Hello %%USER%%,\\nthe power consumption on machine '%%MACHINE%%' is out of range.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%MACHINE%%", machine);
						text = str;
					}
					else if (this.state.data.message.predefinedMessageID === "00000009-0000-0000-0000-000000000001") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let str = (new Localizer()).get("Hello %%USER%%,\\nthe apparent power consumption on machine '%%MACHINE%%' is out of range.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%MACHINE%%", machine);
						text = str;
					}
					else if (this.state.data.message.predefinedMessageID === "00000009-0000-0000-0000-000000000002") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let str = (new Localizer()).get("Hello %%USER%%,\\nthe reactive power consumption on machine '%%MACHINE%%' is out of range.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%MACHINE%%", machine);
						text = str;
					}
					else if (this.state.data.message.predefinedMessageID === "00000009-0000-0000-0000-000000000003") {
						let machine = this.state.machineData.serialNumber;
						if (IsNullOrUndefined(machine))
							machine = this.props.machineID;

						let str = (new Localizer()).get("Hello %%USER%%,\\nthe power factor on machine '%%MACHINE%%' is out of range.\\n\\nKind regards\\nCORE");
						str = str.replace("%%USER%%", this.state.username);
						str = str.replace("%%MACHINE%%", machine);
						text = str;
					}
					else {
						text = (new Localizer()).get("Unknown message");
					}
				}
				else {
					text = this.state.data.message.content;
					if (this.state.data.message.translatable) {
						text = (new Localizer()).get(text);
					}
				}
			}

			if (IsNullOrUndefined(this.props.maxLines)) {
				return (
					<Text style={ styles.messageContent }>
						{text.replace(/\\n/g, '\n')}
					</Text>
				);
			}
			else {
				return (
					<View>
						<Text numberOfLines={this.props.maxLines} style={styles.messageContentPreview}>
							{text.replace(/\\n/g, '\n')}
						</Text>
					</View>
				);
			}
		}
	}
}