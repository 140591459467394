/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

export default class UniqueIDGenerator {
	constructor() {
		// Variables
		this.keys = new Set();
		this.nextKey = 0;

		this.generate = this.generate.bind(this);
		this.release = this.release.bind(this);
	}

	generate() {
		let key = 0;
		if (this.nextKey === Number.MAX_SAFE_INTEGER) {
			key = this.nextKey;
			this.nextKey = 0;
		}
		else
			key = this.nextKey++;

		if (this.keys.has(key))
			return this.generate();

		this.keys.add(key);
		return key;
	}

	release(key) {
		this.keys.delete(key);
	}
}
