import React from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames } from './ApiAuthorizationConstants';
import BasicMessageTemplate from '../Public/Components/BasicMessageTemplate';
import Localizer from '../BusinessLogic/Misc/Localizer';
import Navigator from "../BusinessLogic/Misc/Navigator.js";

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            failed: false
        };
    }

    componentDidMount() {
        const action = this.props.action;
        switch (action) {
            case LoginActions.Login:
                this.login(this.getReturnUrl());
                break;
            case LoginActions.LoginCallback:
                this.processLoginCallback();
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    render() {
        if (this.state.failed) {
            return (
                <BasicMessageTemplate title={(new Localizer()).get("Login")}
                    disabled={false}>
                    <div className="lead text-center">
                        {(new Localizer()).get("Failed to validate the account. Please try again.")}
                    </div>
                </BasicMessageTemplate>
            );
        }
        else {
            return (
                <BasicMessageTemplate title={(new Localizer()).get("Login")}
                    disabled={true}>
                    <div className="lead text-center">
                        {(new Localizer()).get("Processing login")}
                    </div>
                </BasicMessageTemplate>
            );
        }
    }

    async login(returnUrl) {
        const state = { returnUrl };
        const result = await authService.signIn(state);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                break;
            case AuthenticationResultStatus.Success:
                await this.navigateTo(returnUrl);
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ failed: true });
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    }

    async processLoginCallback() {
        const url = window.location.href;
        const result = await authService.completeSignIn(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeSignIn finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateTo(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ failed: true });
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        let fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery) {
            fromQuery = decodeURIComponent(fromQuery);
            if (!fromQuery.startsWith(`${window.location.origin}/`)) {
                // This is an extra check to prevent open redirects.
                throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
            }
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    navigateTo(returnUrl) {
        const _returnUrl = returnUrl.substring(window.location.origin.length);
        (new Navigator()).redirect(_returnUrl);
    }
}
