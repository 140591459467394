/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple component for Error-Messages
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

class Error extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
	static defaultProps = {
		text: "",
		warning: false
	}

	render() {
		if (this.props.warning) {
			return (
				<>
					<div className="d-flex h-100 align-items-center p-3">
						<div className="loader-w--error-text warning m-auto">{this.props.text}</div>
					</div>
				</>);
		}
		else {
			return (
				<>
					<div className="d-flex h-100 align-items-center p-3">
						<div className="loader-w--error-text m-auto">{this.props.text}</div>
					</div>
				</>);
		}
    }
}

export default Error;