/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import settings from 'Settings';
import UniqueIDGenerator from '../../Misc/UniqueIDGenerator.js';
import RequestID from '../../Misc/RequestID';
import IsNullOrUndefined from "../../Misc/Utility.js";
import { AsyncExecution } from "../../Misc/Utility.js";
import Version from "../../Misc/Version.js";
import { TimeoutPromise, GetRequiredAPIVersion } from "../../Misc/Utility.js";
import DatabaseManager from "../../Misc/DatabaseManager.js";
import ConnectionStatusManager from '../../Misc/ConnectionStatusManager.js';
import GroupedFetch from './GroupedFetch.js';

export default class BaseData {
	constructor(ID, fetch: GroupedFetch, databaseID: string) {
		this.ID = ID;
		this.databaseID = databaseID;
		this.keyGen = new UniqueIDGenerator();
		this.requestID = new RequestID();
		this.destroyed = false;
		this.fetch = fetch;

		this.dataInformable = false;
		this.dataError = false;
		this.data = {};
		this.lastModification = null;
		this.cacheID = null;
		this.apiVersion = null;

		this.functions = new Map();
		this.database = null;

		this.connectionStatus = ConnectionStatusManager.STATUS.OFFLINE;
		this.connectionStatusKey = null;

		this.updateTimeout = null;
		this.updateInProcess = false;
		this.updateKey = null;
		this.updatePending = false;
		this.reloadInProcess = false;

		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.inform = this.inform.bind(this);
		this.empty = this.empty.bind(this);
		this.destroy = this.destroy.bind(this);
		this.updateData = this.updateData.bind(this);
		this.reload = this.reload.bind(this);
		this.setData = this.setData.bind(this);
		this.init = this.init.bind(this);
		this.release = this.release.bind(this);
		this.initUpdateListener = this.initUpdateListener.bind(this);
		this.releaseUpdateListener = this.releaseUpdateListener.bind(this);
		this.triggerUpdateData = this.triggerUpdateData.bind(this);
		this.triggerReloadData = this.triggerReloadData.bind(this);
		this.dataChanged = this.dataChanged.bind(this);
	}

	destroy() {
		this.release();

		this.destroyed = true;
	}

	init(databaseName: string) {
		this.databaseName = databaseName;

		this.database = (new DatabaseManager()).getUserDatabase(databaseName);

		// ConnectionStatusManager
		this.connectionStatus = (new ConnectionStatusManager()).getStatus();

		// Update the Data
		(async () => {
			if (this.destroyed)
				return;

			let db = null;
			try {
				db = await TimeoutPromise(this.database.get(this.databaseID), settings.IndexDBTimeout);
			} catch (e) {
				// Error in DB-Operation. We have to call the server
				db = null;
			}

			if (this.destroyed)
				return;

			// Now we may have an db-object. 

			// Inform the user about the current available data
			if (!IsNullOrUndefined(db)) {
				// Set the data
				if (this.setData(db)) {
					// Inform the functions
					this.inform();
				}
			}

			// Init the update functionality
			this.initUpdateListener();

			// Update the data
			this.reload();

			// Register the connection manager
			this.connectionStatusKey = (new ConnectionStatusManager()).register((status) => {
				this.connectionStatus = status;

				if (this.connectionStatus === ConnectionStatusManager.STATUS.OFFLINE) {
					// Cancel all pending requests
					if (!IsNullOrUndefined(this.updateKey))
						this.fetch.abort(this.updateKey);
					this.updateKey = null;
				}
				else {
					// We are back online. Reload the complete data.
					this.reload();
				}
			});
		})();
	}

	release() {
		this.releaseUpdateListener();

		if (!IsNullOrUndefined(this.connectionStatusKey)) {
			(new ConnectionStatusManager()).unregister(this.connectionStatusKey);
			this.connectionStatusKey = null;
		}

		if (!IsNullOrUndefined(this.updateTimeout)) {
			clearTimeout(this.updateTimeout);
			this.updateTimeout = null;
		}

		// Cancel all pending requests
		if (!IsNullOrUndefined(this.updateKey))
			this.fetch.abort(this.updateKey);

		// Clear all remaining functions
		this.functions.forEach((v, k) => {
			this.keyGen.release(k);
		});
		this.functions.clear();
	}

	initUpdateListener() { }
	releaseUpdateListener() { }
	dataChanged() { }

	setData(data) {
		if (IsNullOrUndefined(data.data))
			return false;
		if (IsNullOrUndefined(data.apiVersion))
			return false;
		if (IsNullOrUndefined(data.lastModification))
			return false;
		if (IsNullOrUndefined(data.cacheID))
			return false;
		if (!IsNullOrUndefined(this.lastModification)) {
			if (this.lastModification > data.lastModification)
				return false;
		}
		const requiredAPIVersion = GetRequiredAPIVersion();
		const availableVersion = Version.fromObject(data.apiVersion);
		if (!availableVersion.isCompatible(requiredAPIVersion))
			return false;

		this.data = data.data;

		this.dataInformable = true;
		this.dataError = false;

		this.lastModification = data.lastModification;
		this.apiVersion = availableVersion;
		this.cacheID = data.cacheID;

		this.dataChanged();

		return true;
	}

	reload() {
		if (this.reloadInProcess)
			return;

		if (!IsNullOrUndefined(this.updateTimeout)) {
			clearTimeout(this.updateTimeout);
			this.updateTimeout = null;
		}

		// Cancel all pending requests
		if (!IsNullOrUndefined(this.updateKey))
			this.fetch.abort(this.updateKey);
		this.updateKey = null;
		this.updatePending = false;
		this.updateInProcess = false;

		// Reload the data
		this.reloadInProcess = true;

		this.triggerReloadData();
	}

	triggerUpdateData() {
		if (IsNullOrUndefined(this.updateTimeout)) {
			this.updateTimeout = setTimeout(() => {
				this.updateTimeout = null;
				this.updateData();
			}, settings.DataUpdateDelay);
		}
	}

	triggerReloadData() {
		if (IsNullOrUndefined(this.updateTimeout)) {
			this.updateTimeout = setTimeout(() => {
				this.updateTimeout = null;
				this.updateData();
			}, settings.DataReloadDelay);
		}
	}

	updateData() {
		this.reloadInProcess = false;

		if (this.connectionStatus === ConnectionStatusManager.STATUS.OFFLINE)
			return;

		if (this.updateInProcess) {
			this.updatePending = true;
			return;
		}
		this.updateInProcess = true;

		let api = null;
		if (!IsNullOrUndefined(this.apiVersion))
			api = this.apiVersion.toObject();

		const requestID = this.requestID.newRequest();
		this.updateKey = this.fetch.fetch(this.ID, this.lastModification, this.cacheID, api,
			(data) => {
				this.updateKey = null;

				if (!this.requestID.validateRequest(requestID))
					return;

				// Set the data
				if (this.setData(data)) {
					// Set the Database
					try {
						(async () => {
							await this.database.set(this.databaseID, { apiVersion: this.apiVersion.toObject(), lastModification: this.lastModification, data: this.data, cacheID: this.cacheID }, null, this.apiVersion.toObject(), this.lastModification);
						})();
					} catch (e) {
						console.error("Error during update of cached object in database");
					}

					// Inform the functions
					this.inform();
				}

				this.updateInProcess = false;
				// Reshedule the update if requested
				if (this.updatePending) {
					this.updatePending = false;
					this.updateData();
				}
			},
			(error) => {
				this.updateKey = null;

				switch (error) {
					case GroupedFetch.ERROR.access:
						{
							// We have a valid request but no access to the data. This means that the data on the client should be deleted and that the application should be informed that no access is possible.

							this.data = {};
							this.dataInformable = true;
							this.dataError = true;
							this.lastModification = null;
							this.apiVersion = null;
							this.cacheID = null;

							// Update the Database
							try {
								(async () => {
									await this.database.remove(this.databaseID);
								})();
							} catch (e) {
								console.error("Error during remove of cached object from database");
							}

							this.dataChanged();

							// Inform the functions
							this.inform();
						}
						break;
					case GroupedFetch.ERROR.general:
					default:
						{
							// We have a connection error. This means that we have to use the old state of the data. The application should not be able to decide if this is old or current data.
						}
						break;
				}

				this.updateInProcess = false;
				// Reshedule the update if requested
				if (this.updatePending) {
					this.updatePending = false;
					this.updateData();
				}
			}
		);
	}

	// Register a function for a specific range which is automatically updated from the beginning on
	// @param timeRange The Range of the time from now which should be received. [ms]
	// @param func The function to be informed if data is available
	// @return Key for this Function
	register(func) {
		const key = this.keyGen.generate();

		// Add the Funciton to the registered functions
		this.functions.set(key, func);

		// Inform the Function
		if (this.dataInformable) {
			AsyncExecution(() => { this.informKey(key); });
		}

		return key;
	}

	unregister(key) {
		if (this.functions.delete(key))
			this.keyGen.release(key);
	}

	empty() {
		return this.functions.size === 0;
	}

	inform() {
		if (!this.dataInformable)
			return;

		this.functions.forEach((v, k) => {
			AsyncExecution(() => { this.informKey(k); });
		});
	}

	informKey(key) {
		if (!this.dataInformable)
			return;

		const f = this.functions.get(key);
		if (IsNullOrUndefined(f))
			return;

		try {
			f(this.data, this.dataError);
		} catch (err) {
			// Nothing to do. Errorhandling has to be done in component
			console.error("Error during processing of registered function. Error: " + err);
		}
	}
}