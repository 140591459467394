/********************************************************************************
*
* (c) 2021 - Gehring Technologies GmbH
*
* This is a class to fetch data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

export default class OnlineChecker {
	static checkConnection(onUp, onDown) {
		fetch('/favicon.ico?_=' + Date.now())
			.then(response => {
				// At this point we can safely assume the user has connection to the internet
				//console.log("Internet connection available");
				onUp();
			})
			.catch(error => {
				// The resource could not be reached
				//console.log("No Internet connection", error);
				onDown();
			});
	}
}