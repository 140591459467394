/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 270.93333 270.93333"
            className="svgIcon"
        >
            <path
                d="m 22.59669,0 h 225.75717 c 12.47508,0 22.57977,10.104689 22.57977,22.579781 0,238.205169 -2.81739,249.217389 0,248.309859 H 22.59669 C 15.810562,262.70836 8.1981872,255.10953 0.03045118,248.30986 V 22.579781 C 0.03045118,10.118234 10.135142,0.01354552 22.59669,0 Z"
                fill="none" />
            <path
                d="M 27.120773,27.090319 V 235.98376 c 1.246154,1.31388 2.532945,2.54649 3.86037,3.94164 1.381607,1.4087 2.54649,2.69549 3.670738,3.87392 H 242.69198 c 0.0271,-2.88512 0.0677,-6.09532 0.1219,-9.6577 0.33864,-32.76574 1.00234,-93.40742 1.01589,-207.051301 H 27.120773 M 22.610235,0 H 248.35386 c 12.47508,0 22.5799,10.104689 22.5799,22.579781 0,224.348469 2.3e-4,245.775219 0,248.309779 H 22.610235 C 21.729799,269.11514 1.5610604,248.06605 0.08463327,248.06605 c -0.02710104,0 -0.04060157,0 -0.05420209,0.0135 V 22.579781 C 0.03043118,10.104689 10.135122,0 22.610215,0 Z M 0.03045118,248.09314 v 0.23027 c -0.04060157,-0.12192 -0.04060157,-0.20318 0,-0.23027 z" />
            <rect
                x="109.17734"
                y="2.4303188e-06"
                width="97.890862"
                height="139.1765"
                fill="none" />
            <rect
                x="122.72249"
                y="13.545158"
                width="70.800552"
                height="112.08618"
                fill="none"
                strokeWidth="27.0903" />
            <path
                d="M 120.46046,75.311084 H 15.133306"
                strokeWidth="27.0903" />
            <line
                x1="255.91211"
                x2="195.70378"
                y1="75.175674"
                y2="75.175674"
                fill="none"
                strokeWidth="27.0903" />
            <line
                x1="255.91211"
                x2="15.079142"
                y1="195.72751"
                y2="195.72751"
                fill="none"
                strokeWidth="27.0903" />
        </svg>
    );
}