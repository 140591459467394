/********************************************************************************
*
* (c) 2021 - Gehring Technologies GmbH
*
* This is a class to fetch data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/
import GlobalAppData from 'GlobalAppData';
import IsNullOrUndefined, { IsNullOrUndefinedOrWhitespace } from './Utility.js';
import settings from 'Settings';
import TokenManager from 'TokenManager';
import APIIncompatibleException from "../Exceptions/APIIncompatibleException.js";
import ServerResponseException from "../Exceptions/ServerResponseException.js";
import NetworkException from "../Exceptions/NetworkException.js";
import AbortException from "../Exceptions/AbortException.js";
import { GetRequiredAPIVersion } from "../Misc/Utility";
import Version from "../Misc/Version.js";
import ConnectionStatusManager from "../Misc/ConnectionStatusManager";
import FetchCache from "./FetchCache.js";

export default class FetchHelper {
	static fetchServerWithValidToken(url, options) {
		return new Promise((resolve, reject) => {
			(new TokenManager()).request((token) => {
				// Append token to header
				let myHeader;
				if (!IsNullOrUndefined(options)) {
					myHeader = new Headers(options.headers);
				}
				else {
					myHeader = new Headers();
					options = {};
				}
				myHeader.append("Authorization", "Bearer " + token);
				options.headers = myHeader;

				try {
					fetch(url, options)
						.then((response) => {
							if (response.status === 401) {
								// Request a new token
								(new TokenManager()).renewToken();
								// Get the token
								(new TokenManager()).request((token) => {
									// Redo request
									const newHeader = options.headers;
									newHeader.set("Authorization", "Bearer " + token);
									options.headers = newHeader;

									try {
										return fetch(url, options)
											.then((res) => {
												resolve(res);
											})
											.catch((err) => {
												if (err.name === "AbortError") {
													// Call was aborted -> simply reject the call
													reject(new AbortException());
												} else {
													reject(new NetworkException());
												}
											});
									}
									catch (err) {
										if (err.name === "AbortError") {
											// Call was aborted -> simply reject the call
											reject(new AbortException());
										} else {
											reject(new NetworkException());
										}
									}
								});
							}
							else
								resolve(response);
						})
						.catch((err) => {
							if (err.name === "AbortError") {
								// Call was aborted -> simply reject the call
								reject(new AbortException());
							} else {
								reject(new NetworkException());
							}
						});
				}
				catch (err) {
					if (err.name === "AbortError") {
						// Call was aborted -> simply reject the call
						reject(new AbortException());
					} else {
						reject(new NetworkException());
					}
				}
			});
		});
	}

	static fetchServer(url, options) {
		return new Promise((resolve, reject) => {
			try {
				fetch(url, options)
					.then((response) => {
						resolve(response);
					})
					.catch((err) => {
						if (err.name === "AbortError") {
							// Call was aborted -> simply reject the call
							reject(new AbortException());
						} else {
							reject(new NetworkException());
						}
					});
			}
			catch (e) {
				reject(new NetworkException());
			}
		});
	}

	/** A simple fetch call
	 * @param url The URL to call
	 * @param options Options for the fetch
	 * @param allowAnonymous Anonymous call to the server. This is only relevant for the App-Calls
	 * @return Promise with the result of the fetch. It is possible that this contains also an error which comes from the server
	 */
	static fetch(url, options, allowAnonymous = false) {
		// extend url if it is the app
		if ((new GlobalAppData()).isNativeApp())
			url = settings.FetchUrlExtension + url;

		if (((new GlobalAppData()).isNativeApp() || (new GlobalAppData()).isWebView()) && !allowAnonymous) {
			return this.fetchServerWithValidToken(url, options);
		}
		else {
			return this.fetchServer(url, options);
		}
	}

	static resolvableSecuredFetch(resolve, reject, url, data, abortController, allowAnonymous, retry = true) {
		if ((new ConnectionStatusManager()).getConnectionStatus() === ConnectionStatusManager.STATUS.OFFLINE) {
			if (retry) {
				(new FetchCache()).addPendingRequest(url, data, abortController, allowAnonymous, resolve, reject);
			}
			else {
				reject(new NetworkException());
			}
		}
		else {
			this.fetch(url, {
				...data,
				signal: abortController.signal
			}, allowAnonymous)
				.then((response) => {
					if (!response.ok) {
						if (response.status === 426)
							reject(new APIIncompatibleException());
						else
							reject(new ServerResponseException(response));
					}

					resolve(response);
				})
				.catch((err) => {
					if (err.name === "AbortError" || err.name === "Abort") {
						// Call was aborted -> simply reject the call
						reject(new AbortException());
					} else {
						// Network error -> retry if connection is reestablished

						if (retry) {
							// Add this to the pending requests
							(new FetchCache()).addPendingRequest(url, data, abortController, allowAnonymous, resolve, reject);
						}

						// Inform the connection status manager
						(new ConnectionStatusManager()).setConnectionStatus(ConnectionStatusManager.STATUS.OFFLINE);

						if (!retry) {
							reject(new NetworkException());
						}
					}
				});
		}
	}

	static securedFetch(url, data, abortController, allowAnonymous, retry) {
		return new Promise((resolve, reject) => {
			this.resolvableSecuredFetch(resolve, reject, url, data, abortController, allowAnonymous, retry);
		});
	}

	static async fetchData(url, data, abortController, allowAnonymous, retry) {
		const response = await this.sendData(url, data, abortController, allowAnonymous, null, retry);

		try {
			const json = await response.json();

			// Is this compatible?
			if (!IsNullOrUndefined(json.apiVersion)) {
				const requiredAPIVersion = GetRequiredAPIVersion();
				if (!Version.fromObject(json.apiVersion).isCompatible(requiredAPIVersion))
					throw new APIIncompatibleException();
			}

			return json;
		}
		catch (e) {
			if (e.name === "AbortError")
				throw new AbortException();
			else
				throw e;
		}
	}

	static async fetchFormData(url, data, abortController, allowAnonymous, retry) {
		const response = await this.sendFormData(url, data, abortController, allowAnonymous, null, retry);

		try {
			const json = await response.json();

			// is this compatible?
			if (!IsNullOrUndefined(json.apiVersion)) {
				const requiredAPIVersion = GetRequiredAPIVersion();
				if (!Version.fromObject(json.apiVersion).isCompatible(requiredAPIVersion))
					throw new APIIncompatibleException();
			}

			return json;
		}
		catch (e) {
			if (e.name === "AbortError")
				throw new AbortException();
			else
				throw e;
		}
	}

	/**
	 * Send Data to the server and returns the result
	 * @throws Throws an exception if the data can't be received from the server
	 * @param {any} url
	 * @param {any} data
	 * @param {any} abortController
	 * @param {any} allowAnonymous
	 * @param {any} contentType
	 * @return The received data from the server
	 */
	static async sendData(url, data, abortController, allowAnonymous, contentType, retry) {
		if (IsNullOrUndefinedOrWhitespace(contentType))
			contentType = "application/json";

		// Is this compatible?
		const requiredAPIVersion = GetRequiredAPIVersion();
		data.apiVersion = requiredAPIVersion.toObject();

		const response = await this.securedFetch(url, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
				'Content-Type': contentType
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			redirect: 'follow', // manual, *follow, error
			body: JSON.stringify(data)
		}, abortController, allowAnonymous, retry);

		if (!response.ok) {
			if (response.status === 426)
				throw new APIIncompatibleException();
			else
				throw new ServerResponseException(response);
		}

		return response;
	}

	static async sendFormData(url, formData, abortController, allowAnonymous, retry) {
		// is this compatible?
		const requiredAPIVersion = GetRequiredAPIVersion();
		formData.append('apiVersion', requiredAPIVersion.toObject());

		const response = await this.securedFetch(url, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			redirect: 'follow', // manual, *follow, error
			body: formData
		}, abortController, allowAnonymous, retry);

		if (!response.ok) {
			if (response.status === 426)
				throw new APIIncompatibleException();
			else
				throw new ServerResponseException(response);
		}

		return response;
	}
}