/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import IsNullOrUndefined, { AsyncExecution } from "../../Misc/Utility.js";
import DataArrayProvider from './DataArrayProvider.js';


export default class BaseAreaData {
	constructor(func, dataProvider, idField = "id") {
		this.func = func;
		this.dataProvider = dataProvider;
		this.idField = idField;

		this.destroyed = false;

		this.data = [];
		this.dataInformable = false;
		this.dataError = false;

		this.destroy = this.destroy.bind(this);
		this.areaChanged = this.areaChanged.bind(this);
		this.init = this.init.bind(this);
		this.release = this.release.bind(this);
		this.inform = this.inform.bind(this);
		this.processInform = this.processInform.bind(this);
		this.update = this.update.bind(this);
		this.mapDataToIds = this.mapDataToIds.bind(this);

		this.dataKey = null;
		this.dataArrayProvider = new DataArrayProvider(dataProvider);
	}

	destroy() {
		this.release();

		if (!IsNullOrUndefined(this.dataKey)) {
			this.dataArrayProvider.unregister(this.dataKey);
		}
		this.dataKey = null;

		this.dataArrayProvider.destroy();
		this.dataArrayProvider = null;

		this.destroyed = true;
	}

	init() {

	}

	release() {

	}

	mapDataToIds(data) {
		return data.map((d) => { return d[this.idField]; });
	}

	areaChanged(data, error) {
		if (error) {
			this.dataInformable = true;
			this.dataError = true;
			this.data = [];

			this.inform();
		}
		else {
			// Register all data-fields
			const dataIds = this.mapDataToIds(data);

			const key = this.dataArrayProvider.register(dataIds, (data, error) => {
				this.dataInformable = true;
				this.dataError = error;
				this.data = data;

				this.inform();
			});

			if (!IsNullOrUndefined(this.dataKey)) {
				this.dataArrayProvider.unregister(this.dataKey);
			}
			this.dataKey = key;
		}
	}

	update(startTime, endTime) {
	}

	inform() {
		if (this.destroyed)
			return;
		if (!this.dataInformable)
			return;

		AsyncExecution(() => { this.processInform(); });
	}

	processInform() {
		if (this.destroyed)
			return;
		if (!this.dataInformable)
			return;

		if (this.dataError) {
			try {
				this.func([], true);
			} catch (err) {
				// Nothing to do. Errorhandling has to be done in component
				console.error("Error during processing of registered function. Error: " + err);
			}
		}
		else {
			try {
				this.func(this.data, false);
			} catch (err) {
				// Nothing to do. Errorhandling has to be done in component
				console.error("Error during processing of registered function. Error: " + err);
			}
		}
	}
}
