/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert from the metric unit system to the user unit system
*
* @author: Jannik Müller (Jannik.Mueller@gehring-group.com)
*
*********************************************************************************/

import IsNullOrUndefined from '../Misc/Utility.js';
import configureMeasurements, { volume } from 'convert-units';

var instance;
export default class VolumeUnitSystemConverter {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.convert = configureMeasurements({ volume });

		// UnitSystem
		this.unitSystemDefinition = "l";
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	setDefaultUnitSystem() {
		this.setUnitSystem("l");
	}

	// Set the UnitSystem to use.
	setUnitSystem(_unitSystem) {
		const unitSystem = _unitSystem.toLowerCase();

		switch (unitSystem) {
			case 'm3':
				this.unitSystemDefinition = "m3";
				break;
			case 'mm3':
				this.unitSystemDefinition = "mm3";
				break;
			case 'l':
				this.unitSystemDefinition = "l";
				break;
			case 'floz':
				this.unitSystemDefinition = "floz";
				break;
			case 'gal':
				this.unitSystemDefinition = "gal";
				break;
			default:
				this.unitSystemDefinition = "l";
				if (process.env.NODE_ENV === 'development') {
					console.warn("UnitSystem '" + unitSystem + "' is invalid. Using default.");
				}
				break;
		}
	}

	/** Format the unit to the selected unit system (Best choice)
	 * @param value The Value to use
	 * @param targetUnit The target unit system
	 * @param sourceUnit The source unit system. Defaults to "l"
	 * @return { value, unit }
	 */
	format(value, targetUnit, sourceUnit) {		
		let source = "l";
		if (!IsNullOrUndefined(sourceUnit))
			source = sourceUnit;

		let negativ = false;
		if (value < 0)
			negativ = true;

		if (!IsNullOrUndefined(targetUnit)) {
			let _targetUnit = targetUnit;
			let _source = source;
			if (_targetUnit === "floz") {
				_targetUnit = "fl-oz";
			}
			if (_source === "floz") {
				_source = "fl-oz";
			}			
			const r = this.convert(value).from(_source).to(_targetUnit);
			

			return { value: negativ ? r * -1.0 : r, unit: targetUnit };
		}

		switch (this.unitSystemDefinition) {
			case 'm3':
				{
					const r = this.convert(value).from(source).to("m3");
					return { value: negativ ? r * -1.0 : r, unit: "m3" };
				}
			case 'mm3':
				{
					const r = this.convert(value).from(source).to("mm3");
					return { value: negativ ? r * -1.0 : r, unit: "mm3" };
				}
			case 'floz':
				{
					const r = this.convert(value).from(source).to("fl-oz");
					return { value: negativ ? r * -1.0 : r, unit: "floz" };
				}
			case 'gal':
				{
					const r = this.convert(value).from(source).to("gal");
					return { value: negativ ? r * -1.0 : r, unit: "gal" };
				}
			default:
			case 'l':
				{
					const r = this.convert(value).from(source).to("l");
					return { value: negativ ? r * -1.0 : r, unit: "l" };
				}
		}
	}

	/**
	 * Get the default unit
	 */
	getDefaultUnit() {
		switch (this.unitSystemDefinition) {
			case 'm3':
				return "m3";
			case 'mm3':
				return "mm3";
			case 'floz':
				return "fl-oz";
			case 'gal':
				return "gal";
			case 'l':
			default:
				return "l";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 */
	humanizeShortUnit(unit) {
		switch (unit) {
			case 'm3':
				return "m³";
			case 'mm3':
				return "mm³";
			case 'l':
				return "l";
			case 'floz':
				return "floz";
			case 'gal':
				return "gal";

			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 * @param singular Get the singular unit
	 */
	humanizeUnit(unit, singular) {
		switch (unit) {
			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}
}