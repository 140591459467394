/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { Container, Col, Row } from 'react-bootstrap'
import Localizer from '../../BusinessLogic/Misc/Localizer'
import DateFormat from '../../BusinessLogic/Formater/DateFormat'
import NavButton from '../../Components/BasicElements/NavButton.js';

export default class Layout extends React.Component {
	render() {
		return (
			<Container fluid className="min-h-100vh d-flex flex-column">
				<Row className="flex-fill">
					<Col lg={3} xl={6} className="d-none d-lg-flex pe-0 welcome-pages__image" />
					<Col xs={12} lg={9} xl={6} className="d-flex flex-column">
						{ /* Content */}
						<div className="flex-fill">
							{this.props.children}
						</div>
						{ /* Footer */}
						<Row className="pt-4 px-2 pb-2 mx-0">
							<Col xs className="flex-xl-grow-1 text-xs-center text-lg-start pb-2 justify-content-center justify-content-md-start justify-content-xl-start d-flex text-nowrap">
								<NavButton
									className="border-0 p-0 align-middle me-4 ms-4 ms-md-0"
									text={(new Localizer()).get("Privacy Policy")}
									variant={NavButton.VARIANT.link}
									icon={NavButton.ICON.privacyNote}
									autohideText
									autohideTextSize="xxl"
									to="/Privacy"
								/>
								<NavButton
									className="border-0 p-0 align-middle me-4"
									text={(new Localizer()).get("Terms and conditions")}
									variant={NavButton.VARIANT.link}
									icon={NavButton.ICON.agb}
									autohideText
									autohideTextSize="xxl"
									to="/Terms"
								/>
								<NavButton
									className="border-0 p-0 align-middle me-4"
									text={(new Localizer()).get("Contact")}
									variant={NavButton.VARIANT.link}
									icon={NavButton.ICON.contact}
									autohideText
									autohideTextSize="xxl"
									to="/Contact"
								/>
							</Col>
							<Col xs className="flex-xl-grow-0 text-nowrap text-xs-center text-lg-end pb-2 d-flex justify-content-center justify-content-md-end">
								{String.fromCharCode(169)} {(new DateFormat()).formatYear(new Date())} - {(new Localizer()).get("Gehring Technologies GmbH + Co.KG")}
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}
}