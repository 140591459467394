/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { Modal } from 'react-bootstrap'

export default class SearchDialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.handleClose = this.handleClose.bind(this);
	}
	static defaultProps = {
		show: false,
		searchEntered: (text) => { },
		onHide: () => { }
	}

	handleClose() {
		this.props.onHide();
	}

	render() {
		return (
			<>
				<Modal show={this.props.show} onHide={this.handleClose}>
					<Modal.Body>
						<div className="p-search-form w-100">
							<div className="d-flex align-items-center w-100">
								<button onClick={this.handleSearch}>
									<span className="bg-sprite p-mainnav__navicon icon--search"></span>
								</button>
								<input type="search" className="form-control w-100" id="q2" name="q2" placeholder="Search..." autoComplete="off" data-docs-version="4.1" />
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</>
		);
	}
}