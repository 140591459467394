/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This JavaScript file is used for the edit-mode of the dashboard
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import IsNullOrUndefined from "../../BusinessLogic/Misc/Utility.js";

export default class Scrollbar extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.renderThumb = this.renderThumb.bind(this);
		this.renderHTrack = this.renderHTrack.bind(this);
		this.renderVTrack = this.renderVTrack.bind(this);
		this.renderView = this.renderView.bind(this);

		this.ref = React.createRef();
	}

	static defaultProps = {
		enableAutoHide: false,
		height: null,
		minHeight: null,
		maxHeight: null
	}

	renderThumb({ style, ...props }) {
		const thumbStyle = {
			cursor: 'pointer',
			borderRadius: 'inherit',
			backgroundColor: 'rgba(0,0,0,.2)',
			zIndex: 5
		};
		return (
			<div
				style={{ ...style, ...thumbStyle }}
				{...props} />
		);
	}

	renderHTrack(_ref) {
		const trackStyle = {
			right: 2,
			bottom: 2,
			left: 2,
			borderRadius: 3,
			zIndex: 5
		};
		return (
			<div style={{ ..._ref.style, ...trackStyle }} />
		);
	}

	renderVTrack(_ref) {
		const trackStyle = {
			right: 2,
			bottom: 2,
			top: 2,
			borderRadius: 3,
			zIndex: 5
		};
		return (
			<div style={{ ..._ref.style, ...trackStyle }} />
		);
	}

	renderView({ style, ...props }) {
		const viewStyle = {

		};
		return (
			<div
				className="box"
				style={{ ...style, ...viewStyle }}
				{...props} />
		);
	}

	componentDidMount() {
		this.ref.current.handleWindowResize();
	}

	render() {
		let minHeight = this.props.minHeight;
		if (IsNullOrUndefined(minHeight))
			minHeight = this.props.height;
		let maxHeight = this.props.maxHeight;
		if (IsNullOrUndefined(maxHeight))
			maxHeight = this.props.height;

		let enableAutoHeight = true;
		if (IsNullOrUndefined(minHeight) && IsNullOrUndefined(maxHeight))
			enableAutoHeight = false;

		return (
			<Scrollbars
				ref={this.ref}
				renderView={this.renderView}
				renderThumbHorizontal={this.renderThumb}
				renderThumbVertical={this.renderThumb}
				renderTrackHorizontal={this.renderHTrack}
				renderTrackVertical={this.renderVTrack}
				autoHide
				// Hide delay in ms
				autoHideTimeout={1000}
				// Duration for hide animation in ms.
				autoHideDuration={500}
				autoHeight={enableAutoHeight}
				autoHeightMin={minHeight}
				autoHeightMax={maxHeight}>
				{/*This is required because some browsers scale on large resolutions. This leads to an invisible corner pixel row which is adjusted here.*/}
				<div style={{margin: 1}} className="h-100">
					{this.props.children}
				</div>
			</Scrollbars>
		);
	}
}