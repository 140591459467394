/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert from the metric unit system to the user unit system
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import Localizer from '../Misc/Localizer.js';
import IsNullOrUndefined from '../Misc/Utility.js';
import configureMeasurements, { mass } from 'convert-units';

var instance;
export default class MassUnitSystemConverter {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.convert = configureMeasurements({ mass });

		// UnitSystem
		this.unitSystemDefinition = "metric";
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	setDefaultUnitSystem() {
		this.setUnitSystem("metric");
	}

	// Set the UnitSystem to use. Possible values are "metric" and "imperial"
	setUnitSystem(_unitSystem) {
		const unitSystem = _unitSystem.toLowerCase();

		switch (unitSystem) {
			case 'metric':
				this.unitSystemDefinition = "metric";
				break;
			case 'imperial':
				this.unitSystemDefinition = "imperial";
				break;
			default:
				this.unitSystemDefinition = "metric";
				if (process.env.NODE_ENV === 'development') {
					console.warn("UnitSystem '" + unitSystem + "' is invalid. Using default.");
				}
				break;
		}
	}
	
	/** Format the mass to the selected unit system (Best choice)
	 * @param value The Value to use
	 * @param targetUnit The target unit system
	 * @param sourceUnit The source unit system. Defaults to "g"
	 * @return { value, unit }
	 */
	format(value, targetUnit, sourceUnit) {
		if (IsNullOrUndefined(sourceUnit))
			sourceUnit = "g";

		let negativ = false;
		if (value < 0)
			negativ = true;

		switch (this.unitSystemDefinition) {
			case "imperial": {
				if (typeof targetUnit === 'undefined') {
					// This will stay in the same system. So convert to the system first
					let r = this.convert(this.convert(Math.abs(value)).from(sourceUnit).to("lb")).from("lb").toBest();
					if (IsNullOrUndefined(r))
						r = this.convert(this.convert(Math.abs(value)).from(sourceUnit).to("lb")).from("lb").toBest({ cutOffNumber: 0 });
					return { value: negativ ? r.val * -1.0 : r.val, unit: r.unit };
				}
				else {
					// This will stay in the same system. So convert to the system first
					const r = this.convert(this.convert(Math.abs(value)).from(sourceUnit).to("lb")).from("lb").to(targetUnit);
					return { value: negativ ? r * -1.0 : r, unit: targetUnit };
				}
			}
			case "metric":
			default: {
				if (typeof targetUnit === 'undefined') {
					// This will stay in the same system. So convert to the system first
					let r = this.convert(this.convert(Math.abs(value)).from(sourceUnit).to("g")).from("g").toBest();
					if (IsNullOrUndefined(r))
						r = this.convert(this.convert(Math.abs(value)).from(sourceUnit).to("g")).from("g").toBest({ cutOffNumber: 0 });
					return { value: negativ ? r.val * -1.0 : r.val, unit: r.unit };
				}
				else {
					// This will stay in the same system. So convert to the system first
					const r = this.convert(this.convert(Math.abs(value)).from(sourceUnit).to("g")).from("g").to(targetUnit);
					return { value: negativ ? r * -1.0 : r, unit: targetUnit };
				}
			}
		}
	}

	/**
	 * Get the default unit
	 */
	getDefaultUnit() {
		switch (this.unitSystemDefinition) {
			case 'metric':
				return "g";
			case 'imperial':
				return "lb";
			default:
				return "g";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 */
	humanizeShortUnit(unit) {
		switch (unit) {
			// Metric
			case "ng":
				return "ng";
			case "mcg":
				return "µg";
			case "mg":
				return "mg";
			case "g":
				return "g";
			case "kg":
				return "kg";
			case "mt":
				return "mt";

			// Imperial
			case "oz":
				return "oz";
			case "lb":
				return "lb";
			case "t":
				return "t";

			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 * @param singular Get the singular unit
	 */
	humanizeUnit(unit, singular) {
		switch (unit) {
			// Metric
			case "ng":
				{
					if (singular === true)
						return (new Localizer()).get("Nanogram");
					else
						return (new Localizer()).get("Nanograms");
				}
			case "mcg":
				{
					if (singular === true)
						return (new Localizer()).get("Microgram");
					else
						return (new Localizer()).get("Micrograms");
				}
			case "mg":
				{
					if (singular === true)
						return (new Localizer()).get("Milligram");
					else
						return (new Localizer()).get("Milligrams");
				}
			case "g":
				{
					if (singular === true)
						return (new Localizer()).get("Gram");
					else
						return (new Localizer()).get("Grams");
				}
			case "kg":
				{
					if (singular === true)
						return (new Localizer()).get("Kilogram");
					else
						return (new Localizer()).get("Kilograms");
				}
			case "mt":
				{
					if (singular === true)
						return (new Localizer()).get("Metric Tonne");
					else
						return (new Localizer()).get("Metric Tonnes");
				}

			// Imperial
			case "oz":
				{
					if (singular === true)
						return (new Localizer()).get("Ounce");
					else
						return (new Localizer()).get("Ounces");
				}
			case "lb":
				{
					if (singular === true)
						return (new Localizer()).get("Pound");
					else
						return (new Localizer()).get("Pounds");
				}
			case "t":
				{
					if (singular === true)
						return (new Localizer()).get("Ton");
					else
						return (new Localizer()).get("Tons");
				}
			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}
}