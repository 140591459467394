/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import * as signalR from "@microsoft/signalr";
import settings from 'Settings';
import UniqueIDGenerator from "./UniqueIDGenerator.js";
import IsNullOrUndefined from "./Utility.js";
import ConnectionStatusManager from "./ConnectionStatusManager.js";


const CONNECTIONSTATUS = {
	UNCONNECTED: "unconnected",
	CONNECTED: "connected",
	CONNECTING: "connecting"
};

let instance;
class NotificationService {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		// Functions
		this.destroy = this.destroy.bind(this);
		this.stop = this.stop.bind(this);
		this.start = this.start.bind(this);
		this.processStop = this.processStop.bind(this);
		this.processStart = this.processStart.bind(this);
		this.addTimeoutKey = this.addTimeoutKey.bind(this);
		this.removeTimeoutKey = this.removeTimeoutKey.bind(this);
		this.informConnectionStatus = this.informConnectionStatus.bind(this);
		this.updateConnectionStatus = this.updateConnectionStatus.bind(this);
		this.processStartPortal = this.processStartPortal.bind(this);
		this.processStartMachine = this.processStartMachine.bind(this);

		this.registerHeartbeatMessage = this.registerHeartbeatMessage.bind(this);
		this.registerMachineChanged = this.registerMachineChanged.bind(this);
		this.registerUpdateChanged = this.registerUpdateChanged.bind(this);
		this.registerUpdateCreated = this.registerUpdateCreated.bind(this);
		this.registerPieceAdded = this.registerPieceAdded.bind(this);
		this.registerToolChanged = this.registerToolChanged.bind(this);
		this.registerToolTypeChanged = this.registerToolTypeChanged.bind(this);
		this.registerToolTypeAdded = this.registerToolTypeAdded.bind(this);
		this.registerMachineErrorAdded = this.registerMachineErrorAdded.bind(this);
		this.registerUserCreated = this.registerUserCreated.bind(this);
		this.registerUserRemoved = this.registerUserRemoved.bind(this);
		this.registerUserChanged = this.registerUserChanged.bind(this);
		this.registerGroupCreated = this.registerGroupCreated.bind(this);
		this.registerGroupRemoved = this.registerGroupRemoved.bind(this);
		this.registerGroupChanged = this.registerGroupChanged.bind(this);
		this.registerOrderChanged = this.registerOrderChanged.bind(this);
		this.registerOrderCreated = this.registerOrderCreated.bind(this);
		this.registerMachineMessageCreated = this.registerMachineMessageCreated.bind(this);
		this.registerMessageCreated = this.registerMessageCreated.bind(this);
		this.registerMessageChanged = this.registerMessageChanged.bind(this);
		this.registerFluidChanged = this.registerFluidChanged.bind(this);
		this.registerEnergyChanged = this.registerEnergyChanged.bind(this);
		this.registerMachineMessageChanged = this.registerMachineMessageChanged.bind(this);
		this.registerBillbeeOrderChanged = this.registerBillbeeOrderChanged.bind(this);
		this.registerBackupCreated = this.registerBackupCreated.bind(this);
		this.registerErrorDescriptionChanged = this.registerErrorDescriptionChanged.bind(this);
		this.registerLineChanged = this.registerLineChanged.bind(this);
		this.registerLineCreated = this.registerLineCreated.bind(this);
		this.registerPlantChanged = this.registerPlantChanged.bind(this);
		this.registerPlantCreated = this.registerPlantCreated.bind(this);
		this.registerLinePieceChanged = this.registerLinePieceChanged.bind(this);
		this.registerLinePieceCreated = this.registerLinePieceCreated.bind(this);
		this.registerDashboardChanged = this.registerDashboardChanged.bind(this);
		this.registerDashboardCreated = this.registerDashboardCreated.bind(this);
		this.registerDashboardRemoved = this.registerDashboardRemoved.bind(this);
		this.unregister = this.unregister.bind(this);

		// Variables
		this.keys = new UniqueIDGenerator();
		this.registrations = new Map();

		this.machineConnection = null;
		this.portalConnection = null;
		this.machineConnectionTimeout = null;
		this.portalConnectionTimeout = null;
		this.stopped = true;
		this.processing = false;
		this.shouldStopped = true;
		this.connectionStatus = CONNECTIONSTATUS.UNCONNECTED;
		this.portalConnectionStatus = CONNECTIONSTATUS.UNCONNECTED;
		this.machineConnectionStatus = CONNECTIONSTATUS.UNCONNECTED;

		// Machine HUB
		this.machineConnection = new signalR.HubConnectionBuilder()
			.withUrl(settings.NotificationServiceMachineURL)
			.withAutomaticReconnect({
				nextRetryDelayInMilliseconds: retryContext => {
					if (retryContext.previousRetryCount === 0)
						return settings.reconnectionShortTime;
					else
						return settings.reconnectionTime;
				}
			})
			.build();

		this.machineConnection.onreconnecting((error) => {
			this.machineConnectionStatus = CONNECTIONSTATUS.CONNECTING;
			this.informConnectionStatus();
		});

		this.machineConnection.onreconnected((connectionId) => {
			this.machineConnectionStatus = CONNECTIONSTATUS.CONNECTED;
			this.informConnectionStatus();
		});

		this.machineConnection.onclose(() => {
			this.machineConnectionStatus = CONNECTIONSTATUS.UNCONNECTED;
			this.informConnectionStatus();

			if (!this.shouldStopped)
				(async () => { await this.processStart(); })();
		});

		// Portal HUB
		this.portalConnection = new signalR.HubConnectionBuilder()
			.withUrl(settings.NotificationServicePortalURL)
			.withAutomaticReconnect({
				nextRetryDelayInMilliseconds: retryContext => {
					if (retryContext.previousRetryCount === 0)
						return settings.reconnectionShortTime;
					else
						return settings.reconnectionTime;
				}
			})
			.build();

		this.portalConnection.onreconnecting(() => {
			this.portalConnectionStatus = CONNECTIONSTATUS.CONNECTING;
			this.informConnectionStatus();
		});

		this.portalConnection.onreconnected(() => {
			this.portalConnectionStatus = CONNECTIONSTATUS.CONNECTED;
			this.informConnectionStatus();
		});

		this.portalConnection.onclose(() => {
			this.portalConnectionStatus = CONNECTIONSTATUS.UNCONNECTED;
			this.informConnectionStatus();

			if (!this.shouldStopped)
				(async () => { await this.processStart(); })();
		});

		// Start the connection
		(async () => {
			await this.start();
		})();
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance))
			instance.destroy();
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	destroy() {
		(async () => {
			await this.stop();

			this.machineConnection = null;
			this.portalConnection = null;
		})();
	}

	/** Stop the connection to the Server
	 */
	async stop() {
		if (this.shouldStopped)
			return;
		this.shouldStopped = true;

		await this.processStop();
	}

	/** Stop the connection to the Server
	 */
	async processStop() {
		if (this.processing)
			return;
		if (this.stopped)
			return;

		this.processing = true;

		const internPortalConnectionStatus = this.portalConnectionStatus;
		const internMachineConnectionStatus = this.machineConnectionStatus;

		this.portalConnectionStatus = CONNECTIONSTATUS.UNCONNECTED;
		this.machineConnectionStatus = CONNECTIONSTATUS.UNCONNECTED;
		this.informConnectionStatus();

		if (this.portalConnectionTimeout !== null) {
			clearTimeout(this.portalConnectionTimeout);
			this.portalConnectionTimeout = null;
		}
		if (this.machineConnectionTimeout !== null) {
			clearTimeout(this.machineConnectionTimeout);
			this.machineConnectionTimeout = null;
		}

		if (internMachineConnectionStatus === CONNECTIONSTATUS.CONNECTED || internMachineConnectionStatus === CONNECTIONSTATUS.CONNECTING) {
			try {
				if (!IsNullOrUndefined(this.machineConnection))
					await this.machineConnection.stop();
			} catch (err) {
				// Stop failed.
			}
		}

		if (internPortalConnectionStatus === CONNECTIONSTATUS.CONNECTED || internPortalConnectionStatus === CONNECTIONSTATUS.CONNECTING) {
			try {
				if (!IsNullOrUndefined(this.portalConnection))
					await this.portalConnection.stop();
			} catch (err) {
				// Stop failed.
			}
		}

		this.stopped = true;
		this.processing = false;

		if (!this.shouldStopped)
			await this.processStart();
	}

	/** Stop the connection to the Server
	 */
	async start() {
		if (!this.shouldStopped)
			return;
		this.shouldStopped = false;

		await this.processStart();
	}

	async processStart() {
		if (this.processing)
			return;
		if (!this.stopped)
			return;

		this.processing = true;

		await this.processStartPortal();
		await this.processStartMachine();

		this.stopped = false;
		this.processing = false;

		if (this.shouldStopped)
			await this.processStop();
	}

	async processStartPortal() {
		this.portalConnectionTimeout = null;
		if (this.portalConnectionStatus !== CONNECTIONSTATUS.UNCONNECTED)
			return;

		try {
			this.portalConnectionStatus = CONNECTIONSTATUS.CONNECTING;
			this.informConnectionStatus();

			if (!IsNullOrUndefined(this.portalConnection))
				await this.portalConnection.start();

			this.portalConnectionStatus = CONNECTIONSTATUS.CONNECTED;
			this.informConnectionStatus();
		} catch (err) {
			this.portalConnectionStatus = CONNECTIONSTATUS.UNCONNECTED;
			this.informConnectionStatus();

			this.portalConnectionTimeout = setTimeout((async () => { this.processStartPortal(); }), settings.reconnectionTime);
		}
	}

	async processStartMachine() {
		this.machineConnectionTimeout = null;
		if (this.machineConnectionStatus !== CONNECTIONSTATUS.UNCONNECTED)
			return;

		try {
			this.machineConnectionStatus = CONNECTIONSTATUS.CONNECTING;
			this.informConnectionStatus();

			if (!IsNullOrUndefined(this.machineConnection))
				await this.machineConnection.start();

			this.machineConnectionStatus = CONNECTIONSTATUS.CONNECTED;
			this.informConnectionStatus();
		} catch (err) {
			this.machineConnectionStatus = CONNECTIONSTATUS.UNCONNECTED;
			this.informConnectionStatus();

			this.machineConnectionTimeout = setTimeout((async () => { this.processStartMachine(); }), settings.reconnectionTime);
		}
	}

	updateConnectionStatus() {
		if (this.machineConnectionStatus === CONNECTIONSTATUS.UNCONNECTED)
			this.connectionStatus = CONNECTIONSTATUS.UNCONNECTED;
		else if (this.portalConnectionStatus === CONNECTIONSTATUS.UNCONNECTED)
			this.connectionStatus = CONNECTIONSTATUS.UNCONNECTED;
		else if (this.machineConnectionStatus === CONNECTIONSTATUS.CONNECTING)
			this.connectionStatus = CONNECTIONSTATUS.CONNECTING;
		else if (this.portalConnectionStatus === CONNECTIONSTATUS.CONNECTING)
			this.connectionStatus = CONNECTIONSTATUS.CONNECTING;
		else
			this.connectionStatus = CONNECTIONSTATUS.CONNECTED;
	}

	informConnectionStatus() {
		this.updateConnectionStatus();

		switch (this.connectionStatus) {
			case CONNECTIONSTATUS.CONNECTED:
				(new ConnectionStatusManager()).setNotificationStatus(ConnectionStatusManager.STATUS.ONLINE);
				break;
			case CONNECTIONSTATUS.CONNECTING:
			case CONNECTIONSTATUS.UNCONNECTED:
			default:
				(new ConnectionStatusManager()).setNotificationStatus(ConnectionStatusManager.STATUS.OFFLINE);
				break;
		}
	}

	// Register functions

	/** Register for heartbeat messages. 
	@info The function is called if a heartbeat from the machine arrived in the backend
	*/
	registerHeartbeatMessage(machineID, func) {
		const key = this.keys.generate();

		const f = (status, lastActivity, statusTime) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);

						const _lastActivity = parseInt(lastActivity);
						let _statusTime = _lastActivity;
						if (!IsNullOrUndefined(statusTime))
							_statusTime = parseInt(statusTime);
						func(machineID, status, _lastActivity, _statusTime);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "heartbeat/" + machineID, func: f, timeout: new Set() });
		this.machineConnection.on("heartbeat/" + machineID, f);

		return key;
	}

	registerNewMachine(func) {
		const key = this.keys.generate();

		const f = (machineID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(machineID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "newmachine", func: f, timeout: new Set() });
		this.machineConnection.on("newmachine", f);

		return key;
	}

	/** Register for machine messages. 
	@info The function is called if the a machine-message is received in the backend
	*/
	registerMachineChanged(machineID, func) {
		const key = this.keys.generate();

		const f = () => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(machineID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "machine/" + machineID, func: f, timeout: new Set() });
		this.machineConnection.on("machine/" + machineID, f);

		return key;
	}

	registerLinePieceChanged(lineID, customerPieceID, func) {
		const key = this.keys.generate();

		const f = (machineID, pieceID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(lineID, customerPieceID, machineID, pieceID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "linepiece/" + lineID + "/" + customerPieceID, func: f, timeout: new Set() });
		this.machineConnection.on("linepiece/" + lineID + "/" + customerPieceID, f);

		return key;
	}

	registerLinePieceCreated(lineID, func) {
		const key = this.keys.generate();

		const f = (customerPieceID, machineID, pieceID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(lineID, customerPieceID, machineID, pieceID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "linepiececreated/" + lineID, func: f, timeout: new Set() });
		this.machineConnection.on("linepiececreated/" + lineID, f);

		return key;
	}

	registerLineChanged(lineID, func) {
		const key = this.keys.generate();

		const f = () => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(lineID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "line/" + lineID, func: f, timeout: new Set() });
		this.machineConnection.on("line/" + lineID, f);

		return key;
	}

	registerLineCreated(func) {
		const key = this.keys.generate();

		const f = (lineID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(lineID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "linecreated", func: f, timeout: new Set() });
		this.machineConnection.on("linecreated", f);

		return key;
	}

	registerPlantChanged(plantID, func) {
		const key = this.keys.generate();

		const f = () => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(plantID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "plant/" + plantID, func: f, timeout: new Set() });
		this.machineConnection.on("plant/" + plantID, f);

		return key;
	}

	registerPlantCreated(func) {
		const key = this.keys.generate();

		const f = (plantID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(plantID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "plantcreated", func: f, timeout: new Set() });
		this.machineConnection.on("plantcreated", f);

		return key;
	}

	registerUpdateChanged(updateID, func) {
		const key = this.keys.generate();

		const f = () => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(updateID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "update/" + updateID, func: f, timeout: new Set() });
		this.portalConnection.on("update/" + updateID, f);

		return key;
	}

	registerErrorDescriptionChanged(machineID, func) {
		const key = this.keys.generate();

		const f = () => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(machineID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "errordescription/" + machineID, func: f, timeout: new Set() });
		this.machineConnection.on("errordescription/" + machineID, f);

		return key;
	}

	registerUpdateCreated(func) {
		const key = this.keys.generate();

		const f = (updateID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(updateID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "updatecreated", func: f, timeout: new Set() });
		this.portalConnection.on("updatecreated", f);

		return key;
	}

	/** Register for piece messages. 
	@info The function is called everytime when a piece-message is received in the backend
	*/
	registerPieceAdded(machineID, func) {
		const key = this.keys.generate();

		const f = (pieceID, endOfProduction, status, customerID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(machineID, pieceID, endOfProduction, status, customerID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "pieceadded/" + machineID, func: f, timeout: new Set() });
		this.machineConnection.on("pieceadded/" + machineID, f);

		return key;
	}

	/** Register for piece messages. 
	@info The function is called everytime when a piece-message is received in the backend
	*/
	registerPieceChanged(machineID, func) {
		const key = this.keys.generate();

		const f = (pieceID, endOfProduction, status, customerID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(machineID, pieceID, endOfProduction, status, customerID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "piece/" + machineID, func: f, timeout: new Set() });
		this.machineConnection.on("piece/" + machineID, f);

		return key;
	}

	/** Register for tool messages. 
	@info The function is called if a tool has changed. This is the case if a piece message with this tool is received in the backend
	*/
	registerToolChanged(toolID, func) {
		const key = this.keys.generate();

		const f = (pieceID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(toolID, pieceID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "tool/" + toolID, func: f, timeout: new Set() });
		this.machineConnection.on("tool/" + toolID, f);

		return key;
	}

	/** Register for tool messages. 
	@info The function is called if a tool has changed. This is the case if a piece message with this tool is received in the backend
	*/
	registerToolTypeChanged(toolTypeID, func) {
		const key = this.keys.generate();

		const f = () => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(toolTypeID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "tooltype/" + toolTypeID, func: f, timeout: new Set() });
		this.machineConnection.on("tooltype/" + toolTypeID, f);

		return key;
	}

	/** Register for tool messages. 
	@info The function is called if a tool has changed. This is the case if a piece message with this tool is received in the backend
	*/
	registerToolTypeAdded(func) {
		const key = this.keys.generate();

		const f = (toolTypeID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(toolTypeID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "tooltypeadded", func: f, timeout: new Set() });
		this.machineConnection.on("tooltypeadded", f);

		return key;
	}

	/** Register for MachineMessages. 
	@info The function is called if a status message from the machine arrived in the backend
	*/
	registerMachineErrorAdded(machineID, func) {
		const key = this.keys.generate();

		const f = (errorID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(machineID, errorID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "error/" + machineID, func: f, timeout: new Set() });
		this.machineConnection.on("error/" + machineID, f);

		return key;
	}

	/** Register for MachineMessages. 
	@info The function is called if a status message from the machine arrived in the backend
	*/
	registerUserCreated(func) {
		const key = this.keys.generate();

		const f = (userID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(userID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "usercreated", func: f, timeout: new Set() });
		this.portalConnection.on("usercreated", f);

		return key;
	}

	/** Register for MachineMessages. 
	@info The function is called if a status message from the machine arrived in the backend
	*/
	registerUserRemoved(func) {
		const key = this.keys.generate();

		const f = (userID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(userID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "userremoved", func: f, timeout: new Set() });
		this.portalConnection.on("userremoved", f);

		return key;
	}

	/** Register for User. 
	@info The function is called if a status message from the machine arrived in the backend
	*/
	registerUserChanged(userID, func) {
		const key = this.keys.generate();

		const f = () => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(userID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "user/" + userID, func: f, timeout: new Set() });
		this.portalConnection.on("user/" + userID, f);

		return key;
	}

	/** Register for MachineMessages. 
	@info The function is called if a status message from the machine arrived in the backend
	*/
	registerGroupCreated(func) {
		const key = this.keys.generate();

		const f = (groupID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(groupID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "groupcreated", func: f, timeout: new Set() });
		this.portalConnection.on("groupcreated", f);

		return key;
	}

	/** Register for MachineMessages. 
	@info The function is called if a status message from the machine arrived in the backend
	*/
	registerGroupRemoved(func) {
		const key = this.keys.generate();

		const f = (groupID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(groupID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "groupremoved", func: f, timeout: new Set() });
		this.portalConnection.on("groupremoved", f);

		return key;
	}

	/** Register for MachineMessages. 
	@info The function is called if a status message from the machine arrived in the backend
	*/
	registerGroupChanged(groupID, func) {
		const key = this.keys.generate();

		const f = () => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(groupID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "group/" + groupID, func: f, timeout: new Set() });
		this.portalConnection.on("group/" + groupID, f);

		return key;
	}

	/** Register for Order Messages. 
	@info The function is called if a status message from the machine arrived in the backend
	*/
	registerOrderChanged(orderID, func) {
		const key = this.keys.generate();

		const f = () => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(orderID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "order/" + orderID, func: f, timeout: new Set() });
		this.portalConnection.on("order/" + orderID, f);

		return key;
	}

	/** Register for Order Messages. 
	@info The function is called if a status message from the machine arrived in the backend
	*/
	registerOrderCreated(func) {
		const key = this.keys.generate();

		const f = (orderID) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(orderID);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "ordercreated", func: f, timeout: new Set() });
		this.portalConnection.on("ordercreated", f);

		return key;
	}

	/** Register for Order Messages. 
	@info The function is called if a status message from the machine arrived in the backend
	*/
	registerFluidChanged(machineID, fluidID, func) {
		const key = this.keys.generate();

		const f = (id, time) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(machineID, fluidID, id, time);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "fluid/" + machineID + "_" + fluidID, func: f, timeout: new Set() });
		this.machineConnection.on("fluid/" + machineID + "_" + fluidID, f);

		return key;
	}

	/** Register for Order Messages. 
	@info The function is called if a status message from the machine arrived in the backend
	*/
	registerEnergyChanged(machineID, energyID, func) {
		const key = this.keys.generate();

		const f = (id, startTime, endTime) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(machineID, energyID, id, startTime, endTime);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "energy/" + machineID + "_" + energyID, func: f, timeout: new Set() });
		this.machineConnection.on("energy/" + machineID + "_" + energyID, f);

		return key;
	}

	/** Register for Messages. 
	@info The function is called if a message arrived
	*/
	registerMachineMessageCreated(machineId, func) {
		const key = this.keys.generate();

		const f = (id) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(machineId, id);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "machinemessagecreated/" + machineId, func: f, timeout: new Set() });
		this.machineConnection.on("machinemessagecreated/" + machineId, f);

		return key;
	}

	/** Register for Messages. 
	@info The function is called if a message arrived
	*/
	registerMachineMessageChanged(machineId, func) {
		const key = this.keys.generate();

		const f = (content) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						// Deserialize the content
						const o = JSON.parse(content);

						func(machineId, o.allChanged, o.messageIDs);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "machinemessagechanged/" + machineId, func: f, timeout: new Set() });
		this.portalConnection.on("machinemessagechanged/" + machineId, f);

		return key;
	}

	/** Register for Messages. 
	@info The function is called if a message arrived
	*/
	registerMessageCreated(userId, func) {
		const key = this.keys.generate();

		const f = (id) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(userId, id);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "messagecreated/" + userId, func: f, timeout: new Set() });
		this.portalConnection.on("messagecreated/" + userId, f);

		return key;
	}

	/** Register for Messages. 
	@info The function is called if a message arrived
	*/
	registerMessageChanged(userId, func) {
		const key = this.keys.generate();

		const f = (content) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						// Deserialize the content
						const o = JSON.parse(content);

						func(userId, o.allChanged, o.messageIDs);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "messagechanged/" + userId, func: f, timeout: new Set() });
		this.portalConnection.on("messagechanged/" + userId, f);

		return key;
	}

	/** Register for Billbee Order Changes.
	@info The function is called if a message arrived
	*/
	registerBillbeeOrderChanged(func) {
		const key = this.keys.generate();

		const f = () => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func();
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "billbeeorderchanged", func: f, timeout: new Set() });
		this.portalConnection.on("billbeeorderchanged", f);

		return key;
	}

	/** Register for Billbee Order Changes.
	@info The function is called if a message arrived
	*/
	registerBackupCreated(machineID, func) {
		const key = this.keys.generate();

		const f = (content) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(content);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "MACHINE", con: "backup/" + machineID, func: f, timeout: new Set() });
		this.machineConnection.on("backup/" + machineID, f);

		return key;
	}

	/** Register for Billbee Order Changes.
	@info The function is called if a message arrived
	*/
	registerDashboardChanged(dashboardId, func) {
		const key = this.keys.generate();

		const f = () => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func();
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "dashboardchanged/" + dashboardId, func: f, timeout: new Set() });
		this.portalConnection.on("dashboardchanged/" + dashboardId, f);

		return key;
	}

	registerDashboardCreated(func) {
		const key = this.keys.generate();

		const f = (id) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(id);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "dashboardcreated", func: f, timeout: new Set() });
		this.portalConnection.on("dashboardcreated", f);

		return key;
	}

	registerDashboardRemoved(func) {
		const key = this.keys.generate();

		const f = (id) => {
			try {
				const timeoutKey = setTimeout(() => {
					try {
						this.removeTimeoutKey(key, timeoutKey);
						func(id);
					} catch (e) {
						console.error("Exception in notification function");
					}
				}, 5);

				this.addTimeoutKey(key, timeoutKey);
			} catch (e) {
				console.error("Exception in notification");
			}
		};

		this.registrations.set(key, { hub: "PORTAL", con: "dashboardremoved", func: f, timeout: new Set() });
		this.portalConnection.on("dashboardremoved", f);

		return key;
	}

	addTimeoutKey(key, timeoutkey) {
		let obj = this.registrations.get(key);
		obj.timeout.add(timeoutkey);
		this.registrations.set(key, obj);
	}

	removeTimeoutKey(key, timeoutkey) {
		let obj = this.registrations.get(key);
		obj.timeout.delete(timeoutkey);
		this.registrations.set(key, obj);
	}

	unregister(key) {
		if (process.env.NODE_ENV === 'development') {
			if (IsNullOrUndefined(key)) {
				console.warn("Unregistering an undefined key. This indicates an error in notification registration");
			}
		}

		const obj = this.registrations.get(key);
		if (!IsNullOrUndefined(obj)) {
			// Cancel the in process functions
			if (!IsNullOrUndefined(obj.timeout)) {
				obj.timeout.forEach((e) => {
					clearTimeout(e);
				});
			}

			// Remove the portal connection
			if (obj.hub === "PORTAL")
				this.portalConnection.off(obj.con, obj.func);
			else if (obj.hub === "MACHINE")
				this.machineConnection.off(obj.con, obj.func);
			else
				console.error("Unknown registration HUB")
		}
		this.registrations.delete(key);
		this.keys.release(key);
	}
}

export default NotificationService;