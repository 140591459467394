/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple component for Loading purposes
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";
import Localizer from "../BusinessLogic/Misc/Localizer.js"

class Loading extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }
	static defaultProps = {
	}

	render() {
		return (
			<>
				<div className="d-flex h-100 align-items-center p-3">
					<div className="spinner-border m-auto">
						<span className="sr-only">{(new Localizer()).get("Loading...")}</span>
					</div>
				</div>
			</>);
    }
}

export default Loading;