/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the basic template for autorization pages
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { Col, Row } from 'react-bootstrap'
import { Link } from "react-router-dom";
import Localizer from '../../BusinessLogic/Misc/Localizer'
import { ReactComponent as Logo } from '../../images/gehring-core-full-logo.svg';

export default class BasicMessageTemplate extends React.Component {
	static defaultProps = {
		title: "",
		disabled: false
	}

    render() {
		return (
			<>
				<Row>
					<Col md={10} lg={8} className="text-center ms-auto me-auto">
						<Logo className="img-fluid login-mask__logo" />
					</Col>
				</Row>
				<Row>
					<Col xs={12} className="text-center">
						<h2>{this.props.title}</h2>
					</Col>
				</Row>
				<Row className="justify-content-md-center">
					<Col md={10} lg={8} className="mt-5 pt-1">
						{this.props.children}
					</Col>
				</Row>
				<Row>
					<Col md={10} lg={8} className="text-center mx-auto mt-3">
						<Link to="/Index" className="btn btn-secondary text-uppercase w-100" disabled={this.props.disabled}>{(new Localizer()).get("Go to Log In Page")}</Link>
					</Col>
				</Row>
			</>
		);
    }
}