/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert from the metric unit system to the user unit system
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import Localizer from '../Misc/Localizer.js';
import IsNullOrUndefined from '../Misc/Utility.js';
import configureMeasurements, { length } from 'convert-units';

var instance;
export default class LengthUnitSystemConverter {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.convert = configureMeasurements({ length });

		// UnitSystem
		this.unitSystemDefinition = "metric";
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	setDefaultUnitSystem() {
		this.setUnitSystem("metric");
	}

	// Set the UnitSystem to use. Possible values are "metric" and "imperial"
	setUnitSystem(_unitSystem) {
		const unitSystem = _unitSystem.toLowerCase();

		switch (unitSystem) {
			case 'metric':
				this.unitSystemDefinition = "metric";
				break;
			case 'imperial':
				this.unitSystemDefinition = "imperial";
				break;
			default:
				this.unitSystemDefinition = "metric";
				if (process.env.NODE_ENV === 'development') {
					console.warn("UnitSystem '" + unitSystem + "' is invalid. Using default.");
				}
				break;
		}
	}
	
	/** Format a length to the selected unit system (Best choice)
	 * @param value The Value to use
	 * @param targetUnit The target unit system
	 * @param sourceUnit The source unit system. Defaults to "m"
	 * @return { value, unit }
	 */
	format(value, targetUnit, sourceUnit) {
		if (IsNullOrUndefined(sourceUnit))
			sourceUnit = "m";

		switch (this.unitSystemDefinition) {
			case "imperial": {
				if (typeof targetUnit === 'undefined') {
					// This will stay in the same system. So convert to the system first
					let r = this.convert(this.convert(value).from(sourceUnit).to("ft")).from("ft").toBest({ exclude: ['ft-us', 'fathom', 'nMi'] });
					if (IsNullOrUndefined(r))
						r = this.convert(this.convert(value).from(sourceUnit).to("ft")).from("ft").toBest({ cutOffNumber: 0, exclude: ['ft-us', 'fathom', 'nMi'] });
					return { value: r.val, unit: r.unit };
				}
				else {
					// This will stay in the same system. So convert to the system first
					const r = this.convert(this.convert(value).from(sourceUnit).to("ft")).from("ft").to(targetUnit);
					return { value: r, unit: targetUnit };
				}
			}
			case "metric":
			default: {
				if (typeof targetUnit === 'undefined') {
					// This will stay in the same system. So convert to the system first
					let r = this.convert(this.convert(value).from(sourceUnit).to("m")).from("m").toBest({ exclude: ['nm', 'dm', 'cm'] });
					if (IsNullOrUndefined(r))
						r = this.convert(this.convert(value).from(sourceUnit).to("m")).from("m").toBest({ cutOffNumber: 0, exclude: ['nm', 'dm', 'cm'] });
					return { value: r.val, unit: r.unit };
				}
				else {
					// This will stay in the same system. So convert to the system first
					const r = this.convert(this.convert(value).from(sourceUnit).to("m")).from("m").to(targetUnit);
					return { value: r, unit: targetUnit };
				}
			}
		}
	}

	/**
	 * Get the default unit
	 */
	getDefaultUnit() {
		switch (this.unitSystemDefinition) {
			case 'metric':
				return "m";
			case 'imperial':
				return "ft";
			default:
				return "m";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 */
	humanizeShortUnit(unit) {
		switch (unit) {
			// Metric
			case "nm":
				return "nm";
			case "μm":
				return "µm";
			case "mm":
				return "mm";
			case "cm":
				return "cm";
			case "m":
				return "m";
			case "km":
				return "km";
			case "Mm":
				return "Mm";
			case "Gm":
				return "Gm";

			// Imperial
			case "in":
				return "in";
			case "ft":
				return "ft";
			case "yd":
				return "yd";
			case "mi":
				return "mi";

			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 * @param singular Get the singular unit
	 */
	humanizeUnit(unit, singular) {
		switch (unit) {
			// Metric
			case "nm":
				{
					if(singular === true)
						return (new Localizer()).get("Nanometer");
					else
						return (new Localizer()).get("Nanometers");
				}
			case "μm":
				{
					if (singular === true)
						return (new Localizer()).get("Micrometer");
					else
						return (new Localizer()).get("Micrometers");
				}
			case "mm":
				{
					if (singular === true)
						return (new Localizer()).get("Millimeter");
					else
						return (new Localizer()).get("Millimeters");
				}
			case "cm":
				{
					if (singular === true)
						return (new Localizer()).get("Centimeter");
					else
						return (new Localizer()).get("Centimeters");
				}
			case "m":
				{
					if (singular === true)
						return (new Localizer()).get("Meter");
					else
						return (new Localizer()).get("Meters");
				}
			case "km":
				{
					if (singular === true)
						return (new Localizer()).get("Kilometer");
					else
						return (new Localizer()).get("Kilometers");
				}
			case "Mm":
				{
					if (singular === true)
						return (new Localizer()).get("Megameter");
					else
						return (new Localizer()).get("Megameters");
				}
			case "Gm":
				{
					if (singular === true)
						return (new Localizer()).get("Gigameter");
					else
						return (new Localizer()).get("Gigameters");
				}

			// Imperial
			case "in":
				{
					if (singular === true)
						return (new Localizer()).get("Inch");
					else
						return (new Localizer()).get("Inches");
				}
			case "ft":
				{
					if (singular === true)
						return (new Localizer()).get("Foot");
					else
						return (new Localizer()).get("Feet");
				}
			case "yd":
				{
					if (singular === true)
						return (new Localizer()).get("Yard");
					else
						return (new Localizer()).get("Yards");
				}
			case "mi":
				{
					if (singular === true)
						return (new Localizer()).get("Mile");
					else
						return (new Localizer()).get("Miles");
				}
			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}
}