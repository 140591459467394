/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 105.83333 270.93333"
            className="svgIcon"
        >
            <path d="M 105.83327,135.4667 21.771443,52.311733 2.5e-6,73.962612 62.245213,135.4667 2.5e-6,197.04677 21.771443,218.62166 Z" />
        </svg>
    );
}