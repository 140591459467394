/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import isEqual from 'lodash/isEqual';
import IsNullOrUndefined from "../Misc/Utility.js";
import { IsNullOrUndefinedOrWhitespace } from "../Misc/Utility.js";
import UserData from './UserData.js';
import UniqueIDGenerator from '../Misc/UniqueIDGenerator.js';
import ErrorManager from "../Misc/ErrorManager.js";
import settings from 'Settings';
import FetchHelper from "../Misc/FetchHelper.js";
import { AsyncExecution } from "../Misc/Utility.js";

let instance;
export default class CurrentUser {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.keys = new UniqueIDGenerator();
		this.functions = new Map();

		this.userNotificationKey = null;

		this.abortController = new AbortController();

		// Data
		this.ID = null;
		this.data = {};
		this.dataAvailable = false;
		this.dataError = false;

		this.destroy = this.destroy.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.removeUserID = this.removeUserID.bind(this);
		this.setUserID = this.setUserID.bind(this);
		this.inform = this.inform.bind(this);
		this.hasRight = this.hasRight.bind(this);
		this.hasMachineRight = this.hasMachineRight.bind(this);
		this.hasLineRight = this.hasLineRight.bind(this);
		this.hasPlantRight = this.hasPlantRight.bind(this);
		this.getResolvedRights = this.getResolvedRights.bind(this);
		this.getResolvedMachineRights = this.getResolvedMachineRights.bind(this);
		this.getResolvedLineRights = this.getResolvedLineRights.bind(this);
		this.getResolvedPlantRights = this.getResolvedPlantRights.bind(this);
		this.getLanguage = this.getLanguage.bind(this);
		this.getUnitSystem = this.getUnitSystem.bind(this);
		this.getFlowRateUnitSystem = this.getFlowRateUnitSystem.bind(this);
		this.getPressureUnitSystem = this.getPressureUnitSystem.bind(this);
		this.getTemperatureUnitSystem = this.getTemperatureUnitSystem.bind(this);
		this.getVolumeUnitSystem = this.getVolumeUnitSystem.bind(this);
		this.getConductivityUnitSystem = this.getConductivityUnitSystem.bind(this);
		this.getMassPerTimeUnitSystem = this.getMassPerTimeUnitSystem.bind(this);
		this.getAngleUnitSystem = this.getAngleUnitSystem.bind(this);
		this.handleUserChangedMessage = this.handleUserChangedMessage.bind(this);
		this.getProfileImage = this.getProfileImage.bind(this);
		this.isHeaderVisible = this.isHeaderVisible.bind(this);
		this.isFooterVisible = this.isFooterVisible.bind(this);
		this.getName = this.getName.bind(this);
		this.hasSAPCustomerID = this.hasSAPCustomerID.bind(this);
		this.getVisibleMachines = this.getVisibleMachines.bind(this);
		this.getVisibleLines = this.getVisibleLines.bind(this);
		this.getVisiblePlants = this.getVisiblePlants.bind(this);
		this.hasAutomaticReloadTime = this.hasAutomaticReloadTime.bind(this);
		this.getAutomaticReloadTime = this.getAutomaticReloadTime.bind(this);
		this.shouldReloadOnAPIIncompatibility = this.shouldReloadOnAPIIncompatibility.bind(this);
		this.informKey = this.informKey.bind(this);
		this.getResolvedRightsChangeID = this.getResolvedRightsChangeID.bind(this);
		this.getResolvedMachineRightsChangeID = this.getResolvedMachineRightsChangeID.bind(this);
		this.getResolvedLineRightsChangeID = this.getResolvedLineRightsChangeID.bind(this);
		this.getResolvedPlantRightsChangeID = this.getResolvedPlantRightsChangeID.bind(this);
		this.getResolvedSharedDashboardsChangeID = this.getResolvedSharedDashboardsChangeID.bind(this);
		this.hasVisibleMachineWithRight = this.hasVisibleMachineWithRight.bind(this);
		this.getLastViewedMachines = this.getLastViewedMachines.bind(this);
		this.getLastViewedTools = this.getLastViewedTools.bind(this);
		this.getLastViewedLines = this.getLastViewedLines.bind(this);
		this.getLastViewedPlants = this.getLastViewedPlants.bind(this);
		this.addLastViewedMachine = this.addLastViewedMachine.bind(this);
		this.addLastViewedPlant = this.addLastViewedPlant.bind(this);
		this.addLastViewedTool = this.addLastViewedTool.bind(this);
		this.addLastViewedLine = this.addLastViewedLine.bind(this);
		this.hasSharedDashboard = this.hasSharedDashboard.bind(this);
		this.hasDashboard = this.hasDashboard.bind(this);
		this.getDashboardIds = this.getDashboardIds.bind(this);
		this.isDashboardEditable = this.isDashboardEditable.bind(this);
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance)) {
			instance.destroy();
		}

		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	destroy() {
		if (this.userNotificationKey !== null) {
			(new UserData()).unregister(this.userNotificationKey);
			this.userNotificationKey = null;
		}

		// Cancel all pending requests
		this.abortController.abort();

		// Clear all remaining functions
		this.functions.forEach((v, k) => {
			this.keyGen.release(k);
		});
		this.functions.clear();
	}

	// Remove the current User ID
	removeUserID() {
		this.ID = null;
		this.dataAvailable = false;
		this.dataError = false;
		this.data = {};

		(new UserData()).setCurrentUser(this.ID, this.getResolvedRightsChangeID(), this.getResolvedMachineRightsChangeID(), this.getResolvedPlantRightsChangeID(), this.getResolvedLineRightsChangeID());

		this.inform();
	}

	// Set the current User ID
	setUserID(ID) {
		if (this.ID === ID)
			return;
		this.ID = ID;
		this.dataAvailable = false;
		this.dataError = false;
		this.data = {};

		(new UserData()).setCurrentUser(ID, this.getResolvedRightsChangeID(), this.getResolvedMachineRightsChangeID(), this.getResolvedPlantRightsChangeID(), this.getResolvedLineRightsChangeID());

		this.inform();

		if (this.userNotificationKey !== null) {
			(new UserData()).unregister(this.userNotificationKey);
			this.userNotificationKey = null;
		}

		if (!IsNullOrUndefinedOrWhitespace(this.ID))		
			this.userNotificationKey = (new UserData().register(this.ID, this.handleUserChangedMessage));
	}

	getUserID() {
		return this.ID;
	}

	handleUserChangedMessage(data, dataError) {
		if ((this.dataAvailable && !this.dataError && !dataError) || (this.dataAvailable && this.dataError && dataError)) {
			if (isEqual(this.data, data))
				return;
		}

		this.dataAvailable = true;
		this.dataError = dataError;
		this.data = data;

		(new UserData()).setCurrentUser(this.ID, this.getResolvedRightsChangeID(), this.getResolvedMachineRightsChangeID(), this.getResolvedPlantRightsChangeID(), this.getResolvedLineRightsChangeID());

		// Inform about the change
		this.inform();
	}

	// Inform about a change
	inform() {
		this.functions.forEach((v, k) => {
			AsyncExecution(() => { this.informKey(k); }, this.abortController.signal);
		});
	}

	informKey(key) {
		const f = this.functions.get(key);
		if (IsNullOrUndefined(f))
			return;

		try {
			f(this.dataAvailable, this.dataError);
		} catch (err) {
			// Nothing to do. Errorhandling has to be done in component
			console.error("Error during processing of registered function");
		}
	}

	// Check if the user has a specific right
	hasRight(right) {
		if (!this.dataAvailable || this.dataError)
			return false;

		// Check if the user has a specific right
		if (IsNullOrUndefined(this.data.resolvedRights))
			return false;
		return this.data.resolvedRights.includes(right);
	}

	// Check if the user has a specific right
	hasMachineRight(machineID, right) {
		if (!this.dataAvailable || this.dataError)
			return false;

		// Check if the user has a specific right
		if (IsNullOrUndefined(this.data.machines[machineID]))
			return false;
		if (IsNullOrUndefined(this.data.machines[machineID].resolvedRights))
			return false;
		return this.data.machines[machineID].resolvedRights.includes(right);
	}

	// Check if the user has a specific right
	hasPlantRight(plantID, right) {
		if (!this.dataAvailable || this.dataError)
			return false;

		// Check if the user has a specific right
		if (IsNullOrUndefined(this.data.plants[plantID]))
			return false;
		if (IsNullOrUndefined(this.data.plants[plantID].resolvedRights))
			return false;
		return this.data.plants[plantID].resolvedRights.includes(right);
	}

	// Check if the user has a specific right
	hasLineRight(lineID, right) {
		if (!this.dataAvailable || this.dataError)
			return false;

		// Check if the user has a specific right
		if (IsNullOrUndefined(this.data.lines[lineID]))
			return false;
		if (IsNullOrUndefined(this.data.lines[lineID].resolvedRights))
			return false;
		return this.data.lines[lineID].resolvedRights.includes(right);
	}

	// Get the List with all rights for a specific machine
	getResolvedRights() {
		if (!this.dataAvailable || this.dataError)
			return [];

		// Check if the user has a specific right
		if (IsNullOrUndefined(this.data.resolvedRights))
			return [];
		return this.data.resolvedRights;
	}

	// Get the List with all rights for a specific machine
	getResolvedMachineRights(machineID) {
		if (!this.dataAvailable || this.dataError)
			return [];

		// Check if the user has a specific right
		if (IsNullOrUndefined(this.data.machines[machineID]))
			return [];
		if (IsNullOrUndefined(this.data.machines[machineID].resolvedRights))
			return [];
		return this.data.machines[machineID].resolvedRights;
	}

	// Get the List with all rights for a specific machine
	getResolvedPlantRights(plantID) {
		if (!this.dataAvailable || this.dataError)
			return [];

		// Check if the user has a specific right
		if (IsNullOrUndefined(this.data.plants[plantID]))
			return [];
		if (IsNullOrUndefined(this.data.plants[plantID].resolvedRights))
			return [];
		return this.data.plants[plantID].resolvedRights;
	}

	// Get the List with all rights for a specific machine
	getResolvedLineRights(lineID) {
		if (!this.dataAvailable || this.dataError)
			return [];

		// Check if the user has a specific right
		if (IsNullOrUndefined(this.data.lines[lineID]))
			return [];
		if (IsNullOrUndefined(this.data.lines[lineID].resolvedRights))
			return [];
		return this.data.lines[lineID].resolvedRights;
	}

	// Get the Machines for this User
	getVisibleMachines() {
		if (!this.dataAvailable || this.dataError)
			return [];

		const machines = [];
		if (IsNullOrUndefined(this.data.machines))
			return [];
		for (let id in this.data.machines) {
			machines.push(id);
		}
		return machines;
	}

	// Get the Machines for this User
	getVisiblePlants() {
		if (!this.dataAvailable || this.dataError)
			return [];

		const plants = [];
		if (IsNullOrUndefined(this.data.plants))
			return [];
		for (let id in this.data.plants) {
			plants.push(id);
		}
		return plants;
	}

	// Get the Machines for this User
	getVisibleLines() {
		if (!this.dataAvailable || this.dataError)
			return [];

		const lines = [];
		if (IsNullOrUndefined(this.data.lines))
			return [];
		for (let id in this.data.lines) {
			lines.push(id);
		}
		return lines;
	}

	// Get the Machines with a right for this User
	getVisibleMachinesWithRight(right) {
		if (!this.dataAvailable || this.dataError)
			return [];

		const machines = [];
		if (IsNullOrUndefined(this.data.machines))
			return [];
		for (let id in this.data.machines) {

			if (IsNullOrUndefined(this.data.machines[id].resolvedRights))
				continue;
			if (!this.data.machines[id].resolvedRights.includes(right))
				continue;

			machines.push(id);
		}

		return machines;
	}

	// Get the Machines with a right for this User
	hasVisibleMachineWithRight(right) {
		if (!this.dataAvailable || this.dataError)
			return false;

		if (IsNullOrUndefined(this.data.machines))
			return false;
		for (let id in this.data.machines) {

			if (IsNullOrUndefined(this.data.machines[id].resolvedRights))
				continue;
			if (!this.data.machines[id].resolvedRights.includes(right))
				continue;

			return true;
		}

		return false;
	}

	// Get the last viewed machines
	getLastViewedMachines() {
		if (!this.dataAvailable || this.dataError)
			return [];

		if (IsNullOrUndefined(this.data.lastViewedMachines))
			return [];
		return this.data.lastViewedMachines;
	}

	// Get the last viewed tools
	getLastViewedTools() {
		if (!this.dataAvailable || this.dataError)
			return [];

		if (IsNullOrUndefined(this.data.lastViewedTools))
			return [];
		return this.data.lastViewedTools;
	}

	// Get the last viewed plants
	getLastViewedPlants() {
		if (!this.dataAvailable || this.dataError)
			return [];

		if (IsNullOrUndefined(this.data.lastViewedPlants))
			return [];
		return this.data.lastViewedPlants;
	}

	// Get the last viewed lines
	getLastViewedLines() {
		if (!this.dataAvailable || this.dataError)
			return [];

		if (IsNullOrUndefined(this.data.lastViewedLines))
			return [];
		return this.data.lastViewedLines;
	}

	// Get the language
	getLanguage() {
		if (!this.dataAvailable || this.dataError)
			return "en-us";

		return this.data.language ?? "en-us";
	}

	// Get the unit system
	getUnitSystem() {
		if (!this.dataAvailable || this.dataError)
			return "METRIC";

		return this.data.chosenUnitSystem ?? "METRIC";
	}

	// Get the unit system
	getFlowRateUnitSystem() {
		if (!this.dataAvailable || this.dataError)
			return "M3_S";

		return this.data.flowRateUnitSystem ?? "M3_S";
	}

	// Get the unit system
	getPressureUnitSystem() {
		if (!this.dataAvailable || this.dataError)
			return "BAR";

		return this.data.pressureUnitSystem ?? "BAR";
	}

	// Get the unit system
	getTemperatureUnitSystem() {
		if (!this.dataAvailable || this.dataError)
			return "CELSIUS";

		return this.data.temperatureUnitSystem ?? "CELSIUS";
	}
	// Get the volume-unit system
	getVolumeUnitSystem() {
		if (!this.dataAvailable || this.dataError)
			return "L";

		return this.data.volumeUnitSystem ?? "L";
	}

	// Get the unit system
	getConductivityUnitSystem() {
		if (!this.dataAvailable || this.dataError)
			return "S_M";

		return this.data.conductivityUnitSystem ?? "S_M";
	}

	// Get the volume-unit system
	getAngleUnitSystem() {
		if (!this.dataAvailable || this.dataError)
			return "DEGREE";

		return this.data.angleUnitSystem ?? "DEGREE";
	}

	// Get the volume-unit system
	getMassPerTimeUnitSystem() {
		if (!this.dataAvailable || this.dataError)
			return "METRIC_PER_SECOND";

		return this.data.massPerTimeUnitSystem ?? "METRIC_PER_SECOND";
	}

	// Get the Dashboard
	hasSharedDashboard(id) {
		if (!this.dataAvailable || this.dataError)
			return false;
		if (IsNullOrUndefined(this.data.sharedDashboards))
			return false;
		return !IsNullOrUndefined(this.data.sharedDashboards[id]);
	}

	// Get the Dashboard
	hasDashboard(id) {
		if (!this.dataAvailable || this.dataError)
			return false;
		if (IsNullOrUndefined(this.data.dashboards))
			return false;
		return this.data.dashboards.includes(id);
	}

	// Get the Dashboards
	getDashboardIds() {
		if (!this.dataAvailable || this.dataError)
			return [];
		return this.data.dashboards ?? [];
	}

	isDashboardEditable(dashboardId) {
		if (!this.dataAvailable || this.dataError)
			return false;
		if (IsNullOrUndefined(this.data.sharedDashboards))
			return false;
		if (IsNullOrUndefined(this.data.sharedDashboards[dashboardId]))
			return false;
		return this.data.sharedDashboards[dashboardId] === "EDIT";
	}

	// Get the Dashboards
	getDefaultDashboardID() {
		if (!this.dataAvailable || this.dataError)
			return null;
		return this.data.defaultDesktopDashboard ?? null;
	}

	// Get the Dashboards
	getDefaultMobileDashboardID() {
		if (!this.dataAvailable || this.dataError)
			return null;
		return this.data.defaultMobileDashboard ?? null;
	}

	// Is the header visible?
	isHeaderVisible() {
		if (!this.dataAvailable || this.dataError)
			return true;

		return this.data.showHeader ?? true;
	}

	// Is the footer visible?
	isFooterVisible() {
		if (!this.dataAvailable || this.dataError)
			return true;

		return this.data.showFooter ?? true;
	}

	// Get the user Image or null if not available
	getProfileImage() {
		if (!this.dataAvailable || this.dataError)
			return null;

		return this.data.profileImage ?? null;
	}

	// Get the user name
	getName() {
		if (!this.dataAvailable || this.dataError)
			return "";

		return this.data.name ?? "";
	}

	// Get the desktop navigation mode
	getNavigationToggleMode() {
		if (!this.dataAvailable || this.dataError)
			return "AUTO";

		return this.data.navigationToggleMode ?? "AUTO";
	}

	// Get the desktop navigation mode
	hasSAPCustomerID() {
		if (!this.dataAvailable || this.dataError)
			return false;

		return !IsNullOrUndefinedOrWhitespace(this.data.hasSAPCustomerID);
	}

	// Add the last viewed machines
	addLastViewedMachine(machineID) {
		if (IsNullOrUndefined(this.ID))
			return;

		// Is this already the last viewed?
		if (this.dataAvailable && !this.dataError) {
			if (!IsNullOrUndefined(this.data.lastViewedMachines)) {
				if (this.data.lastViewedMachines.length > 0) {
					if (this.data.lastViewedMachines[0] === machineID)
						return;
				}
			}
		}

		// Inform the User about the State
		const data = {
			userID: this.ID,
			machineID: machineID
		};

		(async () => {
			try {
				await FetchHelper.sendData("/api/User/AddLastViewedMachine", data, this.abortController);
			}
			catch (err) {
				if (err.name === "Abort")
					return;
				else if (err.name === "APIIncompatible") {
					// Added error handling
					(new ErrorManager()).APIIncompatible();
				}
				else {
					// Added error handling
					(new ErrorManager()).internalError();
				}
			}
		})();

		// Have we data which we can directly update?
		if (this.dataAvailable && !this.dataError) {
			// Update the Data
			if (IsNullOrUndefined(this.data.lastViewedMachines))
				this.data.lastViewedMachines = [];

			if (this.data.lastViewedMachines.length > 0) {
				// Remove all duplicates
				this.data.lastViewedMachines = this.data.lastViewedMachines.filter((e) => { return e !== machineID; });
				this.data.lastViewedMachines.unshift(machineID);
			}
			else
				this.data.lastViewedMachines.unshift(machineID);

			if (this.data.lastViewedMachines.length > settings.NumLastViewedMachines)
				this.data.lastViewedMachines.length = settings.NumLastViewedMachines;

			// Inform the user
			this.inform();
		}
	}

	// Add the last viewed tool
	addLastViewedTool(toolID) {
		if (IsNullOrUndefined(this.ID))
			return;

		// Is this already the last viewed?
		if (this.dataAvailable && !this.dataError) {
			if (!IsNullOrUndefined(this.data.lastViewedTools)) {
				if (this.data.lastViewedTools.length > 0) {
					if (this.data.lastViewedTools[0] === toolID)
						return;
				}
			}
		}

		// Inform the User about the State
		const data = {
			userID: this.ID,
			toolID: toolID
		};

		(async () => {
			try {
				await FetchHelper.sendData("/api/User/AddLastViewedTool", data, this.abortController);
			}
			catch (err) {
				if (err.name === "Abort")
					return;
				else if (err.name === "APIIncompatible") {
					// Added error handling
					(new ErrorManager()).APIIncompatible();
				}
				else {
					// Added error handling
					(new ErrorManager()).internalError();
				}
			}
		})();

		// Have we data which we can directly update?
		if (this.dataAvailable && !this.dataError) {
			// Update the Data
			if (IsNullOrUndefined(this.data.lastViewedTools))
				this.data.lastViewedTools = [];

			if (this.data.lastViewedTools.length > 0) {
				// Remove all duplicates
				this.data.lastViewedTools = this.data.lastViewedTools.filter((e) => { return e !== toolID; });
				this.data.lastViewedTools.unshift(toolID);
			}
			else
				this.data.lastViewedTools.unshift(toolID);

			if (this.data.lastViewedTools.length > settings.NumLastViewedTools)
				this.data.lastViewedTools.length = settings.NumLastViewedTools;

			// Inform the user
			this.inform();
		}
	}

	// Add the last viewed plants
	addLastViewedPlant(plantID) {
		if (IsNullOrUndefined(this.ID))
			return;

		// Is this already the last viewed?
		if (this.dataAvailable && !this.dataError) {
			if (!IsNullOrUndefined(this.data.lastViewedPlants)) {
				if (this.data.lastViewedPlants.length > 0) {
					if (this.data.lastViewedPlants[0] === plantID)
						return;
				}
			}
		}

		// Inform the User about the State
		const data = {
			userID: this.ID,
			plantID: plantID
		};

		(async () => {
			try {
				await FetchHelper.sendData("/api/User/AddLastViewedPlant", data, this.abortController);
			}
			catch (err) {
				if (err.name === "Abort")
					return;
				else if (err.name === "APIIncompatible") {
					// Added error handling
					(new ErrorManager()).APIIncompatible();
				}
				else {
					// Added error handling
					(new ErrorManager()).internalError();
				}
			}
		})();

		// Have we data which we can directly update?
		if (this.dataAvailable && !this.dataError) {
			// Update the Data
			if (IsNullOrUndefined(this.data.lastViewedPlants))
				this.data.lastViewedPlants = [];

			if (this.data.lastViewedPlants.length > 0) {
				// Remove all duplicates
				this.data.lastViewedPlants = this.data.lastViewedPlants.filter((e) => { return e !== plantID; });
				this.data.lastViewedPlants.unshift(plantID);
			}
			else
				this.data.lastViewedPlants.unshift(plantID);

			if (this.data.lastViewedPlants.length > settings.NumLastViewedPlants)
				this.data.lastViewedPlants.length = settings.NumLastViewedPlants;

			// Inform the user
			this.inform();
		}
	}

	// Add the last viewed plants
	addLastViewedLine(lineID) {
		if (IsNullOrUndefined(this.ID))
			return;

		// Is this already the last viewed?
		if (this.dataAvailable && !this.dataError) {
			if (!IsNullOrUndefined(this.data.lastViewedLines)) {
				if (this.data.lastViewedLines.length > 0) {
					if (this.data.lastViewedLines[0] === lineID)
						return;
				}
			}
		}

		// Inform the User about the State
		const data = {
			userID: this.ID,
			lineID: lineID
		};

		(async () => {
			try {
				await FetchHelper.sendData("/api/User/AddLastViewedLine", data, this.abortController);
			}
			catch (err) {
				if (err.name === "Abort")
					return;
				else if (err.name === "APIIncompatible") {
					// Added error handling
					(new ErrorManager()).APIIncompatible();
				}
				else {
					// Added error handling
					(new ErrorManager()).internalError();
				}
			}
		})();

		// Have we data which we can directly update?
		if (this.dataAvailable && !this.dataError) {
			// Update the Data
			if (IsNullOrUndefined(this.data.lastViewedLines))
				this.data.lastViewedLines = [];

			if (this.data.lastViewedLines.length > 0) {
				// Remove all duplicates
				this.data.lastViewedLines = this.data.lastViewedLines.filter((e) => { return e !== lineID; });
				this.data.lastViewedLines.unshift(lineID);
			}
			else
				this.data.lastViewedLines.unshift(lineID);

			if (this.data.lastViewedLines.length > settings.NumLastViewedLines)
				this.data.lastViewedLines.length = settings.NumLastViewedLines;

			// Inform the user
			this.inform();
		}
	}

	// Get the reload time
	getAutomaticReloadTime() {
		if (!this.dataAvailable || this.dataError)
			return 0;

		return this.data.automaticReloadTime ?? 0;
	}

	// Does the automatic reload time exist?
	hasAutomaticReloadTime() {
		if (!this.dataAvailable || this.dataError)
			return false;

		return !IsNullOrUndefined(this.data.automaticReloadTime);
	}

	shouldReloadOnAPIIncompatibility() {
		if (!this.dataAvailable || this.dataError)
			return true;

		return this.data.automaticReloadOnAPIIncompatibility ?? true;
	}

	// Get the MachineRightsChangeID
	getResolvedRightsChangeID() {
		if (!this.dataAvailable || this.dataError)
			return null;

		return this.data.resolvedRightsChangeID ?? null;
	}

	// Get the MachineRightsChangeID
	getResolvedMachineRightsChangeID() {
		if (!this.dataAvailable || this.dataError)
			return null;

		return this.data.resolvedMachineRightsChangeID ?? null;
	}

	// Get the LineRightsChangeID
	getResolvedLineRightsChangeID() {
		if (!this.dataAvailable || this.dataError)
			return null;

		return this.data.resolvedLineRightsChangeID ?? null;
	}

	// Get the PlantRightsChangeID
	getResolvedPlantRightsChangeID() {
		if (!this.dataAvailable || this.dataError)
			return null;

		return this.data.resolvedPlantRightsChangeID ?? null;
	}

	// Get the MachineRightsChangeID
	getResolvedSharedDashboardsChangeID() {
		if (!this.dataAvailable || this.dataError)
			return null;

		return this.data.resolvedSharedDashboardsChangeID ?? null;
	}

	// Register for changes of the user data
	register(func) {
		// Integrate the key
		const key = this.keys.generate();
		this.functions.set(key, func);

		AsyncExecution(() => { this.informKey(key); }, this.abortController.signal);

		return key;
	}

	// Unregister
	unregister(key) {
		if (this.functions.delete(key))
			this.keys.release(key);
	}
}