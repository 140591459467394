/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is the main js for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/custom.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import InitStringFormat from './BusinessLogic/Misc/StringFormat.js'
//import registerServiceWorker from './registerServiceWorker';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import zh from 'date-fns/locale/zh-CN';
import { registerLocale } from "react-datepicker";

// Default functionality
InitStringFormat();

// Register supported locales
registerLocale('de-DE', de);
registerLocale('en-US', en);
registerLocale('zh-CN', zh);

// This is the entry-point in the application
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<App />);