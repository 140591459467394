/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 105.83333 270.93333"
            className="svgIcon"
        >
            <path d="M 0,135.4667 84.061835,218.62166 105.83327,196.97078 43.58806,135.4667 105.83327,73.886625 84.061835,52.311735 Z" />
        </svg>
    );
}