/********************************************************************************
*
* (c) 2021 - Gehring Technologies GmbH
*
* This is a class to manage tokens
*
* @author: Julian Saile (Julian.Saile@gehring.de)
*
*********************************************************************************/
import GlobalAppData from "GlobalAppData";
import IsNullOrUndefined, { IsNullOrUndefinedOrWhitespace } from "./Utility";

let instance;
export default class TokenManager {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.pendingRequests = [];

		this.destroy = this.destroy.bind(this);
		this.request = this.request.bind(this);
		this.renewToken = this.renewToken.bind(this);
		this.tokenChanged = this.tokenChanged.bind(this);
		this.isTokenValid = this.isTokenValid.bind(this);

		this.tokenChangedKey = (new GlobalAppData()).registerTokenChanged(() => {
			this.tokenChanged();
		});

		if (!this.isTokenValid()) {
			// Request new access token
			(new GlobalAppData()).requestAccessToken();
		}
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance))
			instance.destroy();
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	destroy() {
		if (!IsNullOrUndefined(this.tokenChangedKey)) {
			(new GlobalAppData()).unregister(this.tokenChangedKey);
			this.tokenChangedKey = null;
		}
	}

	isTokenValid() {
		const token = (new GlobalAppData()).getToken();
		if (!IsNullOrUndefinedOrWhitespace(token))
			return true;
		return false;
	}

	request(func) {
		if (this.isTokenValid()) {
			const token = (new GlobalAppData()).getToken();
			try {
				func(token);
			}
			catch (err) {
				console.error("Error during processing of function");
			}
		}
		else
			this.pendingRequests.push(func);
	}

	renewToken() {
		if (!this.isTokenValid())
			return;

		// Request new access token
		(new GlobalAppData()).requestAccessToken();
	}

	tokenChanged() {
		if (!this.isTokenValid())
			return;

		const oldPendingRequests = this.pendingRequests;
		this.pendingRequests = [];

		oldPendingRequests.forEach((e) => {
			if (this.isTokenValid()) {
				const token = (new GlobalAppData()).getToken();
				try {
					e(token);
				} catch (err) {
					console.error("Error during processing of registered function");
				}
			}
			else {
				this.pendingRequests.push(e);
			}
		});
	}
}