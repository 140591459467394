/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This Manager lists all available Widgets and their modules. This is the entry for all widget-based operations
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

export default class ServerResponseException extends Error {
    constructor(response, message) {
        super(message);
        this.name = "ServerResponse";
        this.response = response;

        this.getResponse = this.getResponse.bind(this);
    }

    getResponse() { return this.response; }
}