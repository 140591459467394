/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This Manager lists all available Widgets and their modules. This is the entry for all widget-based operations
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import UniqueIDGenerator from '../Misc/UniqueIDGenerator.js';
import ErrorManager from "../Misc/ErrorManager.js";
import IsNullOrUndefined from "../Misc/Utility";
import settings from 'Settings';
import FetchHelper from "../Misc/FetchHelper.js";
import { random } from '../../random';

let instance;
export default class ExtensionManager {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.keyGen = new UniqueIDGenerator();
		this.abortController = new AbortController();

		this.functions = new Map();
		this.listFunctions = new Map();

		this.dataAvailable = false;
		this.dataError = false;
		this.data = new Map();

		this.interval = null;

		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.inform = this.inform.bind(this);
		this.getList = this.getList.bind(this);
		this.destroy = this.destroy.bind(this);
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance)) {
			instance.destroy();
		}

		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	destroy() {
		if (!IsNullOrUndefined(instance.interval)) {
			clearInterval(instance.interval);
			instance.interval = null;
		}

		this.abortController.abort();
	}

	updateData() {
		(async () => {
			try {
				// Call the server
				const json = await FetchHelper.fetchData("/api/Extension/list?random=" + random, {}, this.abortController);

				this.data.clear();
				for (let i in json.extensions) {
					this.data.set(i, json.extensions[i]);
				}
				this.dataAvailable = true;
				this.dataError = false;

				// Inform the functions
				this.inform();
			} catch (err) {
				if (err.name === "Abort")
					return;
				else if (err.name === "APIIncompatible") {
					// Added error handling
					(new ErrorManager()).APIIncompatible();
				}
				else {
					// Added error handling
					(new ErrorManager()).dataReceiveError();
				}

				this.dataAvailable = true;
				this.dataError = true;

				// Inform the functions
				this.inform();
			}
		})();
	}

	getList() {
		const l = [];
		this.data.forEach((v, k) => {
			l.push({
				...v,
				id: k
			});
		});
		return l;
	}

	inform() {
		if (!this.dataAvailable)
			return;

		// Inform all list functions
		let l = [];
		if (!this.dataError)
			l = this.getList();
		this.listFunctions.forEach((e) => {
			e(l, this.dataError);
		});

		// Inform all specific requests
		this.functions.forEach((v, k) => {
			// Get the extension
			let d = null;
			if (!this.dataError) {
				d = this.data.get(k);
			}

			// Inform all values
			v.forEach((e) => {
				e(d, this.dataError);
			});
		});
	}

	// request the complete list of data
	registerList(func) {
		const key = this.keyGen.generate();

		if (this.listFunctions.size === 0 && this.functions.size === 0) {
			// Update the Data
			this.updateData();

			// Set the update interval
			this.interval = setInterval(() => {
				this.updateData();
			}, parseInt(settings.LongDataUpdateTime));
		}

		// Add the Funciton to the registered functions
		this.listFunctions.set(key, func);

		// Inform the Function
		if (this.dataAvailable) {
			let l = [];
			if (!this.dataError)
				l = this.getList();
			func(l, this.dataError);
		}

		return key;
	}

	// Request a specific Extension information
	register(id, func) {
		const key = this.keyGen.generate();

		if (this.listFunctions.size === 0 && this.functions.size === 0) {
			// Update the Data
			this.updateData();

			// Set the update interval
			this.interval = setInterval(() => {
				this.updateData();
			}, parseInt(settings.LongDataUpdateTime));
		}

		// Add the Funciton to the registered functions
		let m = this.functions.get(id);
		if (IsNullOrUndefined(m)) {
			m = new Map();
			this.functions.set(id, m);
		}

		m.set(key, func);

		// Inform the Function
		if (this.dataAvailable) {
			// Get the extension
			let d = null;
			if (!this.dataError) {
				d = this.data.get(id);
			}

			func(d, this.dataError);
		}

		return key;
	}

	// Unregister
	unregister(key) {
		// find the element
		this.listFunctions.delete(key);
		let toDelete = [];
		this.functions.forEach((v, k) => {
			v.delete(key);
			if (v.size === 0)
				toDelete.push(k);
		});
		toDelete.forEach((e) => {
			this.functions.delete(e);
		});

		this.keyGen.release(key);

		if (this.listFunctions.size === 0 && this.functions.size === 0) {
			if (!IsNullOrUndefined(this.interval)) {
				clearInterval(this.interval);
				this.interval = null;
			}
		}
	}
}