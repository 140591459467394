/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 270.93333 270.93333"
            className="svgIcon"
        >
            <line
                x1="253.10799"
                x2="17.82535"
                y1="17.82535"
                y2="253.10799"
                fill="none"
                strokeWidth="50.4177" />
            <line
                x1="17.82535"
                x2="253.10799"
                y1="17.82535"
                y2="253.10799"
                fill="none"
                strokeWidth="50.4177" />
        </svg>
    );
}