/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert from the metric unit system to the user unit system
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import IsNullOrUndefined from '../Misc/Utility.js';
import configureMeasurements, { angle } from 'convert-units';

var instance;
export default class AngleUnitSystemConverter {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.convert = configureMeasurements({ angle });

		// UnitSystem
		this.unitSystemDefinition = "degree";
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	setDefaultUnitSystem() {
		this.setUnitSystem("degree");
	}

	// Set the UnitSystem to use. Possible values are "metric" and "imperial"
	setUnitSystem(_unitSystem) {
		const unitSystem = _unitSystem.toLowerCase();

		switch (unitSystem) {
			case 'degree':
				this.unitSystemDefinition = "degree";
				break;
			case 'radians':
				this.unitSystemDefinition = "radians";
				break;
			default:
				this.unitSystemDefinition = "degree";
				if (process.env.NODE_ENV === 'development') {
					console.warn("UnitSystem '" + unitSystem + "' is invalid. Using default.");
				}
				break;
		}
	}

	/** Format the seconds to the selected unit system (Best choice)
	 * @param value The Value to use
	 * @param targetUnit The target unit system
	 * @param sourceUnit The source unit system. Defaults to "deg"
	 * @return { value, unit }
	 */
	format(value, targetUnit, sourceUnit) {
		let source = "deg";
		if (!IsNullOrUndefined(sourceUnit))
			source = sourceUnit;

		let negativ = false;
		if (value < 0)
			negativ = true;

		let target = targetUnit;
		if (IsNullOrUndefined(target)) {
			if (this.unitSystemDefinition === "degree") {
				target = "deg";
			}
			else if (this.unitSystemDefinition === "radians") {
				target = "rad";
			}
		}

		if (source === target)
			return { value: value, unit: target };

		const r = this.convert(Math.abs(value)).from(source).to(target);
		return { value: negativ ? r * -1.0 : r, unit: target };
	}

	/**
	 * Get the default unit
	 */
	getDefaultUnit() {
		if (this.unitSystemDefinition === "degree") {
			return "deg";
		}
		else if (this.unitSystemDefinition === "radians") {
			return "rad";
		}
		return "deg";
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 */
	humanizeShortUnit(unit) {
		switch (unit) {
			case "deg":
				return "°";
			case "rad":
				return "rad";

			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 * @param singular Get the singular unit
	 */
	humanizeUnit(unit, singular) {
		switch (unit) {
			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}
}