/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the basic template for autorization pages
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { Link } from "react-router-dom";
import Localizer from '../../BusinessLogic/Misc/Localizer'
import ErrorManager from '../../BusinessLogic/Misc/ErrorManager'
import FetchHelper from "../../BusinessLogic/Misc/FetchHelper.js";
import GlobalAppData from "GlobalAppData";

export default class CookieConsent extends React.Component {
	constructor(props) {
		super(props);

		this.abortController = new AbortController();

		this.handleAccept = this.handleAccept.bind(this);

		this.state = {
			show: false,
			cookie: ""
		};
	}
	static defaultProps = {
	}

	handleAccept() {
		// Set the cookie
		document.cookie = this.state.cookie;

		// Call the server. This is a fire and forget.
		(async () => {
			try {
				await FetchHelper.sendData("/api/CookieConsent/Accept", {}, this.abortController);
			}
			catch (err) {
				if (err.name === "Abort")
					return;
				else if (err.name === "APIIncompatible") {
					// Added error handling
					(new ErrorManager()).APIIncompatible();
				}
				else {
					// Added error handling
					(new ErrorManager()).internalError();
				}
			}
		})();

		this.setState({ show: false });
	}

	componentDidMount() {
		if (!new GlobalAppData().isWebView()) {
			// Call the server. This is a fire and forget.
			(async () => {
				try {
					const json = await FetchHelper.fetchData("/api/CookieConsent/GetData", {}, this.abortController);
					this.setState({
						show: !json.accepted,
						cookie: json.cookie
					});
				}
				catch (err) {
					if (err.name === "Abort")
						return;
					else if (err.name === "APIIncompatible") {
						// Added error handling
						(new ErrorManager()).APIIncompatible();
					}
					else {
						// Added error handling
						(new ErrorManager()).dataReceiveError();
					}
				}
			})();
		}
		else {
			this.setState({
				show: false
			});
		}
	}

	componentWillUnmount() {
		// Cancel all pending requests
		this.abortController.abort();
	}

	render() {
		if (!this.state.show)
			return <></>;

		return (
			<>
				<div className="collapse show cookie-banner" role="alert">
					<div className="container text-center pt-3">
						<p>
							{(new Localizer()).get("This Application uses Cookies. For more information see the")} <Link className="cookie-banner__link" to="/Privacy">{(new Localizer()).get("Privacy Policy")}</Link> {(new Localizer()).get("and the")} <Link className="cookie-banner__link" to="/Terms">{(new Localizer()).get("Terms and conditions")}</Link>.
						</p>
						<p>
							<button type="button" className="btn btn-outline-light" onClick={this.handleAccept}>{(new Localizer()).get("Accept")}</button>
						</p>
					</div>
				</div>
			</>
		);
    }
}