/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 166.30994 270.93333"
            className="svgIcon"
        >
            <path d="M 83.154965,188.38334 166.30993,104.32151 144.65905,82.55006 83.154965,144.79528 21.57489,82.55006 0,104.32151 Z" />
        </svg>
    );
}