/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import UniqueIDGenerator from "../../Misc/UniqueIDGenerator";

export default class BaseAreaDataContainer {
	constructor() {
		this.keyGen = new UniqueIDGenerator();

		this.areas = [];

		this.destroy = this.destroy.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.empty = this.empty.bind(this);
		this.update = this.update.bind(this);
		this.create = this.create.bind(this);
	}

	destroy() {
		this.areas.forEach((e) => {
			e.destroy();
		});
		this.areas = [];
	}

	create(startTime, endTime, func) {
		return null;
	}

	// Register a specific time. The function is called if the data is available
	register(startTime, endTime, func) {
		const key = this.keyGen.generate();

		let d = this.create(startTime, endTime, func);
		this.areas.push({ key: key, data: d });

		return key;
	}

	update(key, startTime, endTime) {
		const index = this.areas.findIndex((e) => { return e.key === key; })
		if (index >= 0) {
			this.areas[index].data.update(startTime, endTime);
		}
	}

	unregister(key) {
		// find the element
		const index = this.areas.findIndex((e) => { return e.key === key; })
		if (index >= 0) {
			// Data available. Delete.
			this.areas = this.areas.filter((e, i, a) => {
				if (i === index) {
					a[i].data.destroy();
					return false;
				}
				return true;
			});
		}

		this.keyGen.release(key);
	}

	empty() {
		return this.areas.length === 0;
	}
}