/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This Manager lists all available Widgets and their modules. This is the entry for all widget-based operations
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

export default class AbortException extends Error {
    constructor(message) {
        super(message);
        this.name = "Abort";
    }
}