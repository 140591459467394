/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import settings from 'Settings';
import NotificationService from '../Misc/NotificationService.js';
import IsNullOrUndefined from "../Misc/Utility.js";
import UniqueIDGenerator from '../Misc/UniqueIDGenerator.js';
import CurrentUser from "./CurrentUser.js";
import BaseList from './Intern/BaseList.js';
import GroupedFetch from './Intern/GroupedFetch.js';
import BaseData from './Intern/BaseData.js';
import DataProvider from './Intern/DataProvider.js';
import BaseArea from "./Intern/BaseArea.js";
import BaseAreaData from "./Intern/BaseAreaData.js";
import BaseAreaDataContainer from "./Intern/BaseAreaDataContainer.js";
import BaseAutomaticUpdateDataContainer from "./Intern/BaseAutomaticUpdateDataContainer.js";
import UncachedGroupedFetch from './Intern/UncachedGroupedFetch.js';

class List extends BaseList {
	constructor(machineIds, firstIndex, count, sortField, sortOrder, filters, func) {
		super("/api/Error/list", firstIndex, count, sortField, sortOrder, filters, func);

		this.init = this.init.bind(this);
		this.release = this.release.bind(this);
		this.transformFilter = this.transformFilter.bind(this);
		this.customerData = this.customerData.bind(this);

		this.currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
		this.currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

		this.currentUserKey = null;
		this.keys = [];

		this.machineIds = machineIds;
	}

	init() {
		super.init();

		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
			const currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

			if (this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID &&
				this.currentUserRightsChangeID === currentUserRightsChangeID)
				return;

			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
			this.currentUserRightsChangeID = currentUserRightsChangeID;

			// Update the data
			this.reload();
		});

		this.machineIds.forEach((element) => {
			this.keys.push((new NotificationService()).registerMachineErrorAdded(element, (machineId, errorId) => {
				// Update the data
				this.reload();
			}));
		});
	}

	release() {
		this.keys.forEach((element) => {
			(new NotificationService()).unregister(element);
		});
		this.keys = [];

		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}

		super.release();
	}

	transformFilter() {
		// Create the filters
		let codeFilter = null;
		let statusFilter = null;
		let timeFilter = null;
		let severityFilter = null;
		let categoryFilter = null;
		let sourceFilter = null;

		for (const fieldId in this.filters) {
			const val = this.filters[fieldId];

			if (fieldId === "source") {
				if (val === '-')
					sourceFilter = { textFilter: null };
				else
					sourceFilter = { textFilter: val };
			}
			else if (fieldId === "code") {
				if (IsNullOrUndefined(val))
					codeFilter = { start: null, end: null };
				else {
					let start = null;
					let end = null;
					if (IsNullOrUndefined(val.start))
						start = {
							code: val.start.major,
							minorCode: val.start.minor,
							patchCode: val.start.patch,
							internCode: val.start.intern
						};
					if (IsNullOrUndefined(val.end))
						end = {
							code: val.end.major,
							minorCode: val.end.minor,
							patchCode: val.end.patch,
							internCode: val.end.intern
						};
					codeFilter = {
						start: start,
						end: end
					};

				}
			}
			else if (fieldId === "status") {
				if (!IsNullOrUndefined(val)) {
					if (val === "UNKNOWN")
						statusFilter = { status: null };
					else
						statusFilter = { status: val };
				}
			}
			else if (fieldId === "time") {
				timeFilter = { startTime: val.start, endTime: val.end };
			}
			else if (fieldId === "category") {
				if (!IsNullOrUndefined(val)) {
					if (val === "UNKNOWN")
						categoryFilter = { category: null };
					else
						categoryFilter = { category: val };
				}
			}
			else if (fieldId === "severity") {
				if (!IsNullOrUndefined(val)) {
					if (val === "UNKNOWN")
						severityFilter = { severity: null };
					else
						severityFilter = { severity: val };
				}
			}
		}
		// Create the data
		return {
			severityFilter: severityFilter,
			categoryFilter: categoryFilter,
			timeFilter: timeFilter,
			statusFilter: statusFilter,
			errorCodeFilter: codeFilter,
			sourceFilter: sourceFilter
		};
	}

	customerData() {
		// Create the data
		return {
			machineIds: this.machineIds
		};
	}
}

class Area extends BaseArea {
	constructor(machineId, startTime, endTime, func, fetch: UncachedGroupedFetch) {
		super({ machineId: machineId }, startTime, endTime, func, fetch);

		this.machineId = machineId;

		this.initUpdateListener = this.initUpdateListener.bind(this);
		this.releaseUpdateListener = this.releaseUpdateListener.bind(this);

		this.currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();

		this.notificationKey = null;
		this.currentUserKey = null;

		this.init();
	}

	initUpdateListener() {
		// Notification
		this.notificationKey = (new NotificationService()).registerMachineErrorAdded(this.machineId, (machineId, errorId) => {
			this.reload();
		});

		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();

			if (this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID)
				return;

			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;

			// Update the data
			this.reload();
		});
	}

	releaseUpdateListener() {
		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}

		if (this.notificationKey !== null) {
			(new NotificationService()).unregister(this.notificationKey);
			this.notificationKey = null;
		}
	}
}

class AreaData extends BaseAreaData {
	constructor(machineId, startTime, endTime, func, fetch: UncachedGroupedFetch, dataProvider) {
		super(func, dataProvider);

		this.machineId = machineId;

		this.init = this.init.bind(this);
		this.release = this.release.bind(this);
		this.mapDataToIds = this.mapDataToIds.bind(this);
		this.update = this.update.bind(this);

		this.init(startTime, endTime, fetch);
	}

	init(startTime, endTime, fetch) {
		this.area = new Area(this.machineId, startTime, endTime, this.areaChanged, fetch);
	}

	release() {
		this.area.destroy();
		this.area = null;
	}

	update(startTime, endTime) {
		this.area.update(startTime, endTime);
	}

	mapDataToIds(data) {
		return data.map((d) => { return { id: d[this.idField], machineId: this.machineId }; });
	}
}

class AreaDataContainer extends BaseAreaDataContainer {
	constructor(machineId, fetch: UncachedGroupedFetch, dataProvider) {
		super();

		this.machineId = machineId;
		this.fetch = fetch;
		this.dataProvider = dataProvider;

		this.create = this.create.bind(this);
	}

	create(startTime, endTime, func) {
		return new AreaData(this.machineId, startTime, endTime, func, this.fetch, this.dataProvider);
	}
}

class AutomaticUpdateDataContainer extends BaseAutomaticUpdateDataContainer {
	constructor(machineId, fetch: UncachedGroupedFetch, dataProvider) {
		super();

		this.machineId = machineId;
		this.fetch = fetch;
		this.dataProvider = dataProvider;

		this.create = this.create.bind(this);
	}

	create(startTime, endTime, func) {
		return new AreaData(this.machineId, startTime, endTime, func, this.fetch, this.dataProvider);
	}
}

class Data extends BaseData {
	constructor(errorId, machineId, fetch: GroupedFetch) {
		super({ errorId: errorId, machineId: machineId }, fetch, errorId + "_" + machineId);

		this.initUpdateListener = this.initUpdateListener.bind(this);
		this.releaseUpdateListener = this.releaseUpdateListener.bind(this);

		this.currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();
		this.currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();

		this.currentUserKey = null;

		this.init("ErrorData");
	}

	initUpdateListener() {
		// Register for a change of the rights ID
		this.currentUserKey = (new CurrentUser()).register((available, error) => {
			// Changed?
			const currentUserRightsChangeID = (new CurrentUser()).getResolvedRightsChangeID();
			const currentUserMachineRightsChangeID = (new CurrentUser()).getResolvedMachineRightsChangeID();

			if (this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID &&
				this.currentUserRightsChangeID === currentUserRightsChangeID)
				return;

			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
			this.currentUserRightsChangeID = currentUserRightsChangeID;

			// Update the data
			this.reload();
		});
	}

	releaseUpdateListener() {
		if (this.currentUserKey !== null) {
			(new CurrentUser()).unregister(this.currentUserKey);
			this.currentUserKey = null;
		}
	}
}

class DataList {
	constructor(machineId: string, fetch: GroupedFetch, parent) {
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.empty = this.empty.bind(this);

		this.machineId = machineId;
		this.fetch = fetch;
		this.parent = parent;

		this.keys = new Map();
		this.keyGen = new UniqueIDGenerator();
		this.data = new Map();
	}

	destroy() {
		this.data.forEach((v, k) => {
			v.data.destroy();
		});
		this.data.clear();
		this.keys.clear();
	}

	register(id, func) {
		let d = this.data.get(id);
		if (IsNullOrUndefined(d)) {
			d = {
				data: new Data(id, this.machineId, this.fetch),
				timeout: null
			};
			this.data.set(id, d);
		}

		if (!IsNullOrUndefined(d.timeout)) {
			clearTimeout(d.timeout);
			d.timeout = null;
		}

		const subKey = d.data.register(func);

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { key: subKey, id: id });

		return key;
	}

	unregister(key) {
		const obj = this.keys.get(key);
		if (IsNullOrUndefined(obj))
			return;

		const d = this.data.get(obj.id);
		if (IsNullOrUndefined(d))
			return;

		d.data.unregister(obj.key);

		// Free the key
		this.keys.delete(key);
		this.keyGen.release(key);

		if (d.data.empty()) {
			if (IsNullOrUndefined(d.timeout)) {
				d.timeout = setTimeout(() => {
					d.timeout = null;

					if (!d.data.empty())
						return;
					d.data.destroy();
					this.data.delete(obj.id);

					this.parent.addCheckDelete(this.machineId);
				}, settings.LocalDataHoldTime);
			}
		}
	}

	empty() {
		return this.data.size === 0;
	}
}

class ErrorDataProvider extends DataProvider {
	constructor() {
		super();

		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
	}

	register(id, func) {
		return (new ErrorData()).register(id.machineId, id.id, func);
	}
	unregister(key) {
		return (new ErrorData()).unregister(key);
	}
}

let instance;
export default class ErrorData {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.fetch = new GroupedFetch("/api/Error/GetData");
		this.timeFetch = new UncachedGroupedFetch("/api/Error/GetTime");
		this.dataProvider = new ErrorDataProvider();

		this.keys = new Map();
		this.keyGen = new UniqueIDGenerator();

		this.machineArray = new Map();
		this.listArray = new Map();

		this.destroy = this.destroy.bind(this);
		this.checkDelete = this.checkDelete.bind(this);
		this.addCheckDelete = this.addCheckDelete.bind(this);
		this.register = this.register.bind(this);
		this.registerAutomaticUpdate = this.registerAutomaticUpdate.bind(this);
		this.registerArea = this.registerArea.bind(this);
		this.updateArea = this.updateArea.bind(this);
		this.unregister = this.unregister.bind(this);
		this.list = this.list.bind(this);
		this.updateList = this.updateList.bind(this);
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance)) {
			instance.destroy();
		}

		instance = null;
	}

	destroy() {
		this.listArray.forEach((v, k) => {
			v.release();
			v.destroy();
		});

		this.machineArray.forEach((v, k) => {
			if (!IsNullOrUndefined(v.au))
				v.au.destroy();
			if (!IsNullOrUndefined(v.area))
				v.area.destroy();
			if (!IsNullOrUndefined(v.data))
				v.data.destroy();

			if (!IsNullOrUndefined(v.releaseTimeoutKey)) {
				clearTimeout(v.releaseTimeoutKey);
				v.releaseTimeoutKey = null;
			}
		});

		this.machineArray.clear();
		this.listArray.clear();
		this.keys.clear();

		if (!IsNullOrUndefined(this.dataProvider))
			this.dataProvider.destroy();
		this.dataProvider = null;

		if (!IsNullOrUndefined(this.fetch))
			this.fetch.destroy();
		this.fetch = null;
		if (!IsNullOrUndefined(this.timeFetch))
			this.timeFetch.destroy();
		this.timeFetch = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	// Register
	register(machineId, errorId, func) {
		let m = this.machineArray.get(machineId);
		if (IsNullOrUndefined(m)) {
			m = { data: null, au: null, area: null, releaseTimeoutKey: null };
			this.machineArray.set(machineId, m);
		}

		if (IsNullOrUndefined(m.data)) {
			let data = new DataList(machineId, this.fetch, this);
			m.data = data;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Register the Function
		const subKey = m.data.register(errorId, func);

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: 1, key: subKey, machineId: machineId });

		return key;
	}

	// Register for the current dataset
	registerAutomaticUpdate(machineId, timeRange, func) {
		let m = this.machineArray.get(machineId);
		if (IsNullOrUndefined(m)) {
			m = { data: null, au: null, area: null, releaseTimeoutKey: null };
			this.machineArray.set(machineId, m);
		}

		if (IsNullOrUndefined(m.au)) {
			let au = new AutomaticUpdateDataContainer(machineId, this.timeFetch, this.dataProvider);
			m.au = au;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Register the Function
		const subKey = m.au.register(timeRange, func);

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: 2, key: subKey, machineId: machineId });

		return key;
	}

	// Register for the current dataset
	registerArea(machineId, startTime, endTime, func) {
		let m = this.machineArray.get(machineId);
		if (IsNullOrUndefined(m)) {
			m = { data: null, au: null, area: null, releaseTimeoutKey: null };
			this.machineArray.set(machineId, m);
		}

		if (IsNullOrUndefined(m.area)) {
			let area = new AreaDataContainer(machineId, this.timeFetch, this.dataProvider);
			m.area = area;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Register the Function
		const subKey = m.area.register(startTime, endTime, func);

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: 3, key: subKey, machineId: machineId });

		return key;
	}

	// Update for the current dataset
	updateArea(key, startTime, endTime) {
		const k = this.keys.get(key);
		if (IsNullOrUndefined(k))
			return;
		// Is this a area
		if (k.type !== 1)
			return;

		// Get the Machine
		let m = this.machineArray.get(k.machineId);
		if (IsNullOrUndefined(m))
			return;

		if (IsNullOrUndefined(m.area))
			return;

		// Register the Function
		m.area.update(k.key, startTime, endTime);
	}

	// Register for the current dataset
	list(machineIds, firstIndex, count, sortField, sortOrder, filters, func) {
		let data = new List(machineIds, firstIndex, count, sortField, sortOrder, filters, func);
		data.init();

		// Integrate the key
		const key = this.keyGen.generate();
		this.listArray.set(key, data);

		this.keys.set(key, { type: 0 });

		return key;
	}

	updateList(key, firstIndex, count, sortField, sortOrder, filters) {
		const m = this.listArray.get(key);
		if (IsNullOrUndefined(m))
			return true;

		return m.update(firstIndex, count, sortField, sortOrder, filters);
	}

	// Unregister
	unregister(key) {
		const obj = this.keys.get(key);
		if (IsNullOrUndefined(obj))
			return;

		if (obj.type === 0) {
			const m = this.listArray.get(key);
			if (IsNullOrUndefined(m))
				return;

			m.release();
			m.destroy();
			this.listArray.delete(key);
		}
		else {
			const m = this.machineArray.get(obj.machineId);
			if (IsNullOrUndefined(m))
				return;

			switch (obj.type) {
				case 1:
					{
						if (!IsNullOrUndefined(m.data))
							m.data.unregister(obj.key);
					} break;
				case 2:
					{
						if (!IsNullOrUndefined(m.au))
							m.au.unregister(obj.key);
					} break;
				case 3:
					{
						if (!IsNullOrUndefined(m.area))
							m.area.unregister(obj.key);
					} break;
				default:
					break;
			}

			// Release
			if (IsNullOrUndefined(m.releaseTimeoutKey)) {
				m.releaseTimeoutKey = setTimeout(() => { m.releaseTimeoutKey = null; this.checkDelete(obj.machineId); }, settings.LocalDataHoldTime);
			}
		}

		// Free the key
		this.keys.delete(key);
		this.keyGen.release(key);
	}

	addCheckDelete(machineId) {
		const m = this.machineArray.get(machineId);
		if (IsNullOrUndefined(m))
			return;

		if (IsNullOrUndefined(m.releaseTimeoutKey)) {
			m.releaseTimeoutKey = setTimeout(() => { m.releaseTimeoutKey = null; this.checkDelete(machineId); }, settings.LocalDataHoldTime);
		}
	}

	// Check the release
	checkDelete(machineId) {
		const m = this.machineArray.get(machineId);
		if (IsNullOrUndefined(m))
			return;

		// Destroy the Object
		if (!IsNullOrUndefined(m.data)) {
			if (m.data.empty()) {
				m.data.destroy();
				m.data = null;
			}
		}
		if (!IsNullOrUndefined(m.au)) {
			if (m.au.empty()) {
				m.au.destroy();
				m.au = null;
			}
		}
		if (!IsNullOrUndefined(m.area)) {
			if (m.area.empty()) {
				m.area.destroy();
				m.area = null;
			}
		}

		if (IsNullOrUndefined(m.data) && IsNullOrUndefined(m.au) && IsNullOrUndefined(m.area))
			this.machineArray.delete(machineId);
	}
}