/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { Link } from "react-router-dom";
import settings from 'Settings';
import CurrentUser from "../../../BusinessLogic/DataManagement/CurrentUser.js";
import MachineHelper from "../../../BusinessLogic/Misc/MachineHelper.js";
import ToolData from "../../../BusinessLogic/DataManagement/ToolData.js";
import PlantData from "../../../BusinessLogic/DataManagement/PlantData.js";
import LineData from "../../../BusinessLogic/DataManagement/LineData.js";
import Localizer from '../../../BusinessLogic/Misc/Localizer'
import IsNullOrUndefined, { IsNullOrUndefinedOrWhitespace } from "../../../BusinessLogic/Misc/Utility.js";
import Rights from "../../../BusinessLogic/Misc/Rights.js";
import Scrollbar from "../../../Components/Util/Scrollbar.js";

const NAVIGATIONTOGGLEMODE = {
	// auto means hidden if screen up to sm else visible
	auto: "auto",
	visible: "visible",
	hidden: "hidden"
};

export default class MainNav extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.handleSearch = this.handleSearch.bind(this);
		this.handleSearchToggle = this.handleSearchToggle.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.handleEvent = this.handleEvent.bind(this);
		this.handleMachineDataChanged = this.handleMachineDataChanged.bind(this);
		this.handleToolDataChanged = this.handleToolDataChanged.bind(this);
		this.handlePlantDataChanged = this.handlePlantDataChanged.bind(this);
		this.handleLineDataChanged = this.handleLineDataChanged.bind(this);
		this.handleLastViewedToolsToggle = this.handleLastViewedToolsToggle.bind(this);
		this.handleLastViewedMachinesToggle = this.handleLastViewedMachinesToggle.bind(this);
		this.handleLastViewedPlantsToggle = this.handleLastViewedPlantsToggle.bind(this);
		this.handleLastViewedLinesToggle = this.handleLastViewedLinesToggle.bind(this);

		this.userKey = null;
		this.machineKeys = new Map();
		this.toolKeys = new Map();
		this.plantKeys = new Map();
		this.lineKeys = new Map();

		this.ref = React.createRef();

		this.state = {
			userID: "",

			lastViewedMachines: [],
			lastViewedTools: [],
			lastViewedPlants: [],
			lastViewedLines: [],

			lastViewedMachinesVisible: false,
			lastViewedToolsVisible: false,
			lastViewedPlantsVisible: false,
			lastViewedLinesVisible: false,

			// Rights
			Right_Order_Enable: true,
			Right_Dashboard_View: false,
			Right_Machine_EnableOverview: false,
			Right_Machine_EnableLastViewedMachines: false,
			Right_Tool_EnableOverview: false,
			Right_Tool_EnableTypeOverview: false,
			Right_Tool_EnableLastViewedTools: false,
			Right_User_ViewAllUsers: false,
			Right_User_ViewUsers: false,
			Right_Plant_View: false,
			Right_Plant_ViewAll: false,
			Right_Plant_Create: false,
			Right_Line_View: false,
			Right_Line_ViewAll: false,
			Right_Line_Create: false,
			Right_Group_ViewAllGroups: false,
			Right_Group_ViewGroups: false,
			Right_Message_Enable: false,
			Right_Update_View: false,
			Right_Machine_AddRemove: false,
			Right_Extras_CreatePieceSerialNumber: false
		};
	}
	static defaultProps = {
		// Navigation collapsed means that the navigation is not in the normal state (visible in hidden mode or small in visible mode)
		navigationCollapsed: false,
		navigationToggleMode: NAVIGATIONTOGGLEMODE.auto,
		navState: "",
		onNavigationCollapse: () => { },
		onNavigationShow: () => { },
		onShowSearch: () => { },
		search: (text) => { }
	}

	static get NAVIGATIONTOGGLEMODE() {
		return NAVIGATIONTOGGLEMODE;
	}

	toggleMenu(event) {
		event.preventDefault();

		if (this.props.navigationCollapsed)
			this.props.onNavigationShow();
		else
			this.props.onNavigationCollapse();
	}

	handleSearchToggle(event) {
		event.preventDefault();

		this.props.onShowSearch();
	}

	handleLastViewedMachinesToggle(event) {
		event.preventDefault();

		this.setState((prevState, props) => {
			return {
				lastViewedMachinesVisible: !prevState.lastViewedMachinesVisible,
				lastViewedToolsVisible: false,
				lastViewedPlantsVisible: false,
				lastViewedLinesVisible: false,
			};
		});
	}

	handleLastViewedToolsToggle(event) {
		event.preventDefault();

		this.setState((prevState, props) => {
			return {
				lastViewedMachinesVisible: false,
				lastViewedToolsVisible: !prevState.lastViewedToolsVisible,
				lastViewedPlantsVisible: false,
				lastViewedLinesVisible: false,
			};
		});
	}

	handleLastViewedPlantsToggle(event) {
		event.preventDefault();

		this.setState((prevState, props) => {
			return {
				lastViewedMachinesVisible: false,
				lastViewedToolsVisible: false,
				lastViewedPlantsVisible: !prevState.lastViewedPlantsVisible,
				lastViewedLinesVisible: false,
			};
		});
	}

	handleLastViewedLinesToggle(event) {
		event.preventDefault();

		this.setState((prevState, props) => {
			return {
				lastViewedMachinesVisible: false,
				lastViewedToolsVisible: false,
				lastViewedPlantsVisible: false,
				lastViewedLinesVisible: !prevState.lastViewedLinesVisible,
			};
		});
	}

	handleSearch(event) {
		// TODO: Search
		this.props.search("");
	}

	handleEvent() {
		this.props.onNavigationShow();
	}

	handleMachineDataChanged(helper) {
		this.setState((prevState, props) => {
			const idx = prevState.lastViewedMachines.findIndex((e) => { return e.id === helper.getID(); });
			if (IsNullOrUndefined(idx))
				return {};

			let a = [...prevState.lastViewedMachines];
			a[idx].name = helper.getName();

			return {
				lastViewedMachines: a
			};
		});
	}

	handleToolDataChanged(id, data, error) {
		this.setState((prevState, props) => {
			const idx = prevState.lastViewedTools.findIndex((e) => { return e.id === id; });
			if (IsNullOrUndefined(idx))
				return {};

			let a = [...prevState.lastViewedTools];
			if (!error)
				a[idx].name = data.data?.serialNumber ?? null;
			else
				a[idx].name = null;

			return {
				lastViewedTools: a
			};
		});
	}

	handlePlantDataChanged(id, data, error) {
		this.setState((prevState, props) => {
			const idx = prevState.lastViewedPlants.findIndex((e) => { return e.id === id; });
			if (IsNullOrUndefined(idx))
				return {};

			let a = [...prevState.lastViewedPlants];
			if (!error)
				a[idx].name = data.data?.name ?? null;
			else
				a[idx].name = null;

			return {
				lastViewedPlants: a
			};
		});
	}

	handleLineDataChanged(id, data, error) {
		this.setState((prevState, props) => {
			const idx = prevState.lastViewedLines.findIndex((e) => { return e.id === id; });
			if (IsNullOrUndefined(idx))
				return {};

			let a = [...prevState.lastViewedLines];
			if (!error)
				a[idx].name = data.data?.name ?? null;
			else
				a[idx].name = null;

			return {
				lastViewedLines: a
			};
		});
	}

	componentDidMount() {
		if (!IsNullOrUndefined(this.ref.current)) {
			this.ref.current.addEventListener('click', this);
		}

		// Get the user data
		this.userKey = (new CurrentUser()).register((loaded, error) => {
			const lvm = (new CurrentUser()).getLastViewedMachines();
			const lvt = (new CurrentUser()).getLastViewedTools();
			const lvl = (new CurrentUser()).getLastViewedLines();
			const lvp = (new CurrentUser()).getLastViewedPlants();

			this.setState((prevState, props) => {
				let lastViewedMachines = [];
				lvm.forEach((e) => {
					const t = prevState.lastViewedMachines.find(x => x.id === e);
					if (IsNullOrUndefined(t))
						lastViewedMachines.push({ id: e, name: null });
					else
						lastViewedMachines.push(t);
				});

				let lastViewedTools = [];
				lvt.forEach((e) => {
					const t = prevState.lastViewedTools.find(x => x.id === e);
					if (IsNullOrUndefined(t))
						lastViewedTools.push({ id: e, name: null });
					else
						lastViewedTools.push(t);
				});

				let lastViewedPlants = [];
				lvp.forEach((e) => {
					const t = prevState.lastViewedPlants.find(x => x.id === e);
					if (IsNullOrUndefined(t))
						lastViewedPlants.push({ id: e, name: null });
					else
						lastViewedPlants.push(t);
				});

				let lastViewedLines = [];
				lvl.forEach((e) => {
					const t = prevState.lastViewedLines.find(x => x.id === e);
					if (IsNullOrUndefined(t))
						lastViewedLines.push({ id: e, name: null });
					else
						lastViewedLines.push(t);
				});

				return {
					// Settings
					userID: (new CurrentUser()).getUserID(),

					lastViewedMachines: lastViewedMachines,
					lastViewedTools: lastViewedTools,
					lastViewedLines: lastViewedLines,
					lastViewedPlants: lastViewedPlants,

					// Rights
					Right_Order_Enable: (new CurrentUser()).hasRight(Rights.RIGHTS.Order_Enable),
					Right_Dashboard_View: (new CurrentUser()).hasRight(Rights.RIGHTS.Dashboard_View),
					Right_Machine_EnableOverview: (new CurrentUser()).hasRight(Rights.RIGHTS.Machine_EnableOverview),
					Right_Machine_EnableLastViewedMachines: (new CurrentUser()).hasRight(Rights.RIGHTS.Machine_EnableLastViewedMachines),
					Right_Tool_EnableOverview: (new CurrentUser()).hasRight(Rights.RIGHTS.Tool_EnableOverview),
					Right_Tool_EnableTypeOverview: (new CurrentUser()).hasRight(Rights.RIGHTS.Tool_EnableTypeOverview),
					Right_Tool_EnableLastViewedTools: (new CurrentUser()).hasRight(Rights.RIGHTS.Tool_EnableLastViewedTools),
					Right_User_ViewAllUsers: (new CurrentUser()).hasRight(Rights.RIGHTS.User_ViewAllUsers),
					Right_User_ViewUsers: (new CurrentUser()).hasRight(Rights.RIGHTS.User_ViewUsers),
					Right_Plant_View: (new CurrentUser()).hasRight(Rights.RIGHTS.Plant_View),
					Right_Plant_ViewAll: (new CurrentUser()).hasRight(Rights.RIGHTS.Plant_ViewAll),
					Right_Plant_Create: (new CurrentUser()).hasRight(Rights.RIGHTS.Plant_Create),
					Right_Line_View: (new CurrentUser()).hasRight(Rights.RIGHTS.Line_View),
					Right_Line_ViewAll: (new CurrentUser()).hasRight(Rights.RIGHTS.Line_ViewAll),
					Right_Line_Create: (new CurrentUser()).hasRight(Rights.RIGHTS.Line_Create),
					Right_Group_ViewAllGroups: (new CurrentUser()).hasRight(Rights.RIGHTS.Group_ViewAllGroups),
					Right_Group_ViewGroups: (new CurrentUser()).hasRight(Rights.RIGHTS.Group_ViewGroups),
					Right_Message_Enable: (new CurrentUser()).hasRight(Rights.RIGHTS.Message_Enable),
					Right_Update_View: (new CurrentUser()).hasRight(Rights.RIGHTS.Update_View),
					Right_Machine_AddRemove: (new CurrentUser()).hasRight(Rights.RIGHTS.Machine_AddRemove),
					Right_Extras_CreatePieceSerialNumber: (new CurrentUser()).hasRight(Rights.RIGHTS.Extras_CreatePieceSerialNumber),
				};
			});

			{
				// Remove old machines
				let toDelete = [];
				this.machineKeys.forEach((v, k) => {
					if (!lvm.includes(k)) {
						toDelete.push(k);

						v.destroy();
					}
				});
				toDelete.forEach((e) => {
					this.machineKeys.delete(e);
				});

				// Register new machines
				lvm.forEach((e) => {
					if (!this.machineKeys.has(e))
						this.machineKeys.set(e, new MachineHelper(e, this.handleMachineDataChanged));
				});
			}

			{
				// Remove old tools
				let toDelete = [];
				this.toolKeys.forEach((v, k) => {
					if (!lvt.includes(k)) {
						toDelete.push(k);

						(new ToolData()).unregister(v);
					}
				});
				toDelete.forEach((e) => {
					this.toolKeys.delete(e);
				});

				// Register new tools
				lvt.forEach((e) => {
					if (!this.toolKeys.has(e))
						this.toolKeys.set(e, (new ToolData()).register(e, (data, error) => { this.handleToolDataChanged(e, data, error); }));
				});
			}

			{
				// Remove old plants
				let toDelete = [];
				this.plantKeys.forEach((v, k) => {
					if (!lvp.includes(k)) {
						toDelete.push(k);

						(new PlantData()).unregister(v);
					}
				});
				toDelete.forEach((e) => {
					this.plantKeys.delete(e);
				});

				// Register new machines
				lvp.forEach((e) => {
					if (!this.plantKeys.has(e))
						this.plantKeys.set(e, (new PlantData()).register(e, (data, error) => { this.handlePlantDataChanged(e, data, error); }));
				});
			}

			{
				// Remove old lines
				let toDelete = [];
				this.lineKeys.forEach((v, k) => {
					if (!lvl.includes(k)) {
						toDelete.push(k);

						(new LineData()).unregister(v);
					}
				});
				toDelete.forEach((e) => {
					this.lineKeys.delete(e);
				});

				// Register new lines
				lvl.forEach((e) => {
					if (!this.lineKeys.has(e))
						this.lineKeys.set(e, (new LineData()).register(e, (data, error) => { this.handleLineDataChanged(e, data, error); }));
				});
			}

		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.navigationCollapsed !== this.props.navigationCollapsed) {
			if (!IsNullOrUndefined(this.ref.current)) {
				this.ref.current.addEventListener('click', this);
			}
		}
	}

	componentWillUnmount() {
		// Remove the User
		if (this.userKey !== null) {
			(new CurrentUser()).unregister(this.userKey);
			this.userKey = null;
		}

		this.toolKeys.forEach((v, k) => {
			(new ToolData()).unregister(v);
		});
		this.toolKeys.clear();

		this.machineKeys.forEach((v, k) => {
			v.destroy();
		});
		this.machineKeys.clear();

		this.lineKeys.forEach((v, k) => {
			(new LineData()).unregister(v);
		});
		this.lineKeys.clear();

		this.plantKeys.forEach((v, k) => {
			(new PlantData()).unregister(v);
		});
		this.plantKeys.clear();

		if (!IsNullOrUndefined(this.ref.current))
			this.ref.current.removeEventListener('click', this);
	}

	render() {
		const navToggled = this.props.navigationCollapsed ? "toggled" : "";

		let navToggleMode = "togglemode-auto";
		if (this.props.navigationToggleMode == NAVIGATIONTOGGLEMODE.auto)
			navToggleMode = "togglemode-auto";
		else if (this.props.navigationToggleMode == NAVIGATIONTOGGLEMODE.hidden)
			navToggleMode = "togglemode-hidden";
		else if (this.props.navigationToggleMode == NAVIGATIONTOGGLEMODE.visible)
			navToggleMode = "togglemode-visible";

		let lastViewedMachines = [];
		{
			let first = true;
			if (this.state.Right_Machine_EnableLastViewedMachines) {
				this.state.lastViewedMachines.forEach((e) => {
					var d = (
						<li key={"lastViewedMachines_" + e.id} className={"nav-item " + ((first && this.props.navState === "MachineDetails") ? "active" : "")}>
							<Link to={"/Protected/Machine/Details?machineID=" + e.id} className="p-subnav__link">
								<span className={"p-subnav__link--placeholder multi-collapse-2 " + navToggled}></span>
								<span className="bg-sprite icon--powertrain"></span>
								<span className={"multi-collapse-2 p-subnav__link--text " + navToggled} >
									{e.name}
								</span>
							</Link>
						</li>
					);

					lastViewedMachines.push(d);

					first = false;
				});
			}
			if (this.state.Right_Machine_AddRemove) {
				var d = (
					<li key="newMachine" className={"nav-item " + (this.props.navState === "MachineCreate" ? "active" : "")}>
						<Link to="/Protected/Machine/Create" className="p-subnav__link border-top">
							<span className={"p-subnav__link--placeholder multi-collapse-2 " + navToggled}></span>
							<span className="bg-sprite icon--settings"></span>
							<span className={"multi-collapse-2 p-subnav__link--text " + navToggled}>
								{(new Localizer()).get("Create")}
							</span>
						</Link>
					</li>
				);

				lastViewedMachines.push(d);
			}
		}

		let lastViewedTools = [];
		{
			let first = true;
			if (this.state.Right_Tool_EnableLastViewedTools) {
				this.state.lastViewedTools.forEach((e) => {
					let name = e.name;
					if (IsNullOrUndefined(name))
						name = e.id;

					const d = (
						<li key={"lastViewedTools_" + e.id} className={"nav-item " + ((first && this.props.navState === "ToolDetails") ? "active" : "")}>
							<Link to={"/Protected/Tool/Details?toolID=" + e.id} className="p-subnav__link">
								<span className={"p-subnav__link--placeholder multi-collapse-2 " + navToggled}></span>
								<span className="bg-sprite icon--single-tool"></span>
								<span className={"multi-collapse-2 p-subnav__link--text " + navToggled} >
									{name}
								</span>
							</Link>
						</li>
					);

					lastViewedTools.push(d);

					first = false;
				});
			}
		}

		let lastViewedLines = [];
		{
			let first = true;
			this.state.lastViewedLines.forEach((e) => {
				const d = (
					<li key={"lastViewedLines_" + e.id} className={"nav-item " + ((first && (this.props.navState === "LineDetails" || this.props.navState === "LinePieceDetails")) ? "active" : "")}>
						<Link to={"/Protected/Line/Details?lineID=" + e.id} className="p-subnav__link">
							<span className={"p-subnav__link--placeholder multi-collapse-2 " + navToggled}></span>
							<span className="bg-sprite icon--lines-default"></span>
							<span className={"multi-collapse-2 p-subnav__link--text " + navToggled} >
								{IsNullOrUndefinedOrWhitespace(e.name) ? e.id : e.name}
							</span>
						</Link>
					</li>
				);

				lastViewedLines.push(d);

				first = false;
			});
			if (this.state.Right_Line_Create) {
				const d = (
					<li key="newLine" className={"nav-item " + (this.props.navState === "LineCreate" ? "active" : "")}>
						<Link to="/Protected/Line/Create" className="p-subnav__link border-top">
							<span className={"p-subnav__link--placeholder multi-collapse-2 " + navToggled}></span>
							<span className="bg-sprite icon--settings"></span>
							<span className={"multi-collapse-2 p-subnav__link--text " + navToggled}>
								{(new Localizer()).get("Create")}
							</span>
						</Link>
					</li>
				);

				lastViewedLines.push(d);
			}
		}

		let lastViewedPlants = [];
		{
			let first = true;
			this.state.lastViewedPlants.forEach((e) => {
				const d = (
					<li key={"lastViewedPlants_" + e.id} className={"nav-item " + ((first && this.props.navState === "PlantDetails") ? "active" : "")}>
						<Link to={"/Protected/Plant/Details?plantID=" + e.id} className="p-subnav__link">
							<span className={"p-subnav__link--placeholder multi-collapse-2 " + navToggled}></span>
							<span className="bg-sprite icon--plants-default"></span>
							<span className={"multi-collapse-2 p-subnav__link--text " + navToggled} >
								{IsNullOrUndefinedOrWhitespace(e.name) ? e.id : e.name}
							</span>
						</Link>
					</li>
				);

				lastViewedPlants.push(d);

				first = false;
			});
			if (this.state.Right_Plant_Create) {
				const d = (
					<li key="newPlant" className={"nav-item " + (this.props.navState === "PlantCreate" ? "active" : "")}>
						<Link to="/Protected/Plant/Create" className="p-subnav__link border-top">
							<span className={"p-subnav__link--placeholder multi-collapse-2 " + navToggled}></span>
							<span className="bg-sprite icon--settings"></span>
							<span className={"multi-collapse-2 p-subnav__link--text " + navToggled}>
								{(new Localizer()).get("Create")}
							</span>
						</Link>
					</li>
				);

				lastViewedPlants.push(d);
			}
		}

		return (
			<>
				{this.props.navigationCollapsed &&
					<div className={"mainnav--fullScreen " + navToggleMode} ref={this.ref} />
				}
				<div className={"mainnav h-100 align-top " + navToggled + " " + navToggleMode}>
					<div className={"mainnav--content vh-100"}>
						<div className="mainnav__nav-toggler">
							{/*Toggle Navigation links*/}
							<button className={"navbar-toggler p-0"} onClick={this.toggleMenu}>
								<span className="navbar-toggler-icon bg-sprite"></span>
							</button>
						</div>
						<nav className="mainnav--waves">
							<Scrollbar enableAutoHide={true}>

								{/*Navigation Items*/}
								<ul className="nav flex-column">

									{/*Search Form Button (opens second search form when navigation is collapsed)*/}
									{settings.ShowDevelopmentFeatures &&
										<li className="nav-item position-relative">
											<button className="navbar-toggler nav-link" onClick={this.handleSearchToggle}>
												<span className="bg-sprite mainnav__navicon icon--search"></span>
											</button>

											{/*Search Form 1*/}
											<div className={"position-absolute p-search-form " + navToggled}>
												<div className="d-flex align-items-center">
													<button onClick={this.handleSearch}>
														<span className="bg-sprite mainnav__navicon icon--search"></span>
													</button>
													<input type="search" className="form-control" id="q" name="q" placeholder="Search..." autoComplete="off" data-docs-version="4.1" />
												</div>
											</div>
										</li>
									}

									{/*Simple Navigation Item*/}
									{this.state.Right_Dashboard_View &&
										<li className={"nav-item " + (this.props.navState === "Dashboard" ? "active" : "")}>
											<Link to="/Protected/Dashboard" className="nav-link nav-link-container">
												<span className="bg-sprite mainnav__navicon icon--dash"></span>
												<span className={"mainnav_navtext multi-collapse-2 " + navToggled}>{(new Localizer()).get("Dashboard")}</span>
											</Link>
										</li>
									}

									{/* Item with Sub - Menu */}
									{this.state.Right_Machine_EnableOverview &&
										<li className={"nav-item d-flex flex-column position-relative " + (this.props.navState === "MachineOverview" ? "active" : "") + " " + ((this.props.navState === "MachineDetails" || this.props.navState === "MachineCreate") ? "child-active" : "")} >
											<div className={"multi-collapse-2 nav-link-container " + navToggled}>
												<Link to="/Protected/Machine/Overview" className="nav-link flex-column">
													<span className="d-flex w-100 align-items-center">
													<span className="bg-sprite mainnav__navicon icon--machines"></span>
														<span className={"multi-collapse-2 mainnav_navtext " + navToggled}>
															<span>{(new Localizer()).get("Machines")}</span>
														</span>
													</span>
												</Link>
												{lastViewedMachines.length > 0 &&
													<span className={"multi-collapse-2 nav-link-button " + navToggled + (this.state.lastViewedMachinesVisible ? " p-subnav-container-visible" : "")} onClick={this.handleLastViewedMachinesToggle}>
														<button className="bg-sprite"></button>
													</span>
												}
											</div>
											{lastViewedMachines.length > 0 &&
												<>
													<span className={"p-subnav-container " + navToggled + (this.state.lastViewedMachinesVisible ? " p-subnav-container-visible" : "")}>
														<span className="p-subnav-container-subnavtoggler" onClick={this.handleLastViewedMachinesToggle}>
															<span className="p-subnav-container-subnavtoggler-styling">
																<button type="button" className="bg-sprite"></button>
															</span>
														</span>
														<ul className={"nav flex-column p-subnav multi-collapse-1 " + (this.state.lastViewedMachinesVisible ? " toggled" : "")}>
															{lastViewedMachines}
														</ul>
													</span>
												</>
											}
										</li>
									}

									{/* Item with Sub - Menu */}
									{(this.state.Right_Tool_EnableOverview || this.state.Right_Tool_EnableTypeOverview) &&
										<li className={"nav-item d-flex flex-column position-relative " + (this.props.navState === "ToolOverview" ? "active" : "") + " " + (this.props.navState === "ToolDetails" ? "child-active" : "")} >
											<div className={"multi-collapse-2 nav-link-container " + navToggled}>
												<Link to="/Protected/Tool/Overview" className="nav-link flex-column">
													<span className="d-flex w-100 align-items-center">
													<span className="bg-sprite mainnav__navicon icon--tools"></span>
														<span className={"multi-collapse-2 mainnav_navtext " + navToggled}>
															<span>{(new Localizer()).get("Tools")}</span>
														</span>
													</span>
												</Link>
												{lastViewedTools.length > 0 &&
													<span className={"multi-collapse-2 nav-link-button " + navToggled + (this.state.lastViewedToolsVisible ? " p-subnav-container-visible" : "")} onClick={this.handleLastViewedToolsToggle}>
														<button className="bg-sprite"></button>
													</span>
												}
											</div>
											{lastViewedTools.length > 0 &&
												<>
													<span className={"p-subnav-container " + navToggled + (this.state.lastViewedToolsVisible ? " p-subnav-container-visible" : "")}>
														<span className="p-subnav-container-subnavtoggler" onClick={this.handleLastViewedToolsToggle}>
															<span className="p-subnav-container-subnavtoggler-styling">
																<button type="button" className="bg-sprite"></button>
															</span>
														</span>
														<ul className={"nav flex-column p-subnav multi-collapse-1 " + (this.state.lastViewedToolsVisible ? " toggled" : "")}>
															{lastViewedTools}
														</ul>
													</span>
												</>
											}
										</li>
									}

									{/* Item with Sub - Menu */}
									{(this.state.Right_Plant_View || this.state.Right_Plant_ViewAll) &&
										<li className={"nav-item d-flex flex-column position-relative " + (this.props.navState === "PlantOverview" ? "active" : "") + " " + ((this.props.navState === "PlantDetails" || this.props.navState === "PlantCreate") ? "child-active" : "")} >
											<div className={"multi-collapse-2 nav-link-container " + navToggled}>
												<Link to="/Protected/Plant/Overview" className="nav-link flex-column">
													<span className="d-flex w-100 align-items-center">
														<span className="bg-sprite mainnav__navicon icon--plants"></span>
														<span className={"multi-collapse-2 mainnav_navtext " + navToggled}>
															<span>{(new Localizer()).get("Plants")}</span>
														</span>
													</span>
												</Link>
												{lastViewedPlants.length > 0 &&
													<span className={"multi-collapse-2 nav-link-button " + navToggled + (this.state.lastViewedPlantsVisible ? " p-subnav-container-visible" : "")} onClick={this.handleLastViewedPlantsToggle}>
														<button className="bg-sprite"></button>
													</span>
												}
											</div>
											{lastViewedPlants.length > 0 &&
												<>
													<span className={"p-subnav-container " + navToggled + (this.state.lastViewedPlantsVisible ? " p-subnav-container-visible" : "")}>
														<span className="p-subnav-container-subnavtoggler" onClick={this.handleLastViewedPlantsToggle}>
															<span className="p-subnav-container-subnavtoggler-styling">
																<button type="button" className="bg-sprite"></button>
															</span>
														</span>
														<ul className={"nav flex-column p-subnav multi-collapse-1 " + (this.state.lastViewedPlantsVisible ? " toggled" : "")}>
															{lastViewedPlants}
														</ul>
													</span>
												</>
											}
										</li>
									}

									{/* Item with Sub - Menu */}
									{(this.state.Right_Line_View || this.state.Right_Line_ViewAll) &&
										<li className={"nav-item d-flex flex-column position-relative " + (this.props.navState === "LineOverview" ? "active" : "") + " " + ((this.props.navState === "LineDetails" || this.props.navState === "LineCreate" || this.props.navState === "LinePieceDetails") ? "child-active" : "")} >
											<div className={"multi-collapse-2 nav-link-container " + navToggled}>
												<Link to="/Protected/Line/Overview" className="nav-link flex-column">
													<span className="d-flex w-100 align-items-center">
														<span className="bg-sprite mainnav__navicon icon--lines"></span>
														<span className={"multi-collapse-2 mainnav_navtext " + navToggled}>
															<span>{(new Localizer()).get("Lines")}</span>
														</span>
													</span>
												</Link>
												{lastViewedLines.length > 0 &&
													<span className={"multi-collapse-2 nav-link-button " + navToggled + (this.state.lastViewedLinesVisible ? " p-subnav-container-visible" : "")} onClick={this.handleLastViewedLinesToggle}>
														<button className="bg-sprite"></button>
													</span>
												}
											</div>
											{lastViewedLines.length > 0 &&
												<>
													<span className={"p-subnav-container " + navToggled + (this.state.lastViewedLinesVisible ? " p-subnav-container-visible" : "")}>
														<span className="p-subnav-container-subnavtoggler" onClick={this.handleLastViewedLinesToggle}>
															<span className="p-subnav-container-subnavtoggler-styling">
																<button type="button" className="bg-sprite"></button>
															</span>
														</span>
														<ul className={"nav flex-column p-subnav multi-collapse-1 " + (this.state.lastViewedLinesVisible ? " toggled" : "")}>
															{lastViewedLines}
														</ul>
													</span>
												</>
											}
										</li>
									}

									{this.state.Right_Message_Enable &&
										<li className={"nav-item " + (this.props.navState === "Message" ? " active" : "")} >
											<Link to="/Protected/Message/Overview" className="nav-link nav-link-container mainnav__link">
												<span className="bg-sprite mainnav__navicon icon--messages"></span>
												<span className={"mainnav_navtext multi-collapse-2 " + navToggled}>{(new Localizer()).get("Messages")}</span>
											</Link>
										</li>
									}
									{(settings.ShowDevelopmentFeatures && this.state.Right_Order_Enable) &&
										<li className={"nav-item " + (this.props.navState === "Order" ? " active" : "")} >
											<Link to="/Protected/Order/Overview" className="nav-link nav-link-container mainnav__link">
												<span className="bg-sprite mainnav__navicon icon--order"></span>
												<span className={"mainnav_navtext multi-collapse-2 " + navToggled}>{(new Localizer()).get("Order")}</span>
											</Link>
										</li>
									}
									{this.state.Right_Update_View &&
										<li className={"nav-item " + (this.props.navState === "Update" ? " active" : "")} >
											<Link to="/Protected/Update" className="nav-link nav-link-container mainnav__link">
												<span className="bg-sprite mainnav__navicon icon--update-manag"></span>
												<span className={"mainnav_navtext multi-collapse-2 " + navToggled}>{(new Localizer()).get("Update Management")}</span>
											</Link>
										</li>
									}
									{this.state.Right_Extras_CreatePieceSerialNumber &&
										<li className={"nav-item " + (this.props.navState === "Extras" ? " active" : "")} >
											<Link to="/Protected/Extras" className="nav-link nav-link-container mainnav__link">
												<span className="bg-sprite mainnav__navicon icon--extras"></span>
												<span className={"mainnav_navtext multi-collapse-2 " + navToggled}>{(new Localizer()).get("Extras")}</span>
											</Link>
										</li>
									}
									{(this.state.Right_User_ViewAllUsers ||
										this.state.Right_User_ViewUsers ||
										this.state.Right_Group_ViewAllGroups ||
										this.state.Right_Group_ViewGroups) &&
										<li className={"nav-item " + (this.props.navState === "UserManagement" ? " active" : "")} >
											<Link to="/Protected/User/Overview" className="nav-link nav-link-container mainnav__link">
												<span className="bg-sprite mainnav__navicon icon--user"></span>
												<span className={"mainnav_navtext multi-collapse-2 " + navToggled}>{(new Localizer()).get("User Management")}</span>
											</Link>
										</li>
									}
								</ul>
							</Scrollbar>
						</nav>
					</div>
				</div>
			</>
		);
	}
}