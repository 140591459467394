export const ApplicationName = 'CustomerPortal';

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback'
};

export const ApplicationPaths = {
	ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
	ApiAuthorization: 'authentication',
	Login: `${LoginActions.Login}`,
	LoginCallback: `${LoginActions.LoginCallback}`,
	LogOut: `${LogoutActions.Logout}`,
	LoggedOut: `${LogoutActions.LoggedOut}`,
	LogOutCallback: `${LogoutActions.LogoutCallback}`
};
