/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is the entry point for the application
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Localizer from '../../../BusinessLogic/Misc/Localizer';
import DateFormat from '../../../BusinessLogic/Formater/DateFormat';
import NavButton from '../../../Components/BasicElements/NavButton.js';
import ExtNavButton from '../../../Components/BasicElements/ExtNavButton.js';

export default class Footer extends React.Component {
	render() {
		return (
			<>
				<footer className="p-footer">
					<div className="p-maincontent--padding-x">
						<Row className="pt-4 pb-2 mx-0 p-maincontent--margin-x">
							<Col xs={12} md={6} xl={8} className="text-xs-center text-lg-start px-sm-0 pb-2 justify-content-center justify-content-md-start d-flex text-nowrap">
								<ExtNavButton
									className="border-0 p-0 align-middle me-4 ms-4 ms-md-0"
									text={(new Localizer()).get("Gehring Home")}
									variant={ExtNavButton.VARIANT.link}
									icon={ExtNavButton.ICON.home}
									autohideText
									autohideTextSize="xxl"
									url="https://www.gehring-group.com"
									newWindow
								/>
								<ExtNavButton
									className="border-0 p-0 align-middle me-4"
									text={(new Localizer()).get("Gehring Shop")}
									variant={ExtNavButton.VARIANT.link}
									icon={ExtNavButton.ICON.shop}
									autohideText
									autohideTextSize="xxl"
									url="https://www.gehring-webshop.com"
									newWindow
								/>
								<NavButton
									className="border-0 p-0 align-middle me-4"
									text={(new Localizer()).get("Privacy Policy")}
									variant={NavButton.VARIANT.link}
									icon={NavButton.ICON.privacyNote}
									autohideText
									autohideTextSize="xxl"
									to="/Protected/Privacy"
								/>
								<NavButton
									className="border-0 p-0 align-middle me-4"
									text={(new Localizer()).get("Terms and conditions")}
									variant={NavButton.VARIANT.link}
									icon={NavButton.ICON.agb}
									autohideText
									autohideTextSize="xxl"
									to="/Protected/Terms"
								/>
								<NavButton
									className="border-0 p-0 align-middle me-4"
									text={(new Localizer()).get("Contact")}
									variant={NavButton.VARIANT.link}
									icon={NavButton.ICON.contact}
									autohideText
									autohideTextSize="xxl"
									to="/Protected/Contact"
								/>
								<NavButton
									className="border-0 p-0 align-middle me-4"
									text={(new Localizer()).get("About")}
									variant={NavButton.VARIANT.link}
									icon={NavButton.ICON.etc}
									autohideText
									autohideTextSize="xxl"
									to="/Protected/About"
								/>
							</Col>
							<Col xs={12} md={6} xl={4} className="text-xs-center text-lg-end pb-2 px-sm-0 d-flex justify-content-center justify-content-md-end text-nowrap">
								{String.fromCharCode(169)} {(new DateFormat()).formatYear(new Date())} - {(new Localizer()).get("Gehring Technologies GmbH + Co.KG")}
							</Col>
						</Row>
					</div>
				</footer>
			</>
		);
	}
}