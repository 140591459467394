/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper for Strings
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

function InitStringFormat() {
	// eslint-disable-next-line no-extend-native
	String.prototype.format = function () {
		var a = this;
		for (let k in arguments) {
			if (arguments.hasOwnProperty(k)) {
				a = a.replace(`{${k}}`, arguments[k]);
			}
		}
		return a;
	}
}

export default InitStringFormat;