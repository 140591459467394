import React, { Component, Fragment } from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ai } from './TelemetryService';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
export default class TelemetryProvider extends Component {
    render() {
        const { children } = this.props;
        return (
            <AppInsightsContext.Provider value={ai.reactPlugin}>
                {children}
            </AppInsightsContext.Provider>
        );
    }
}
