import React from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { QueryParameterNames, LogoutActions, ApplicationPaths } from './ApiAuthorizationConstants';
import Localizer from '../BusinessLogic/Misc/Localizer';
import BasicMessageTemplate from '../Public/Components/BasicMessageTemplate';
import Navigator from "../BusinessLogic/Misc/Navigator.js";

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
export class Logout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            failure: false
        };
    }

    componentDidMount() {
        const action = this.props.action;
        switch (action) {
            case LogoutActions.Logout:
                this.logout(this.getReturnUrl());
                break;
            case LogoutActions.LogoutCallback:
                this.processLogoutCallback();
                break;
            case LogoutActions.LoggedOut:
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    render() {
        let text = "";
        let processing = false;
        const action = this.props.action;
        switch (action) {
            case LogoutActions.Logout:
                text = (new Localizer()).get("Processing logout");
                processing = true;
                break;
            case LogoutActions.LogoutCallback:
                if (this.state.failure)
                    text = (new Localizer()).get("Logout failed. Please try again.");
                else {
                    text = (new Localizer()).get("Processing logout");
                    processing = true;
                }
                break;
            case LogoutActions.LoggedOut:
                if (this.state.failure)
                    text = (new Localizer()).get("Logout failed. Please try again.");
                else
                    text = (new Localizer()).get("You have been successfully logged out of the application.")
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }

        return (
            <BasicMessageTemplate title={(new Localizer()).get("Logout")}
                disabled={processing}>
                <div className="lead text-center">
                    {text}
                </div>
            </BasicMessageTemplate>
        );
    }

    async logout(returnUrl) {
        const state = { returnUrl };
        const isauthenticated = await authService.isAuthenticated();
        if (isauthenticated) {
            const result = await authService.signOut(state);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    await this.navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    this.setState({ failure: true });
                    break;
                default:
                    throw new Error("Invalid authentication result status.");
            }
        } else {
            await this.navigateToReturnUrl(returnUrl);
        }
    }

    async processLogoutCallback() {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeAuthentication finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ failure: true });
                break;
            default:
                throw new Error("Invalid authentication result status.");
        }
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}/${ApplicationPaths.ApiAuthorization}/${ApplicationPaths.LoggedOut}`;
    }

    navigateToReturnUrl(returnUrl) {
        const _returnUrl = returnUrl.substring(window.location.origin.length);
        (new Navigator()).redirect(_returnUrl);
    }
}
