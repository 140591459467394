/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import NotificationService from '../Misc/NotificationService.js';
import settings from 'Settings';
import UniqueIDGenerator from '../Misc/UniqueIDGenerator.js';
import IsNullOrUndefined from "../Misc/Utility.js";
import Rights from '../Misc/Rights.js';
import GroupedFetch from './Intern/GroupedFetch.js';
import BaseData from './Intern/BaseData.js';
import BaseList from './Intern/BaseList.js';

class Data extends BaseData {
	constructor(ID: string, fetch: GroupedFetch, currentUserID: string, currentUserRightsChangeID: string, currentUserMachineRightsChangeID: string, currentUserPlantRightsChangeID: string, currentUserLineRightsChangeID: string) {
		super(ID, fetch, ID);

		this.initUpdateListener = this.initUpdateListener.bind(this);
		this.releaseUpdateListener = this.releaseUpdateListener.bind(this);
		this.dataChanged = this.dataChanged.bind(this);
		this.setCurrentUser = this.setCurrentUser.bind(this);

		this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
		this.currentUserRightsChangeID = currentUserRightsChangeID;
		this.currentUserPlantRightsChangeID = currentUserPlantRightsChangeID;
		this.currentUserLineRightsChangeID = currentUserLineRightsChangeID;

		this.newMachineNotification = null;
		this.newLineNotification = null;
		this.newPlantNotification = null;
		this.userNotificationKey = null;

		this.init("UserData");
	}

	setCurrentUser(currentUserID, currentUserRightsChangeID, currentUserMachineRightsChangeID, currentUserPlantRightsChangeID, currentUserLineRightsChangeID) {
		if (this.ID === currentUserID) {
			this.currentUserRightsChangeID = currentUserRightsChangeID;
			this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
			this.currentUserPlantRightsChangeID = currentUserPlantRightsChangeID;
			this.currentUserLineRightsChangeID = currentUserLineRightsChangeID;
			return;
		}

		// Changed?
		if (this.currentUserMachineRightsChangeID === currentUserMachineRightsChangeID &&
			this.currentUserRightsChangeID === currentUserRightsChangeID &&
			this.currentUserPlantRightsChangeID === currentUserPlantRightsChangeID &&
			this.currentUserLineRightsChangeID === currentUserLineRightsChangeID)
			return;

		this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
		this.currentUserRightsChangeID = currentUserRightsChangeID;
		this.currentUserPlantRightsChangeID = currentUserPlantRightsChangeID;
		this.currentUserLineRightsChangeID = currentUserLineRightsChangeID;

		// Update the data
		this.reload();
	}

	initUpdateListener() {
		// Notification
		this.userNotificationKey = (new NotificationService()).registerUserChanged(this.ID, (userId) => {
			this.triggerUpdateData();
		});
	}

	releaseUpdateListener() {
		if (this.userNotificationKey !== null) {
			(new NotificationService()).unregister(this.userNotificationKey);
			this.userNotificationKey = null;
		}

		if (!IsNullOrUndefined(this.newMachineNotification)) {
			(new NotificationService()).unregister(this.newMachineNotification);
			this.newMachineNotification = null;
		}

		if (!IsNullOrUndefined(this.newLineNotification)) {
			(new NotificationService()).unregister(this.newLineNotification);
			this.newLineNotification = null;
		}

		if (!IsNullOrUndefined(this.newPlantNotification)) {
			(new NotificationService()).unregister(this.newPlantNotification);
			this.newPlantNotification = null;
		}
	}

	dataChanged() {
		// New Machine/Line/Plant Notification. This is required since a new machine may require an reload of the user
		if (!IsNullOrUndefined(this.data.resolvedRights)) {
			if (!this.data.resolvedRights.includes(Rights.RIGHTS.Machine_ViewAllMachines)) {
				if (!IsNullOrUndefined(this.newMachineNotification)) {
					(new NotificationService()).unregister(this.newMachineNotification);
					this.newMachineNotification = null;
				}
			}
			else {
				if (IsNullOrUndefined(this.newMachineNotification)) {
					this.newMachineNotification = (new NotificationService()).registerNewMachine((id) => {
						this.triggerUpdateData();
					});
					// Required since we don't know if we have "forgotten" a message due to time of flight of the message
					this.triggerUpdateData();
				}
			}

			if (!this.data.resolvedRights.includes(Rights.RIGHTS.Line_ViewAll)) {
				if (!IsNullOrUndefined(this.newLineNotification)) {
					(new NotificationService()).unregister(this.newLineNotification);
					this.newLineNotification = null;
				}
			}
			else {
				if (IsNullOrUndefined(this.newLineNotification)) {
					this.newLineNotification = (new NotificationService()).registerLineCreated((id) => {
						this.triggerUpdateData();
					});
					// Required since we don't know if we have "forgotten" a message due to time of flight of the message
					this.triggerUpdateData();
				}
			}

			if (!this.data.resolvedRights.includes(Rights.RIGHTS.Plant_ViewAll)) {
				if (!IsNullOrUndefined(this.newPlantNotification)) {
					(new NotificationService()).unregister(this.newPlantNotification);
					this.newPlantNotification = null;
				}
			}
			else {
				if (IsNullOrUndefined(this.newPlantNotification)) {
					this.newPlantNotification = (new NotificationService()).registerPlantCreated((id) => {
						this.triggerUpdateData();
					});
					// Required since we don't know if we have "forgotten" a message due to time of flight of the message
					this.triggerUpdateData();
				}
			}
		}
		else {
			if (!IsNullOrUndefined(this.newMachineNotification)) {
				(new NotificationService()).unregister(this.newMachineNotification);
				this.newMachineNotification = null;
			}
			if (!IsNullOrUndefined(this.newLineNotification)) {
				(new NotificationService()).unregister(this.newLineNotification);
				this.newLineNotification = null;
			}
			if (!IsNullOrUndefined(this.newPlantNotification)) {
				(new NotificationService()).unregister(this.newPlantNotification);
				this.newPlantNotification = null;
			}
		}
	}
}

class List extends BaseList {
	constructor(currentUserID, firstIndex, count, sortField, sortOrder, filters, func) {
		super("/api/User/list", firstIndex, count, sortField, sortOrder, filters, func);

		this.init = this.init.bind(this);
		this.release = this.release.bind(this);
		this.unregisterUsers = this.unregisterUsers.bind(this);
		this.dataChanged = this.dataChanged.bind(this);
		this.transformFilter = this.transformFilter.bind(this);

		this.createdKey = null;
		this.removedKey = null;
		this.keys = [];
	}

	init() {
		super.init();

		this.createdKey = (new NotificationService()).registerUserCreated((userId) => { this.reload(); });
		this.removedKey = (new NotificationService()).registerUserRemoved((userId) => { this.reload(); });
	}

	release() {
		if (!IsNullOrUndefined(this.createdKey)) {
			(new NotificationService()).unregister(this.createdKey);
			this.createdKey = null;
		}
		if (!IsNullOrUndefined(this.removedKey)) {
			(new NotificationService()).unregister(this.removedKey);
			this.removedKey = null;
		}

		// Remove old machines
		this.unregisterUsers();

		super.release();
	}

	setCurrentUser(currentUserID) {
		this.reload();
	}

	// Unregister the currently registered Machines
	unregisterUsers() {
		this.keys.forEach(function (element) {
			(new NotificationService()).unregister(element);
		});
		this.keys = [];
	}

	dataChanged() {
		// Remove old machines
		this.unregisterUsers();

		// Register the machines
		for (let i = 0; i < this.data.length; i++) {
			var userid = this.data[i].id;
			this.keys.push((new NotificationService()).registerUserChanged(userid, (userId) => { this.triggerUpdateData(); }));
		}
	}

	transformFilter() {
		// Create the filters
		let userNameFilter = null;
		let nameFilter = null;
		let emailFilter = null;
		let companyFilter = null;
		let statusFilter = null;

		for (const fieldId in this.filters) {
			const val = this.filters[fieldId];

			if (fieldId === "username") {
				if (val === '-')
					userNameFilter = { textFilter: null };
				else
					userNameFilter = { textFilter: val };
			}
			else if (fieldId === "name") {
				if (val === '-')
					nameFilter = { textFilter: null };
				else
					nameFilter = { textFilter: val };
			}
			else if (fieldId === "company") {
				if (val === '-')
					companyFilter = { textFilter: null };
				else
					companyFilter = { textFilter: val };
			}
			else if (fieldId === "email") {
				if (val === '-')
					emailFilter = { textFilter: null };
				else
					emailFilter = { textFilter: val };
			}
			else if (fieldId === "status") {
				if (!IsNullOrUndefined(val)) {
					if (val === true)
						statusFilter = { status: true };
					else
						statusFilter = { status: false };
				}
			}
		}

		// Create the data
		return {
			userNameFilter: userNameFilter,
			nameFilter: nameFilter,
			companyFilter: companyFilter,
			statusFilter: statusFilter,
			emailFilter: emailFilter
		};

	}
}


let instance;
export default class UserData {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.currentUserID = null;
		this.currentUserMachineRightsChangeID = null;
		this.currentUserRightsChangeID = null;
		this.currentUserPlantRightsChangeID = null;
		this.currentUserLineRightsChangeID = null;

		this.keys = new Map();
		this.keyGen = new UniqueIDGenerator();

		this.fetch = new GroupedFetch("/api/User/GetData");
		this.userArray = new Map();
		this.listArray = new Map();

		this.destroy = this.destroy.bind(this);
		this.setCurrentUser = this.setCurrentUser.bind(this);
		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.checkDelete = this.checkDelete.bind(this);
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance)) {
			instance.destroy();
		}

		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	destroy() {
		this.userArray.forEach((v, k) => {
			if (!IsNullOrUndefined(v.data)) {
				v.data.destroy();
				v.data = null;
			}

			if (!IsNullOrUndefined(v.releaseTimeoutKey)) {
				clearTimeout(v.releaseTimeoutKey);
				v.releaseTimeoutKey = null;
			}
		});
		this.listArray.forEach((v, k) => {
			v.release();
			v.destroy();
		});

		this.listArray.clear();
		this.userArray.clear();
		this.keys.clear();

		if (!IsNullOrUndefined(this.fetch))
			this.fetch.destroy();
		this.fetch = null;
	}

	setCurrentUser(currentUserID, currentUserRightsChangeID, currentUserMachineRightsChangeID, currentUserPlantRightsChangeID, currentUserLineRightsChangeID) {
		this.currentUserMachineRightsChangeID = currentUserMachineRightsChangeID;
		this.currentUserRightsChangeID = currentUserRightsChangeID;
		this.currentUserLineRightsChangeID = currentUserLineRightsChangeID;
		this.currentUserPlantRightsChangeID = currentUserPlantRightsChangeID;

		this.userArray.forEach((v, k) => {
			if (!IsNullOrUndefined(v.data))
				v.data.setCurrentUser(currentUserID, currentUserRightsChangeID, currentUserMachineRightsChangeID, currentUserPlantRightsChangeID, currentUserLineRightsChangeID);
		});
		this.listArray.forEach((v, k) => {
			v.setCurrentUser(currentUserID);
		});
	}

	// Register for the current dataset
	register(userID, func) {
		let m = this.userArray.get(userID);
		if (IsNullOrUndefined(m)) {
			m = { releaseTimeoutKey: null, data: null };
			this.userArray.set(userID, m);
		}

		if (IsNullOrUndefined(m.data)) {
			let data = new Data(userID, this.fetch, this.currentUserID, this.currentUserRightsChangeID, this.currentUserMachineRightsChangeID, this.currentUserPlantRightsChangeID, this.currentUserLineRightsChangeID);
			m.data = data;
		}

		// Clear the release-timeout
		if (!IsNullOrUndefined(m.releaseTimeoutKey)) {
			clearTimeout(m.releaseTimeoutKey);
			m.releaseTimeoutKey = null;
		}

		// Register the Function
		const subKey = m.data.register(func);

		// Integrate the key
		const key = this.keyGen.generate();
		this.keys.set(key, { type: 0, key: subKey, userID: userID });

		return key;
	}

	// Register for the current dataset
	list(firstIndex, count, sortField, sortOrder, filters, func) {
		let data = new List(this.currentUserID, firstIndex, count, sortField, sortOrder, filters, func);
		data.init();

		// Integrate the key
		const key = this.keyGen.generate();
		this.listArray.set(key, data);

		this.keys.set(key, { type: 1 });

		return key;
	}

	updateList(key, firstIndex, count, sortField, sortOrder, filters) {
		const m = this.listArray.get(key);
		if (IsNullOrUndefined(m))
			return true;

		return m.update(firstIndex, count, sortField, sortOrder, filters);
	}

	// Unregister
	unregister(key) {
		const obj = this.keys.get(key);
		if (IsNullOrUndefined(obj))
			return;

		switch (obj.type) {
			case 0:
				{
					const m = this.userArray.get(obj.userID);
					if (IsNullOrUndefined(m))
						return;

					m.data.unregister(obj.key);

					if (IsNullOrUndefined(m.releaseTimeoutKey)) {
						m.releaseTimeoutKey = setTimeout(() => { m.releaseTimeoutKey = null; this.checkDelete(obj.userID); }, settings.LocalDataHoldTime);
					}
				} break;
			case 1:
				{
					const m = this.listArray.get(key);
					if (IsNullOrUndefined(m))
						return;

					m.release();
					m.destroy();
					this.listArray.delete(key);
				} break;
			default:
				break;
		}

		// Free the key
		this.keys.delete(key);
		this.keyGen.release(key);
	}

	// Check the release
	checkDelete(userID) {
		const m = this.userArray.get(userID);
		if (IsNullOrUndefined(m))
			return;

		if (!IsNullOrUndefined(m.data)) {
			if (m.data.empty()) {
				m.data.destroy();
				m.data = null;
			}
		}

		if (IsNullOrUndefined(m.data))
			this.userArray.delete(userID);
	}
}