/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 325.17449 270.93333"
            className="svgIcon"
        >
            <path d="M 135.84657,176.10684 H 62.47766 L 0,27.093675 h 203.20052 l -7.64041,27.092992 H 40.74838 l 39.73734,94.826493 h 36.34108 z m 102.286,-73.67612 23.82438,-75.337045 h 26.1359 L 298.08094,0 h -56.82381 l -33.63157,108.06613 z m -136.47804,87.22295 c -11.21689,0 -20.32025,9.10336 -20.32025,20.31958 0,11.21689 9.10336,20.32025 20.32025,20.32025 11.2169,0 20.32026,-9.10336 20.32026,-20.32025 0,-11.21622 -9.10336,-20.31958 -20.32026,-20.31958 z m 189.81615,-46.79953 c 19.48877,27.34626 14.08819,46.5647 33.70394,74.055 l -75.68111,54.02419 c -26.3523,-19.70585 -55.99232,-34.58935 -87.49329,-44.01766 -10.85509,-3.61131 -17.82716,-10.62025 -15.42486,-19.3263 2.9423,-7.82131 10.94588,-12.42726 19.2,-11.12678 7.15298,0.4096 14.0527,1.78859 20.7715,4.06187 L 133.84158,126.4326 c -4.80597,-6.49899 -3.26997,-15.76824 3.39286,-20.48205 6.57408,-4.73088 15.76823,-3.19488 20.48273,3.39285 l 35.74511,50.05039 c 2.06165,2.1504 5.50912,2.27328 7.69433,0.2048 1.80907,-1.65888 2.18453,-4.20523 1.14005,-6.39659 l -8.79616,-12.40883 14.17831,-3.52256 c 3.72053,-1.01035 7.62265,0.4096 9.84405,3.52256 l 7.15298,10.169 c 1.4063,2.6351 4.7855,3.63179 7.33321,2.2255 2.63509,-1.4063 3.61131,-4.69675 2.20501,-7.31546 -0.2048,-0.4096 -0.47786,-0.79189 -0.83285,-1.14005 l -8.32717,-11.66814 13.9264,-3.14027 c 3.64544,-0.79189 7.42332,0.68267 9.59078,3.72054 l 6.12352,8.5975 c 1.37216,2.63509 4.69675,3.66592 7.27928,2.29376 2.63509,-1.31755 3.6864,-4.67627 2.29376,-7.22466 -0.2048,-0.4096 -0.47787,-0.88747 -0.86699,-1.17419 l -6.86353,-9.57303 4.13696,-0.72363 c 14.37764,-2.58048 22.01805,4.84011 30.79646,16.99635 z m -162.56,-48.767654 c 8.81391,-6.649174 20.9879,-6.649174 29.8025,0 -4.13696,-13.529088 -18.44156,-21.151062 -31.9884,-16.942422 -13.54684,4.13696 -21.16881,18.441558 -16.94242,31.987706 1.62475,5.33163 4.89472,10.00653 9.41056,13.22189 -3.39285,-10.51238 0.47787,-22.01804 9.66315,-28.230993 z" />
        </svg>
    );
}