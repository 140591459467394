/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import GlobalAppData from './GlobalAppData.js';
import UniqueIDGenerator from './UniqueIDGenerator.js';
import IsNullOrUndefined from "./Utility";

let instance;
export default class Navigator {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.keyGen = new UniqueIDGenerator();
		this.locationChangedKey = null;

		this.redirectFunctions = new Map();
		this.reloadFunctions = new Map();

		this.destroy = this.destroy.bind(this);
		this.redirect = this.redirect.bind(this);
		this.reload = this.reload.bind(this);
		this.registerRedirect = this.registerRedirect.bind(this);
		this.registerReload = this.registerReload.bind(this);
		this.unregister = this.unregister.bind(this);


		this.locationChangedKey = (new GlobalAppData()).registerLocationChanged((location) => { this.redirect(location); });
	}

	// Destroy the class
	static destroySingleton() {
		if (!IsNullOrUndefined(instance))
			instance.destroy();
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	destroy() {
		if (!IsNullOrUndefined(this.locationChangedKey)) {
			(new GlobalAppData()).unregister(this.locationChangedKey);
			this.locationChangedKey = null;
		}
	}

	redirect(path) {
		this.redirectFunctions.forEach((v) => {
			v(path);
		});
	}

	reload() {
		this.reloadFunctions.forEach((v) => {
			v();
		});
	}

	registerRedirect(func) {
		const key = this.keyGen.generate();

		this.redirectFunctions.set(key, func);

		return key;
	}

	registerReload(func) {
		const key = this.keyGen.generate();

		this.reloadFunctions.set(key, func);

		return key;
	}

	unregister(key) {
		this.redirectFunctions.delete(key);
		this.reloadFunctions.delete(key);
	}
}