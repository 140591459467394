/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import settings from 'Settings';
import ErrorManager from '../../Misc/ErrorManager.js';
import RequestID from '../../Misc/RequestID';
import IsNullOrUndefined from "../../Misc/Utility.js";
import ConnectionStatusManager from '../../Misc/ConnectionStatusManager.js';
import isEqual from 'lodash/isEqual';
import FetchHelper from "../../Misc/FetchHelper.js";

export default class BaseList {
	constructor(url: string, firstIndex, count, sortField, sortOrder, filters, func) {
		this.url = url;
		this.firstIndex = firstIndex;
		this.count = count;
		this.sortField = sortField;
		this.sortOrder = sortOrder;
		this.filters = filters;
		this.func = func;


		this.requestID = new RequestID();
		this.destroyed = false;
		this.abortController = new AbortController();

		this.reloadInProcess = false;

		this.dataInformable = false;
		this.dataError = false;
		this.data = [];
		this.totalCount = 0;

		// ConnectionStatusManager
		this.connectionStatusKey = null;
		this.connectionStatus = ConnectionStatusManager.STATUS.OFFLINE;

		this.updateTimeout = null;
		this.updateInProcess = false;
		this.updatePending = false;


		this.destroy = this.destroy.bind(this);
		this.init = this.init.bind(this);
		this.release = this.release.bind(this);
		this.reload = this.reload.bind(this);
		this.transformFilter = this.transformFilter.bind(this);
		this.dataChanged = this.dataChanged.bind(this);
		this.inform = this.inform.bind(this);
		this.updateData = this.updateData.bind(this);
		this.triggerUpdateData = this.triggerUpdateData.bind(this);
		this.triggerReloadData = this.triggerReloadData.bind(this);
		this.customerData = this.customerData.bind(this);
	}

	destroy() {
		this.destroyed = true;
	}

	init() {
		// ConnectionStatusManager
		this.connectionStatus = (new ConnectionStatusManager()).getStatus();

		// Register the connection manager
		this.connectionStatusKey = (new ConnectionStatusManager()).register((status) => {
			this.connectionStatus = status;

			if (this.connectionStatus === ConnectionStatusManager.STATUS.OFFLINE) {
				// Cancel all pending requests
				this.abortController.abort();
				this.abortController = new AbortController();
			}
			else {
				// We are back online. Reload the complete data.
				this.reload();
			}
		});

		// Update the data
		this.reload();
	}

	release() {
		if (!IsNullOrUndefined(this.connectionStatusKey)) {
			(new ConnectionStatusManager()).unregister(this.connectionStatusKey);
			this.connectionStatusKey = null;
		}

		if (!IsNullOrUndefined(this.updateTimeout)) {
			clearTimeout(this.updateTimeout);
			this.updateTimeout = null;
		}

		this.abortController.abort();
	}

	dataChanged() { }
	customerData() { return {}; }

	update(firstIndex, count, sortField, sortOrder, filters) {
		if (this.firstIndex === firstIndex && 
			this.count === count &&
			this.sortField === sortField &&
			this.sortOrder === sortOrder &&
			isEqual(this.filters, filters))
		return true;

		this.firstIndex = firstIndex;
		this.count = count;
		this.sortField = sortField;
		this.sortOrder = sortOrder;
		this.filters = filters;

		this.reload();

		return false;
	}

	reload() {
		if (this.reloadInProcess)
			return;

		if (!IsNullOrUndefined(this.updateTimeout)) {
			clearTimeout(this.updateTimeout);
			this.updateTimeout = null;
		}

		// Cancel all pending requests
		this.updatePending = false;
		this.updateInProcess = false;
		this.abortController.abort();
		this.abortController = new AbortController();

		// Reload the data
		this.reloadInProcess = true;

		this.triggerReloadData();
	}

	triggerUpdateData() {
		if (IsNullOrUndefined(this.updateTimeout)) {
			this.updateTimeout = setTimeout(() => {
				this.updateTimeout = null;
				this.updateData();
			}, settings.DataUpdateDelay);
		}
	}

	triggerReloadData() {
		if (IsNullOrUndefined(this.updateTimeout)) {
			this.updateTimeout = setTimeout(() => {
				this.updateTimeout = null;
				this.updateData();
			}, settings.DataReloadDelay);
		}
	}

	updateData() {
		this.reloadInProcess = false;

		if (this.connectionStatus === ConnectionStatusManager.STATUS.OFFLINE)
			return;

		if (this.updateInProcess) {
			this.updatePending = true;
			return;
		}
		this.updateInProcess = true;

		const filterData = this.transformFilter();
		const customerData = this.customerData();
		let data = {
			...filterData,
			requestID: this.requestID.newRequest(),
			startIndex: this.firstIndex,
			sortId: this.sortField,
			sortOrder: this.sortOrder,
			count: this.count,
			...customerData
		};

		// Call the server
		(async () => {
			try {
				const json = await FetchHelper.fetchData(this.url, data, this.abortController);
				if (this.destroyed)
					return;

				if (!this.requestID.validateRequest(json.requestID))
					return;

				if (json.dataAvailable) {
					this.data = json.data;
					this.totalCount = json.totalSize;
					this.dataInformable = true;
					this.dataError = false;

					this.dataChanged();

					this.inform();
				}
				else {
					// We have a valid request but no access to the data. This means that the data on the client should be deleted and that the application should be informed that no access is possible.

					this.data = [];
					this.totalCount = 0;

					this.dataInformable = true;
					this.dataError = true;

					this.dataChanged();

					this.inform();
				}

				this.updateInProcess = false;
				// Reshedule the update if requested
				if (this.updatePending) {
					this.updatePending = false;
					this.updateData();
				}
			}
			catch (err) {
				if (err.name === "Abort") {
					this.updateInProcess = false;
					// Reshedule the update if requested
					if (this.updatePending) {
						this.updatePending = false;
						this.updateData();
					}
					return;
				}
				else if (err.name === "APIIncompatible") {
					// Added error handling
					(new ErrorManager()).APIIncompatible();
				}

				this.updateInProcess = false;
				// Reshedule the update if requested
				if (this.updatePending) {
					this.updatePending = false;
					this.updateData();
				}
			}
		})();
	}

	transformFilter() {
		return {};
	}

	inform() {
		if (!this.dataInformable)
			return;

		try {
			this.func(this.data, this.totalCount, this.dataError);
		} catch (err) {
			// Nothing to do. Errorhandling has to be done in component
			console.error("Error during processing of registered function. Err: " + err);
		}
	}
}