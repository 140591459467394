/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import IsNullOrUndefined from "./Utility.js";
import { IsNullOrUndefinedOrWhitespace } from "./Utility.js";
import MachineData from '../DataManagement/MachineData.js';

export default class MachineHelper {
	constructor(machineID, onChange) {
		this.machineID = machineID;
		this.onChange = onChange;

		this.destroy = this.destroy.bind(this);
		this.getID = this.getID.bind(this);
		this.getData = this.getData.bind(this);
		this.getName = this.getName.bind(this);
		this.hasTool = this.hasTool.bind(this);
		this.hasFluid = this.hasFluid.bind(this);
		this.hasLaserSpindle = this.hasLaserSpindle.bind(this);
		this.hasEnergy = this.hasEnergy.bind(this);

		this.error = false;
		this.data = {};

		// Register the Machine
		this.machineKey = (new MachineData()).register(this.machineID, (data, error) => {
			this.data = data;
			this.error = error;

			this.onChange(this);
		});
	}

	destroy() {
		if (this.machineKey !== null) {
			(new MachineData()).unregister(this.machineKey);
			this.machineKey = null;
		}
	}

	hasError() {
		return this.error;
	}

	getID() {
		return this.machineID;
	}

	getData() {
		return this.data;
	}

	getName() {
		return IsNullOrUndefinedOrWhitespace(this.data.serialNumber) ? this.machineID : this.data.serialNumber;
	}

	/// Is there a laserSpindle in this machine?
	hasLaserSpindle() {
		if (!IsNullOrUndefined(this.data.productionSteps)) {
			for (let ps in this.data.productionSteps) {
				if (IsNullOrUndefinedOrWhitespace(this.data.productionSteps[ps].type))
					continue;
				if (this.data.productionSteps[ps].type === "LASERSPINDLE") {
					return true;
				}
			}
		}
		return false;
	}

	/// Does this machine contain at lease one Tool
	hasTool(onlyActive = false) {
		if (IsNullOrUndefined(this.data.tools))
			return false;

		if (onlyActive) {
			for (let toolID in this.data.tools) {
				if (this.data.tools[toolID].active)
					return true;
			}
			return false;
		}
		else {
			return true;
		}
	}

	/// Does this machine contain at least one fluid
	hasFluid(onlyActive = false) {
		if (IsNullOrUndefined(this.data.fluids))
			return false;

		if (onlyActive) {
			for (let fluidID in this.data.fluids) {
				if (this.data.fluids[fluidID].active)
					return true;
			}
			return false;
		}
		else {
			return true;
		}
	}

	/// Does this machine contain at least one energy net
	hasEnergy(onlyActive = false) {
		if (IsNullOrUndefined(this.data.energy))
			return false;

		if (onlyActive) {
			for (let energyID in this.data.energy) {
				if (this.data.energy[energyID].active)
					return true;
			}
			return false;
		}
		else {
			return true;
		}
	}

}