/********************************************************************************
*
* (c) 2021 - Gehring Technologies GmbH
*
* This is a component to navigate with the mobile app
*
* @author: Julian Saile (Julian.Saile@gehring.de)
*
*********************************************************************************/
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import GlobalAppData from "GlobalAppData";
import NavigatorSingleton from "../BusinessLogic/Misc/Navigator.js";

class Navigator extends React.Component {
	constructor(props) {
		super(props);

		this.reloadKey = null;
		this.redirectKey = null;
	}

	static defaultProps = {
		navigate: () => { },
		location: null
	}

	componentDidMount() {
		// Navigator
		this.redirectKey = (new NavigatorSingleton()).registerRedirect((path) => {
			this.props.navigate(path);
		});
		this.reloadKey = (new NavigatorSingleton()).registerReload(() => {
			this.props.navigate(0);
		});

		// Inform the app if the location changes
		new GlobalAppData().locationChanged(location.pathname + location.search);
	}

	componentDidUpdate(prevProps, prevState) {
		// Inform the app if the location changes
		new GlobalAppData().locationChanged(location.pathname + location.search);
	}

	componentWillUnmount() {
		if (this.redirectKey !== null) {
			(new NavigatorSingleton()).unregister(this.redirectKey);
			this.redirectKey = null;
		}
		if (this.reloadKey !== null) {
			(new NavigatorSingleton()).unregister(this.reloadKey);
			this.reloadKey = null;
		}
	}

	render() {
		return <></>;
	}
}

export default (() => {
	const Wrapper = (props) => {
		const navigate = useNavigate();
		const location = useLocation();

		return (
			<Navigator
				navigate={navigate}
				location={location}
			/>
		);
	};

	return Wrapper;
})();