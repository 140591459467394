const metric = {
    C_span: {
        name: {
            singular: 'degree Celsius'
            , plural: 'degrees Celsius'
        }
        , to_anchor: 1
    },
    mK_span: {
        name: {
            singular: 'degree milli Kelvin'
            , plural: 'degrees milli Kelvin'
        }
        , to_anchor: 1 / 1000
    },
    K_span: {
        name: {
            singular: 'degree Kelvin'
            , plural: 'degrees Kelvin'
        }
        , to_anchor: 1
    }
};

const imperial = {
    F_span: {
        name: {
            singular: 'degree Fahrenheit'
            , plural: 'degrees Fahrenheit'
        }
        , to_anchor: 1
    },
    R_span: {
        name: {
            singular: 'degree Rankine'
            , plural: 'degrees Rankine'
        }
        , to_anchor: 1
    }
};


const measure = {
    systems: {
        metric,
        imperial,
    },
    anchors: {
        metric: {
            imperial: {
                transform: function (C) {
                    return C / (5 / 9);
                },
            },
        },
        imperial: {
            metric: {
                transform: function (F) {
                    return F * (5 / 9);
                },
            },
        },
    },
};

export default measure;