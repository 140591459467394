/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is a simple contact page
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

import React from 'react';
import { matchRoutes, useLocation } from "react-router-dom";
import Layout from './Layout';

const matchCurrentPath = (path) => {
	const routes = [{ path: path }];
	const location = useLocation();
	const matches = matchRoutes(routes, location);
	if (matches === null)
		return false;
	if (matches.length > 0)
		return true;
	return false;
}

export default function LayoutRelay({ children }) {
    let nav = "";
	if (matchCurrentPath(`/Protected/Machine/Details`)) {
		nav = "MachineDetails";
	}
	if (matchCurrentPath(`/Protected/Machine/Create`)) {
		nav = "MachineCreate";
	}
	if (matchCurrentPath(`/Protected/Machine/Overview`)) {
		nav = "MachineOverview";
	}
	if (matchCurrentPath(`/Protected/Tool/Details`)) {
		nav = "ToolDetails";
	}
	if (matchCurrentPath(`/Protected/Tool/Overview`)) {
		nav = "ToolOverview";
	}
	if (matchCurrentPath(`/Protected/Tool/TypeDetails`)) {
		nav = "ToolOverview";
	}
	if (matchCurrentPath(`/Protected/Dashboard`)) {
		nav = "Dashboard";
	}
	if (matchCurrentPath(`/Protected/Order/*`)) {
		nav = "Order";
	}
	if (matchCurrentPath(`/Protected/Update`)) {
		nav = "Update";
	}
	if (matchCurrentPath(`/Protected/Extras`)) {
		nav = "Extras";
	}
	if (matchCurrentPath(`/Protected/User/Overview`)) {
		nav = "UserManagement";
	}
	if (matchCurrentPath(`/Protected/User/Details`)) {
		nav = "UserManagement";
	}
	if (matchCurrentPath(`/Protected/Evaluation/Index`)) {
		nav = "Evaluation";
	}
	if (matchCurrentPath(`/Protected/Message/Overview`)) {
		nav = "Message";
	}
	if (matchCurrentPath(`/Protected/Line/PieceDetails`)) {
		nav = "LinePieceDetails";
	}
	if (matchCurrentPath(`/Protected/Line/Details`)) {
		nav = "LineDetails";
	}
	if (matchCurrentPath(`/Protected/Line/Create`)) {
		nav = "LineCreate";
	}
	if (matchCurrentPath(`/Protected/Line/Overview`)) {
		nav = "LineOverview";
	}
	if (matchCurrentPath(`/Protected/Plant/Details`)) {
		nav = "PlantDetails";
	}
	if (matchCurrentPath(`/Protected/Plant/Create`)) {
		nav = "PlantCreate";
	}
	if (matchCurrentPath(`/Protected/Plant/Overview`)) {
		nav = "PlantOverview";
	}

	return <Layout navigationState={nav}>{children}</Layout>;
}