/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper for Strings
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import settings from 'Settings';
import Version from './Version.js';
import { v4 as uuidv4 } from 'uuid';
import getUuidByString from 'uuid-by-string';
import AbortException from '../Exceptions/AbortException';

function IsNullOrUndefined(value) {
	if (typeof value === 'undefined')
		return true;
	if (value === null)
		return true;
	return false;
}

export function IsNullOrUndefinedOrWhitespace(value) {
    if (typeof value === 'undefined')
        return true;
    if (value === null)
        return true;
    if (/^\s*$/.test(value))
        return true;
    return false;
}

export function ComputeNiceScale(min, max, ticks = 11) {
    let tiks = ticks;
    // Adjust ticks if needed
    if (tiks < 2) {
        tiks = 2
    }
    tiks -= 1;

    let range = Math.abs(max - min);
    let tempStep = range / tiks;
    if (tempStep <= 0)
        tempStep = 1;

    let mag = Math.floor(Math.log10(tempStep))
    let magPow = Math.pow(10, mag)
    let magMsd = Math.round(tempStep / magPow)
    if (magMsd < 1) {
        magMsd = 1
    }
    let stepSize = magMsd * magPow


    // build Y label array.
    // Lower and upper bounds calculations
    let lb = stepSize * Math.floor(min / stepSize)
    let ub = stepSize * Math.ceil(max / stepSize)

	return { min: lb, max: ub };
}

export function ImportAllFromDirectory(r) {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images;
}

/*  mergeSortedArrays(arrays[, keepDuplicates[, comparator[, thisArg]]])
    Merges multiple sorted arrays into a new sorted array.
    Arguments:
        - arrays: array of sorted arrays to be merged
        - keepDuplicates (optional): (true/false) whether to keep duplicate values
            Default: false
        - comparator (optional): function used to compare values
            Default: sort numbers in ascending order
            Example comparator: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
        - thisArg (optional): comparator is bound to thisArg when invoked
    Returns: a new sorted array containing all the values from the arrays
*/
export function MergeSortedArrays(arrays, keepDuplicates, comparator, thisArg) {
	// Coerce to boolean to speed up testings in some javascript engines:
	keepDuplicates = !!keepDuplicates;

	// By default, sort numbers in ascending order:
	if (!comparator) comparator = function (a, b) { return a - b; };

	var nb = arrays.length,     // Number of arrays to be merged
		iter = new Array(nb),   // Current position of iteration of each array
		next = [],              // Keep each array sorted by the value of their next element
		length = 0;             // The combined length of all arrays

	// Populate iter and next:
	for (var i = 0, arr; i < nb; i++) {
		arr = arrays[i];
		iter[i] = 0;
		if (arr.length > 0) {
			insertNextIndex(next, i, arr[0], comparator, thisArg);
		}
		length += arr.length;
	}

	// Insert index of array into next:
	function insertNextIndex(next, index, val, comparator, thisArg) {
		var i = next.length;
		while (i--) {    // Reverse loop...
			var j = next[i];
			if (comparator.call(thisArg, arrays[j][iter[j]], val) >= 0) {    // ...until we find a greater value
				break;
			}
		}
		next.splice(i + 1, 0, index);
	}


	var merged = keepDuplicates ? new Array(length) : [],
		k = 0,  // Iterate over merged
		min, val, lastVal;

	// First iteration to get a value for lastVal (for duplicate checks):
	if (!keepDuplicates && next.length > 0) {
		min = next.pop();
		arr = arrays[min];
		i = iter[min]++;
		val = arr[i];
		merged[k++] = val;
		lastVal = val;
		if (++i < arr.length) {  // If available, insert next value in next:
			insertNextIndex(next, min, arr[i], comparator, thisArg);
		}
	}

	// Merge multiple arrays:
	while (next.length > 1) {    // While there is still multiple arrays to be merged
		min = next.pop();
		arr = arrays[min];
		i = iter[min]++;
		val = arr[i];
		if (keepDuplicates || comparator.call(thisArg, lastVal, val) !== 0) {
			merged[k++] = val;
			lastVal = val;
		}
		if (++i < arr.length) {  // If available, insert next value in next:
			insertNextIndex(next, min, arr[i], comparator, thisArg);
		}
	}

	// When there remain only 1 array with unmerged values, use a faster loop:
	if (next.length > 0) {
		arr = arrays[next[0]];
		i = iter[next[0]];
		length = arr.length;

		while (i < length) { // To the end
			val = arr[i++];
			if (keepDuplicates || comparator.call(thisArg, lastVal, val) !== 0) {
				merged[k++] = val;
				lastVal = val;
			}
		}
	}

	return merged;
}

export function SafeMin(a, b) {
	if (IsNullOrUndefined(a))
		return b;
	if (IsNullOrUndefined(b))
		return a;
	return Math.min(a, b);
}

export function SafeMax(a, b) {
	if (IsNullOrUndefined(a))
		return b;
	if (IsNullOrUndefined(b))
		return a;
	return Math.max(a, b);
}

export function GetRequiredAPIVersion() {
	return new Version(settings.APIVersionMajor, settings.APIVersionMinor);
}

export function Delay(time) {
	return new Promise((resolve, reject) => setTimeout(resolve, time));
}

export function DelayExecution(func, abortSignal) {
	return new Promise((resolve, reject) => {
		if (!IsNullOrUndefined(abortSignal)) {
			if (abortSignal.aborted) {
				reject(new AbortException());
				return;
			}
		}

		let timeout = null;
		let eventHandler = () => {
			abortSignal.removeEventListener('abort', eventHandler);

			if (!IsNullOrUndefined(timeout))
				clearTimeout(timeout);
			timeout = null;

			reject(new AbortException());
		};

		timeout = setTimeout(() => {
			if (!IsNullOrUndefined(abortSignal)) {
				abortSignal.removeEventListener('abort', eventHandler);
			}

			const res = func();
			resolve(res);
			return;
		}, 0);

		if (!IsNullOrUndefined(abortSignal)) {
			abortSignal.addEventListener('abort', eventHandler);
		}
	});
}

export function AsyncExecution(func, abortSignal) {
	return new Promise((resolve, reject) => {
		if (!IsNullOrUndefined(abortSignal)) {
			if (abortSignal.aborted) {
				resolve();
				return;
			}
		}

		let timeout = null;
		let eventHandler = () => {
			abortSignal.removeEventListener('abort', eventHandler);

			if (!IsNullOrUndefined(timeout))
				clearTimeout(timeout);
			timeout = null;

			resolve();
		};

		timeout = setTimeout(() => {
			timeout = null;
			if (!IsNullOrUndefined(abortSignal)) {
				abortSignal.removeEventListener('abort', eventHandler);
			}

			func();
			resolve();
			return;
		}, 0);

		if (!IsNullOrUndefined(abortSignal)) {
			abortSignal.addEventListener('abort', eventHandler);
		}
	});
}

export function TimeoutPromise(promise, time) {
	let timer;
	let k = [];
	k.push(promise);
	k.push(new Promise((resolve, reject) => timer = setTimeout(() => { reject({ name: "Timeout" }); }, time)));
	return Promise.race(k).finally(() => clearTimeout(timer));
}

export function ColourToRGB(str) {
	var hash = 0;
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}

	let rgb = {};
	rgb.r = (hash >> (0 * 8)) & 0xFF;
	rgb.g = (hash >> (1 * 8)) & 0xFF;
	rgb.b = (hash >> (2 * 8)) & 0xFF;

	return rgb;
}

export function getSlice(time, startTime, endTime, numSlices) {
	const totalTime = (endTime - startTime) > 0 ? (endTime - startTime) : 1;
	const sliceTime = totalTime / numSlices;
	const normalizedTime = (time - startTime) / sliceTime;

	if (normalizedTime < 0)
		return { idx: -1, factor: 0 };
	if (normalizedTime >= numSlices)
		return { idx: numSlices, factor: 0 };

	const factor = normalizedTime % 1;
	const idx = Math.floor(normalizedTime);

	return { idx: idx, factor: factor };
}

export function downloadData(saveas, data) {
	const url = window.URL.createObjectURL(data);
	const anchor = document.createElement("a");
	anchor.style.display = 'none';
	anchor.href = url;
	anchor.download = saveas;
	anchor.click();

	// Clean up
	window.URL.revokeObjectURL(url);
}

export function generateUUID() {
	return uuidv4();
}

export function generateUUIDFromString(str) {
	return getUuidByString(str, 5);
}

export function zeroPad(num, places) {
	return String(num).padStart(places, '0');
}

export function vec3Mult(a, b) {
	return {
		x: a.x * b.x,
		y: a.y * b.y,
		z: a.z * b.z
	};
}

export function vec3MultReal(a, b) {
	return {
		x: a.x * b,
		y: a.y * b,
		z: a.z * b
	};
}

export function vec3Add(a, b) {
	return {
		x: a.x + b.x,
		y: a.y + b.y,
		z: a.z + b.z
	};
}

export function removeNullOrUndefined(obj) {
	/**
	 * More accurately check the type of a JavaScript object
	 * @param  {Object} obj The object
	 * @return {String}     The object type
	 */
	function getType(obj) {
		return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
	}

	function removeArrays() {
		return obj.filter((e) => {
			if (IsNullOrUndefined(e))
				return false;
			return true;
		});
	}

	function removeObjects() {
		return Object.keys(obj)
			.filter((k) => {
				if (IsNullOrUndefined(obj[k]))
					return false;
				return true;
			})
			.reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
	}

	// Get the object type
	let type = getType(obj);

	// Compare based on type
	if (type === 'array') return removeArrays();
	if (type === 'object') return removeObjects();
	return obj;

}

export default IsNullOrUndefined;