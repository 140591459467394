/********************************************************************************
*
* (c) 2023 - Gehring Technologies GmbH
*
* This is a simple function which creates a icon
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

export default (props) => {
    return (
        <svg
            viewBox="0 0 257.38401 270.93333"
            className="svgIcon"
        >
            <path d="M 116.32909,246.34184 H 0 V 0 h 102.19787 c 31.71741,0 98.40339,74.232943 98.40339,98.661478 v 27.584962 c -6.38097,-2.70887 -13.16903,-4.36287 -20.07897,-4.79814 v -14.06362 c 0,-42.136603 -60.12887,-25.200749 -60.12887,-25.200749 0,0 15.2286,-61.578547 -26.460439,-61.578547 H 20.079482 V 225.88087 H 103.1324 c 3.07757,7.51982 7.56028,14.47021 13.19669,20.45892 z m 79.7741,-98.55291 c -34.02072,-0.15363 -61.74087,27.35453 -61.90371,61.28051 -0.15363,33.99359 27.35504,61.72757 61.28052,61.86275 34.00741,0.20483 61.72756,-27.31407 61.8904,-61.25338 0,-0.10241 0.0133,-0.20483 0.0133,-0.30724 0.10242,-33.91216 -27.39498,-61.45667 -61.28052,-61.56472 z m 35.6737,71.82107 h -25.53922 v 25.68823 H 185.83353 V 219.61 H 160.2943 v -20.45842 h 25.53923 v -25.68824 h 20.40414 v 25.68824 h 25.53922 z" />
        </svg>
    );
}