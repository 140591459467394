import React from 'react'
import { Navigate } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import GlobalAppData from 'GlobalAppData';

export default class AuthorizeRoute extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated } = this.state;

        if (!ready) {
            return <></>;
        } else {
            if (authenticated || (new GlobalAppData()).isWebView()) {
                return this.props.children;
            }
            else {
                const redirectUrl = `/${ApplicationPaths.ApiAuthorization}/${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(window.location.href)}`;
                return <Navigate to={redirectUrl} replace />
            }
        }
    }
}
