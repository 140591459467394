/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This JavaScript file is used to display a Export-Button
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import React from "react";

import Button from "./Button";
import Navigator from "../../BusinessLogic/Misc/Navigator";

export default class NavButton extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.handleOnClick = this.handleOnClick.bind(this);
	}

	static defaultProps = {
		to: ""
	}

	static get VARIANT() {
		return Button.VARIANT;
	}

	static get ICON() {
		return Button.ICON;
	}

	static get SIZE() {
		return Button.SIZE;
	}

	handleOnClick() {
		(new Navigator()).redirect(this.props.to);
	}

	render() {
		return <Button {...this.props}
			onClick={this.handleOnClick}
		/>;
	}
}