/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple management for the production time data
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import UniqueIDGenerator from '../../Misc/UniqueIDGenerator.js';
import IsNullOrUndefined from "../../Misc/Utility.js";
import { AsyncExecution } from "../../Misc/Utility.js";
import DataProvider from "./DataProvider";

export default class DataArrayProvider {
	constructor(dataProvider : DataProvider) {
		this.dataProvider = dataProvider;

		this.keyGen = new UniqueIDGenerator();
		this.abortController = new AbortController();

		this.functions = new Map();

		this.register = this.register.bind(this);
		this.unregister = this.unregister.bind(this);
		this.inform = this.inform.bind(this);
		this.destroy = this.destroy.bind(this);
		this.getKey = this.getKey.bind(this);
	}

	destroy() {
		this.functions.forEach((f) => {
			f.keys.forEach((e) => {
				this.dataProvider.unregister(e);
			});
		});
		this.functions.clear();

		this.abortController.abort();
	}

	getKey(id) {
		return JSON.stringify(id);
	}

	register(idList, func) {
		const key = this.keyGen.generate();

		let obj = {
			keys: [],
			func: func,
			error: new Set(),
			received: new Set(),
			data: [],
			changed: idList.length > 0 ? false : true
		};
		this.functions.set(key, obj);

		// Register new data
		idList.forEach((e) => {
			const eKey = this.getKey(e);

			// Initialise the received list
			obj.received.add(eKey);

			let d = {
				id: e,
				data: null
			};

			const dataKey = this.dataProvider.register(e, (data, error) => {
				if (obj.received.size > 0)
					obj.received.delete(eKey);

				if (error)
					obj.error.add(eKey);
				else {
					if (obj.error.size > 0)
						obj.error.delete(eKey);
				}

				d.data = data;

				obj.changed = true;

				AsyncExecution(() => { this.inform(key); }, this.abortController.signal);
			});

			obj.data.push(d);
			obj.keys.push(dataKey);
		});

		// Inform the Function
		AsyncExecution(() => { this.inform(key); }, this.abortController.signal);

		return key;
	}

	unregister(key) {
		let f = this.functions.get(key);
		if (IsNullOrUndefined(f))
			return;

		f.keys.forEach((e) => {
			this.dataProvider.unregister(e);
		});

		if (this.functions.delete(key))
			this.keyGen.release(key);
	}

	inform(key) {
		const f = this.functions.get(key);
		if (IsNullOrUndefined(f))
			return;

		// Something changed?
		if (!f.changed)
			return;
		f.changed = false;

		// Available?
		if (f.received.size > 0)
			return;

		if (f.error.size > 0) {
			try {
				f.func([], true);
			} catch (err) {
				// Nothing to do. Errorhandling has to be done in component
				console.error("Error during processing of registered function. Err: " + err);
			}
		}
		else {
			try {
				f.func(f.data, false);
			} catch (err) {
				// Nothing to do. Errorhandling has to be done in component
				console.error("Error during processing of registered function. Err: " + err);
			}
		}
	}
}