/********************************************************************************
*
* (c) 2020 - Gehring Technologies GmbH
*
* This is a simple helper to convert a timerange to a usable format
*
* @author: Stephan Starke (Stephan.Starke@gehring-group.com)
*
*********************************************************************************/

var namespace = 'accessTracking_mixin';

module.exports = accessTrackingPlugin;

function accessTrackingPlugin() {
	var access = this.createStore(this.storage, null, this._namespacePrefix + namespace)
	this.exceptions = [];

	return {
		set: accessTracking_set,
		get: accessTracking_get,
		remove: accessTracking_remove,
		removeOldestKey: removeOldestKey,
		setExceptions: setExceptions
	}

	function accessTracking_set(super_fn, key, val) {
		if (!this.hasNamespace(namespace)) {
			if (!this.exceptions.includes(key)) {
				let accesses = access.get("AccessList");
				if (typeof accesses !== 'undefined')
					accesses = accesses.filter(x => x !== key);
				else
					accesses = [];
				accesses.push(key);
				access.set("AccessList", accesses);
			}
		}
		try {
			return super_fn();
		} catch (e) {
			// Remove olf access
			if (!this.hasNamespace(namespace)) {
				if (!this.exceptions.includes(key)) {
					let accesses = access.get("AccessList");
					if (typeof accesses !== 'undefined')
						accesses = accesses.filter(x => x !== key);
					else
						accesses = [];
					access.set("AccessList", accesses);
				}
			}

			throw e;
		}
	}

	function accessTracking_get(super_fn, key) {
		if (!this.hasNamespace(namespace)) {
			if (!this.exceptions.includes(key)) {
				let accesses = access.get("AccessList");
				if (typeof accesses !== 'undefined')
					accesses = accesses.filter(x => x !== key);
				else
					accesses = [];
				accesses.push(key);
				access.set("AccessList", accesses);
			}
		}
		return super_fn();
	}

	function accessTracking_remove(super_fn, key) {
		if (!this.hasNamespace(namespace)) {
			if (!this.exceptions.includes(key)) {
				let accesses = access.get("AccessList");
				if (typeof accesses !== 'undefined')
					accesses = accesses.filter(x => x !== key);
				else
					accesses = [];
				access.set("AccessList", accesses);
			}
		}
		return super_fn();
	}

	function removeOldestKey(_) {
		var accesses = access.get("AccessList");
		if (typeof accesses === 'undefined' || accesses.length === 0)
			return false;
		var key;
		while (typeof key === 'undefined' || this.exceptions.includes(key)) {
			if (accesses.length === 0)
				return false;
			key = accesses.shift();
		}

		// Remove key
		this.remove(key);
		return true;
	}

	function setExceptions(_, exceptions) {
		this.exceptions = exceptions;
	}
}