/********************************************************************************
*
* (c) 2019 - Gehring Technologies GmbH
*
* This is a simple helper to convert from the metric unit system to the user unit system
*
* @author: Stephan Starke (Stephan.Starke@gehring.de)
*
*********************************************************************************/

import Localizer from '../Misc/Localizer.js';
import IsNullOrUndefined from '../Misc/Utility.js';
import configureMeasurements, { area } from 'convert-units';

var instance;
export default class AreaUnitSystemConverter {
	constructor() {
		if (instance) {
			return instance;
		}
		instance = this;

		this.convert = configureMeasurements({ area });

		// UnitSystem
		this.unitSystemDefinition = "metric";
	}

	// Destroy the class
	static destroySingleton() {
		instance = null;
	}

	// Singleton
	static getSingleton() {
		return instance;
	}

	setDefaultUnitSystem() {
		this.setUnitSystem("metric");
	}

	// Set the UnitSystem to use. Possible values are "metric" and "imperial"
	setUnitSystem(_unitSystem) {
		const unitSystem = _unitSystem.toLowerCase();

		switch (unitSystem) {
			case 'metric':
				this.unitSystemDefinition = "metric";
				break;
			case 'imperial':
				this.unitSystemDefinition = "imperial";
				break;
			default:
				this.unitSystemDefinition = "metric";
				if (process.env.NODE_ENV === 'development') {
					console.warn("UnitSystem '" + unitSystem + "' is invalid. Using default.");
				}
				break;
		}
	}
	
	/** Format a length to the selected unit system (Best choice)
	 * @param value The Value to use
	 * @param targetUnit The target unit system
	 * @param sourceUnit The source unit system. Defaults to "m"
	 * @return { value, unit }
	 */
	format(value, targetUnit, sourceUnit) {
		if (IsNullOrUndefined(sourceUnit))
			sourceUnit = "m2";

		switch (this.unitSystemDefinition) {
			case "imperial": {
				if (typeof targetUnit === 'undefined') {
					// This will stay in the same system. So convert to the system first
					let r = this.convert(this.convert(value).from(sourceUnit).to("ft2")).from("ft2").toBest({ exclude: ['ac'] });
					if (IsNullOrUndefined(r))
						r = this.convert(this.convert(value).from(sourceUnit).to("ft2")).from("ft2").toBest({ cutOffNumber: 0, exclude: ['ac'] });
					return { value: r.val, unit: r.unit };
				}
				else {
					// This will stay in the same system. So convert to the system first
					const r = this.convert(this.convert(value).from(sourceUnit).to("ft2")).from("ft2").to(targetUnit);
					return { value: r, unit: targetUnit };
				}
			}
			case "metric":
			default: {
				if (typeof targetUnit === 'undefined') {
					// This will stay in the same system. So convert to the system first
					let r = this.convert(this.convert(value).from(sourceUnit).to("m2")).from("m2").toBest({ exclude: ['nm2', 'cm2', 'ha'] });
					if (IsNullOrUndefined(r))
						r = this.convert(this.convert(value).from(sourceUnit).to("m2")).from("m2").toBest({ cutOffNumber: 0, exclude: ['nm2', 'cm2', 'ha'] });
					return { value: r.val, unit: r.unit };
				}
				else {
					// This will stay in the same system. So convert to the system first
					const r = this.convert(this.convert(value).from(sourceUnit).to("m2")).from("m2").to(targetUnit);
					return { value: r, unit: targetUnit };
				}
			}
		}
	}

	/**
	 * Get the default unit
	 */
	getDefaultUnit() {
		switch (this.unitSystemDefinition) {
			case 'metric':
				return "m2";
			case 'imperial':
				return "ft2";
			default:
				return "m2";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 */
	humanizeShortUnit(unit) {
		switch (unit) {
			// Metric
			case "nm2":
				return "nm²";
			case "μm2":
				return "µm²";
			case "mm2":
				return "mm²";
			case "cm2":
				return "cm²";
			case "m2":
				return "m²";
			case "km2":
				return "km²";

			// Imperial
			case "in2":
				return "in²";
			case "ft2":
				return "ft²";
			case "yd2":
				return "yd²";
			case "ac":
				return "ac";
			case "mi2":
				return "mi²";

			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}

	/** Get the corresponding Unit
	 * @parma unit The unit declaration
	 * @param singular Get the singular unit
	 */
	humanizeUnit(unit, singular) {
		switch (unit) {
			// Metric
			case "nm2":
				{
					if(singular === true)
						return (new Localizer()).get("Square Nanometer");
					else
						return (new Localizer()).get("Square Nanometers");
				}
			case "μm2":
				{
					if (singular === true)
						return (new Localizer()).get("Square Micrometer");
					else
						return (new Localizer()).get("Square Micrometers");
				}
			case "mm2":
				{
					if (singular === true)
						return (new Localizer()).get("Square Millimeter");
					else
						return (new Localizer()).get("Square Millimeters");
				}
			case "cm2":
				{
					if (singular === true)
						return (new Localizer()).get("Square Centimeter");
					else
						return (new Localizer()).get("Square Centimeters");
				}
			case "m2":
				{
					if (singular === true)
						return (new Localizer()).get("Square Meter");
					else
						return (new Localizer()).get("Square Meters");
				}
			case "km2":
				{
					if (singular === true)
						return (new Localizer()).get("Square Kilometer");
					else
						return (new Localizer()).get("Square Kilometers");
				}

			// Imperial
			case "in2":
				{
					if (singular === true)
						return (new Localizer()).get("Square Inch");
					else
						return (new Localizer()).get("Square Inches");
				}
			case "ft2":
				{
					if (singular === true)
						return (new Localizer()).get("Square Foot");
					else
						return (new Localizer()).get("Square Feet");
				}
			case "yd2":
				{
					if (singular === true)
						return (new Localizer()).get("Square Yard");
					else
						return (new Localizer()).get("Square Yards");
				}
			case "ac":
				{
					if (singular === true)
						return (new Localizer()).get("Acre");
					else
						return (new Localizer()).get("Acres");
				}
			case "mi2":
				{
					if (singular === true)
						return (new Localizer()).get("Square Mile");
					else
						return (new Localizer()).get("Square Miles");
				}
			default:
				console.warn("Human readable unit for '" + unit + "' not found.");
				return "";
		}
	}
}